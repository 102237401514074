import React from 'react';
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import { useWindowSize } from 'usehooks-ts';
import { twMerge } from 'tailwind-merge';
import { ImArrowDown, ImArrowRight } from 'react-icons/im';

import { Step1, Step2, Step3, Step4 } from './LineOnboarding';

type Props = {
    isOpen?: boolean;
    onToggle?: () => void;
    onClickStartConnect?: () => void;
};

function LineConnectPreparationModal(props: Props) {
    const { isOpen, onToggle, onClickStartConnect } = props;

    const { width } = useWindowSize();
    const sm = 640;

    const stepContainerTwClasses =
        'relative flex flex-col border border-neutral-200 rounded-md p-4';
    const stepBaseTwClasses =
        'absolute top-[calc(100%_+_2px)] left-1/2 -translate-x-1/2 translate-y-0';
    const stepEvenTwClasses =
        'sm:hidden 2xl:block 2xl:top-1/2 2xl:translate-x-0 2xl:-translate-y-1/2 2xl:left-[calc(100%_+_6px)]';
    const stepOddTwClasses =
        'sm:top-1/2 sm:translate-x-0 sm:-translate-y-1/2 sm:left-[calc(100%_+_6px)]';

    const ArrowIcon = width > sm ? ImArrowRight : ImArrowDown;

    return (
        <Modal
            isOpen={isOpen}
            toggle={onToggle}
            size="lg"
            className="max-w-[98vw] px-4"
        >
            <ModalHeader
                toggle={onToggle}
                className="items-center [&_.modal-title]:flex-1"
            >
                <h2 className="flex-1 text-center">
                    เพิ่ม FocusOne
                    เป็นเพื่อนเพื่อรับการแจ้งเตือนที่สะดวกและรวดเร็วผ่าน Line
                    Chat
                </h2>
            </ModalHeader>
            <ModalBody className="grid grid-cols-1 gap-10 sm:grid-cols-2 2xl:grid-cols-4">
                <div className={stepContainerTwClasses}>
                    <Step1 />

                    <ArrowIcon
                        size={32}
                        color="#F7980A"
                        className={twMerge(stepBaseTwClasses, stepOddTwClasses)}
                    />
                </div>
                <div className={stepContainerTwClasses}>
                    <Step2 />

                    <ArrowIcon
                        size={32}
                        color="#F7980A"
                        className={twMerge(
                            stepBaseTwClasses,
                            stepEvenTwClasses
                        )}
                    />
                </div>
                <div className={stepContainerTwClasses}>
                    <Step3 />

                    <ArrowIcon
                        size={32}
                        color="#F7980A"
                        className={twMerge(stepBaseTwClasses, stepOddTwClasses)}
                    />
                </div>
                <div className={stepContainerTwClasses}>
                    <Step4 />
                </div>
            </ModalBody>
            <ModalFooter className="justify-center sticky bottom-0 bg-white border-t">
                <Button
                    size="lg"
                    className="bg-[#06C755] border-0 py-3 px-5"
                    onClick={onClickStartConnect}
                >
                    เริ่มต้น Connect!
                </Button>
            </ModalFooter>
        </Modal>
    );
}

export default LineConnectPreparationModal;
