import axios from "axios";
import { WorkflowInbox } from "models/workflow/WorkflowInbox";
import { WorkflowInboxParams } from "../schemas/Workflow.shema";
import { ResponseList } from "models/Response";

export function retrieveWorkflowInbox(params: WorkflowInboxParams) {
    return axios.get<ResponseList<WorkflowInbox>>('/v4/WorkflowInbox/Get', {
        params,
        headers: {
            Authorization: 'Bearer ' + localStorage.getItem('WF_APPLICATION')
        }
    });
}