import { Action, Reducer } from 'redux';
import { AppThunkAction } from '../';
import * as SigIn from '../../models/auth/SignIn';

interface RequestFunctionAction {
    type: 'REQUEST_SIGNIN';
}

interface ReceiveFunctionAction {
    type: 'RECEIVE_SIGNIN';
    model: SigIn.ResponseSignIn;
}

type KnownAction = RequestFunctionAction | ReceiveFunctionAction

export const actionCreators = {
    requestFunction: (username: string, password: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        console.log('start call api username ' + username + ' and password ' + password)
        const formData = new FormData();
        formData.append("username", username);
        formData.append("password", password);

        if (appState) {

            fetch(`/v1/auth/login`, {
                method: 'POST',
                body: formData
            })
                .then(response => response.json() as Promise<SigIn.ResponseSignIn>)
                .then(data => {
                    console.log('RECEIVE_SIGNIN', data)
                    localStorage.setItem('WF_APPLICATION', JSON.stringify(data));
                    dispatch({ type: 'RECEIVE_SIGNIN', model: data });
                })
                .then(error => {
                    
                    console.log('REQUEST_SIGNIN', error)
                    dispatch({ type: 'REQUEST_SIGNIN' });
                });
            dispatch({ type: 'REQUEST_SIGNIN' });
        }
    }
};

const unloadedState: SigIn.SignInState = { isLoading: false, isLoginSuccess:false, response: undefined };

export const reducer: Reducer<SigIn.SignInState> = (state: SigIn.SignInState | undefined, incomingAction: Action): SigIn.SignInState => {
    if (state == undefined) {
        console.log('reducer state is undefined')
        return unloadedState;
    }

    const action = incomingAction as KnownAction;
    switch (action.type) {

        case 'REQUEST_SIGNIN':
            console.log('reducer RECEIVE_SIGNIN')
            return {
                isLoading: true,
                isLoginSuccess: false,
            };
        case 'RECEIVE_SIGNIN':
            console.log('reducer RECEIVE_SIGNIN', action.model)
            return {
                isLoading: false,
                isLoginSuccess : true,
                response: action.model
            };

        default: return state;
    }
};