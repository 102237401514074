import React, { useState } from 'react'
import { Button, Table } from "react-bootstrap";
import { Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import { FiMoreHorizontal } from 'react-icons/fi';
import { FaCheck, FaClock, FaTimes } from "react-icons/fa";
import * as ModelSearchWorkflowInbox from '../../models/workflow/SearchWorkflowInbox';
import BorderGrid from '../../common/BorderGrid';
import { formatDMY } from '../../modules/Common';

type align_ = "left" | "center" | "right";

interface Props {
    checkresponse: boolean,
    formData: Array<ModelSearchWorkflowInbox.ResponseWorkflowInboxValue>,
}

interface ModalData {
    positionGroupName: string,
    employeeId?: string,   // approveCode
    employeeName?: string,   // approveName
    workflowInboxStatus: string,
    description: string,
    approvedOn: string,
}

interface WorkflowInboxInfoModalState {
    isToggleModal: boolean,
    tableData?: Array<ModalData>
}

const WorkflowInboxInformationMobileTable = (props: Props) => {
    const [workflowInboxInfoModalData, setWorkflowInboxInfoModalData] = useState<WorkflowInboxInfoModalState>({ isToggleModal: false });

    const { checkresponse, formData } = props;
    //console.log("[props] form data =", formData)

    function prepareMoreDetailData(index: number): ModalData[] {
        const listApproveDetailArr: Array<ModelSearchWorkflowInbox.WorkflowInboxValue> = formData['listApprovalDetail'];
        //console.log("list approve detail =", listApproveDetailArr)

        const listApproveDetail = listApproveDetailArr[index];
        //console.log("list approve detail =", listApproveDetail)

        const modalDataArr: Array<ModalData> = [];
        modalDataArr.push({
            positionGroupName: listApproveDetail.positiongroupname,
            workflowInboxStatus: listApproveDetail.status,
            description: listApproveDetail.reason || "-",
            approvedOn: listApproveDetail.status.toUpperCase() === "APPROVED" || listApproveDetail.status.toUpperCase() === "CANCEL"
                ? formatDMY(String(listApproveDetail.updatedOnFormatDateTime))
                : "-",
        })

        return modalDataArr;
    }

    const listApprovalDetailStatusWaiting = {
        width: '100%',
        backgroundColor: 'rgb(249, 115, 22)',
        color: '#FFF'
    };

    const listApprovalDetailStatusApprove = {
        width: '100%',
        backgroundColor: '#268E6C',
        color: '#FFF'
    };

    const listApprovalDetailStatusCancel = {
        width: '100%',
        backgroundColor: '#D7373F',
        color: '#FFF'
    };

    const listApprovalDetailStatusDefaultColor = {
        width: '100%',
        backgroundColor: '#1473E6',
        color: '#FFFFFF'
    };

    const listEmployeePositionGroupTableRow = {
        width: '100%',
        display: 'flex',
        justifyContent: 'space-between'
    }

    const moreDetail: React.CSSProperties = {
        borderLeftStyle: 'hidden',
    }

    return (
        <React.Fragment>
            <Table responsive hover size="lg" className="table-bordered">
                {!checkresponse && formData['listApprovalDetail'].map((item: ModelSearchWorkflowInbox.WorkflowInboxValue, idx: number) => {
                    const displayStatus =
                        item.status === 'CANCEL'
                            ? 'Rejected'
                            : item.status.charAt(0) + item.status.toLowerCase().slice(1);

                    return (
                        <tbody key={idx}>
                            <tr
                                key={'1-' + idx}
                                style={item.status == "APPROVED"
                                    ? listApprovalDetailStatusApprove
                                    : item.status == "CANCEL"
                                        ? listApprovalDetailStatusCancel
                                        : listApprovalDetailStatusWaiting}
                            >
                                <td className="align-middle">{idx + 1}</td>
                                <td className="align-middle">
                                    {item.positiongroupname}
                                </td>
                                <td
                                    style={moreDetail}
                                    className="w-fit"
                                    onClick={() => setWorkflowInboxInfoModalData({ isToggleModal: true, tableData: prepareMoreDetailData(idx) })}
                                >
                                    <div className="flex justify-end">
                                        <div className={`flex gap-2 items-center text-white` /* + `${item.status === 'APPROVED' ? 'text-white' : item.status === 'CANCEL' ? 'text-[#D7373F]' : 'text-orange-500'}` */}>
                                            {
                                                item.status === 'APPROVED'
                                                    ? <FaCheck />
                                                    : item.status === 'CANCEL'
                                                        ? <FaTimes />
                                                        : <FaClock />
                                            }
                                            <span>{displayStatus}</span>
                                        </div>
                                        <button className="ml-4 p-1 border rounded-md w-9 h-9">
                                            <FiMoreHorizontal
                                                size={18}
                                                color="#FFF"
                                            />
                                        </button>
                                    </div>
                                </td>
                            </tr>
                            <tr key={'2-' + idx}>
                                <td></td>
                                <td colSpan={5}>
                                    <table style={{ width: '100%' }} className="table-borderless">
                                        {(!checkresponse)
                                            ? formData['listApprovalDetail'][idx]['listEmployeeInPositionGroup']
                                                .map((subitem: ModelSearchWorkflowInbox.listEmployeeInPositionGroup, subidx) =>
                                                    <tbody key={subidx}>
                                                        <tr key={subidx} style={listEmployeePositionGroupTableRow}>
                                                            <td><b>{subitem.empid}</b></td>
                                                            <td style={{ color: '#4B4B4B' }}>{subitem.empname}</td>
                                                            {/*<td className="text-center" style={{ width: '10%' }}></td>*/}
                                                            {/*<td style={{ width: '30%' }}></td>*/}
                                                            {/*<td style={{ width: '25%' }}></td>*/}
                                                        </tr>
                                                    </tbody>
                                                )
                                            : null}
                                    </table>
                                </td>
                            </tr>
                        </tbody>
                    );
                })}
            </Table>

            {/*More detail modal*/}
            <Modal isOpen={workflowInboxInfoModalData.isToggleModal} toggle={() => setWorkflowInboxInfoModalData({ isToggleModal: false })} size="lg" backdrop="static" keyboard={false}>
                <ModalHeader toggle={() => setWorkflowInboxInfoModalData({ isToggleModal: false })}>More Detail</ModalHeader>
                <ModalBody>
                    {workflowInboxInfoModalData.tableData !== undefined &&
                        workflowInboxInfoModalData.tableData.length !== 0 &&
                        workflowInboxInfoModalData.tableData.map((data: ModalData, index) => {
                            const isApproved = data.workflowInboxStatus.toUpperCase() === "APPROVED";
                            const isCancel = data.workflowInboxStatus.toUpperCase() === "CANCEL";
                            const statusStyle: React.CSSProperties =
                                (isApproved)
                                    ? { color: '#268E6C' }
                                    : (isCancel)
                                        ? { color: '#D7373F' }
                                        : { color: '#FF8F00' };
                            return (
                                <BorderGrid key={index}>
                                    <BorderGrid.Row>
                                        <BorderGrid.Col>
                                            <div style={{ color: '#1473E6' }}>
                                                <b>{data.positionGroupName}</b>
                                            </div>
                                            <div style={statusStyle}>
                                                {isApproved
                                                    ? <FaCheck />
                                                    : isCancel
                                                        ? <FaTimes />
                                                        : <FaClock />}
                                                <span style={{ marginLeft: '5px' }}>{data.workflowInboxStatus}</span>
                                            </div>
                                        </BorderGrid.Col>
                                    </BorderGrid.Row>
                                    <BorderGrid.Row>
                                        <BorderGrid.Col>
                                            <div>
                                                <label style={{ color: '#4B4B4B' }}>Description</label>
                                            </div>
                                            <div>
                                                <span>{data.description}</span>
                                            </div>
                                        </BorderGrid.Col>
                                    </BorderGrid.Row>
                                    <BorderGrid.Row>
                                        <BorderGrid.Col>
                                            <div>
                                                <label style={{ color: '#4B4B4B' }}>Approve Date</label>
                                            </div>
                                            <div>
                                                <span>{data.approvedOn}</span>
                                            </div>
                                        </BorderGrid.Col>
                                    </BorderGrid.Row>
                                </BorderGrid>
                            )
                        })
                    }
                </ModalBody>
                <ModalFooter>
                    <Button className="bg-[#6c757d]" variant="secondary" onClick={() => setWorkflowInboxInfoModalData({ isToggleModal: false })}>Cancel</Button>
                </ModalFooter>
            </Modal>
        </React.Fragment>
    )
}

export default WorkflowInboxInformationMobileTable