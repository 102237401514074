import * as React from "react";
import { connect } from "react-redux";
import { Button, Row, Col, Tabs, Tab } from "react-bootstrap";
import { FaPlusCircle, FaArrowCircleLeft } from "react-icons/fa";
import { Link, RouteComponentProps } from "react-router-dom";
import Swal from 'sweetalert2'

import WorkflowComponent from './WorkflowComponent'
import TaskComponent from './TaskComponent'

import { ApplicationState } from "../../store";
import * as createWorkflowModels from '../../models/workflow/CreateWorkflow'
import * as createWorkflowStore from '../../store/workflow/CreateWorkflow'

import * as searchWorkflowStore from '../../store/workflow/SearchWorkflow'
import * as searchWorkflowModels from '../../models/workflow/SearchWorkflow'

import * as AutocompleteWorkflowGroupStore from '../../store/autocomplete/WorkflowGroup'
import * as ModelsWorkflow from '../../models/autocomplete/WorkflowGroup'

import * as AppState from '../../store/AppState'
import * as ModelsAppState from '../../models/AppState'
type AppStateProps = ModelsAppState.AppState & typeof AppState.actionCreators

// รับค่าจากหน้าหลัก
type Props = {};
type ComposedProps = Props & RouteComponentProps;
type createworkflowProps = createWorkflowModels.CreateWorkflowState & typeof createWorkflowStore.actionCreators
type searchworkflowProps = searchWorkflowModels.SearchWorkflowState & typeof searchWorkflowStore.actionCreators
type autocompleteWorkflowGroupProps = ModelsWorkflow.GetGroupState & typeof AutocompleteWorkflowGroupStore.actionCreators


export interface conditionTask {
    obj_id: string,
    wf_id: string,
    position_group_id: string,
    parameter_code: string,
    parameter_name: string,
    parameter_value: string,
    parameter_operator: string
}

export interface Task {
    wf_id: string,
    task_type_id: string,
    task_id: string,
    position_group_id: string,
    position_group_name: string,
    child: string,
    y_next_task: string,
    n_next_task: string,
    chg_status_to_table: string,
    chg_status_to_value: string,
    chg_status_where: string,
    condition_list: [conditionTask],
}

export interface Conditionlist {
    wf_id: string,
    wf_name: string,
    created_by: string,
    created_on: string,
    updated_by: string,
    updated_on: string,
    active: string,
    wf_conditoin_list: [],
    task_list: []
}

class AddWorkflow extends React.Component<any, { active: string, mode: string, fixBugScreen: boolean, requestConfig: boolean, requestTask: boolean, wf_conditoin_list: any, task_list: any, workflow_value: any, check_workflow: boolean, workflowID: any, isready: boolean, responseSearch: any, checkSearch: boolean, isSettW: boolean, isSetT: boolean, company?: string },
    createworkflowProps & ComposedProps & autocompleteWorkflowGroupProps & AppStateProps> {
    constructor(props: any) {
        super(props);

        this.blindConfig = this.blindConfig.bind(this)
        this.blindTask = this.blindTask.bind(this)

        this.state = {
            requestConfig: false,
            requestTask: false,
            wf_conditoin_list: "",
            task_list: [],
            workflow_value: "",
            check_workflow: true,
            workflowID: "",
            isready: true,
            responseSearch: "",
            checkSearch: false,
            isSettW: false,
            isSetT: false,
            fixBugScreen: false,
            mode: "",
            active: "",
            company: undefined
        }

    }

    componentDidMount() {
        this.props.updateUrl(this.props.location.pathname)
        var company = this.props.company
        if (company != this.state.company) {
            this.setState({
                company: company
            });
        }
        this.props.requestWorkflowgroup("", "CLEAR")
        const { state, statusSearch, responseSearch } = this.props.location
        console.log(state);
        this.setState({ mode: state.mode })
        if (state && this.state.isready == true) {
            console.log(state);
            this.props.requestsearchworkflow(this.state.isready, "POST", state.wfid, company)
            this.setState({
                isready: false,
                workflowID: state.wfid,
                checkSearch: true
            })
        }
    }

    async onsave() {
        this.setState((prevstate) => ({
            requestConfig: true,
            requestTask: true
        }))
        //console.log("this.state.task_list")
        //console.log(this.state.wf_conditoin_list)
        //console.log(this.state.task_list)
        document.getElementsByClassName('Loader__background')[0].className = "Loader__background show";

    }

    onsuccess = () => {

        var checkRepeat: any = []
        var showRepeat: any = []

        for (let obj of this.state.task_list) {
            for (let ele of this.state.task_list) {
                if (obj == ele)
                    continue;
                if (ele.position_group_id === obj.position_group_id) {
                    checkRepeat.push(obj);
                    break;
                }
            }
        }

        if (checkRepeat != "") {
            showRepeat = checkRepeat.reduce((ac, a) => ac.find(x => x.position_group_id === a.position_group_id) ? [...ac] : [...ac, a], []);
        }

        if (this.state.wf_conditoin_list.wf_id == "" || this.state.wf_conditoin_list.wf_name == "") {
            document.getElementsByClassName('Loader__background')[0].className = "Loader__background hide";
            Swal.fire({
                title: 'ไม่สำเร็จ!',
                text: 'โปรดกรอกข้อมูลส่วน Workflow',
                icon: 'warning',
                showCancelButton: false,
                confirmButtonColor: '#3085d6',
                confirmButtonText: 'ยืนยัน!'
            }).then((result) => {
                if (result.value) {

                }
            })
        } else if (showRepeat.length > 0) {
            document.getElementsByClassName('Loader__background')[0].className = "Loader__background hide";
            Swal.fire({
                title: 'โปรดเลือก Role Employee ที่ไม่ซ้ำกัน',
                text: 'รหัสที่ซ้ำ: ' + showRepeat.map((item) => String(item.position_group_id)),
                icon: 'warning',
                showCancelButton: false,
                confirmButtonColor: '#3085d6',
                confirmButtonText: 'ยืนยัน!'
            }).then((result) => {
                if (result.value) {

                }
            })
        }
        else if (this.state.task_list.length > 0 && this.state.task_list[0].position_group_id != "") {
            var rows: Array<Task> = [];
            rows = this.state.task_list
            for (var i = 0; i < this.state.task_list.length; i++) {
                var ext: Array<conditionTask> = [];
                ext = this.state.task_list[i].condition_list

                for (var a = 0; a < this.state.task_list[i].condition_list.length; a++) {
                    const taskItem = {
                        obj_id: this.state.task_list[i].condition_list[a].obj_id,
                        wf_id: this.state.wf_conditoin_list.wf_id,
                        position_group_id: this.state.task_list[i].condition_list[a].position_group_id,
                        parameter_code: this.state.task_list[i].condition_list[a].parameter_code,
                        parameter_name: this.state.task_list[i].condition_list[a].parameter_name,
                        parameter_value: this.state.task_list[i].condition_list[a].parameter_value,
                        parameter_operator: this.state.task_list[i].condition_list[a].parameter_operator
                    }
                    if (this.state.task_list[i].condition_list[a].parameter_code === "") {
                        this.setState({
                            task_list: this.state.task_list[i].condition_list = []
                        });
                    } else {
                        ext[a] = taskItem;
                        this.setState({
                            task_list: ext
                        });
                    }
                }

                const item = {
                    wf_id: this.state.wf_conditoin_list.wf_id,
                    task_type_id: this.state.task_list[i].task_type_id,
                    task_id: this.state.task_list[i].task_id,
                    position_group_id: this.state.task_list[i].position_group_id,
                    position_group_name: this.state.task_list[i].position_group_name,
                    child: this.state.task_list[i].child,
                    y_next_task: this.state.task_list[i].y_next_task,
                    n_next_task: this.state.task_list[i].n_next_task,
                    chg_status_to_table: this.state.task_list[i].chg_status_to_table,
                    chg_status_to_value: this.state.task_list[i].chg_status_to_value,
                    chg_status_where: this.state.task_list[i].chg_status_where,
                    condition_list: this.state.task_list[i].condition_list,
                };
                rows[i] = item
                this.setState({
                    task_list: rows
                })

                var res: Conditionlist = this.state.wf_conditoin_list
                res.task_list = this.state.task_list
                this.setState({
                    workflow_value: res,
                    check_workflow: true
                })
            }

            setTimeout(() => {
                this.props.requestCreateworkflow(true, "POST", "jft", this.state.workflow_value, this.state.company)
            }, 2000);
        } else {
            document.getElementsByClassName('Loader__background')[0].className = "Loader__background hide";
            Swal.fire({
                title: 'ไม่สำเร็จ!',
                text: 'โปรดกรอกข้อมูลส่วน Task',
                icon: 'warning',
                showCancelButton: false,
                confirmButtonColor: '#3085d6',
                confirmButtonText: 'ยืนยัน!'
            }).then((result) => {
                if (result.value) {

                }
            })
        }
    }

    blindConfig(value: any, result: any, check: boolean) {
        this.setState((prevstate) => ({
            requestConfig: false,
            wf_conditoin_list: result,
            isSettW: true,
        }))
    }

    blindTask(value: any) {
        this.setState((prevstate) => ({
            requestTask: false,
            task_list: value,
            isSetT: true
        }))
    }

    createWorkflow() {
        this.props.requestCreateworkflow(true, "POST", "jft", this.state.workflow_value, this.state.company)
    }

    back() {
        //window.location.href = "/workflow";
        this.props.history.push('/workflow')
    }

    componentDidUpdate(nextProps: any, nextState: any) {
        const { isLoadCreateWorkflow, statusCreateWorkflow, statusSearch, responseSearch, messageCreateWorkflow } = this.props;
        var company = this.props.company
        if (company != this.state.company) {
            this.setState({
                company: company
            });
        }

        if (isLoadCreateWorkflow && statusCreateWorkflow == 200 && this.state.check_workflow == true) {
            document.getElementsByClassName('Loader__background')[0].className = "Loader__background hide";
            this.setState({
                check_workflow: false
            })
            Swal.fire({
                title: 'สำเร็จ!',
                text: 'เพิ่มข้อมูลสำเร็จ',
                icon: 'success',
                showCancelButton: false,
                confirmButtonColor: '#3085d6',
                confirmButtonText: 'ยืนยัน!',
                allowOutsideClick: false
            }).then((result) => {
                if (result.value) {
                    console.log('redirect!!')
                    // return <Link to="/positiongroup" />;
                    //window.location.href = "/workflow"
                    this.props.requestCreateworkflow(true, "CLEAR", "", "", this.state.company)
                    this.props.history.push('/workflow')
                    //window.location.href = "/workflow"
                }
            })
        } else if (isLoadCreateWorkflow && statusCreateWorkflow == 204 && this.state.check_workflow == true) {
            var msg = JSON.parse(messageCreateWorkflow);
            Swal.fire({
                title: 'ไม่สำเร็จ!',
                text: msg.message,
                icon: 'error',
                showCancelButton: false,
                confirmButtonColor: '#3085d6',
                confirmButtonText: 'ยืนยัน!',
                allowOutsideClick: false
            }).then((result) => {
                if (result.value) {
                    this.props.requestCreateworkflow(true, "CLEAR", "jft", this.state.workflow_value, this.state.company)
                }
            })
        }

        if (statusSearch == 200 && responseSearch && this.state.checkSearch == true) {
            document.getElementsByClassName('Loader__background')[0].className = "Loader__background hide";
            console.log('edit :')
            console.log(responseSearch)
            this.setState({
                responseSearch: responseSearch,
                checkSearch: false
            })
            this.blindTask(responseSearch.task_list)
        }

        if (this.state.isSettW && this.state.isSetT) {
            this.setState({
                isSettW: false,
                isSetT: false
            })
            this.onsuccess()
        }
    }

    componentWillUnmount() {
        this.props.requestsearchworkflow(this.state.isready, "CLEAR", this.state.company)
        this.setState({
            wf_conditoin_list: "",
            task_list: [],
            workflow_value: ""
        })
    }

    switchPage = (page) => {
        if (page == "Task") {
            setTimeout(() => {
                this.setState({ fixBugScreen: true });
            }, 200);
        }
    }

    render() {
        var { requestConfig, requestTask, responseSearch, mode } = this.state
        /* console.log("responseSearch")
         console.log(responseSearch)*/

        return (
            <div>
                <Row>
                    <Col>
                        <div className="master-data-content">
                            <div>
                                <div className='trapezoid-head' style={{ width: 'fit-content', display: "inline-block" }}>
                                    <span>
                                        Workflow Criteria - Config
                                    </span>
                                </div>
                                <div style={{ display: "inline", float: "right" }}>
                                    <Link to="/workflow">
                                        <Button className="text-light" variant="success" size="sm" ><FaArrowCircleLeft />&nbsp;Back</Button>
                                    </Link>
                                    {/*<Button className="mx-2" variant="info" size="sm" onClick={() => this.onsave()}><FaPlusCircle />&nbsp;Save</Button>*/}
                                </div>
                            </div>
                            <div className="mat-box" >
                                <Row >
                                    <Col>
                                        <div className="container"  >
                                            <Tabs defaultActiveKey="Workflow" id="controlled-tab-example" onSelect={this.switchPage}>
                                                <Tab eventKey="Workflow" title="Workflow">
                                                    <WorkflowComponent requestConfig={requestConfig} blindConfig={this.blindConfig.bind(this)} responseSearch={responseSearch} />
                                                </Tab>
                                                <Tab eventKey="Task" title="Task" >
                                                    {mode == "Add" &&
                                                        <TaskComponent switchPage={this.switchPage.bind(this)} fixBugScreen={this.state.fixBugScreen} requestTask={requestTask} blindTask={this.blindTask.bind(this)} responseSearch={responseSearch} />
                                                    }
                                                    {mode == "Edit" && this.state.fixBugScreen &&
                                                        <TaskComponent switchPage={this.switchPage.bind(this)} fixBugScreen={this.state.fixBugScreen} requestTask={requestTask} blindTask={this.blindTask.bind(this)} responseSearch={responseSearch} />
                                                    }
                                                </Tab>
                                            </Tabs>
                                        </div>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        <div style={{ display: "inline", float: "right" }}>
                                            <Button className="mx-2" color="primary" size="sm" onClick={() => this.onsave()}><FaPlusCircle />&nbsp;Save</Button>
                                        </div>
                                    </Col>
                                </Row>
                            </div>
                        </div>
                    </Col>
                </Row>
            </div>
        );
    }
}

export default connect((state: ApplicationState) => ({ ...state.appState, ...state.createWorkflow, ...state.searchWorkflow, ...state.autocompleteWorkflowGroup }), ({ ...createWorkflowStore.actionCreators, ...searchWorkflowStore.actionCreators, ...AutocompleteWorkflowGroupStore.actionCreators, ...AppState.actionCreators }))(AddWorkflow)
