/* eslint-disable */

import { Action, Reducer } from 'redux';
import { AppThunkAction } from '..';
import { RequestOptions } from '../../models/Common';
import { ResponseList } from '../../models/Response';
import { EmailLog, EmailLogStateStore } from '../../models/log/EmailLog';

interface RequestEmailLog {
    type: 'REQUEST_EMAIL_LOG';
}

interface ReceiveEmailLog {
    type: 'RECEIVE_EMAIL_LOG';
    data: ResponseList<EmailLog>;
}

interface FailureEmailLog {
    type: 'FAILURE_EMAIL_LOG';
    error: any
}

interface ClearEmailLog {
    type: 'CLEAR_EMAIL_LOG';
}

type KnownAction =
    | RequestEmailLog
    | ReceiveEmailLog
    | FailureEmailLog
    | ClearEmailLog;

export const actionCreators = {
    requestEmailLog: (options: RequestOptions<null, { sids?: string[], doc_no?: string, type?: string, page?: number, pageSize?: number }>): AppThunkAction<KnownAction> => (dispatch, _getState) => {
        const { method, params } = options;
        const searchSids = new URLSearchParams(params?.sids!.map(sid => ['sid', sid])).toString().replace('&', '?');
        delete params?.sids;
        const searchOptional = new URLSearchParams(params as any).toString();

        dispatch({ type: 'REQUEST_EMAIL_LOG' });

        fetch('/v1/email-log?' + searchSids + '&' + searchOptional, {
            method,
            headers: {
                'Authorization': 'Bearer ' + localStorage.getItem('WF_APPLICATION')
            }
        })
            .then(response => {
                if (response.status === 200) {
                    return response.json();
                } else {
                    return Promise.reject(response.json());
                }
            })
            .then(data => {
                console.log('ngame', data)
                dispatch({ type: 'RECEIVE_EMAIL_LOG', data });
            })
            .catch(error => dispatch({ type: 'FAILURE_EMAIL_LOG', error }));
    }
}

const initialState: EmailLogStateStore = {
    isLoading: false,
    response: null,
    status: 0,
    error: null
};

export const reducer: Reducer<EmailLogStateStore> = (state = initialState, action: KnownAction) => {
    switch (action.type) {
        case 'REQUEST_EMAIL_LOG': {
            return {
                ...state,
                isLoading: true
            };
        }
        case 'RECEIVE_EMAIL_LOG': {
            return {
                ...state,
                isLoading: false,
                response: action.data,
                status: 200
            };
        }
        case 'FAILURE_EMAIL_LOG': {
            return {
                ...state,
                isLoading: false,
                status: 400,
                error: action.error
            };
        }
        case 'CLEAR_EMAIL_LOG': {
            return initialState;
        }
        default: return state;
    }
}