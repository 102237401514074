import { Action, Reducer } from 'redux';
import { AppThunkAction } from '..';

import * as Models from '../../models/workflow/BatchDetail'

interface BeforeLoadBatchDetail {
    type: 'LOAD_BATCHDETAIL'
}
interface LoadBatchDetail {
    type: 'LOADBATCHDETAIL'
    response: Models.BatchDetailRes
    status: Number,
    statusMessage: String
}
interface FailLoadBatchDetail {
    type: 'LOADBATCHDETAILFAIL'
    status: number,
    statusMessage: any
}

interface ClearLoadBatchDetail {
    type: 'CLEARLOADBATCHDETAIL'
    status: number,
    statusMessage: any
}

type KnownAction = LoadBatchDetail | FailLoadBatchDetail | ClearLoadBatchDetail | BeforeLoadBatchDetail

export const actionCreators = {
    requestGetBatchDetail: (p: boolean, method: String, wf_type: string, batch_id: string, app_id: string, user_id: string , mode : string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        console.log('call actionCreators : requestGetBatchDetail')
        console.log(user_id)
        if (appState && appState.BatchDetail && p && method == 'GET') {
            fetch('/v2/workflow/batch-detail?wf_type=' + wf_type + '&batch_id=' + batch_id + '&app_id=' + app_id + '&user_id=' + user_id + '&mode=' + mode, {
                method: 'GET',
                headers: {
                    'Authorization': 'Bearer ' + localStorage.getItem('WF_APPLICATION'),
                },
            })
                .then((response) => {
                    //console.log("requestBatchDetailInbox")
                    //console.log(response)
                    return response
                })
                .then((data) => {
                    //console.log(data)
                    if (!data.ok) {
                        data.json().then(err => {
                            //console.log(err.message_res)
                            console.log(err)
                            dispatch({ type: 'LOADBATCHDETAILFAIL', status: 204, statusMessage: err.message });
                        })
                    } else {
                        data.json().then(data => {
                            //console.log(data)
                            dispatch({ type: 'LOADBATCHDETAIL', response: data as Models.BatchDetailRes, status: 200, statusMessage: '' });
                        })
                    }
                })

            dispatch({ type: 'LOAD_BATCHDETAIL' });

        } else if (method == "CLEAR") {
            console.log("dispatch : CLEARLOADBATCHDETAIL")
            dispatch({ type: 'CLEARLOADBATCHDETAIL', status: 400, statusMessage: "" });
        }
    },
}

const unloadedState: Models.GetBatchDetailState = { isLoadBatchDetail: false };

export const reducer: Reducer<Models.GetBatchDetailState> = (state: Models.GetBatchDetailState | undefined, incomingAction: Action): Models.GetBatchDetailState => {
    if (state == undefined) {
        return unloadedState;
    }
    const action = incomingAction as KnownAction;
    switch (action.type) {
        case 'LOAD_BATCHDETAIL':
            console.log("LOAD_BATCHDETAIL")
            return {
                isLoadBatchDetail: false,
            }

        case 'LOADBATCHDETAIL':
            console.log("LOADBATCHDETAIL")
            return {
                isLoadBatchDetail: true,
                responseBatchDetail: action.response,
                statusBatchDetail: 200
            }

        case 'LOADBATCHDETAILFAIL':
            console.log("LOADBATCHDETAILFAIL")
            return {
                isLoadBatchDetail: true,
                messageBatchDetail: action.statusMessage,
                statusBatchDetail: action.status
            }
        case 'CLEARLOADBATCHDETAIL':
            console.log("CLEARLOADBATCHDETAIL")
            return {
                isLoadBatchDetail: false,
                messageBatchDetail: "",
                statusBatchDetail: 400
            }
        default: return state;
    }
}