import { AdminMasterEmployeeResponse } from 'data/MasterEmployee/schemas/MasterEmployee.shema';
import { formatDateTime } from 'modules/Common';
import React from 'react';
import { Button, Container, Row, Col, Form, Accordion, Table, Spinner } from "react-bootstrap";
import { FaEdit, FaTimesCircle } from 'react-icons/fa';

interface Props {
    userAdminMasterEmployee: AdminMasterEmployeeResponse[]
    deleteUserAdmin: (user: string) => void
    mappingCompany: Map<string, string>;
}

function WorkfloConfigAdminTable(props: Props) {
    const { userAdminMasterEmployee, deleteUserAdmin, mappingCompany } = props

    const handleDeleteUser = (user: string) => {
        deleteUserAdmin(user)
    }

    return (
        <Container>
            <Row className="mt-3">
                <Col>
                    <Table
                        className="table-bordered align-middle"
                        size="sm"
                    >
                        <thead>
                            <tr
                                style={{ width: '100%' }}
                                className="table-active thead-light text-center"
                            >
                                <th style={{ width: '5%' }}></th>
                                <th style={{ width: '5%' }}>Company</th>
                                <th style={{ width: '10%' }}>Role Access</th>
                                <th style={{ width: '15%' }}>Employee ID</th>
                                <th style={{ width: '10%' }}>Employee Name</th>
                                <th style={{ width: '15%' }}>Create By</th>
                                <th style={{ width: '15%' }}>Create On</th>
                            </tr>
                        </thead>
                        <tbody>
                            {!userAdminMasterEmployee ? (
                                <tr>
                                    <td
                                        colSpan={7}
                                        style={{
                                            background: 'rgba(209, 250, 221, 1)',
                                            color: 'black',
                                            fontSize: '10pt',
                                            textAlign: 'center'
                                        }}
                                    >
                                        <Spinner
                                            animation="border"
                                            size="sm"
                                        />
                                        &nbsp;&nbsp;Loading...
                                    </td>
                                </tr>
                            ) : (
                                userAdminMasterEmployee.map((value, index) => (
                                    <tr key={index}>
                                        <td className="text-center"><Button className="mx-1" variant="outline-danger" size="sm" onClick={() => { handleDeleteUser(value.id) }}><FaTimesCircle /></Button></td>
                                        <td className="text-center">{mappingCompany.get(value.sid) ?? "*"}</td>
                                        <td className="text-center">{value.role_access}</td>
                                        <td>{value.id}</td>
                                        <td className="text-center">{value.empname}</td>
                                        <td className="text-center">{value.created_by}</td>
                                        <td className="text-center">{formatDateTime(value.created_on)}</td>
                                    </tr>
                                ))
                            )}

                        </tbody>
                    </Table>
                </Col>
            </Row>
        </Container >
    );
}

export default WorkfloConfigAdminTable;
