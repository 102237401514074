import React, { PropsWithChildren } from 'react';
import { IconType } from 'react-icons';
import { twMerge } from 'tailwind-merge';

type ContainerProps = React.DetailedHTMLProps<
    React.HTMLAttributes<HTMLDivElement>,
    HTMLDivElement
>;

function Container(props: PropsWithChildren<ContainerProps>) {
    const { children, className, ...divProps } = props;

    return (
        <div
            {...divProps}
            className={twMerge(
                'mx-3 [&_>_div:not(:last-child)]:border-l-2',
                className
            )}
        >
            {children}
        </div>
    );
}

const STEPPER_STATUS_CLASSES: Record<
    Exclude<StepperProps['status'], undefined>,
    string
> = {
    success: 'bg-lime-600',
    warning: 'bg-yellow-600',
    neutral: 'bg-neutral-600',
    danger: 'bg-red-600'
};

const STEPPER_BORDER_CLASSES: Record<
    Exclude<StepperProps['status'], undefined>,
    string
> = {
    success: 'border-lime-600',
    warning: 'border-yellow-600',
    neutral: 'border-neutral-600',
    danger: 'border-red-600'
};

type StepperProps = {
    icon: IconType;
    status?: 'success' | 'warning' | 'neutral' | 'danger';
};

function Stepper(props: PropsWithChildren<StepperProps>) {
    const { children, status, icon: Icon } = props;

    return (
        <div
            className={twMerge(
                'flex',
                STEPPER_BORDER_CLASSES[status || 'neutral']
            )}
        >
            <div
                className={twMerge(
                    'flex h-6 w-6 -translate-x-1/2 items-center justify-center rounded-full',
                    STEPPER_STATUS_CLASSES[status || 'neutral']
                )}
            >
                <Icon
                    size={14}
                    className="text-white"
                />
            </div>

            <div className="flex gap-4">{children}</div>
        </div>
    );
}

export default Object.assign(Stepper, {
    Container
});
