import * as React from "react";
import { Container } from 'reactstrap';
import { ApplicationState } from "../../store";
import { Button, Card, Col, Form, Row, Table } from "react-bootstrap";
import * as ModelsWorkflowInbox from '../../models/workflow/WorkflowInbox';
import { TextAlignProperty } from 'csstype'
type align_ = "left" | "center" | "right";

class ReportInformation extends React.Component<any, { isLoading: boolean, src: string, JSONReport_List: ModelsWorkflowInbox.JSONReportList }>{

    constructor(props: any) {
        super(props);
        this.state = {
            isLoading: true,
            src: "",
            JSONReport_List: {
                DataHeader: {
                    column: []
                },
                ListDataItem: []
            }
        }
    }

    componentDidMount() {
        const { JSONReportList } = this.props
        const { isLoading } = this.state

        if (JSONReportList != undefined && isLoading) {
            this.setState({
                isLoading: false
            })
        }
    }

    componentDidUpdate(nextProps: any, nextState: any) {
        const { JSONReportList } = this.props
        const { isLoading } = this.state

        if (JSONReportList != undefined && isLoading && JSONReportList.ListDataItem != undefined) {
            this.setState({
                isLoading: false
            })
        }
    }

    render() {
        const { JSONReportList } = this.props
        console.log(JSONReportList)
        console.log(JSONReportList.DataHeader.row)
        return (
            <div>
                <Container fluid={true} className="mt-3">
                    {JSONReportList.DataHeader.row == undefined ?

                        <Row>
                            <Col>
                                <Card>
                                    <Card.Body>
                                        {JSONReportList.DataHeader.column.length == 0 &&
                                            <Row>
                                                <Col>
                                                    <div className="text-center">
                                                        <label>ไม่พบข้อมูล..</label>
                                                    </div>
                                                </Col>
                                            </Row>
                                        }
                                        {JSONReportList.DataHeader.column.length != 0 &&
                                            <Row>
                                                <Col lg={12}>
                                                    <table className="text-left" style={{ width: '100%' }}>
                                                        <tbody>
                                                            {JSONReportList.DataHeader.column.map((data: ModelsWorkflowInbox.ColoumList, idx) => {
                                                                return (
                                                                    <tr key={idx} style={{ width: '100%' }}>
                                                                        <td style={{ width: '20%' }}><label><b>{data.label} :</b></label></td>
                                                                        <td style={{ width: '80%' }}>{data.value}</td>
                                                                    </tr>
                                                                )
                                                            })}
                                                        </tbody>
                                                    </table>
                                                </Col>
                                            </Row>
                                        }
                                        {/*}*/}
                                    </Card.Body>
                                </Card>
                            </Col>
                        </Row>

                        :

                        <Row>
                            <Col>
                                <Card>
                                    <Card.Body>
                                        {JSONReportList.DataHeader.row.length == 0 &&
                                            <Row>
                                                <Col>
                                                    <div className="text-center">
                                                        <label>ไม่พบข้อมูล..</label>
                                                    </div>
                                                </Col>
                                            </Row>
                                        }
                                        {JSONReportList.DataHeader.row.length != 0 &&
                                            <Row>
                                                <Col lg={12}>
                                                    {JSONReportList.DataHeader.row.map((data: ModelsWorkflowInbox.DataHeader, idx) => {
                                                        return (
                                                            <Row key={idx} className="mt-3">
                                                                {data.column.map((item: ModelsWorkflowInbox.ColoumList, ids) => {
                                                                    return (
                                                                        <Col key={ids} lg="3" className="table-hover">
                                                                            <Row>
                                                                                <Col lg="5" className="font-weight-bold bg-light text-dark">{item.label} :</Col>
                                                                                <Col lg="7">{item.value}</Col>
                                                                            </Row>
                                                                        </Col>
                                                                    )
                                                                })}
                                                            </Row>
                                                        )
                                                    })}
                                                </Col>
                                            </Row>
                                        }
                                        {/*}*/}
                                    </Card.Body>
                                </Card>
                            </Col>
                        </Row>
                    }


                    {!this.state.isLoading && JSONReportList.ListDataItem != undefined && JSONReportList.ListDataItem.length != 0 && JSONReportList.ListDataItem.map((ListItem: ModelsWorkflowInbox.DataItem, idList) => {
                        return (
                            <Row className="mt-3" key={idList}>
                                <Col>
                                    <div style={{ overflowX: "scroll" }}>
                                        <table className="table table-bordered" style={{ width: "max-content" }}>
                                            <thead>
                                                {/*<tr className="table-active d-flex">*/}
                                                <tr className="table-active">
                                                    {ListItem.tableheader.map((data: ModelsWorkflowInbox.tableheader, idx) => {
                                                        return (
                                                            <th key={idx} style={{ width: data.width != "" ? data.width + "px" : "auto", textAlign: data.align as align_ }} align={data.align as align_}>{data.label}</th>
                                                            //<th className="col-lg-6" key={idx}>{data.label}</th>
                                                        )
                                                    })}
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {ListItem.tabledata.map((data: ModelsWorkflowInbox.tabledata, idx) => {
                                                    return (
                                                        //<tr key={idx} className="d-flex">
                                                        <tr key={idx}>
                                                            {data.row.map((item: string, idx2) => {
                                                                return (
                                                                    <td key={idx2} style={{ textAlign: ListItem.tableheader[idx2].align as align_ }}>{item}</td>
                                                                    //<td className="col-lg-6" key={idx2}>{item}</td>
                                                                )
                                                            })}
                                                        </tr>
                                                    )
                                                })}
                                            </tbody>
                                        </table>
                                    </div>
                                </Col>
                            </Row>
                        )
                    })
                    }
                </Container>
            </div>
        )
    }
}

export default ReportInformation;