import { useCallback, useEffect, useState } from 'react';
import { useLocation } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import Swal from 'sweetalert2';
import queryString from 'query-string';
import axios from 'axios';

import { ApplicationState } from 'store';
import * as WorkflowInboxStore from 'store/workflow/WorkflowInbox';
import * as ApproveRejectWorkflowStore from 'store/workflow/ApproveRejectWorkflow';
import { Reference, UserInformation } from 'models/auth/SignIn';
import { WorkflowInbox } from 'models/workflow/WorkflowInbox';
import { ResponseList } from 'models/Response';
import { Response } from 'models/reference/Reference';
import { parseJwt } from 'modules/Common';
import {
    ApproveRejectWorkflowRequest,
    ApproveRejectWorkflowResponse
} from 'models/workflow/ApproveRejectWorkflow';
import { SpinnerLoader } from 'helpers/dom-utils';
import { extractAccessToken } from 'helpers/decodeLocalStorage';

function WorkflowOnlineFormViewModel() {
    const dispatch = useDispatch();
    const { workflowInbox, approveRejectWorkflow } = useSelector<
        Defined<ApplicationState>,
        Defined<
            Pick<ApplicationState, 'workflowInbox' | 'approveRejectWorkflow'>
        >
    >(state => ({
        workflowInbox: state.workflowInbox,
        approveRejectWorkflow: state.approveRejectWorkflow
    }));
    const location = useLocation();

    // Authentication
    const [accessToken, setAccessToken] = useState<string>();
    const [auth, setAuth] = useState<UserInformation>();

    // Approving and rejection
    const [prepareAction, setPrepareAction] = useState<DocumentAction>('');
    const [message, setMessage] = useState('');
    const [isOpenConfirmModal, setIsOpenConfirmModal] = useState(false);

    // Information
    const [isOpenInformationModal, setIsOpenInformationModal] = useState(false);
    const [selectedDocument, setSelectedDocument] = useState<WorkflowInbox>();

    // Reports
    const [reportsReponse, setReportsResponse] =
        useState<ResponseList<WorkflowInbox>>();

    // Displaying
    const [displayStyle, setDisplayStyle] = useState<'grid' | 'list'>(
        (localStorage.getItem('ONLINE_FORM_DISPLAY_STYLE') as any) ?? 'list'
    );
    const [mode, setMode] = useState<'Inbox' | 'Report'>('Inbox');

    const [selectedDocuments, setSelectedDocuments] = useState(
        new Set<WorkflowInbox>()
    );

    const { isLoadWorkflowInbox, statusWorkflowInbox, responseWorkflowInbox } =
        workflowInbox;
    const {
        isLoadApproveReject,
        statusResponseApproveReject,
        responseApproveReject
    } = approveRejectWorkflow;

    // Authentication validator
    useEffect(() => {
        validateAuthentication();
    }, []);

    // Initial fetch documents
    useEffect(() => {
        if (!auth) {
            return;
        }

        fetchWorkflowInbox();
        fetchWorkflowDashboard();
    }, [auth]);

    // Response from server when get workflow inbox
    useEffect(() => {
        if (
            isLoadWorkflowInbox &&
            (statusWorkflowInbox === 200 || (statusWorkflowInbox ?? 0) > 200)
        ) {
            SpinnerLoader.hide();
        }
    }, [workflowInbox]);

    // Response from server when approve or reject effect
    useEffect(() => {
        const {
            isLoadApproveReject,
            statusResponseApproveReject,
            responseApproveReject,
            messageResponseApproveReject
        } = approveRejectWorkflow;

        if (
            isLoadApproveReject &&
            statusResponseApproveReject === 200 &&
            responseApproveReject
        ) {
            if (
                responseApproveReject.result_list?.every(
                    document =>
                        document.status !== 'ERROR' &&
                        document.status !== 'ESIG0010'
                )
            ) {
                handleNavigateToESignature(responseApproveReject.result_list);
            } else if (
                responseApproveReject.result_list?.every(
                    document => document.status === 'ESIG0010'
                )
            ) {
                handleCloseApprovalStatusModal();
                Swal.fire({
                    title: 'Failed CA',
                    html: 'Fail CA คุณระบุ Mode การเซ็นต์เอกสารอิเล็คทรอนิกส์ แบบ Level 3 โดยยังไม่มีการนำเข้า Personal Certificate โปรดตรวจสอบให้แน่ใจในข้อมูลส่วนตัวของคุณ หากยังต้องการใช้การเซ็นต์เอกสารอิเล็คทรอนิกส์แบบ Level 3 โดยการเข้าไปที่ ข้อมูลส่วนตัว > นำเข้า CA และบันทึกข้อมูล หากคุณยังไม่มี CA คุณสามารถปรับ Mode การเซ็นเอกสารอิเล็คทรอนิกส์เป็น Level 2 หรือ Level 1',
                    icon: 'error',
                    confirmButtonText: 'OK'
                });
            }
        } else if (
            isLoadApproveReject &&
            (statusResponseApproveReject ?? 400) > 200
        ) {
            Swal.fire('Error', messageResponseApproveReject, 'error');
        }

        // Clean up state
        if (
            isLoadApproveReject &&
            (statusResponseApproveReject === 200 ||
                (statusResponseApproveReject ?? 0) > 200)
        ) {
            fetchWorkflowInbox();
            setSelectedDocuments(new Set());
            toggleInformationModal(false);
            SpinnerLoader.hide();
            // toggleConfirmModal('');
            // dispatch(
            //     ApproveRejectWorkflowStore.actionCreators.requestApproveWorkflowList(
            //         false,
            //         'CLEAR',
            //         '',
            //         '',
            //         accessToken
            //     )
            // );
        }
    }, [approveRejectWorkflow]);

    const validateAuthentication = async () => {
        const { ref_id, token } = queryString.parse(location.search) as Record<
            string,
            string
        >;

        if (ref_id) {
            const response = await axios.get<Response>('/workflow/reference', {
                params: {
                    id: ref_id
                }
            });
            const data = response.data;
            const params = new URLSearchParams(data.parameter);
            const token = params.get('token')!;
            const reference = parseJwt(token) as Reference;
            const auth = JSON.parse(reference.extra) as UserInformation;

            if (Date.now() < reference.exp * 1000) {
                localStorage.setItem(
                    'WF_APPLICATION',
                    JSON.stringify({ access_token: token })
                );
            }

            setAccessToken(JSON.stringify({ access_token: token }));
            setAuth(auth);
        } else if (token) {
            const auth = extractAccessToken(token, { replaceOldToken: true });

            setAccessToken(JSON.stringify({ access_token: token }));
            setAuth(auth);
        }
    };

    const fetchWorkflowInbox = () => {
        if (!auth) {
            return;
        }

        SpinnerLoader.show();

        dispatch(
            WorkflowInboxStore.actionCreators.requestWorkflowInbox(
                true,
                'POST',
                auth.citizen_id ?? auth.username,
                ['e5mPFn1JEi76UvyyPkOf'],
                1,
                100,
                '',
                {
                    wfNo: '',
                    fiscalYear: '',
                    docAmount: '',
                    docAmountTo: '',
                    createBy: '',
                    createByTo: '',
                    requester_id: '',
                    updatedFrom: '',
                    updatedTo: '',
                    userName: ''
                },
                accessToken
            )
        );
    };

    const fetchWorkflowDashboard = async () => {
        if (!auth) {
            return;
        }

        try {
            SpinnerLoader.show();

            const response = await axios.get<ResponseList<WorkflowInbox>>(
                '/v4/workflow/dashboard',
                {
                    params: {
                        user_id: auth.citizen_id ?? auth.username,
                        app_id: 'e5mPFn1JEi76UvyyPkOf'
                    },
                    headers: {
                        Authorization: `Bearer ${JSON.stringify({
                            access_token: accessToken
                        })}`
                    }
                }
            );
            const data = response.data;

            setReportsResponse(data);
        } catch (error) {
        } finally {
            SpinnerLoader.hide();
        }
    };

    const toggleConfirmModal = (action: DocumentAction) => {
        setMessage('');
        setPrepareAction(action);
        setIsOpenConfirmModal(prevState => !prevState);
    };

    const toggleInformationModal = <T extends boolean>(
        isOpen: T,
        document?: T extends false ? never : WorkflowInbox
    ) => {
        setSelectedDocument(document);
        setIsOpenInformationModal(isOpen);
    };

    const confirmAction = (
        confirmMessage: string,
        documents: WorkflowInbox[]
    ) => {
        setMessage(confirmMessage);

        if (!auth) {
            return;
        }

        SpinnerLoader.show();

        const prepareDocuments: ApproveRejectWorkflowRequest = {
            app_id: 'e5mPFn1JEi76UvyyPkOf',
            user_id: auth.citizen_id ?? auth.username,
            wf_type: 'SC_CT_ONLINE_FORM',
            doc_no_list: documents.map(document => document.docNo),
            instance_no_list: documents.map(document => document.wfInstanceno),
            message: confirmMessage
        };

        // Test
        // prepareDocuments.doc_no_list.push('26CC2EC9-00090_13');
        // prepareDocuments.instance_no_list.push(
        //     'custom_c7607c8c679147beacfb0d4b0774f620-26CC2EC9-00090_13:0001227'
        // );

        const functionName: keyof typeof ApproveRejectWorkflowStore.actionCreators =
            prepareAction === 'approve'
                ? 'requestApproveWorkflowList'
                : 'requestRejectWorkflowList';
        dispatch(
            ApproveRejectWorkflowStore.actionCreators[functionName](
                true,
                'PUT',
                [prepareDocuments],
                confirmMessage,
                accessToken
            )
        );
    };

    const handleChangeDisplayStyle = (style: typeof displayStyle) => {
        setDisplayStyle(style);
        localStorage.setItem('ONLINE_FORM_DISPLAY_STYLE', style);
    };

    const handleChangeTab = useCallback((tab: typeof mode) => {
        setMode(tab);
    }, []);

    const handleChangeSelectAllDocuments = useCallback(
        (event: React.ChangeEvent<HTMLInputElement>) => {
            if (
                !responseWorkflowInbox ||
                responseWorkflowInbox.total_count < 0
            ) {
                return;
            }

            event.persist();
            const checked = event.target.checked;
            if (!checked) {
                setSelectedDocuments(new Set());

                return;
            }

            setSelectedDocuments(new Set(responseWorkflowInbox.result_list));
        },
        [responseWorkflowInbox, setSelectedDocuments]
    );

    const handleSelectForm = (document: WorkflowInbox) => {
        setSelectedDocuments(prevState => {
            const nextState = new Set(prevState);

            if (nextState.has(document)) {
                nextState.delete(document);
            } else {
                nextState.add(document);
            }

            return nextState;
        });
    };

    const handleCloseApprovalStatusModal = () => {
        dispatch(
            ApproveRejectWorkflowStore.actionCreators.requestApproveWorkflowList(
                false,
                'CLEAR',
                '',
                '',
                accessToken
            )
        );

        toggleConfirmModal('');
    };

    const handleNavigateToESignature = (
        documents: ApproveRejectWorkflowResponse[]
    ) => {
        const foundWaitOtpDocument = documents.find(
            document =>
                document.status === 'wait_otp' ||
                document.status === 'wait_sign'
        );

        foundWaitOtpDocument &&
            window.location.replace(foundWaitOtpDocument.redirectLink);
    };

    return {
        auth,
        accessToken,
        mode,
        displayStyle,
        selectedDocuments,
        selectedDocument,
        prepareAction,
        message,
        isOpenConfirmModal,
        isOpenInformationModal,
        reportsReponse,
        responseWorkflowInbox,
        responseApproveReject,
        fetchWorkflowInbox,
        handleChangeDisplayStyle,
        handleChangeSelectAllDocuments,
        handleSelectForm,
        handleChangeTab,
        handleCloseApprovalStatusModal,
        handleNavigateToESignature,
        confirmAction,
        toggleConfirmModal,
        toggleInformationModal
    };
}

export default WorkflowOnlineFormViewModel;
