import * as React from "react";
import { connect } from "react-redux";
import { Button, Row, Col } from "react-bootstrap";
import { FaPlusCircle } from "react-icons/fa";
import { Link } from "react-router-dom";
import WorkflowTable from './WorkflowTable'
import WorkflowConfigSearchTap from "../positionemployee/WorkflowConfigSearchTap";

import * as AppState from '../../store/AppState'
import * as ModelsAppState from '../../models/AppState'
import { ApplicationState } from "../../store";
type AppStateProps = ModelsAppState.AppState & typeof AppState.actionCreators

class Workflow extends React.Component<any, { roleid: string, empid: string, authority_group: string, workflowid: string, workflowName: string, mode: string, reset: boolean, resetSearchTap: boolean }, AppStateProps> {

    constructor(props: any) {
        super(props);
        this.state = {
            roleid: "",
            empid: "",
            authority_group: "",
            workflowid: "",
            workflowName: "",
            reset: false,
            resetSearchTap: false,
            mode: "Workflow"
        }
    }
    callbackReset = () => {
        this.setState({
            reset: false
        })
    }

    handleReset = (value: boolean) => {
        this.setState({
            reset: value
        })
    }

    handleSearch = (role_id: any, emp_id: any, authority_group: any, workflow_id: any, workflow_name: any) => {
        //console.log(role_id + " - " + emp_id)
        this.setState({
            roleid: role_id,
            empid: emp_id,
            authority_group: authority_group,
            workflowid: workflow_id,
            workflowName: workflow_name
        })
        setTimeout(() => {
            this.handleReset(true);
        }, 300);

    }

    callbackResetSearch = () => {
        this.setState({
            resetSearchTap: true
        })
    }

    callbackResetSearchTap = () => {
        this.setState({
            resetSearchTap: false
        })
    }

    componentDidMount() {
        // document.getElementsByClassName('Loader__background')[0].className = "Loader__background show";
        this.props.updateUrl(this.props.location.pathname)
    }

    render() {
        return (
            <div>
                <div className="master-data-content">
                    <div className='trapezoid-head' style={{ width: '200px' }}>
                        <span>
                            Workflow Criteria
                        </span>
                    </div>
                    <div className="mat-box">
                        <WorkflowConfigSearchTap handleSearch={this.handleSearch} callbackResetSearchTap={this.callbackResetSearchTap.bind(this)} keyResetSearchTap={this.state.resetSearchTap} SearchMode={this.state.mode} />
                        <Row>
                            <Col>
                                <div className='trapezoid trapezoid-purple' style={{ width: '170px', display: 'inline-block' }}>
                                    <span>
                                        <i className="fa fa-search" aria-hidden="true"></i>
                                        Workflow Criteria
                                    </span>
                                </div>
                                <div style={{ display: 'inline-block', float: 'right' }}>
                                    <Link to={{ pathname: '/addworkflow', state: { mode: "Add" } }}>
                                        <Button variant="outline-info" size="sm">
                                            <FaPlusCircle />
                                            &nbsp;Create Workflow
                                        </Button>
                                    </Link>
                                </div>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <WorkflowTable callbackReset={this.callbackReset.bind(this)} callbackResetSearch={this.callbackResetSearch.bind(this)} keyReset={this.state.reset} roleid={this.state.roleid} empid={this.state.empid} authority_group={this.state.authority_group} workflowid={this.state.workflowid} workflowName={this.state.workflowName} />
                            </Col>
                        </Row>
                    </div>
                </div>
            </div>
        );
    }
}
export default connect((state: ApplicationState) => ({ ...state.appState }), ({ ...AppState.actionCreators }))(Workflow)
