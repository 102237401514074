import * as React from "react";
import { connect } from "react-redux";
import { Button, Row, Col, Image, Table, Form, Card, ButtonGroup } from "react-bootstrap";
import { Container } from 'reactstrap';
import { FaFileAlt, FaPhotoVideo, FaPlusCircle, FaTimes, FaReply } from "react-icons/fa";
import Swal from 'sweetalert2'
import { parseJwt } from '../../modules/Common';
import * as RemarkModels from '../../models/remark/Remark'
import * as RemarkStore from '../../store/remark/Remark'
import { ApplicationState } from "../../store";
import { url } from "inspector";

type store = RemarkModels.GetRemarkState & typeof RemarkStore.actionCreators

class RemarkReplyComment extends React.Component<any, { userId: string, formReply: RemarkModels.RemarkReq, isready: boolean, fileUpload: any }, store>{
    //inputRef: React.RefObject<unknown>;
    inputRef = React.createRef<HTMLInputElement>();
    constructor(props: any) {
        super(props);
        this.inputRef = React.createRef();

        this.state = {
            userId: '',
            isready: false,
            formReply: {
                sid: "",
                wfid: "",
                instanceno: "",
                type: "",
                message: "",
                original_content_url: "",
                preview_image_url: "",
                remark_ref_code: "",
                instance_ref_code: "",
                status: "",
                created_by: "",
                //fileList: FormData
            },
            fileUpload: []
        }
        this.refreshRemark = this.refreshRemark.bind(this)
        this.handleSaveReply = this.handleSaveReply.bind(this)
    }

    componentDidMount() {
        //this.inputRef.current.focus();
        var ls: any = localStorage.getItem("WF_APPLICATION");
        var jls: any = JSON.parse(ls)
        var access_token = parseJwt(jls.access_token)
        var extra = JSON.parse(access_token.extra)
        console.log(extra)

        var user_id = ""
        if (extra.citizen_id) {
            console.log('Azure Login ID: ' + extra.citizen_id)
            user_id = extra.citizen_id
            this.setState({ userId: extra.citizen_id })
        } else if (extra.username) {
            console.log('Local Login ID: ' + extra.sid)
            user_id = extra.username
            this.setState({ userId: extra.username })
        }
    }

    componentDidUpdate(nextProps, nextState) {
        const { AddRemark } = this.props

        if (this.state.isready && AddRemark.isLoadAddRemark && AddRemark.statusAddRemark == 200) {
            document.getElementsByClassName('Loader__background')[0].className = "Loader__background hide";
            this.refreshRemark()
            this.props.requestAddRemark(true, "CLEAR", [])

        } else if (this.state.isready && AddRemark.isLoadAddRemark && AddRemark.statusAddRemark != 200) {
            document.getElementsByClassName('Loader__background')[0].className = "Loader__background hide";
            var msg = AddRemark.messageAddRemark
            Swal.fire({
                title: 'ไม่สำเร็จ!',
                text: msg.message,
                icon: 'error',
                showCancelButton: false,
                confirmButtonColor: '#3085d6',
                confirmButtonText: 'ยืนยัน!',
                allowOutsideClick: false
            }).then((result) => {
                if (result.value) {
                    this.refreshRemark()
                    this.props.requestAddRemark(true, "CLEAR", [])
                }
            })
        }

        //if (this.props.saveReply) {
        //    //this.props.resetReply()
        //    this.handleSaveReply()
        //}
    }

    refreshRemark = () => {
        var wfData = JSON.parse(JSON.stringify(this.props.workflowValue))
        this.props.requestGetRemark(true, 'GET', wfData.appId, wfData.wfId, wfData.wfInstanceNo)
        this.setState({
            formReply: {
                sid: "",
                wfid: "",
                instanceno: "",
                type: "",
                message: "",
                original_content_url: "",
                preview_image_url: "",
                remark_ref_code: "",
                instance_ref_code: "",
                status: "",
                created_by: "",
                //fileList: {}
            },
            fileUpload: [],
            isready : false
        })
    }

    handleChange = (e: any, key: string) => {
        var dataArr = this.state.formReply
        dataArr.message = e.target.value
        this.setState({
            formReply: dataArr
        })
    }

    public handleSaveReply = () => {
        console.log('save')
        //type = text, image, file
        if (this.state.formReply.message != "") {
            this.setState({
                isready : true
            })
            var dataArr = this.state.formReply
            var wfData = JSON.parse(JSON.stringify(this.props.workflowValue))

            var type : string = 'text'
            if (this.state.fileUpload.length != 0) {
                type = 'image'
            }

            dataArr.sid = wfData.appId
            dataArr.wfid = wfData.wfId
            dataArr.instanceno = wfData.wfInstanceNo
            dataArr.type = type
            dataArr.message = dataArr.message
            //dataArr.original_content_url = dataArr.original_content_url
            //dataArr.preview_image_url = dataArr.preview_image_url
            //dataArr.instance_ref_code = dataArr.instance_ref_code
            dataArr.status = 'active'
            dataArr.created_by = this.state.userId
            dataArr.remark_ref_code = this.props.replyId
            dataArr.instance_ref_code = this.props.replyInstanceno

            console.log(JSON.stringify(dataArr))
            this.props.handleSaveReply()
            this.props.requestAddRemark(true, "POST", dataArr, this.state.fileUpload[0])
            document.getElementsByClassName('Loader__background')[0].className = "Loader__background show";
        } else {
            Swal.fire({
                title: 'คำเตือน!',
                text: 'โปรดเพิ่มความคิดเห็น',
                icon: 'warning',
                showCancelButton: false,
                confirmButtonColor: '#3085d6',
                confirmButtonText: 'ตกลง!',
                allowOutsideClick: false
            }).then((result) => {
                if (result.value) {
                }
            })
        }
    }

    handleClickAddfile = () => {
        console.log(this.inputRef)
        this.inputRef.current?.click();
    }

    handleChangeAddfile = (e: any) => {
        console.log(JSON.stringify(e.target.files))
        if (e.target.files.length != 0) {
            var fileList = this.state.fileUpload
            fileList.push(e.target.files[0])
            this.setState({
                fileUpload: fileList
            })
            console.log(JSON.stringify(this.state.fileUpload[0].File))
        }
    }

    handleDeleteFile = (id: number) => {
        var dataArr = JSON.parse(JSON.stringify(this.state.fileUpload))
        dataArr.splice(id, 1)
        this.setState({
            fileUpload: dataArr
        })
    }

    getSrcFile = (file: any) => {
        console.log(file)
        var url = URL.createObjectURL(file)
        console.log(url)
        return url;
    }

    render() {
        return (
            <Container fluid={true} className="mt-4">
                <Row>
                    <Col>
                        <Form.Control
                            as="textarea"
                            rows={3}
                            //readOnly={true}
                            placeholder=""
                            onChange={(e) => this.handleChange(e, 'message')}
                            value={this.state.formReply.message}
                        //ref={this.textInput}
                        />
                    </Col>
                </Row>
                {this.state.fileUpload.length != 0 &&
                    <Row>
                        <Col>
                            <div className="system-message-comment-container-reply-file-container">
                                {/*<Card>*/}
                                {this.state.fileUpload.map((item: file, idx) =>
                                    <span key={idx} className="system-message-attach-image-block-adder" style={{ position: 'relative', display: 'inline-block', backgroundImage: 'url(' + this.getSrcFile(item) + ')', backgroundRepeat: 'no-repeat, repeat', backgroundPosition: 'center', backgroundSize: 'cover' }}>
                                        <FaTimes className="file-insert-remover" style={{ display: 'inline' }} onClick={() => this.handleDeleteFile(idx)} />
                                        {/*<div className="system-message-attach-image-block" style={{ backgroundImage: url({item.file}) }}></div>*/}
                                    </span>
                                )}
                                <div className="system-message-attach-image-block-adder" onClick={() => this.handleClickAddfile()}>
                                    <FaPlusCircle />
                                </div>
                                {/*</Card>*/}
                            </div>
                        </Col>
                    </Row>
                }
                <Row className="mt-2">
                    {/*<Col>*/}
                    {/*    <div className="text-left">*/}
                    {/*        <ButtonGroup aria-label="Basic example">*/}
                    {/*            <Button variant="outline-info">Task</Button>*/}
                    {/*            <Button variant="outline-info">Meeting</Button>*/}
                    {/*            <Button variant="outline-warning">Note</Button>*/}
                    {/*        </ButtonGroup>*/}
                    {/*    </div>*/}
                    {/*</Col>*/}
                    <Col>
                        <div className="text-right">
                            <Form.Group className="position-relative mb-3">
                                <Form.Control
                                    id="input-file"
                                    type="file"
                                    name="file"
                                    ref={this.inputRef}
                                    onChange={(e) => this.handleChangeAddfile(e)}
                                    hidden
                                />
                            </Form.Group>
                            <ButtonGroup aria-label="Basic example">
                                <Button type="file" variant="outline-dark" size="sm" onClick={() => this.handleClickAddfile()}><FaPhotoVideo style={{ color: 'green' }} /> Photo</Button>
                                <Button variant="outline-dark" size="sm"><FaFileAlt style={{ color: 'yellow' }} /> File</Button>
                            </ButtonGroup>
                            <Button variant="info" className="mx-2" size="sm" onClick={() => this.handleSaveReply()}><FaReply /> ตอบกลับความคิดเห็น</Button>
                        </div>
                    </Col>
                </Row>
            </Container>
        )
    }
} export default connect((state: ApplicationState) => ({ ...state.Remark }), ({ ...RemarkStore.actionCreators }))(RemarkReplyComment)

export interface file {
    File: []
}