import { Action, Reducer } from 'redux';
import { AppThunkAction } from '..';
// models
import * as Models from '../../models/workflow/WorkflowConfig'

interface LoadWorkflowConfig {
    type: 'LOAD_WORKFLOWCONFIG'
}
interface LoadedWorkflowConfig {
    type: 'LOADEDWORKFLOWCONFIG'
    response: Models.ResponseWorkflow
    status: Number,
    statusMessage: String
}
interface FailLoadedWorkflowConfig {
    type: 'LOADFAIL'
    status: number,
    statusMessage: any
}
interface ClearLoadWorkflowConfig {
    type: 'CLEAR_WORKFLOWCONFIG'
}

type KnownAction = LoadedWorkflowConfig | FailLoadedWorkflowConfig | LoadWorkflowConfig | ClearLoadWorkflowConfig

export const actionCreators = {
    requestWorkflowConfig: (p: boolean, method: string, company: string, role_id: string, emp_id: string, authority_group: string, workflow_id: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        console.log('call actionCreators : requestWorkflowConfig')
        if (appState && appState.workflowConfig && p && method == 'POST') {
            fetch(`/v2/Workflow/Get?app_id=` + company + `&role_id=` + role_id + `&emp_id=` + emp_id + `&authority_group=` + authority_group + `&workflow_id=` + workflow_id, {
                method: 'GET',
                headers: {
                    'Authorization': 'Bearer ' + localStorage.getItem('WF_APPLICATION'),
                },
            })
                .then((response) => {
                    return response
                })
                .then((data) => {
                    //console.log(data)
                    if (!data.ok) {
                        data.json().then(err => {
                            //console.log(err.message_res)
                            console.log('errorrrr')
                            dispatch({ type: 'LOADFAIL', status: 204, statusMessage: err.message });
                        })
                    } else {
                        data.json().then(data => {
                            //console.log(data)
                            dispatch({ type: 'LOADEDWORKFLOWCONFIG', response: data as Models.ResponseWorkflow, status: 200, statusMessage: '' });
                        })
                    }
                })

            dispatch({ type: 'LOAD_WORKFLOWCONFIG' });
        } else if (method = "CLEAR") {
            dispatch({ type: 'CLEAR_WORKFLOWCONFIG' });
        }
    }
}

const unloadedState: Models.GetWorkflowState = { isLoadWorkflowMaster: false };

export const reducer: Reducer<Models.GetWorkflowState> = (state: Models.GetWorkflowState | undefined, incomingAction: Action): Models.GetWorkflowState => {
    if (state == undefined) {
        return unloadedState;
    }
    const action = incomingAction as KnownAction;
    switch (action.type) {
        case 'LOAD_WORKFLOWCONFIG':
            return {
                isLoadWorkflowMaster: false,
            }

        case 'LOADEDWORKFLOWCONFIG':
            return {
                isLoadWorkflowMaster: true,
                responseWorkflowMaste: action.response,
                statusWorkflowMaste: action.status
            }

        case 'LOADFAIL':
            return {
                isLoadWorkflowMaster: true,
                messageWorkflowMaste: action.statusMessage,
                statusWorkflowMaste: action.status
            }

        case 'CLEAR_WORKFLOWCONFIG':
            return {
                isLoadWorkflowMaster: false,
                messageWorkflowMaste: '',
                statusWorkflowMaste: 0
            }
        default: return state;
    }
}