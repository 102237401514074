import React from 'react';

type ElementProps<P> = React.DetailedHTMLProps<React.HTMLAttributes<P>, P>;

function BorderGrid(props: ElementProps<HTMLDivElement>) {
    const {
        children,
        className = ''
    } = props;
    const childrenArray = addAdditionalClassToChildren(children, 'border-top')

    return (
        <div
            {...props}
            style={{
                ...props.style,
                border: '1px solid #CACACA',
                borderRadius: '3px',
                flex: '1',
            }}
            className={`border-grid border ${className}`}
        >
            {childrenArray}
        </div>
    );
}

function BorderGridRow(props: ElementProps<HTMLDivElement>) {
    const {
        children,
        className = ''
    } = props;
    const childrenArray = addAdditionalClassToChildren(children, 'border-left')

    return (
        <div
            {...props}
            style={{
                ...props.style,
                display: 'flex',
                borderColor: '#CACACA'
            }}
            className={`border-grid-row ${className}`}
        >
            {childrenArray}
        </div>
    );
}

function BorderGridCol(props: ElementProps<HTMLDivElement>) {
    const {
        children,
        className = ''
    } = props;

    return (
        <div
            {...props}
            style={{
                ...props.style,
                flex: 1,
                borderColor: '#CACACA',
                padding: '8px',
            }}
            className={`border-grid-col ${className}`}
        >
            {children}
        </div>
    );
}

// Helper (Do not copy. Compatible with this file only)
function addAdditionalClassToChildren(children: React.ReactNode, className: string) {
    const childrenArray = React.Children.toArray(children);
    const childrenArrayLength = childrenArray.length

    if (childrenArrayLength > 1) {
        for (let i = 0; i < childrenArrayLength; i++) {
            const child = childrenArray[i];

            if (!React.isValidElement(child)) {
                return null;
            }

            if (typeof child.type === 'string') {
                return null;
            }

            if (i === 0) {
                continue;
            }

            if (child.props.className) {
                className = `${child.props.className} ${className}`
            }
            childrenArray[i] = React.cloneElement(child as React.ReactElement<ElementProps<HTMLDivElement>>, { ...child.props, className });
        }
    }

    return childrenArray;
}

export default Object.assign(BorderGrid, {
    Row: BorderGridRow,
    Col: BorderGridCol
});