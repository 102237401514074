import { Action, Reducer } from 'redux';
import { AppThunkAction } from '..';
// models
import * as Models from '../../models/callbackconfig/CallbackMaster'

interface BeforeLoadCallbackconfig {
    type: 'LOAD_CALLBACK_CONFIG'
}
interface LoadCallbackconfig{
    type: 'LOADCALLBACKCONFIG'
    response: Models.CallbackResponse
    status: Number,
    statusMessage: String
}
interface FailCallbackconfig {
    type: 'LOADFAIL'
    status: number,
    statusMessage: any
}
interface ClearLoadCallbackconfig {
    type: 'CLEARLOADCALLBACKCONFIG'
}
type KnownAction = LoadCallbackconfig | FailCallbackconfig | BeforeLoadCallbackconfig | ClearLoadCallbackconfig

export const actionCreators = {
    requestCallbackMaster: (p: boolean, method: String, company :string): AppThunkAction<KnownAction> => async (dispatch, getState) => {
        const appState = getState();
        console.log('call actionCreators : requestCallbackMaster')
        if (appState && appState.positionEmployee && p && method == 'POST') {
            await fetch(`/v2/Callbackconfig/Get?app_id=` + company, {
                method: 'GET',
                headers: {
                    'Authorization': 'Bearer ' + localStorage.getItem('WF_APPLICATION'),
                },
            })
                .then((response) => {
                    return response
                })
                .then((data) => {
                    //console.log(data)
                    if (!data.ok) {
                        data.json().then(err => {
                            //console.log(err.message_res)
                            console.log('dispatch : LOADFAIL')
                            dispatch({ type: 'LOADFAIL', status: 204, statusMessage: err.message });
                        })
                    } else {
                        data.json().then(data => {
                            //console.log(data)
                            console.log('dispatch : LOADCALLBACKCONFIG')
                            dispatch({ type: 'LOADCALLBACKCONFIG', response: data as Models.CallbackResponse, status: 200, statusMessage: '' });
                        })
                    }
                })
            dispatch({ type: 'LOAD_CALLBACK_CONFIG' });
        } else if (method == 'CLEAR') {
            dispatch({ type: 'CLEARLOADCALLBACKCONFIG' });
        }
    }
}

const unloadedState: Models.CallbackMasterState = { CallbackisLoad: false };

export const reducer: Reducer<Models.CallbackMasterState> = (state: Models.CallbackMasterState | undefined, incomingAction: Action): Models.CallbackMasterState => {
    if (state == undefined) {
        return unloadedState;
    }
    const action = incomingAction as KnownAction;
    switch (action.type) {
        case 'LOAD_CALLBACK_CONFIG':
            return {
                CallbackisLoad: false,
            }

        case 'LOADCALLBACKCONFIG':
            return {
                CallbackisLoad: true,
                Callbackresponse: action.response,
                Callbackstatus: action.status
            }

        case 'LOADFAIL':
            return {
                CallbackisLoad: true,
                Callbackmessage: action.statusMessage,
                Callbackstatus: action.status
            }

        case 'CLEARLOADCALLBACKCONFIG':
            return {
                CallbackisLoad: false,
                Callbackmessage: '',
                Callbackstatus: 400
            }
        default: return state;
    }
}