import {
    DatasourceReturnType,
    ResponseError
} from '../../../models/common/respone.model';
import {
    getLIFF,
    putLineId
} from '../datasources/LineConnect.datasource';
import {
    ResponseGetLIFF,
    RequestAddLineId,
    ResponseAddLineId
} from '../schemas/LineConnect.schema';

export async function getLIFFData(
): Promise<DatasourceReturnType<ResponseError, ResponseGetLIFF>> {
    try {
        const data = (await getLIFF()).data;

        return [null!, data];
    } catch (error) {
        throw error;
    }
}

export async function updateLineId(
    params: RequestAddLineId
): Promise<DatasourceReturnType<ResponseError, ResponseAddLineId>> {
    try {
        const data = (await putLineId(params)).data;

        return [null!, data];
    } catch (error) {
        throw error;
    }
}