import * as React from "react";
import { Row, Col, InputGroup, FormControl } from "react-bootstrap";

const formdata = {
    conditionid: "",
    conditionname: "",
}

class AddConditionDetail extends React.Component<any, { conditionID: String, conditionName: string, formData: {}, checkSendValue: boolean, checkUpdate: boolean, user_login_id: string }> {

    constructor(props: any) {
        super(props);
        this.state = {
            conditionID: "",
            conditionName: "",
            formData: {},
            checkSendValue: true,
            checkUpdate: true,
            user_login_id: "jft",
        }
    }

    haddleAddCondition = (mode: String) => (e: { target: { name: any; value: any; }; }) => {
        if (mode == "id") {
            this.setState({
                conditionID: e.target.value
            })
        } else if (mode == "name") {
            this.setState({
                conditionName: e.target.value
            })
        }
    }

    componentDidUpdate(nextProps: any, nextState: any) {
        const { requestValue, updateValue, checkupdateID, checkEditModal, checkSetEditModal} = this.props
        if (requestValue && checkEditModal) {
            let formData = { ...this.state.formData }
            const item = {
                id: this.state.conditionID,
                name: this.state.conditionName,
                user_login_id: this.state.user_login_id
            };
            formData = item;
            this.setState(prevState => ({
                formData
            }));
            this.props.blindConditionValue(item)
            this.props.clearCheckEditModal("Edit")
        } else if (checkupdateID && updateValue != "" && checkSetEditModal) {
            this.props.clearCheckEditModal("setEdit")
            this.setState(prevState => ({
                conditionID: updateValue.id,
                conditionName: updateValue.name,
                checkUpdate: false,
            }))
           
            let formData = { ...this.state.formData }
            const item = {
                id: updateValue.id,
                name: updateValue.name,
                user_login_id: this.state.user_login_id
            };
            formData = item;
            this.setState(prevState => ({
                formData,
            }));
        }
    }

    render() {
        return (
            <div>
                <Row>
                    <Col>
                        <InputGroup className="mb-3">
                            <InputGroup.Prepend>
                                <InputGroup.Text id="basic-addon1">Parameter ID :</InputGroup.Text>
                            </InputGroup.Prepend>
                            <FormControl
                                placeholder="ID"
                                aria-label="Username"
                                aria-describedby="basic-addon1"
                                type="text"
                                onChange={this.haddleAddCondition('id')}
                                value={String(this.state.conditionID)}
                                readOnly={this.props.readonlyUpdate}
                            />
                        </InputGroup>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <InputGroup className="mb-3">
                            <InputGroup.Prepend>
                                <InputGroup.Text id="basic-addon1">Parameter Name :</InputGroup.Text>
                            </InputGroup.Prepend>
                            <FormControl
                                placeholder="Name"
                                aria-label="Username"
                                aria-describedby="basic-addon1"
                                type="text"
                                onChange={this.haddleAddCondition('name')}
                                value={this.state.conditionName}
                            />
                        </InputGroup>
                    </Col>
                </Row>
            </div>
        )
    }
}
export default AddConditionDetail