import { Action, Reducer } from 'redux';
import { AppThunkAction } from '..';

import * as Models from '../../models/workflow/WorkflowType'

interface BeforeLoadWorkflowType {
    type: 'LOAD_WORKFLOWTYPE'
}
interface LoadWorkflowType {
    type: 'LOADWORKFLOWTYPE'
    response: Models.ResponseWorkflowType
    status: Number,
    statusMessage: String
}
interface FailLoadWorkflowType {
    type: 'LOADWORKFLOWTYPEFAIL'
    status: number,
    statusMessage: any
}

interface ClearLoadWorkflowType {
    type: 'CLEARLOADWORKFLOWTYPE'
    status: number,
    statusMessage: any
}

type KnownAction = LoadWorkflowType | FailLoadWorkflowType | ClearLoadWorkflowType | BeforeLoadWorkflowType

export const actionCreators = {
    requestWorkflowTypeInbox: (p: boolean, method: String, user_id: string, sids: string[], searchReq: Models.searchValue): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.workflowInbox && p && method == 'POST') {
            var sid = "", stanffid = "";
            sids.forEach(element => {
                sid += "&app_id=" + element
            });

            var createdOnFrom = searchReq.createBy;
            var createdOnTo = searchReq.createByTo;

            //if (searchReq.createByTo) {
            //    createdOnTo = searchReq.createByTo + '235900';
            //}

            //if (searchReq.createBy) {
            //    createdOnFrom = searchReq.createBy + '000000'
            //}

            //var createdOnTo = searchReq.createByTo
            //if (searchReq.createBy !== "" && createdOnTo === "") {
            //    createdOnTo = searchReq.createBy
            //}
            if (searchReq.userName !== "" && searchReq.userName !== "*") {
                //if (searchReq.userName == "*") {
                //    user_id = "";
                //} else {
                user_id = searchReq.userName
                //}
            }
            //console.log(user_id)
            fetch('/v4/WorkflowTypeInbox/Get?user_id=' + user_id + sid + '&doc_no=' + searchReq.wfNo + '&doc_year=' + searchReq.fiscalYear + '&doc_date_from=' + createdOnFrom + '&doc_date_to=' + createdOnTo + '&requester_id=' + searchReq.requester_id + '&amount_from=' + searchReq.docAmount + '&amount_to=' + searchReq.docAmountTo + '&stanffid=' + stanffid, {
                method: 'GET',
                headers: {
                    'Authorization': 'Bearer ' + localStorage.getItem('WF_APPLICATION'),
                },
            })
                .then((response) => {
                    //console.log("requestWorkflowTypeInbox")
                    //console.log(response)
                    return response
                })
                .then((data) => {
                    //console.log(data)
                    if (!data.ok) {
                        data.json().then(err => {
                            //console.log(err.message_res)
                            console.log(err)
                            dispatch({ type: 'LOADWORKFLOWTYPEFAIL', status: 204, statusMessage: err.message });
                        })
                    } else {
                        data.json().then(data => {
                            //console.log(data)
                            dispatch({ type: 'LOADWORKFLOWTYPE', response: data as Models.ResponseWorkflowType, status: 200, statusMessage: '' });
                        })
                    }
                })

            dispatch({ type: 'LOAD_WORKFLOWTYPE' });

        } else if (method == "CLEAR") {
            dispatch({ type: 'CLEARLOADWORKFLOWTYPE', status: 400, statusMessage: "" });
        }
    },

    requestWorkflowTypeReport: (p: boolean, method: String, user_id: string, app_id: string[], searchReq: Models.searchValue, wfStatus: string[]): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.workflowInbox && p && method == 'POST') {
            //console.log("post")
            //console.log(wf_id)
            //console.log(sid)
            var app_ids = ""
            app_id.forEach(element => {
                app_ids += "&app_id=" + element
            });

            var wfstatus = ""
            wfStatus.forEach(element => {
                wfstatus += "&wf_status=" + element
            });

            var createdOnTo = searchReq.createByTo
            //if (createdOnTo) {
            //    createdOnTo += '235900';
            //}
            var updatedOnTo = searchReq.updatedTo
            //if (updatedOnTo) {
            //    updatedOnTo += '235900';
            //}

            var createdfrom = searchReq.createBy
            //if (createdfrom) {
            //    createdfrom += '000000'
            //}

            var updatefrom = searchReq.updatedFrom
            //if (updatefrom) {
            //    updatefrom += '000000'
            //}
            console.log(searchReq)
            fetch('/v4/WorkflowTypeReport/Get?user_id=' + user_id + app_ids + wfstatus + '&doc_no=' + searchReq.wfNo + '&doc_year=' + searchReq.fiscalYear + '&doc_date_from=' + createdfrom + '&doc_date_to=' + createdOnTo + '&updated_from=' + updatefrom + '&updated_to=' + updatedOnTo + '&requester_id=' + searchReq.requester_id + '&amount_from=' + searchReq.docAmount + '&amount_to=' + searchReq.docAmountTo, {
                method: 'GET',
                headers: {
                    'Authorization': 'Bearer ' + localStorage.getItem('WF_APPLICATION'),
                },
            })
                .then((response) => {
                    //console.log("requestWorkflowTypeInbox")
                    console.log(response)
                    return response
                })
                .then((data) => {
                    //console.log(data)
                    if (!data.ok) {
                        data.json().then(err => {
                            //console.log(err.message_res)
                            console.log(err)
                            dispatch({ type: 'LOADWORKFLOWTYPEFAIL', status: 204, statusMessage: err.message });
                        })
                    } else {
                        data.json().then(data => {
                            //console.log(data)
                            dispatch({ type: 'LOADWORKFLOWTYPE', response: data as Models.ResponseWorkflowType, status: 200, statusMessage: '' });
                        })
                    }
                })

            dispatch({ type: 'LOAD_WORKFLOWTYPE' });

        } else if (method == "CLEAR") {
            console.log("dispatch : CLEARLOADWORKFLOWTYPE")
            dispatch({ type: 'CLEARLOADWORKFLOWTYPE', status: 400, statusMessage: "" });
        }
    }
}

const unloadedState: Models.GetWorkflowTypeState = { isLoadWorkflowType: false };

export const reducer: Reducer<Models.GetWorkflowTypeState> = (state: Models.GetWorkflowTypeState | undefined, incomingAction: Action): Models.GetWorkflowTypeState => {
    if (state == undefined) {
        return unloadedState;
    }
    const action = incomingAction as KnownAction;
    switch (action.type) {
        case 'LOAD_WORKFLOWTYPE':
            return {
                isLoadWorkflowType: false,
            }

        case 'LOADWORKFLOWTYPE':
            return {
                isLoadWorkflowType: true,
                responseWorkflowType: action.response,
                statusWorkflowType: action.status
            }

        case 'LOADWORKFLOWTYPEFAIL':
            return {
                isLoadWorkflowType: true,
                messageWorkflowType: action.statusMessage,
                statusWorkflowType: action.status
            }
        case 'CLEARLOADWORKFLOWTYPE':
            return {
                isLoadWorkflowType: false,
                messageWorkflowType: "",
                statusWorkflowType: 400
            }
        default: return state;
    }
}