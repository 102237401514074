import {
    BufferLogResponse,
    BufferLogResponseList
} from 'data/Log/schemas/LogBuffer.shema';
import { formatDateTime, formatYMDToDMY } from 'modules/Common';
import React from 'react';
import { Button, Container, Row, Col, Table, Spinner } from 'react-bootstrap';
import { FaSearch, FaTimesCircle } from 'react-icons/fa';

type Props = {
    logBuffer: BufferLogResponse;
    mappingCompany: Map<string, string>;
    explorerDetail: (logDetail?: BufferLogResponseList) => void;
};
function LogBufferTable(props: Props) {
    const { logBuffer, mappingCompany, explorerDetail } = props;

    return (
        <Container>
            <Row className="mt-3">
                <Col>
                    <Table
                        className="table-bordered align-middle"
                        size="sm"
                    >
                        <thead>
                            <tr
                                style={{ width: '100%' }}
                                className="table-active thead-light text-center"
                            >
                                <th style={{ width: '5%' }}>Buffer ID</th>
                                <th style={{ width: '5%' }}>Buffer Status</th>
                                <th style={{ width: '5%' }}>Company</th>
                                <th style={{ width: '10%' }}>
                                    Document Number
                                </th>
                                <th style={{ width: '8%' }}>Document Year</th>
                                <th style={{ width: '10%' }}>Create By</th>
                                <th style={{ width: '10%' }}>Create On</th>
                                <th style={{ width: '5%' }}>Type</th>
                                <th style={{ width: '5%' }}>See More</th>
                            </tr>
                        </thead>
                        <tbody>
                            {!logBuffer ? (
                                <tr>
                                    <td
                                        colSpan={9}
                                        style={{
                                            background:
                                                'rgba(209, 250, 221, 1)',
                                            color: 'black',
                                            fontSize: '10pt',
                                            textAlign: 'center'
                                        }}
                                    >
                                        {/* <Spinner
                                            animation="border"
                                            size="sm"
                                        /> */}
                                        &nbsp;&nbsp;No data...
                                    </td>
                                </tr>
                            ) : (
                                logBuffer.resultList.map((value, index) => (
                                    <tr key={index}>
                                        <td className="text-center">
                                            {value.bufferid}
                                        </td>
                                        <td className="text-center">
                                            {value.bufferstatus}
                                        </td>
                                        <td>{mappingCompany.get(value.sid)}</td>
                                        <td>{value.paramdocno}</td>
                                        <td className="text-center">
                                            {value.paramdocyear}
                                        </td>
                                        <td>
                                            {value.created_by}
                                        </td>
                                        <td className="text-center">
                                            {formatYMDToDMY(value.created_on)}
                                        </td>
                                        <td className="text-center">
                                            {value.type}
                                        </td>
                                        <td className="text-center">
                                            <FaSearch
                                                onClick={() =>
                                                    explorerDetail(value)
                                                }
                                            />
                                        </td>
                                    </tr>
                                ))
                            )}
                        </tbody>
                    </Table>
                </Col>
            </Row>
        </Container>
    );
}

export default LogBufferTable;
