import * as React from "react";
import { Container } from 'reactstrap';
import { ApplicationState } from "../../store";
import { Button, Card, Col, Form, Row, Table } from "react-bootstrap";

class TransactionInformation extends React.Component<any, { isLoading : boolean , src : string}>{

    constructor(props: any) {
        super(props);
        this.state = {
            isLoading: true,
            src : ""
        }
    }

    componentDidMount() {
        const { workflowValue } = this.props

        //fetch("/v1/transaction/get-url?username=admin")
        //    .then(res => res.json())
        //    .then(
        //        (result) => {
        //            console.log(result)
        //        },
        //        (error) => {
        //        }
        //    )

        if (workflowValue.wfType == "PR") {
            //var src: string = "https://scieco-p2p-uat.thailanderp.com/PR/PRTransaction.aspx?defaultpage=true&docnumber=" + workflowValue.docNo + "&fiscalyear=" + workflowValue.fiscalYear + "&doctype=" + workflowValue.docType + "&mode=DISPLAY"
            var src: string = "https://qmix-p2p-uat2.thailanderp.com/Ingress.aspx?token=eyJhbGciOiJSUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6IjF4a2JMWWRYbUFzdEQ5WEtwWklOd05UU2dHaSIsInNlciI6IlMwMDAyIiwiYXBwIjoiMW1IVVNYTktlR25tM3lNejRrOXZEb0RCQTNiIiwia2V5IjoiMXBtdW9waEZIU21hUExITER6d3g3NmpGVW5OIiwiZXh0cmEiOiJ7XCJjaXRpemVuX2lkXCI6XCJhZG1pblwiLFwiZmlyc3RfbmFtZVwiOm51bGwsXCJsYXN0X25hbWVcIjpudWxsLFwiZW1haWxcIjpudWxsfSIsImV4cCI6MTk0NjQ3Nzk3NywiaWF0IjoxNjMwOTAxOTc3LCJpc3MiOiJTT05BUiBJTk5PVkFUSU9OIENPLiwgTFRELiJ9.kQ5fGDUJA-jpIIIHzaGwrT7rSgUqYZFVdyQ0PSjNexRj7uPqeuxYsAAFTTx0diAxBektRcbNL_BD_xaeDeDQACoeYNUvfguQWuaBAE0S5fjHYyN_ENlaDZXdSuZmbuK1mN0d2FhoH2vxV4lDwZNLBXNQLqWWOaSdlqEQ1L0RqZc&tgurl=%2fPO%2fv2%2fPOTransaction.aspx%3Fdefaultpage=true%26docnumber=K410D21000063%26fiscalyear=2021%26doctype=K410D%26mode=DISPLAY&lang=en-US"
            console.log(src)
            //var src = "https://link.focusonesoftware.com"
            this.setState({
                src : src
            })
        } else if (workflowValue.wfType == "PO") {
            //var src: string = "https://qmix-p2p-uat.thailanderp.com/PO/v2/POTransaction.aspx?defaultpage=true&docnumber=" + workflowValue.docNo + "&fiscalyear=" + workflowValue.fiscalYear + "&doctype=" + workflowValue.docType + "&mode=DISPLAY"
            var src: string = "https://qmix-p2p-uat2.thailanderp.com/Ingress.aspx?token=eyJhbGciOiJSUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6IjF4a2JMWWRYbUFzdEQ5WEtwWklOd05UU2dHaSIsInNlciI6IlMwMDAyIiwiYXBwIjoiMW1IVVNYTktlR25tM3lNejRrOXZEb0RCQTNiIiwia2V5IjoiMXBtdW9waEZIU21hUExITER6d3g3NmpGVW5OIiwiZXh0cmEiOiJ7XCJjaXRpemVuX2lkXCI6XCJhZG1pblwiLFwiZmlyc3RfbmFtZVwiOm51bGwsXCJsYXN0X25hbWVcIjpudWxsLFwiZW1haWxcIjpudWxsfSIsImV4cCI6MTk0NjQ3Nzk3NywiaWF0IjoxNjMwOTAxOTc3LCJpc3MiOiJTT05BUiBJTk5PVkFUSU9OIENPLiwgTFRELiJ9.kQ5fGDUJA-jpIIIHzaGwrT7rSgUqYZFVdyQ0PSjNexRj7uPqeuxYsAAFTTx0diAxBektRcbNL_BD_xaeDeDQACoeYNUvfguQWuaBAE0S5fjHYyN_ENlaDZXdSuZmbuK1mN0d2FhoH2vxV4lDwZNLBXNQLqWWOaSdlqEQ1L0RqZc&tgurl=%2fPO%2fv2%2fPOTransaction.aspx%3Fdefaultpage=true%26docnumber=K410D21000063%26fiscalyear=2021%26doctype=K410D%26mode=DISPLAY%26isiframe=true&lang=en-US"
            console.log(src)
            this.setState({
                src: src
            })
        }
    }

    hideSpinner = () => {
        this.setState({
            isLoading: false
        })
    }

    render() {
        const { wfId, docNo, wfInstanceno, userId, statusWorkflowInboxValue, responseWorkflowInboxValue, showmodal, isLoadWorkflowInboxValue } = this.props

        //if (this.state.isLoading) {
        //    document.getElementsByClassName('Loader__background')[0].className = "Loader__background show";
        //}
        //else {
        //    if (this.props.isWorkflowReport == undefined) {
        //        document.getElementsByClassName('Loader__background')[0].className = "Loader__background hide";
        //    }
        //}

        console.log(this.state.src)

        return (
            <Container fluid={true} className="mt-3">
                <Row className="mt-3">
                    {/*<iframe style={{ WebkitWritingMode: "vertical-lr", overflow: 'hidden' }} src="https://qmix-p2p-uat2.thailanderp.com/Ingress.aspx?token=eyJhbGciOiJSUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6IjF4a2JMWWRYbUFzdEQ5WEtwWklOd05UU2dHaSIsInNlciI6IlMwMDAyIiwiYXBwIjoiMW1IVVNYTktlR25tM3lNejRrOXZEb0RCQTNiIiwia2V5IjoiMXBtdW9waEZIU21hUExITER6d3g3NmpGVW5OIiwiZXh0cmEiOiJ7XCJjaXRpemVuX2lkXCI6XCJhZG1pblwiLFwiZmlyc3RfbmFtZVwiOm51bGwsXCJsYXN0X25hbWVcIjpudWxsLFwiZW1haWxcIjpudWxsfSIsImV4cCI6MTk0NjQ3Nzk3NywiaWF0IjoxNjMwOTAxOTc3LCJpc3MiOiJTT05BUiBJTk5PVkFUSU9OIENPLiwgTFRELiJ9.kQ5fGDUJA-jpIIIHzaGwrT7rSgUqYZFVdyQ0PSjNexRj7uPqeuxYsAAFTTx0diAxBektRcbNL_BD_xaeDeDQACoeYNUvfguQWuaBAE0S5fjHYyN_ENlaDZXdSuZmbuK1mN0d2FhoH2vxV4lDwZNLBXNQLqWWOaSdlqEQ1L0RqZc&tgurl=%2fPO%2fv2%2fPOTransaction.aspx%3Fdefaultpage=true%26docnumber=K410D21000063%26fiscalyear=2021%26doctype=K410D%26mode=DISPLAY%26isiframe=true&lang=en-US" scrolling="no" name="myIframe" height="100%" id="IFrame" width="100%" title="Iframe Example" onLoad={this.hideSpinner} frameBorder="0" ref="iframe"></iframe>*/}
                </Row>
            </Container>
        );
    }
}

export default TransactionInformation;