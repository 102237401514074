import axios from 'axios';
import {
    getExportLogBuffer,
    getLogBuffer
} from 'data/Log/repositories/LogBuffer.repository';
import {
    BufferLogRequest,
    BufferLogResponse,
    BufferLogResponseList
} from 'data/Log/schemas/LogBuffer.shema';
import useLoader from 'hooks/useLoader';
import {
    MasterCompanyList,
    MasterCompanyResponse
} from 'models/company/MasterCompany';
import { useEffect, useState } from 'react';
import Swal from 'sweetalert2';
import * as XLSX from 'xlsx';

function LogBufferViewModel() {
    const loading = useLoader();
    const [dateForm, setDateForm] = useState(new Date());
    const [dateTo, setDateTo] = useState(new Date());
    const [searchHelp, setSearchHelp] = useState<BufferLogRequest>({
        BufferId: '',
        BufferStatus: '',
        CreatedTo: '',
        CreatedOn: '',
        DocumentNumber: '',
        DocumentYear: '',
        Page: 1,
        PageSize: 20,
        SID: ''
    });
    const [companyMaster, setCompanyMater] = useState<MasterCompanyList[]>();
    const [logBufferResponse, setLogBufferResponse] =
        useState<BufferLogResponse>();

    const [explorerDetail, setExplorerDetail] = useState<boolean>(false);
    const [logBufferDetail, setLogBufferDetail] =
        useState<BufferLogResponseList>();

    const [selectdAllCompany, setSelectdAllCompany] = useState<boolean>(true);

    useEffect(() => {
        fetchCompanyMaster();
    }, []);

    useEffect(() => {
        handleSearchLog();
    }, [searchHelp.PageSize, searchHelp.Page]);

    const fetchCompanyMaster = async () => {
        const response = await axios.get<MasterCompanyResponse>(
            '/v1/company_master',
            {
                headers: {
                    Authorization:
                        'Bearer ' + localStorage.getItem('WF_APPLICATION')
                }
            }
        );
        setCompanyMater(response.data.result_list);
    };

    const fetchLogBuffer = async () => {
        if (searchHelp.SID === '' && !selectdAllCompany) {
            Swal.fire({
                icon: 'info',
                title: 'Please choose',
                text: 'กรุณาเลือก Company'
            });

            return;
        }
        try {
            loading.show();
            const [, response] = await getLogBuffer(searchHelp);
            if (response) {
                setExplorerDetail(false);
                setLogBufferResponse(response);
            }
        } finally {
            loading.hide();
        }
    };
    const handleSearchLog = async () => {
        await fetchLogBuffer();
    };

    const mappingCompany = new Map<string, string>(
        companyMaster?.map(value => [value.sid, value.company_name])
    );

    const handleChangeSearchHelp = <K extends keyof BufferLogRequest>(
        key: K,
        value: BufferLogRequest[K]
    ) => {
        setSearchHelp(prevState => ({
            ...prevState,
            [key]: value
        }));
    };

    const handleExplorerDetail = (logDetail?: BufferLogResponseList) => {
        setExplorerDetail(prev => !prev);

        if (logDetail) {
            setLogBufferDetail(logDetail);
        }
    };

    const handleCheckboxChange = (value: string) => {
        setSearchHelp(prevState => {
            let newBufferStatus =
                prevState.BufferStatus.split(',').filter(Boolean);

            if (newBufferStatus.includes(value)) {
                newBufferStatus = newBufferStatus.filter(
                    item => item !== value
                );
            } else {
                newBufferStatus.push(value);
            }

            const updatedBufferStatus = newBufferStatus.join(',');

            return {
                ...prevState,
                BufferStatus: updatedBufferStatus
            };
        });
    };

    const ExportTemplate = async () => {
        try {
            loading.show();
            var date = new Date();
            var filename = 'LogBuffer' + date.getTime().toString() + '.xlsx';
            var Employee: any = [];

            const [, response] = await getExportLogBuffer(searchHelp);
            if (response) {
                response.map(item => {
                    let data = {};
                    data['Company'] = mappingCompany.get(item.sid);
                    data['Buffer ID'] = item.bufferid;
                    data['Buffer Status'] = item.bufferstatus;
                    data['Instance Number'] = item.instanceno;
                    data['Document Number'] = item.paramdocno;
                    data['Document Year'] = item.paramdocyear;
                    data['Type'] = item.type;
                    data['created_by'] = item.created_by;
                    data['created_on'] = item.created_on;
                    data['Buffer Error Massage'] = item.buffer_errormessage;
                    data['Json Data'] = item.jsondata;
                    Employee.push(data);
                });
                var ws_Employee = XLSX.utils.json_to_sheet(Employee);
                var wb = XLSX.utils.book_new();
                XLSX.utils.book_append_sheet(wb, ws_Employee, 'Log Buffer');
                XLSX.writeFile(wb, filename);
            }
        } finally {
            loading.hide();
        }
    };

    return {
        dateTo,
        dateForm,
        searchHelp,
        companyMaster,
        explorerDetail,
        logBufferDetail,
        handleSearchLog,
        selectdAllCompany,
        logBufferResponse,

        setDateForm,
        setDateTo,
        setSearchHelp,
        mappingCompany,
        ExportTemplate,
        setSelectdAllCompany,
        handleCheckboxChange,
        handleExplorerDetail,
        handleChangeSearchHelp
    };
}

export default LogBufferViewModel;
