import * as React from "react";
import { connect } from "react-redux";
import { Row, Col, Spinner } from "react-bootstrap";
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import WorkflowLogTable from './LogTable';
import WorkflowLogSearchIdTab from './WorkflowLogSearchIdTab'
import { ApplicationState } from "../../store";

import * as LogTypeModels from '../../models/log/LogType'
import * as LogTypeStore from '../../store/log/LogType'

import * as AppState from '../../store/AppState'
import * as ModelsAppState from '../../models/AppState'

type store = LogTypeModels.GetLogTypeState & typeof LogTypeStore.actionCreators
type AppStateProps = ModelsAppState.AppState & typeof AppState.actionCreators

interface Props { }

interface State {
    currentPage: any,
    sids: string[],
    checkRequest: boolean,
    uniqueLogType: any,
    countResponse: any,
    LogTypeColour: any,
    countTab: any,
    pageTab: any,
    key: any,
    checkPage: any,
    SidArr: any,
    searchReq: any,
    isFetching: boolean,
    selectedLogType: string,
    showUniqueTab: boolean
}

class WorkflowLog extends React.Component<any, State, store & AppStateProps> {
    constructor(props: any) {
        super(props);

        this.state = {
            currentPage: 0,
            sids: ['*'],
            checkRequest: true,
            uniqueLogType: [],
            countResponse: "",
            LogTypeColour: ['#6D6875', '#B5838D', '#E5989B', '#FFB4A2', '#FFCDB2', '#CDB4DB', '#FFAFCC', '#BDE0FE', '#A2D2FF', '#0096C7'],
            countTab: 0,
            pageTab: null,
            key: "",
            checkPage: 0,
            SidArr: [],
            searchReq: {
                wfNo: "",
                docAmount: "",
                docAmountTo: "",
                logType: "",
                createBy: "",
                createByTo: "",
                requester_id: "",
                updatedFrom: "",
                updatedTo: "",
            },
            isFetching: false,
            selectedLogType: "",
            showUniqueTab: false
        }
        this.blindSidArr = this.blindSidArr.bind(this)
        this.pageSet = this.pageSet.bind(this)
    }

    componentDidMount() {
        var ls: any = localStorage.getItem("WF_APPLICATION");
        var jls: any = JSON.parse(ls)
        //this.props.requestLogTypeInbox(true, 'POST', this.state.sids)
        this.props.updateUrl(this.props.location.pathname)
    }

    componentDidUpdate(nextProps: any, nextState: any) {
        const { statusLogType, responseLogType } = this.props
        if (statusLogType == 200 && this.state.checkRequest && this.state.isFetching) {
            console.log(JSON.stringify(responseLogType))
            if (responseLogType['result_list']) {
                var dataRs: any = []
                //var dataArr: any = {}
                var countArr: number = 0
                for (let i = 0; i < responseLogType['result_list'].length; i++) {
                    countArr += parseInt(responseLogType['result_list'][i].count)
                }
                console.log(countArr)
                for (let i = 0; i < responseLogType['result_list'].length; i++) {
                    var result = dataRs.filter(item => item.log_type === responseLogType['result_list'][i].log_type);
                    if (result.length > 0) {
                        result[0].count = (parseInt(result[0].count) + parseInt(responseLogType['result_list'][i].count)).toString()
                    }
                    else {
                        dataRs.push(responseLogType['result_list'][i])
                    }
                }
                console.log(dataRs)
                
                if (this.state.searchReq.wfLogType && typeof this.state.searchReq.wfLogType !== "undefined") {
                    dataRs = dataRs.filter((obj) => obj.log_type === this.state.searchReq.wfLogType);
                    countArr = dataRs.reduce((accumulator, obj) => obj.count, null);
                    this.setState({showUniqueTab: false})
                } else {
                    this.setState({showUniqueTab: true})
                }

                this.setState({
                    checkRequest: false,
                    uniqueLogType: dataRs,
                    countResponse: countArr,
                    countTab: countArr,
                    isFetching: false
                })

            }
        }
    }

    handleSearch = (value: any, checkAll: any, searchReq: any) => {
        this.props.requestLogTypeInbox(true, "CLEAR", this.props.sids)

        this.setState({ checkRequest: true });
        this.setState({ isFetching: true });

        var dataReq = searchReq
        dataReq['createBy'] = searchReq.createBy
        dataReq['createByTo'] = searchReq.createByTo
        var sids = ['']
        //false
        if (checkAll) {
            sids = ["*"]
        }
        else {
            sids.shift()
            sids.push(value);
        }

        this.setState({
            sids: sids,
            searchReq: dataReq
        });

        this.props.requestLogTypeInbox(true, 'POST', sids, dataReq)

        setTimeout(() => {
            console.log(this.state.sids)
            //this.refresh();
        }, 1000);
    };

    onSelectLogType = (selectLogType: string) => {
        this.setState({
            selectedLogType: selectLogType
        })
    }

    blindTabs() {

    }

    public blindSidArr(value: any) {
        console.log(value)
        this.setState({
            SidArr: value
        })
    }
    public pageSet(value: any) {
        console.log(value)
        this.setState({
            checkPage: value
        })
    }

    handdleChangeLogType = (key: any, index: any) => {
        console.log(key)

        let x = document.getElementsByClassName("trapezoid");
        for (let i = 0; i < x.length; i++) {
            if (x.length > 0) { x[i].classList.remove("active"); }
        }
        if (index != null) {
            var element = document.getElementById("trapezoidTap" + index)
            if (element) {
                element.classList.add('active')
                this.setState({
                    pageTab: key.log_type,
                    countTab: key.count,
                    currentPage: index + 1
                })

                setTimeout(() => {
                    console.log('Tab : ' + this.state.pageTab + ' Tabcount : ' + this.state.countTab)
                }, 1000);
            }
        } else {
            var element = document.getElementById("trapezoidTapAll")
            if (element) {
                element.classList.add('active')
                this.setState({
                    pageTab: key.log_type,
                    countTab: key.count,
                    currentPage: 0
                })
                //this.refresh()
                setTimeout(() => {
                    console.log('Tab : ' + this.state.pageTab + ' Tabcount : ' + this.state.countTab)
                }, 1000);
            }
        }
    }

    render() {
        return (
            <div>
                <div className="master-data-content">
                    <div className='trapezoid-head' style={{ width: '200px' }}>
                        <span>
                            Workflow Log
                        </span>
                    </div>
                    <div className="mat-box">
                        <WorkflowLogSearchIdTab 
                        blindSidArr={this.blindSidArr}
                        handleSearch={this.handleSearch} 
                        isFetching={this.state.isFetching}
                        onSelectLogType={this.onSelectLogType}
                        />
                        {
                            (!this.state.checkRequest)
                                ? (
                                    <Row>
                                        <Col>
                                            <AppBar position="static" color="inherit" style={{ boxShadow: 'none', zIndex: 900 }}>
                                                <Tabs
                                                    indicatorColor="primary"
                                                    textColor="primary"
                                                    variant="scrollable"
                                                    scrollButtons="auto"
                                                    aria-label="scrollable auto tabs example"
                                                    value={this.state.currentPage}
                                                    onChange={this.blindTabs}
                                                >
                                                    <div className='nav-item' onClick={() => this.handdleChangeLogType('', null)}>
                                                        <a className='pointer'>
                                                            <div className='trapezoid trapezoid-purple active' id='trapezoidTapAll' style={{ width: '230px' }}>
                                                                <span>
                                                                    <i className="fa fa-search" aria-hidden="true"></i>
                                                                    {this.state.checkRequest ? 'เอกสาร (0 รายการ)' : 'ประวัติทั้งหมด (' + this.state.countResponse + ' รายการ)'}
                                                                </span>
                                                            </div>
                                                        </a>
                                                    </div>
                                                    {this.state.showUniqueTab && this.state.uniqueLogType.length !== 0 ? this.state.uniqueLogType.map((data, index) => {
                                                        return (
                                                            <div key={index} className='nav-item' onClick={() => this.handdleChangeLogType(data, index)}>
                                                                <a className='pointer'>
                                                                    <div className='trapezoid' id={'trapezoidTap' + index} style={{ width: '230px', borderBottom: "32px solid " + this.state.LogTypeColour[index] + "" }}>
                                                                        <span>
                                                                            <i className="fa fa-search" aria-hidden="true"></i>
                                                                            {this.state.checkRequest ? 'เอกสาร (0 รายการ)' : data.log_type + ' (' + data.count + ' รายการ)'}
                                                                        </span>
                                                                    </div>
                                                                </a>
                                                            </div>
                                                        )
                                                    }) : undefined}


                                                    {/*                                        <div className='nav-item' onClick={() => this.handdleChangeLogType('', null)}>
                                            <a className='pointer'>
                                                <div className='trapezoid trapezoid-purple active' id='trapezoidTapAll' style={{ width: '230px' }}>
                                                    <span>
                                                        <i className="fa fa-search" aria-hidden="true"></i>
                                                        INFO (3 รายการ)
                                                    </span>
                                                </div>
                                            </a>
                                        </div>*/}
                                                </Tabs>
                                            </AppBar>
                                        </Col>
                                    </Row>
                                )
                                : (this.state.isFetching) &&
                                <Row>
                                    <Col className="d-flex justify-content-center align-items-center mt-3">
                                        <Spinner animation="border" role="status" />
                                    </Col>
                                </Row>
                        }
                        <Row>
                            <Col>
                                <WorkflowLogTable 
                                    pageTab={this.state.pageTab} 
                                    countTab={this.state.countTab} 
                                    sids={this.state.sids}
                                    selectedlogType={this.state.selectedLogType}
                                />
                            </Col>
                        </Row>
                    </div>
                </div>
            </div>
        )
    }
}

export default connect((state: ApplicationState) => ({ ...state.LogType, ...state.appState }), ({ ...LogTypeStore.actionCreators, ...AppState.actionCreators }))(WorkflowLog)