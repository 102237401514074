function extname(fileName: string): string {
    return fileName.slice(fileName.lastIndexOf('.'));
}

export function numberWithCommas(n: string) {
    if (n) {
        var formatFloat = parseFloat(n).toFixed(2);

        return formatFloat.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    } else {
        return n;
    }
}

export function parseJwt(token: string) {
    var base64Url = token.split('.')[1];
    var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    var jsonPayload = decodeURIComponent(atob(base64).split('').map(function (c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
    }).join(''));

    return JSON.parse(jsonPayload);
};

export function insertTextWithLineBreaks(str: string) {
    if (str) {
        return str.replace(/(?:\r\n|\r|\n)/g, '\n');
    } else {
        return str;
    }
}

export function formatYMDToDMY(dmy: string) {
    if (dmy != undefined && dmy != "" && dmy != null) {
        var year = dmy.substring(0, 4);
        var month = dmy.substring(4, 6);
        var day = dmy.substring(6, 8);
        var Datevalue = "";
        var datevalue = "";
        var UTC = new Date()
        if (dmy.length < 9) {
            datevalue = day + '-' + month + '-' + year

        } else {
            var hour = dmy.substring(8, 10);
            var m = dmy.substring(10, 12);
            var s = dmy.substring(12, 14);
            datevalue = day + '-' + month + '-' + year + ' ' + hour + ':' + m + ':' + s

            /*let localTime = toTimestamp(Datevalue).valueOf() * 1000
            let localOffset = UTC.getTimezoneOffset() * 60000;
            let utc = localTime + localOffset;
            let thai = utc + (3600000 * 14);
            let dateUTCUse = new Date(thai);
            let dateUTCUseString = dateUTCUse.toLocaleString()
            
            datevalue = dateUTCUseString.split('/').join('-')
            datevalue = datevalue.split(',').join('  ');*/
        }
        return datevalue;
    } else {
        Datevalue = "";

        return Datevalue;
    }
}

function toTimestamp(strDate) {
    var datum = Date.parse(strDate);
    return datum / 1000;
}

export function formatDMY(dmy: string) {

    if (dmy != undefined && dmy != "" && dmy != null) {

        var dateUTC = ""

        dateUTC = dmy.split('.').join('/')
        var year = parseInt(dmy.substring(6, 10));
        var month = parseInt(dmy.substring(3, 5));
        var day = parseInt(dmy.substring(0, 2));
        var hour = parseInt(dmy.substring(11, 13));
        var m = parseInt(dmy.substring(14, 16));
        var s = parseInt(dmy.substring(17, 19));

        dateUTC = month + '/' + day + '/' + year + ' ' + hour + ':' + m + ':' + s

        //var UTC = new Date()
        //let localTime = toTimestamp(dateUTC).valueOf() * 1000
        //let localOffset = UTC.getTimezoneOffset() * 60000;
        //let utc = localTime + localOffset;
        //let thai = utc + (3600000 * 14);
        let dateUTCUse = new Date(dateUTC);
        let dateUTCUseString = dateUTCUse.toLocaleString('en-IN', { day: '2-digit', month: '2-digit', year: 'numeric', hour: '2-digit', minute: '2-digit', second: '2-digit', hour12: false })
        var datevalue = "";
        datevalue = dateUTCUseString.split('/').join('-')

        return datevalue.split(',').join('  ');

    } else {
        datevalue = "";

        return datevalue;
    }
}

export function formatDocDate(dmy: string) {
    if (dmy != undefined && dmy != "" && dmy != null) {

        var dateUTC = ""

        var year = dmy.substring(0, 4);
        var month = dmy.substring(4, 6);
        var day = dmy.substring(6, 8);

        dateUTC = day + '-' + month + '-' + year

        return dateUTC

        //var UTC = new Date()
        //let localTime = toTimestamp(dateUTC).valueOf() * 1000
        //let localOffset = UTC.getTimezoneOffset() * 60000;
        //let utc = localTime + localOffset;
        //let thai = utc + (3600000 * 14);
        //let dateUTCUse = new Date(dateUTC);
        //let dateUTCUseString = dateUTCUse.toLocaleString('en-IN', { day: '2-digit', month: '2-digit', year: 'numeric', hour: '2-digit', minute: '2-digit', second: '2-digit', hour12: false })
        //var datevalue = "";
        //datevalue = dateUTCUseString.split('/').join('-')

        //return datevalue.split(',').join('  ');

    } else {

        return "";
    }
}

export function formatDateYYYYMMDD(dmy: string) {
    if (dmy != undefined && dmy != "" && dmy != null) {

        var dateUTC = ""

        var year = dmy.substring(0, 4);
        var month = dmy.substring(4, 6);
        var day = dmy.substring(6, 8);

        dateUTC = year + '/' + month + '/' + day

        return dateUTC

        //var UTC = new Date()
        //let localTime = toTimestamp(dateUTC).valueOf() * 1000
        //let localOffset = UTC.getTimezoneOffset() * 60000;
        //let utc = localTime + localOffset;
        //let thai = utc + (3600000 * 14);
        //let dateUTCUse = new Date(dateUTC);
        //let dateUTCUseString = dateUTCUse.toLocaleString('en-IN', { day: '2-digit', month: '2-digit', year: 'numeric', hour: '2-digit', minute: '2-digit', second: '2-digit', hour12: false })
        //var datevalue = "";
        //datevalue = dateUTCUseString.split('/').join('-')

        //return datevalue.split(',').join('  ');

    } else {

        return "";
    }
}


export async function onClickFile(e: any) {
    var filePath = e.target.dataset.filepath
    var fileName = e.target.dataset.filename
    var env = await getEnv()
    var ls: any = localStorage.getItem("WF_APPLICATION");
    if (ls) {
        var jls: any = JSON.parse(ls)
        var token = jls.access_token

        var encodedFilePath = btoa(filePath);

        var p = "";
        //if (extname(filePath).slice(1).toLowerCase() == "pdf") {
            //p = 'https://dms.sonarinno.com/view?ref_path=' + encodedFilePath + '&token=' + token
            p = env.dms_url + '/view?ref_path=' + encodedFilePath + '&token=' + token;
        //} else {
            //p = 'https://dms-api.sonarinno.com/v1/dms/file?ref_path=' + encodedFilePath + '&token=' + token
        //    p = env.dms_api + '/v1/dms/file?ref_path=' + encodedFilePath + '&token=' + token;
        //}
        if (fileName) {
            var encodedFilename = encodeURIComponent(fileName);
            p += '&file_name=' + encodedFilename
        }

        var extraWindow = window.open(
            p,
            '_blank'
        );
    }
}

export async function onClickFileShowIframe(e: any, mode: string, token: string) {
    var filePath: string = e.target.dataset.filepath
    var fileName: string = e.target.dataset.filename

    var env = await getEnv()
    var ls: any = localStorage.getItem("WF_APPLICATION");
    if (ls) {
        var jls: any = JSON.parse(ls)
        var accessToken = jls.access_token
        if (accessToken) {
            token = accessToken;
        }
    }

    var encodedFilePath = btoa(filePath);

    var fileExtension = '';
    if (fileName) {
        var fileNameWithExtension = fileName.split('.')
        if (fileNameWithExtension.length > 1) {
            fileExtension = fileNameWithExtension[fileNameWithExtension.length - 1]
        }
    }


    var p = "";
    //if ((extname(filePath).slice(1).toLowerCase() == "pdf") || (fileExtension == "pdf")) {
        //p = 'https://scg-dms.sonarinno.com/view?ref_path=' + encodedFilePath + '&token=' + token
        p = env.dms_url + '/view?ref_path=' + encodedFilePath + '&token=' + token;
    //} else {
        //p = 'https://scg-dms-api.sonarinno.com/v1/dms/file?ref_path=' + encodedFilePath + '&token=' + token;
    //    p = env.dms_api + '/v1/dms/file?ref_path=' + encodedFilePath + '&token=' + token;
    //}

    if (mode) {
        p += '&mode=' + mode
    }

    if (fileName) {
        var encodedFilename = encodeURIComponent(fileName);
        p += '&file_name=' + encodedFilename

        return p
    }
}

export async function onClickFileShowIframeWithReadOnly(e: any, token: string) {
    var filePath: string = e.target.dataset.filepath
    var fileName: string = e.target.dataset.filename
    var env = await getEnv()
    var ls: any = localStorage.getItem("WF_APPLICATION");
    if (ls) {
        var jls: any = JSON.parse(ls)
        var accessToken = jls.access_token
        if (accessToken) {
            token = accessToken;
        }
    }

    var encodedFilePath = btoa(filePath);

    var fileExtension = '';
    if (fileName) {
        var fileNameWithExtension = fileName.split('.')
        if (fileNameWithExtension.length > 1) {
            fileExtension = fileNameWithExtension[fileNameWithExtension.length - 1]
        }
    }

    var p = "";
    //if ((extname(filePath).slice(1).toLowerCase() == "pdf") || (fileExtension == "pdf")) {
        //p = 'https://scg-dms.sonarinno.com/view?ref_path=' + encodedFilePath + '&token=' + token
        p = env.dms_url + '/view?ref_path=' + encodedFilePath + '&token=' + token + '&read_only=true';
    //} else {
        //p = 'https://scg-dms-api.sonarinno.com/v1/dms/file?ref_path=' + encodedFilePath + '&token=' + token;
    //    p = env.dms_api + '/v1/dms/file?ref_path=' + encodedFilePath + '&token=' + token + '&read_only=true';
    //}

    if (fileName) {
        var encodedFilename = encodeURIComponent(fileName);
        p += '&file_name=' + encodedFilename

        return p
    }
}

export async function onClickFileShow(e: any, token: string) {
    var filePath: string = e.target.dataset.filepath
    var fileName: string = e.target.dataset.filename
    var mode: string = e.target.dataset.mode

    var env = await getEnv()
    var ls: any = localStorage.getItem("WF_APPLICATION");
    if (ls) {
        var jls: any = JSON.parse(ls)
        var accessToken = jls.access_token
        if (accessToken) {
            token = accessToken;
        }
    }

    var encodedFilePath = btoa(filePath);

    var fileExtension = '';
    if (fileName) {
        var fileNameWithExtension = fileName.split('.')
        if (fileNameWithExtension.length > 1) {
            fileExtension = fileNameWithExtension[fileNameWithExtension.length - 1]
        }
    }

    var p = "";
    //if ((extname(filePath).slice(1).toLowerCase() == "pdf") || (fileExtension == "pdf")) {
        //p = 'https://scg-dms.sonarinno.com/view?ref_path=' + encodedFilePath + '&token=' + token
        p = env.dms_url + '/view?ref_path=' + encodedFilePath + '&token=' + token;
    //} else {
        //p = 'https://scg-dms-api.sonarinno.com/v1/dms/file?ref_path=' + encodedFilePath + '&token=' + token;
    //    p = env.dms_api + '/v1/dms/file?ref_path=' + encodedFilePath + '&token=' + token;
    //}

    if (mode) {
        p += '&mode=' + mode
    }

    if (fileName) {
        var encodedFilename = encodeURIComponent(fileName);
        p += '&file_name=' + encodedFilename

        window.open(
            p,
            '_blank'
        );
    }
}

export function changeSidToName(value: any, arr: any) {
    var checkValue: any = ''
    for (let i = 0; i < arr.length; i++) {
        if (arr[i].sid == value) {
            checkValue = arr[i].company_name
        }
    }
    if (checkValue == '') {
        return value
    } else {
        return checkValue
    }

}

export function changeWFTypeToApproveType(value: string) {
    var type: string = "";
    if (value == "APINVOICE") {
        type = "Posting and verify Approve(V)"
    } else if (value == "GLPOSTING") {
        type = "Posting Approve(A)"
    } else if (value == "OUTGOING") {
        type = "Posting and Payment Approval"
    }

    return type;
}

//export async function getScgDms() {
//    var result_list = ""
//    await fetch("/v1/version")
//        .then(res => res.json())
//        .then(
//            (result) => {
//                result_list = result.ScgDmsUrl
//            },
//            (error) => {
//            }
//        )

//    return result_list
//}

//export async function getScgDmsApi() {
//    var result_list = ""
//    await fetch("/v1/version")
//        .then(res => res.json())
//        .then(
//            (result) => {
//                result_list = result.ScgDmsApi
//            },
//            (error) => {
//            }
//        )

//    return result_list
//}

//export async function getDms() {
//    var result_list = ""
//    await fetch("/v1/version")
//        .then(res => res.json())
//        .then(
//            (result) => {
//                result_list = result.DMSUrl
//            },
//            (error) => {
//            }
//        )

//    return result_list
//}

//export async function getDmsApi() {
//    var result_list = ""
//    await fetch("/v1/version")
//        .then(res => res.json())
//        .then(
//            (result) => {
//                result_list = result.ScgDmsApi
//            },
//            (error) => {
//            }
//        )

//    return result_list
//}

export async function getEnv() {
    var res: any = null
    await fetch("/v1/env")
        .then(res => res.json())
        .then(
            (result) => {
                res = result
            },
            (error) => {
            }
        )

    return res
}

export async function getRedirectURL() {
    var res = ""
    await fetch("/v1/env")
        .then(res => res.json())
        .then(
            (result) => {
                res = result.redirect_url
            },
            (error) => {
            }
        )

    return window.open(res, '_self')
}

export function checkExp(e: any, autoRedirect = true) {
    var exp = new Date(e.exp * 1000)
    var date_now = Date.now();
    var now = new Date(date_now)
    if ((exp < now) && autoRedirect) {
        getRedirectURL()
    }
}

export function formatDateTime(inputDateTimeString: string): string {
    // Parse the input string to a Date object
    const date = new Date(inputDateTimeString);

    // Format the date and time components
    const formattedDate = `${date.getDate().toString().padStart(2, '0')}-${(date.getMonth() + 1).toString().padStart(2, '0')}-${date.getFullYear()}`;
    const formattedTime = `${date.getHours().toString().padStart(2, '0')}:${date.getMinutes().toString().padStart(2, '0')}:${date.getSeconds().toString().padStart(2, '0')}`;

    // Concatenate the formatted date and time
    const formattedDateTime = `${formattedDate} ${formattedTime}`;

    return formattedDateTime;
}

export function convertToYYYYMMDD(dateTimeString: string): string {
    const date = new Date(dateTimeString);
    const year = date.getFullYear();
    const month = (date.getMonth() + 1).toString().padStart(2, '0');
    const day = date.getDate().toString().padStart(2, '0');
    const hours = date.getHours().toString().padStart(2, '0');
    const minutes = date.getMinutes().toString().padStart(2, '0');
    const seconds = date.getSeconds().toString().padStart(2, '0');

    return `${year}${month}${day}${hours}${minutes}${seconds}`;
}