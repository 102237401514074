import * as React from "react";
import { connect } from "react-redux";
import { Card, Button, Row, Col, Table, FormControl, Form } from "react-bootstrap";
import { Link, RouteComponentProps } from "react-router-dom";
import { FaPlusCircle, FaArrowCircleLeft, FaTrashAlt, FaUserAlt } from 'react-icons/fa';
import Swal from 'sweetalert2'

import AutoCompleteGroup from '../autocomplete/AutoCompleteGroup'
// Create
import * as CreateGroupStore from '../../store/group/CreateGroup'
import * as Models from '../../models/group/CreateGroup'
// Search
import * as SearchGroupStore from '../../store/group/SearchGroup'
import * as ModelsSearchGroup from '../../models/group/SearchGroup'
import { ApplicationState } from "../../store";

import * as AutocompleteGroupStore from '../../store/autocomplete/GroupSearch'
import * as ModelsGroups from '../../models/autocomplete/GroupSearch'

import AutcoCompleteConditionID from '../autocomplete/AutoCompleteConditionID'
import { exit } from "process";
import { parseJwt } from '../../modules/Common';

import * as AppState from '../../store/AppState'
import * as ModelsAppState from '../../models/AppState'
type AppStateProps = ModelsAppState.AppState & typeof AppState.actionCreators

//export interface inititem {
//    username: string,
//    validform: string,
//    validto: string
//};

export interface initicol {
    paramcode: string,
    paramoperator: string,
    paramvalue: string
};

export interface autocomplete {
    sid: string,
    code: string,
    name: string,
    display: string
};


export interface Splitgroup {
    position_group_id: any
}

type CreateGroupProps = Models.CreatePositionGroupState & typeof CreateGroupStore.actionCreators
type SearchGroupProps = ModelsSearchGroup.SearchGrouptate & typeof SearchGroupStore.actionCreators
type autocompletegroupProps = ModelsGroups.GetGroupState & typeof AutocompleteGroupStore.actionCreators
type Props = {};
type ComposedProps = Props & RouteComponentProps;

class AddPositionGroup extends React.Component<any,
    { checkRepeat: boolean, rows: Array<Models.Position>, rowsSelect: Array<Models.PositionSelect>, row2: Array<initicol>, position_group_id: any, position_group_name: string, position_code: string, created_by: string, created_on: string, updated_by: string, updated_on: string, autocomplete: Array<autocomplete>, obj: Array<Models.Position>, objSelect: Array<Models.PositionSelect>, isready: boolean, checkUpdate: boolean, checkReadonly: boolean, userId: any, delete_position_group_list: Array<Models.Position>, delete_position_condition_list: Array<Models.SubPosi>, roleChange: Array<autocomplete>, company?: string, initautocomplete: Array<autocomplete> }
    , CreateGroupProps & SearchGroupProps & autocompletegroupProps & AppStateProps> {
    constructor(props: any) {
        super(props);
        this.handlechageAutocomplete = this.handlechageAutocomplete.bind(this)
        this.handlechageCondition = this.handlechageCondition.bind(this)

        this.state = {
            rows: [],
            rowsSelect: [],
            row2: [],
            position_group_id: "",
            position_group_name: "",
            position_code: "",
            created_by: "",
            created_on: "",
            updated_by: "",
            updated_on: "",
            obj: [],
            objSelect: [],
            autocomplete: [],
            isready: true,
            checkUpdate: true,
            checkReadonly: false,
            userId: "",
            delete_position_group_list: [],
            delete_position_condition_list: [],
            roleChange: [],
            checkRepeat: false,
            company: undefined,
            initautocomplete: []
        };
    }

    componentDidMount() {
        this.props.updateUrl(this.props.location.pathname)
        var company = this.props.company
        this.props.requestGroupSearch("", "CLEAR")
        var ls: any = localStorage.getItem("WF_APPLICATION");
        var jls: any = JSON.parse(ls)
        var access_token = parseJwt(jls.access_token)
        var extra = JSON.parse(access_token.extra)
        console.log(extra)

        var user_id = ""
        if (extra.citizen_id) {
            console.log('Azure Login ID: ' + extra.citizen_id)
            user_id = extra.citizen_id
            this.setState({ userId: extra.citizen_id })
        } else if (extra.username) {
            console.log('Local Login ID: ' + extra.sid)
            user_id = extra.username
            this.setState({ userId: extra.username })
        }

        this.setState({
            company: company
        })

        const { state } = this.props.location
        console.log(state);
        if (state != undefined && this.state.isready == true) {
            console.log(state);
            this.props.requestsearchgroup(this.state.isready, "POST", state.positioncode, company)
            this.setState({
                isready: false
            })
        } else if (state == undefined && this.state.isready == true) {
            this.setState(this.state)
        }
    }

    componentWillUnmount() {
        this.props.requestCreateGroup(true, "CLEAR", "jft_username", this.state.obj, this.state.company)
        if (!this.state.isready) {
            console.log('resett');
            this.setState(this.state)
        }
    }

    handleChangePosition = (idx: number, mode: string) => (e: { target: { name: any; value: any; }; }) => {
        var rows: Array<Models.Position> = [];
        rows = this.state.obj;
        console.log(rows)
    };

    handleChangeSub = (idPosition: number, idSub: number, mode: string) => (e: { target: { name: any; value: any; }; }) => {
        console.log('rowsub')
        var rowsub: Array<Models.Position> = [];
        rowsub = this.state.obj
        if (mode == 'Code') {
            rowsub[idPosition].condition_list[idSub]['parameter_code'] = e.target.value
        } else if (mode == 'Operator') {
            rowsub[idPosition].condition_list[idSub]['parameter_operator'] = e.target.value
        } else if (mode == 'Value') {
            rowsub[idPosition].condition_list[idSub]['parameter_value'] = e.target.value
        }

        if (this.state.autocomplete.length == 0) {
            Swal.fire({
                title: 'โปรดเลือก Role Employee',
                text: 'โปรดเลือกข้อมูลใน Role Employee',
                icon: 'warning',
                showCancelButton: false,
                confirmButtonColor: '#3085d6',
                confirmButtonText: 'ยืนยัน!'
            }).then((result) => {

            })
        } else {
            var posicode: Splitgroup = {
                position_group_id: "",
            }
            posicode.position_group_id = String(this.state.autocomplete[idPosition]['code']).split("_")[0]
            console.log(posicode)
            rowsub[idPosition].condition_list[idSub]['position_group_id'] = posicode.position_group_id
            rowsub[idPosition].condition_list[idSub]['position_code'] = this.state.autocomplete[idPosition]['code']
        }

        rowsub[idPosition].position_group_id = this.state.position_group_id
        rowsub[idPosition].position_group_name = this.state.position_group_name
        rowsub[idPosition].created_by = this.state.created_by
        rowsub[idPosition].created_on = this.state.created_on
        rowsub[idPosition].updated_by = this.state.updated_by
        rowsub[idPosition].updated_on = this.state.updated_on

        this.setState({ obj: rowsub })
        setTimeout(() => {
            console.log(this.state.obj)
        }, 500);
    };

    onheaderChange = (mode: string) => (e: { target: { name: any; value: any; }; }) => {
        console.log("header")
        if (mode == 'PositionGroup') {
            this.setState({
                position_group_id: e.target.value
            })
        } else if (mode == 'CreateBy') {
            this.setState({
                created_by: e.target.value
            })
        } else if (mode == 'CreateOn') {
            this.setState({
                created_on: e.target.value
            })
        } else if (mode == 'PositionName') {
            this.setState({
                position_group_name: e.target.value
            })
        } else if (mode == 'UpdateBy') {
            this.setState({
                updated_by: e.target.value
            })
        } else {
            this.setState({
                updated_on: e.target.value
            })
        }
    }

    handleAddPosition = () => {

        var blindPosi: Array<Models.Position> = [];
        blindPosi = this.state.obj;
        var blindPosiSelect: Array<Models.PositionSelect> = [];
        blindPosiSelect = this.state.objSelect;

        const itemSelect = {
            sid: "",
            code: "",
            name: "",
            display: ""
        };

        var landingSub: Models.SubPosi = {
            obj_id: "",
            characteristic: "",
            position_group_id: "",
            position_code: "",
            parameter_code: "",
            parameter_value: "",
            parameter_operator: "",
            condition_display: ""
        }

        var landibgPosition: Models.Position = {
            po_id: "",
            task_type_id: "",
            task_id: "",
            position_group_id: "",
            position_group_name: "",
            position_code: "",
            created_by: "",
            created_on: "",
            updated_by: "",
            updated_on: "",
            condition_list: [landingSub] as any
        }

        var landingSubSelect: Models.SubPosiSelect = {
            conditionid: "",
            conditionname: ""
        }

        var landibgPositionSelect: Models.PositionSelect = {
            condition_list_select: [landingSubSelect] as any
        }

        blindPosi.push(landibgPosition);
        blindPosiSelect.push(landibgPositionSelect);
        this.setState({ obj: blindPosi, objSelect: blindPosiSelect, autocomplete: [...this.state.autocomplete, itemSelect] });
        //this.setState({ obj: blindPosi, objSelect: blindPosiSelect });

        setTimeout(() => {
            console.log(this.state.obj)
        }, 500);
    };

    handleAddSub = (i: number) => {
        var blindPosi: Array<Models.Position> = []
        blindPosi = this.state.obj
        var blindPosiSelect: Array<Models.PositionSelect> = [];
        blindPosiSelect = this.state.objSelect;

        var landingSub: Models.SubPosi = {
            obj_id: "",
            characteristic: "",
            position_group_id: "",
            position_code: "",
            parameter_code: "",
            parameter_value: "",
            parameter_operator: "",
            condition_display: ""
        }

        var landingSubSelect: Models.SubPosiSelect = {
            conditionid: "",
            conditionname: ""
        }

        blindPosi[i].condition_list.push(landingSub)
        blindPosiSelect[i].condition_list_select.push(landingSubSelect)
        this.setState({
            obj: blindPosi,
            objSelect: blindPosiSelect
        })
        setTimeout(() => {
            console.log(this.state.obj)
        }, 500);
    };

    handleRemoveSpecificPosition = (idPosition: number) => () => {
        console.log('rows')

        var rows = this.state.obj
        var rowsSelect = this.state.objSelect

        var deleterow = rows[idPosition]

        rows.splice(idPosition, 1)
        rowsSelect.splice(idPosition, 1)

        this.setState({ rows, rowsSelect, checkRepeat: true })

        var autocomplete = [...this.state.autocomplete]
        autocomplete.splice(idPosition, 1)
        this.setState({ autocomplete });

        var blindDelete: Array<Models.Position> = [];
        blindDelete = this.state.delete_position_group_list;
        blindDelete.push(deleterow)

        this.setState({ delete_position_group_list: blindDelete });

        //var blindSubdelete: Array<Models.SubPosi> = [];
        //blindSubdelete = this.state.delete_position_condition_list;
        //for (var i = 0; i < deleterow.condition_list.length; i++) {
        //    blindSubdelete.push(deleterow.condition_list[i])
        //}
        //this.setState({ delete_position_condition_list: blindSubdelete })

        setTimeout(() => {
            console.log(this.state.delete_position_group_list)
            console.log(this.state.delete_position_condition_list)
        }, 500);
    }

    handleRemoveSpecificSub = (idPosition: number, idSub: number) => {
        console.log('rowsub')
        var rowsub: Array<Models.Position> = [];
        rowsub = this.state.obj
        var rowsubSelect: Array<Models.PositionSelect> = [];
        rowsubSelect = this.state.objSelect

        var deletersubrow = rowsub[idPosition].condition_list[idSub]

        rowsub[idPosition].condition_list.splice(idSub, 1)
        rowsubSelect[idPosition].condition_list_select.splice(idSub, 1)

        this.setState({ obj: rowsub, objSelect: rowsubSelect })

        setTimeout(() => {
            console.log(this.state.obj)
        }, 500);

        var blindSubdelete: Array<Models.SubPosi> = [];
        blindSubdelete = this.state.delete_position_condition_list;
        blindSubdelete.push(deletersubrow)
        this.setState({ delete_position_condition_list: blindSubdelete })
        setTimeout(() => {
            console.log(this.state.delete_position_condition_list)
        }, 500);
    }

    onsubmit = () => {
        var checkAuto: Array<autocomplete> = []
        checkAuto = this.state.roleChange
        var checkRepeat: any = []
        var showRepeat: any = []

        for (let obj of this.state.autocomplete) {
            for (let ele of this.state.autocomplete) {
                if (obj == ele)
                    continue;
                if (ele.code === obj.code) {
                    checkRepeat.push(obj);
                    break;
                }
            }
        }

        if (checkRepeat != "") {
            showRepeat = checkRepeat.reduce((ac, a) => ac.find(x => x.code === a.code) ? [...ac] : [...ac, a], []);
        }

        if (this.state.position_group_id == "" || this.state.position_group_name == "") {
            Swal.fire({
                title: 'โปรดกรอกข้อมูลให้ครบถ้วน !!',
                text: 'โปรดกรอกข้อมูล Authority Group ID และ Authority Name',
                icon: 'warning',
                showCancelButton: false,
                confirmButtonColor: '#3085d6',
                confirmButtonText: 'ยืนยัน!'
            }).then((result) => {

            })
        }
        else if (this.state.autocomplete.length == 0) {
            Swal.fire({
                title: 'โปรดเลือก Role Employee',
                text: '',
                icon: 'warning',
                showCancelButton: false,
                confirmButtonColor: '#3085d6',
                confirmButtonText: 'ยืนยัน!'
            }).then((result) => {

            })
        }
        else if (this.state.autocomplete.length > 0) {
            if (this.state.autocomplete[0].code == "") {
                Swal.fire({
                    title: 'โปรดเลือก Role Employee',
                    text: '',
                    icon: 'warning',
                    showCancelButton: false,
                    confirmButtonColor: '#3085d6',
                    confirmButtonText: 'ยืนยัน!'
                }).then((result) => {

                })
            } else if (checkRepeat.length > 0) {
                Swal.fire({
                    title: 'โปรดเลือก Role Employee ที่ไม่ซ้ำกัน',
                    text: 'รหัสที่ซ้ำ: ' + showRepeat.map((item) => String(item.code)),
                    icon: 'warning',
                    showCancelButton: false,
                    confirmButtonColor: '#3085d6',
                    confirmButtonText: 'ยืนยัน!'
                }).then((result) => {

                })
            } else {
                var submitCheck: Array<autocomplete> = [];
                var submitValue: Array<Models.Position> = [];
                submitValue = this.state.obj
                for (let i = 0; i < this.state.obj.length; i++) {
                    console.log(this.state.autocomplete[i]['code'])
                    submitValue[i].position_group_id = this.state.position_group_id
                    submitValue[i].position_code = this.state.autocomplete[i]['code']
                    submitValue[i].position_group_name = this.state.position_group_name
                    submitValue[i].created_by = this.state.created_by
                    submitValue[i].created_on = this.state.created_on
                    submitValue[i].updated_by = this.state.updated_by
                    submitValue[i].updated_on = this.state.updated_on

                    var checkAuto: Array<autocomplete> = []
                    checkAuto = this.state.roleChange
                    console.log("this.state.autocomplete[i]['code']")
                    console.log(this.state.autocomplete[i]['code'])
                    if (this.state.autocomplete[i]['code'] == undefined || this.state.autocomplete[i]['code'] == "") {
                        var landingrow: autocomplete = {
                            sid: "",
                            code: this.state.autocomplete[i]['code'],
                            name: "",
                            display: this.state.autocomplete[i]['display']
                        }
                        submitCheck.push(landingrow)
                    } else {
                        //document.getElementsByClassName('Loader__background')[0].className = "Loader__background show";
                        for (var a = 0; a < submitValue[i].condition_list.length; a++) {
                            submitValue[i].condition_list[a].position_group_id = String(this.state.autocomplete[i]['code']).split("_")[0]
                            submitValue[i].condition_list[a].position_code = this.state.autocomplete[i]['code']
                        }
                    }
                }
                this.setState({ obj: submitValue })
                if (submitCheck.length == 0) {
                    console.log("errorrr")
                    console.log(this.state.obj)
                    document.getElementsByClassName('Loader__background')[0].className = "Loader__background show";
                    console.log(this.state.delete_position_group_list);
                    this.props.requestCreateGroup(true, "POST", this.state.userId, this.state.obj, this.state.delete_position_group_list, this.state.delete_position_condition_list, this.state.company)
                } else {
                    Swal.fire({
                        title: 'โปรดเลือก Role Employee ที่ไม่ซ้ำกัน',
                        text: '',
                        icon: 'warning',
                        showCancelButton: false,
                        confirmButtonColor: '#3085d6',
                        confirmButtonText: 'ยืนยัน!'
                    }).then((result) => {

                    })
                }
            }
        }

    }

    public handlechageAutocomplete(idx: number, value: any) {
        console.log(idx, value)
        console.log(this.state.autocomplete)
        if (this.state.autocomplete[idx].code != value[0]['code'] && this.state.autocomplete[idx].code != "") {
            console.log("---- handlechageAutocomplete ----- ")
            console.log(this.state.autocomplete[idx].code + ' != ' + value[0]['code'])
            console.log(this.state.initautocomplete)
            var rowsub: Array<Models.Position> = [];
            rowsub = JSON.parse(JSON.stringify(this.state.obj))
            console.log(this.state.initautocomplete[idx].code)
            rowsub[idx].position_code = this.state.initautocomplete[idx].code
            console.log(rowsub[idx])
            console.log(rowsub[idx].position_code)
            var blindDelete: Array<Models.Position> = [];
            blindDelete = this.state.delete_position_group_list;
            blindDelete.push(rowsub[idx])
            console.log(blindDelete)
            console.log(this.state.delete_position_group_list)
            this.setState({
                delete_position_group_list: blindDelete
            })
            console.log(JSON.stringify(this.state.delete_position_group_list))

        }
        var checkAuto: Array<autocomplete> = []
        checkAuto = this.state.roleChange
        var workflowcondi: any = checkAuto.filter(item => value.some(other => item.code == other.code));

        checkAuto[idx] = value[0];
        console.log(checkAuto);
        this.setState({
            roleChange: checkAuto
        })

        var blindAuto: Array<autocomplete> = []
        blindAuto = this.state.autocomplete
        var landingauto: autocomplete = {
            sid: value[0]['sid'],
            code: value[0]['code'],
            name: value[0]['name'],
            display: value[0]['display']
        }
        blindAuto[idx] = landingauto
        this.setState({
            autocomplete: blindAuto
        })
        //}
        console.log("----  ----- ")
    }

    public handlechageCondition = (idx: any, subid: any, value: any) => {
        var rowsub: Array<Models.Position> = [];
        var rowsubSelect: Array<Models.PositionSelect> = [];
        rowsub = this.state.obj
        rowsubSelect = this.state.objSelect

        rowsub[idx].condition_list[subid]['parameter_code'] = value[0]['conditionid']

        rowsubSelect[idx].condition_list_select[subid].conditionid = value[0]['conditionid']
        rowsubSelect[idx].condition_list_select[subid].conditionname = value[0]['conditionname']

        this.setState({ obj: rowsub, objSelect: rowsubSelect })
    }

    componentDidUpdate(nextProps: any, nextState: any) {
        const { statusCreate, statusSearch, responseSearch, MessageCreateGroup } = this.props;
        var company = this.props.company
        if (company != this.state.company) {

            this.setState({
                company: company
            });
        }

        if (statusCreate === 200) {
            document.getElementsByClassName('Loader__background')[0].className = "Loader__background hide";
            Swal.fire({
                title: 'สำเร็จ!',
                text: 'เพิ่มข้อมูลสำเร็จ',
                icon: 'success',
                showCancelButton: false,
                confirmButtonColor: '#3085d6',
                confirmButtonText: 'ยืนยัน!'
            }).then((result) => {
                if (result.value) {
                    console.log('redirect!!')
                    this.props.requestCreateGroup(true, "CLEAR", "jft_username", this.state.obj, this.state.company)
                    //window.location.href = "/authoritygroup"
                    this.props.history.push('/authoritygroup')
                }
            })
        } else if (statusSearch == 200 && this.state.checkUpdate) {
            console.log(responseSearch['result_list']);
            var autocompleterow: Array<autocomplete> = []

            for (var a = 0; a < responseSearch['result_list'].length; a++) {
                var blindPosiSelect: Array<Models.PositionSelect> = [];
                blindPosiSelect = this.state.objSelect;
                var blindPosiSelectHost: any = []

                autocompleterow = this.state.autocomplete
                var landingrow: autocomplete = {
                    sid: "",
                    code: responseSearch['result_list'][a]['position_code'],
                    name: "",
                    display: responseSearch['result_list'][a]['position_code']
                }
                autocompleterow.push(landingrow)

                if (responseSearch['result_list'][a]['condition_list'].length == 0) {
                    var landingSubSelect: Models.SubPosiSelect = {
                        conditionid: "",
                        conditionname: ""
                    }

                    blindPosiSelectHost.push(landingSubSelect)
                }

                for (var i = 0; i < responseSearch['result_list'][a]['condition_list'].length; i++) {

                    var landingSubSelect: Models.SubPosiSelect = {
                        conditionid: responseSearch['result_list'][a]['condition_list'][i]['parameter_code'],
                        conditionname: responseSearch['result_list'][a]['condition_list'][i]['condition_display']
                    }

                    blindPosiSelectHost.push(landingSubSelect)
                }
                var landibgPositionSelect: Models.PositionSelect = {
                    condition_list_select: blindPosiSelectHost as any
                }

                blindPosiSelect.push(landibgPositionSelect);

                var resultArr: Array<Models.Position> = responseSearch['result_list']
                for (let j = 0; j < resultArr.length; j++) {
                    if (resultArr[j].condition_list.length == 0) {
                        console.log(resultArr[j].position_group_id)
                        var landingSub: Models.SubPosi = {
                            obj_id: "",
                            characteristic: "",
                            position_group_id: responseSearch['result_list'][j]['position_group_id'],
                            position_code: responseSearch['result_list'][j]['position_code'],
                            parameter_code: "",
                            parameter_value: "",
                            parameter_operator: "",
                            condition_display: ""
                        }
                        resultArr[j].condition_list.push(landingSub)
                    }
                }

                this.setState({
                    objSelect: blindPosiSelect,
                    obj: resultArr,
                    position_group_id: responseSearch['result_list'][a]['position_group_id'],
                    created_by: responseSearch['result_list'][a]['created_by'],
                    created_on: responseSearch['result_list'][a]['created_on'],
                    position_group_name: responseSearch['result_list'][a]['position_group_name'],
                    updated_by: responseSearch['result_list'][a]['updated_by'],
                    updated_on: responseSearch['result_list'][a]['updated_on'],
                })
            }
            console.log(autocompleterow)
            this.setState({

                autocomplete: autocompleterow,
                //roleChange: autocompleterow,
                initautocomplete: autocompleterow,
                checkUpdate: false,
                checkReadonly: true
            })
            if (this.state.autocomplete.length != 0) {
                document.getElementsByClassName('Loader__background')[0].className = "Loader__background hide";
            }
        } else if (statusCreate == 204) {
            document.getElementsByClassName('Loader__background')[0].className = "Loader__background hide";
            Swal.fire({
                title: 'ไม่สำเร็จ!',
                text: '' + MessageCreateGroup + '',
                icon: 'error',
                showCancelButton: false,
                confirmButtonColor: '#3085d6',
                confirmButtonText: 'ยืนยัน!'
            }).then((result) => {
                if (result.value) {
                    // Clear state
                    this.props.requestCreateGroup(true, "CLEAR", "jft_username", this.state.obj, this.state.company)
                }
            })
        }
    }

    componentWillMount() {
        this.props.requestsearchgroup(this.state.isready, "CLEAR", this.state.company)
        this.setState({
            rows: [],
            row2: [],
            position_group_id: "",
            position_group_name: "",
            position_code: "",
            created_by: "",
            created_on: "",
            updated_by: "",
            updated_on: "",
            obj: [],
            objSelect: [],
            autocomplete: [],
            isready: true,
            checkUpdate: true,
            checkReadonly: false
        })
    }

    formatDMY = (dmy: string) => {

        if (dmy != undefined && dmy != "" && dmy != null) {
            var year = dmy.substring(0, 4);
            var month = dmy.substring(4, 6);
            var day = dmy.substring(6, 8);
            var Datevalue = "";
            if (dmy.length < 9) {
                Datevalue = year + '-' + month + '-' + day
            } else {
                var hour = dmy.substring(8, 10);
                var second = dmy.substring(10, 12);
                var millssecond = dmy.substring(12, 14);
                Datevalue = year + '-' + month + '-' + day + ' ' + hour + ':' + second + ':' + millssecond
            }

            return Datevalue;
        } else {
            Datevalue = "";
            return Datevalue;
        }
    }

    render() {
        return (
            <div>
                <div className="master-data-content">
                    <div className='trapezoid-head' style={{ width: '200px' }}>
                        <span>
                            Authority Group
                        </span>
                    </div>
                    <div className="mat-box">
                        <Row>
                            <Col>
                                <div>
                                    <div className='trapezoid trapezoid-purple' style={{ width: 'fit-content', display: "inline-block" }}>
                                        <span>
                                            Authority Group
                                        </span>
                                    </div>
                                    <div style={{ display: "inline", float: "right" }}>
                                        <Link to="/authoritygroup">
                                            <Button className="text-light" variant="success" size="sm"><FaArrowCircleLeft />&nbsp;Back</Button>
                                        </Link>
                                        {/*<Button className="mx-2" variant="info" size="sm" onClick={this.onsubmit}><FaPlusCircle />&nbsp;Save</Button>*/}
                                    </div>
                                </div>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <Card className="shadow-lg p-3 mb-5 bg-white rounded">
                                    <Card.Body>
                                        <Row>
                                            <Col sm={6} md={4}>
                                                <Form.Group>
                                                    <Form.Label>Authority Group :</Form.Label>
                                                    <Form.Control type="text" placeholder="ID_Name" name='' onChange={this.onheaderChange('PositionGroup')} value={this.state.position_group_id} readOnly={this.state.checkReadonly} />
                                                </Form.Group>
                                            </Col>
                                            <Col sm={6} md={4}>
                                                <Form.Group>
                                                    <Form.Label>Create By :</Form.Label>
                                                    <Form.Control type="text" placeholder="" name='' onChange={this.onheaderChange('CreateBy')} value={this.state.created_by} readOnly={true} />
                                                </Form.Group>
                                            </Col>
                                            <Col sm={6} md={4}>
                                                <Form.Group>
                                                    <Form.Label>Create On :</Form.Label>
                                                    <Form.Control type="text" placeholder="" name='' onChange={this.onheaderChange('CreateOn')} value={!this.state.checkUpdate ? this.formatDMY(String(this.state.created_on)) : ''} readOnly={true} />
                                                </Form.Group>
                                            </Col>
                                            <Col sm={6} md={4}>
                                                <Form.Group>
                                                    <Form.Label>Authority Name :</Form.Label>
                                                    <Form.Control type="text" placeholder="" name='' onChange={this.onheaderChange('PositionName')} value={this.state.position_group_name} />
                                                </Form.Group>
                                            </Col>
                                            <Col sm={6} md={4}>
                                                <Form.Group>
                                                    <Form.Label>Update By :</Form.Label>
                                                    <Form.Control type="text" placeholder="" name='' onChange={this.onheaderChange('UpdateBy')} value={this.state.updated_by} readOnly={true} />
                                                </Form.Group>
                                            </Col>
                                            <Col sm={6} md={4}>
                                                <Form.Group>
                                                    <Form.Label>Update On :</Form.Label>
                                                    <Form.Control type="text" placeholder="" name='' onChange={this.onheaderChange('UpdateOn')} value={!this.state.checkUpdate ? this.formatDMY(String(this.state.updated_on)) : ''} readOnly={true} />
                                                </Form.Group>
                                            </Col>
                                        </Row>
                                        <Row className='mt-3'>
                                            <Col>
                                                <Table className="table-bordered align-middle" size="sm">
                                                    <thead>
                                                        <tr className='text-center thead-light' style={{ width: '100%' }}>
                                                            <th style={{ width: '3%' }}>#</th>
                                                            <th style={{ width: '5%' }}>No.</th>
                                                            <th style={{ width: '82%' }}>Role Employee</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {this.state.obj.map((item: Models.Position, idx) => (
                                                            <tr id="addr0" key={idx}>
                                                                <td className='text-center'><Button className="mx-2" variant="danger" size="sm" onClick={this.handleRemoveSpecificPosition(idx)}><FaTrashAlt /></Button></td>
                                                                <td className='text-center' colSpan={2}>
                                                                    <Table className="table-borderless align-middle" size="sm">
                                                                        <tbody>
                                                                            <tr>
                                                                                <td style={{ width: '5%' }}>{idx + 1}</td>
                                                                                <td style={{ width: '95%' }}>
                                                                                    <AutoCompleteGroup handlechageAutocomplete={this.handlechageAutocomplete.bind(this, idx)} valueData={this.state.autocomplete[idx]} roleChange={this.state.roleChange} />
                                                                                </td>
                                                                            </tr>
                                                                        </tbody>
                                                                    </Table>
                                                                    <Table>
                                                                        <thead>
                                                                            <tr className='text-center thead-light' style={{ width: '100%' }}>
                                                                                <th style={{ width: '5%' }}><Button className="mx-2 bg-[#138496]" variant="info" size="sm" onClick={() => this.handleAddSub(idx)} ><FaPlusCircle /></Button></th>
                                                                                <th style={{ width: '30%' }}>Parameter Code</th>
                                                                                <th style={{ width: '30%' }}>Parameter Operator</th>
                                                                                <th style={{ width: '35%' }}>Parameter Value</th>
                                                                            </tr>
                                                                        </thead>
                                                                        <tbody>
                                                                            {item.condition_list.map((item2: Models.SubPosi, subid) => (
                                                                                <tr id="sub0" key={subid + 1} className='mt-2'>
                                                                                    <th style={{ width: '5%' }}><Button className="mx-2" variant="danger" size="sm" onClick={() => this.handleRemoveSpecificSub(idx, subid)}><FaTrashAlt /></Button></th>
                                                                                    <th style={{ width: '30%' }}>
                                                                                        <AutcoCompleteConditionID handlechageCondition={this.handlechageCondition.bind(this, idx, subid)} valueData={this.state.objSelect[idx].condition_list_select[subid]} valueWorflow={this.state.obj[idx]['condition_list'][subid]['parameter_code']} blockData={this.state.obj[idx].condition_list[subid]['parameter_code']} />
                                                                                        {/*<AutcoCompleteConditionID handlechageCondition={this.handlechageCondition.bind(this, idx, subid)} valueWorflow={this.state.obj[idx]['condition_list'][subid]['parameter_code']} blockData={this.state.obj[idx].condition_list[subid]['parameter_code']} />*/}
                                                                                    </th>
                                                                                    <th style={{ width: '30%' }}>
                                                                                        <Form.Group controlId="exampleForm.ControlSelect1" >
                                                                                            <Form.Control as="select" onChange={this.handleChangeSub(idx, subid, 'Operator')} value={this.state.obj[idx]['condition_list'][subid]['parameter_operator']}>
                                                                                                <option hidden={true}>Please Select..</option>
                                                                                                <option>{"="}</option>
                                                                                                <option>{">"}</option>
                                                                                                <option>{">="}</option>
                                                                                                <option>{"<"}</option>
                                                                                                <option>{"<="}</option>
                                                                                                <option>{"<>"}</option>
                                                                                            </Form.Control>
                                                                                        </Form.Group>
                                                                                    </th>
                                                                                    <th style={{ width: '35%' }}>
                                                                                        <FormControl
                                                                                            placeholder="Parameter Value"
                                                                                            onChange={this.handleChangeSub(idx, subid, 'Value')}
                                                                                            value={this.state.obj[idx]['condition_list'][subid]['parameter_value']}
                                                                                        />
                                                                                    </th>
                                                                                </tr>
                                                                            ))}
                                                                        </tbody>
                                                                    </Table>
                                                                </td>
                                                            </tr>
                                                        ))}
                                                        <tr>
                                                            <td className='text-center'><Button className="mx-2" color="primary" size="sm" onClick={this.handleAddPosition}><FaPlusCircle /></Button></td>
                                                            <td></td>
                                                            <td></td>
                                                        </tr>
                                                    </tbody>
                                                </Table>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col>
                                                <div style={{ display: "inline", float: "right" }}>
                                                    <Button className="mx-2" color="primary" size="sm" onClick={this.onsubmit}><FaPlusCircle />&nbsp;Save</Button>
                                                </div>
                                            </Col>
                                        </Row>
                                    </Card.Body>
                                </Card>
                            </Col>
                        </Row>
                    </div>
                </div>
            </div>
        )
    }
}

export default connect((state: ApplicationState) => ({ ...state.appState, ...state.createGroup, ...state.searchGroup, ...state.autoCompleteGroup }), ({ ...CreateGroupStore.actionCreators, ...SearchGroupStore.actionCreators, ...AutocompleteGroupStore.actionCreators, ...AppState.actionCreators }))(AddPositionGroup)
