import { Action, Reducer } from 'redux';
import { AppThunkAction } from '..';

import * as Models from '../../models/callbackconfig/CreateCallback'

interface BeforeCreateCallback {
    type: 'CREATE_CALLBACK'
}
interface CreateCallback {
    type: 'CREATECALLBACK'
    response: Models.CallbackResponse
    status: Number,
    statusMessage: String
}
interface FailCreateCreateCallback {
    type: 'CREATECALLBACKFAIL'
    status: number,
    statusMessage: any
}
interface ClearCreateCallback {
    type: 'CLEARCREATECALLBACK'
}

type KnownAction = CreateCallback | FailCreateCreateCallback | ClearCreateCallback | BeforeCreateCallback

export const actionCreators = {
    requestCreateCallback: (p: boolean, method: String, formData: Models.CallbackReq, company: string): AppThunkAction<KnownAction> => async (dispatch, getState) => {
        console.log('call actionCreators : requestCreateCallback')
        const appState = getState();
        if (appState && appState.createEmployee && p && method == 'POST') {
            console.log(formData)
            await fetch(`/v2/Callbackconfig/Create?app_id=` + company, {
                method: 'POST',
                headers: {

                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + localStorage.getItem('WF_APPLICATION'),
                },
                body: JSON.stringify({
                    result_list: formData
                }),
            })
                .then((response) => {
                    return response
                })
                .then((data) => {
                    //console.log(data)
                    if (!data.ok) {
                        data.json().then(err => {
                            //console.log(err.message_res)
                            console.log('dispatch : CREATECALLBACKFAIL')
                            dispatch({ type: 'CREATECALLBACKFAIL', status: 204, statusMessage: err.message });
                        })
                    } else {
                        data.json().then(data => {
                            //console.log(data)
                            console.log('dispatch : CREATECALLBACK')
                            dispatch({ type: 'CREATECALLBACK', response: data as Models.CallbackResponse, status: 200, statusMessage: '' });
                        })
                    }
                })

            dispatch({ type: 'CREATE_CALLBACK' });

        } else if (method == 'CLEAR') {
            console.log('dispatch : CLEARCREATECALLBACK')
            dispatch({ type: 'CLEARCREATECALLBACK' });
        }
    }
}

const unloadedState: Models.SaveCallbackState = { SaveCallbackisLoad: false };

export const reducer: Reducer<Models.SaveCallbackState> = (state: Models.SaveCallbackState | undefined, incomingAction: Action): Models.SaveCallbackState => {
    if (state == undefined) {
        return unloadedState;
    }
    const action = incomingAction as KnownAction;
    switch (action.type) {
        case 'CREATE_CALLBACK':
            return {
                SaveCallbackisLoad: false,

            }
        case 'CREATECALLBACK':
            return {
                SaveCallbackisLoad: true,
                SaveCallbackresponse: action.response,
                SaveCallbackstatus: action.status

            }
        case 'CREATECALLBACKFAIL':
            return {
                SaveCallbackisLoad: true,
                SaveCallbackmessage: action.statusMessage,
                SaveCallbackstatus: action.status
            }
        case 'CLEARCREATECALLBACK':
            return {
                SaveCallbackisLoad: false,
                SaveCallbackmessage: "",
                SaveCallbackstatus: 400,
            }
        default: return state;
    }
}