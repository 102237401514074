/*import React from 'react';*/
import * as React from 'react';
import { connect } from 'react-redux';
import { Row, Col, Table, Spinner } from 'react-bootstrap';

import WorkflowLogSearchIdTab from './WorkflowLogSearchIdTab';
import { ApplicationState } from '../../store';
import { EmailLogStateStore } from '../../models/log/EmailLog';
import { actionCreators } from '../../store/log/EmailLog';

interface EmailLogProps {
    emailLog: EmailLogStateStore;
}

type Props = EmailLogProps & typeof actionCreators;

interface State { }

class EmailLog extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);

        this.handleSearch = this.handleSearch.bind(this);
    }

    public handleSearch(sids: string[], isCheckAll: boolean, searchReq: {wfNo: string, wfLogType:string}): void {
        const { requestEmailLog } = this.props;

        if (isCheckAll) {
            sids = ['*']
        }

        requestEmailLog({
            method: 'GET',
            params: {
                sids,
                doc_no: searchReq.wfNo,
                type: searchReq.wfLogType,
            }
        });
    }
    
    onSelectLogType = (selectLogType: string) => {
        this.setState({
            selectedLogType: selectLogType
        })
    }

    public render(): React.ReactNode {
        const { isLoading, response, status } = this.props.emailLog;

        return (
            <div className="master-data-content">
                <Row>
                    <Col>
                        <div className='trapezoid-head' style={{ width: '200px' }}>
                            <span>
                                Workflow Email Log
                            </span>
                        </div>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <WorkflowLogSearchIdTab
                            handleSearch={this.handleSearch}
                            isFetching={isLoading}
                            canNullDocNo={true}
                            onSelectLogType={this.onSelectLogType}
                        />
                    </Col>
                </Row>
                <Row className="mt-3">
                    <Col style={{ overflow: 'hidden' }}>
                        <div style={{ overflowX: 'scroll' }}>
                            <Table className="table-bordered table-hover align-middle table-responsive-sm">
                                <thead >
                                    <tr className='text-center table-active thead-light text-nowrap' style={{ width: '100%' }}>
                                        <th>Log type</th>
                                        <th>Module</th>
                                        <th>Doc No.</th>
                                        <th>Mail To</th>
                                        <th>Mail CC</th>
                                        <th>Mail BCC</th>
                                        <th>Message</th>
                                        <th>Subject</th>
                                        <th>Create By</th>
                                        <th>Create On</th>
                                    </tr>
                                </thead>
                                {
                                    isLoading
                                    && (
                                        <tbody>
                                            <tr>
                                                <td
                                                    colSpan={11}
                                                    style={{ background: "rgba(209, 250, 221, 1)", color: "black", fontSize: '10pt', textAlign: "center" }}
                                                >
                                                    <Spinner animation="border" size="sm" />
                                                    &nbsp;&nbsp;Loading...
                                                </td>
                                            </tr>
                                        </tbody>
                                    )
                                }

                                {
                                    !isLoading
                                    && (status === 200)
                                    && (response === null || response.result_list === null)
                                    && (
                                        <tbody>
                                            <tr>
                                                <td
                                                    colSpan={11}
                                                    style={{ background: "#b7b7a4", color: "black", fontSize: '10pt', textAlign: "center" }}
                                                >
                                                    &nbsp;&nbsp;No Data...
                                                </td>
                                            </tr>
                                        </tbody>
                                    )
                                }

                                <tbody>
                                    {
                                        !isLoading
                                        && (status === 200)
                                        && (response !== null)
                                        && (response.result_list !== null)
                                        && (
                                            response.result_list.map((log, index) => (
                                                <tr key={index}>
                                                    <td>{log.log_type}</td>
                                                    <td>{log.module}</td>
                                                    <td>{log.docno}</td>
                                                    <td>{log.mail_to}</td>
                                                    <td>{log.mail_cc}</td>
                                                    <td>{log.mail_bcc}</td>
                                                    <td>{log.message}</td>
                                                    <td>{log.mail_subject}</td>
                                                    <td>{log.created_by}</td>
                                                    <td>{log.created_on}</td>
                                                </tr>
                                            ))
                                        )
                                    }
                                </tbody>
                            </Table>
                        </div>
                    </Col>
                </Row>
            </div>
        );
    }
}

export default connect(
    (state: ApplicationState, ownProps: Props) => ({ ...ownProps, emailLog: state.emailLog }),
    actionCreators
)(EmailLog as any);