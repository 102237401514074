import * as React from "react";
import { connect } from "react-redux";
import { Row, Col, Image, Spinner } from "react-bootstrap";
import { Container, Modal, ModalHeader, ModalBody, ModalFooter, UncontrolledPopover, PopoverHeader, PopoverBody , Button } from 'reactstrap';
import { Lightbox } from "react-modal-image";
//import converted from "./RemarkStyle";
import { FaEdit, FaReply } from "react-icons/fa"
import * as $ from 'jquery';
import { findDOMNode } from 'react-dom'
//var Images = require('../../../image/profile.png');
import { formatDMY, parseJwt, formatYMDToDMY } from '../../modules/Common';
import '../../style/remark/Remark.css'
import * as RemarkModels from '../../models/remark/Remark'
import * as RemarkStore from '../../store/remark/Remark'
import { ApplicationState } from "../../store";
import RemarkAddComment from './RemarkAddComment'
import RemarkReplyComment from './RemarkReplyComment'

type store = RemarkModels.GetRemarkState & typeof RemarkStore.actionCreators

class RemarkCommentTable extends React.Component<any, { userId: string, token: string, isready: boolean, countRemark: number, openPicture: boolean, openPictureUrl: string, replyComment: boolean, replyId: string, replyInstanceno: string, saveReply: boolean, dmsApi: string, dataResult: Array<RemarkModels.RemarkList>}, store> {

    constructor(props: any) {
        super(props);

        this.state = {
            userId: '',
            token: '',
            isready: true,
            countRemark: 0,
            openPicture: false,
            openPictureUrl: '',
            replyComment: false,
            replyId: '',
            replyInstanceno: '',
            saveReply: false,
            dataResult: [],
            dmsApi: ""
        }
    }

    componentDidMount() {
        console.log(this.props.workflowValue)
        if (this.state.isready) {
            var wfData = JSON.parse(JSON.stringify(this.props.workflowValue))
            this.props.requestGetRemark(true, 'GET', wfData.appId, wfData.wfId, wfData.wfInstanceNo)
            this.setState({
                isready: false
            })
        }

        var ls: any = localStorage.getItem("WF_APPLICATION");
        var jls: any = JSON.parse(ls)
        var access_token = parseJwt(jls.access_token)
        var extra = JSON.parse(access_token.extra)
        console.log(extra)

        var user_id = ""
        if (extra.citizen_id) {
            user_id = extra.citizen_id
            this.setState({ userId: extra.citizen_id, token: jls.access_token })
        } else if (extra.username) {
            user_id = extra.username
            this.setState({ userId: extra.username, token: jls.access_token })
        }

        fetch("/v1/env")
            .then(res => res.json())
            .then(
                (result) => {
                    console.log(result.ScgDmsUrl)
                    this.setState({
                        dmsApi: result.dms_api
                    });
                },
                (error) => {
                }
            )
    }

    componentDidUpdate(nextProps, nextState) {
        const { GetRemark } = this.props
        if (GetRemark.isLoadGetRemark && GetRemark.statusGetRemark == 200 && GetRemark.responseGetRemark.total_count != 0 && GetRemark.responseGetRemark.total_count != this.state.countRemark) {
            this.setState({
                countRemark: GetRemark.responseGetRemark.total_count,
                replyComment : false
            })

            console.log(GetRemark.responseGetRemark.result_list)
            var unique = GetRemark.responseGetRemark.result_list.map(item => item.id).filter((value, index, self) => self.indexOf(value) === index)
            console.log(unique)

            var dataRs: Array<RemarkModels.RemarkList> = GetRemark.responseGetRemark.result_list
            var dataArr : Array<RemarkModels.RemarkList> = []
            for (var i = 0; i < unique.length; i++) {
                for (var j = 0; j < dataRs.length; j++) {
                    if (unique[i] == dataRs[j].id) {
                        dataArr.push(dataRs[j])
                    } else if (unique[i] == dataRs[j].remark_ref_code) {
                        dataArr.push(dataRs[j])
                    }
                }
            }

            console.log(dataArr)
            this.setState({
                dataResult: dataArr
            })
        }
    }
    
    componentWillUnmount() {
        var wfData = JSON.parse(JSON.stringify(this.props.workflowValue))
        this.props.requestGetRemark(true, 'CLEAR', wfData.appId, wfData.wfId, wfData.wfInstanceNo)
        this.setState({
            countRemark: 0
        })
    }

    HideRemark = (obj: any) => {
        const ls = findDOMNode(this.refs.toggle)
        console.log(ls)
        $(ls).hide();
        $(ls).next().next().show();
        $(ls).closest(".feed-activity-command").next().html("");
    };

    LoadRemark = (obj: any, id: any, total: any) => {
        $(obj).hide();
        $(obj).prev().show();
        //$(obj).closest(".feed-activity-command").next().feedActivityRemark(id, total);
    }

    getFileRemark = (key: string) => {
        var encodedFilePath = btoa(key);
        //var url = 'https://dms-api.sonarinno.com/v1/dms/file?ref_path=' + encodedFilePath + '&token=' + this.state.token
        var url = this.state.dmsApi + '/v1/dms/file?ref_path=' + encodedFilePath + '&token=' + this.state.token;
        return url;
    }

    closeLightbox = (url: string) => {
        if (this.state.openPicture) {
            this.setState({
                openPicture: false,
                openPictureUrl: ''
            })
        } else {
            this.setState({
                openPicture: true,
                openPictureUrl: url
            })
        }
    }

    replyToggle = (id: string, instanceno : string) => {
        console.log(id)
        var value = id.split('_')[1]
        if (this.state.replyComment) {
            this.setState({
                replyComment: false,
                replyId: '',
                replyInstanceno: ''
            })
        } else {
            this.setState({
                replyComment: true,
                replyId: value,
                replyInstanceno : instanceno
            })
        }
    }

    handleSaveReply = () => {
        this.setState({
            replyComment : false
        })
    }

    //componentDidMount() {
    //    //var _this = this;
    //    //$(document).ready(function () {
    //    //    $(_this).feedActivityRemark = function (aobj, total) {

    //    //        var container = $("<div/>", {
    //    //            class: "system-message-comment-container"
    //    //        });

    //    //        $(this).append(container);

    //    //        $(container).activityRemark({
    //    //            myImagePath: "/images/user.png",
    //    //            aobjectlink: aobj,
    //    //            allowDivider: false,
    //    //            islazyLoad: true,
    //    //            lazyLoadTotal: total,
    //    //            isEasyMode: true,
    //    //            onRendered: function () {
    //    //                var showLoad = $("[data-show-load='" + aobj + "']");
    //    //                showLoad.hide();
    //    //                showLoad.prev().show();
    //    //            },
    //    //            getData: {
    //    //                url: "/TimeAttendance/TimeAttendance.ashx",
    //    //                key: {
    //    //                    q: "taskremark-lazyload",
    //    //                    obj: aobj
    //    //                }
    //    //            },
    //    //            postData: {
    //    //                url: '/widget/SystemMessageFormAPI.aspx',
    //    //                key: {
    //    //                    type: "postremark",
    //    //                    aobj: aobj
    //    //                }
    //    //            },
    //    //            editData: {
    //    //                url: "/timeattendance/TimeAttendance.ashx",
    //    //                key: {
    //    //                    q: "editremark"
    //    //                }
    //    //            }
    //    //        });
    //    //    }
    //    //});
    //};

    render() {
        const { GetRemark } = this.props
        console.log(this.state.replyComment)
        return (
            <Container fluid={true} className="mt-3">
                <div>
                    <div className="feed-activity-command" ref="toggle">

                        <span style={{ cursor: 'pointer', display: 'none' }} onClick={() => this.HideRemark(this)}>
                            <i className="fa fa-comments fa-fw"></i>
                            <b>ซ่อนความคิดเห็น</b>
                        </span>
                        <span style={{ display: 'none' }} data-show-load="5700012TH0000002220210318004">
                            กำลังโหลดความคิดเห็น...
                        </span>
                        <span style={{ cursor: 'pointer' }} onClick={() => this.LoadRemark(this, '5700012TH0000002220210318004', '1')}>
                            <i className="fa fa-comments fa-fw"></i>
                            <b>ความคิดเห็น ({this.state.countRemark})</b>
                        </span>
                        <div className="feed-activity-remark" data-aobjectlink="5700012TH0000002220210318004"></div>
                    </div>
                    <div className="feed-activity-remark" style={{ overflowY: 'auto' }}>
                        {!GetRemark.isLoadGetRemark ? <Row><Col><div className="text-center"><Spinner animation="border" /></div></Col></Row> : ''}
                        {GetRemark.isLoadGetRemark && GetRemark.statusGetRemark == 200 && GetRemark.responseGetRemark.total_count == 0 ? <Row className="mt-3"><Col><h4 className="text center text-muted">ไม่มีความคิดเห็น..</h4></Col></Row> : ''}
                        {GetRemark.isLoadGetRemark && GetRemark.statusGetRemark == 200 && this.state.dataResult.length != 0 && this.state.dataResult.map((item: RemarkModels.RemarkList, idx) =>
                            <div>
                                <div className={"feed-activity-remark " + item.remark_ref_code != "0" ? '' : 'mx-5'}>
                                    <p className="system-message-comment-container-remark" data-message-type={item.type} data-message-seq={item.instance_ref_code}>
                                        <Image className="system-message-comment-container-remark-img image-box" src="https://www.pngitem.com/pimgs/m/78-786293_1240-x-1240-0-avatar-profile-icon-png.png" style={{ width: "35px" }} />
                                        <span className="system-message-comment-container-remark-fullname">{item.created_by != "" ? item.created_by : "Anonymous"}</span>
                                        <span className="system-message-comment-container-remark-date">{item.created_on}</span>
                                    </p>
                                    <p className="system-message-comment-container-remark-message mx-2">
                                        <div>
                                            <span className="remark-text-container mt-5 mx-5">{item.message}</span>
                                        </div>
                                        <FaEdit style={{ color: 'rgb(0, 133, 161)', marginLeft: '10px', float: 'right' }} />
                                        <FaReply id={'remark-feed_' + item.id} style={{ color: 'rgb(0, 133, 161)', marginLeft: '10px', float: 'right' }} />
                                    </p>
                                    {/*<p className="alert alert-info system-message-comment-container-event-message mt-3">*/}
                                    {/*    <i className="fa fa-info-circle"></i>*/}
                                    {/*    <span className="event-message-text">{item.message}</span>*/}
                                    {/*</p>*/}

                                    {/*<p className="system-message-comment-container-remark-message">*/}
                                    {/*    <div>*/}
                                    {/*        <span className="remark-text-container"></span>*/}
                                    {/*    </div>*/}
                                    {/*    <i className="fa fa-reply pull-right fa-option-activity-remark" style={{ color: "rgb(0, 133, 161)", cursor: "pointer", marginLeft: "10px" }}></i>*/}
                                    {/*</p>*/}
                                    {/*<div className="system-message-attach-files-container" style={{ margin: "10px" }}>*/}
                                    {/*    <div className="system-message-attach-image-block-adder" style={{ display: "none" }}>*/}
                                    {/*        <i className="fa fa-plus"></i>*/}
                                    {/*    </div>*/}
                                    {/*</div>*/}
                                </div>
                                {item.original_content_url != "" &&
                                    <div className="system-message-attach-files-container">
                                        <a className="system-message-attach-files mx-5" onClick={() => this.closeLightbox(this.getFileRemark(item.original_content_url))}>
                                            <div className="system-message-attach-image-block" style={{ backgroundImage: 'url(' + this.getFileRemark(item.original_content_url) + ')' }}>

                                            </div>
                                        </a>
                                    </div>
                                }
                                <UncontrolledPopover trigger="click" placement="left-start" isOpen={this.state.replyComment && this.state.replyId == item.id ? true : false} target={'remark-feed_' + item.id} toggle={() => this.replyToggle('remark-feed_' + item.id, item.instanceno)}>
                                    <PopoverHeader>Reply {this.state.replyId}</PopoverHeader>
                                    <PopoverBody>
                                        <Row>
                                            <RemarkReplyComment replyStatus={this.state.replyComment} replyId={this.state.replyId} replyInstanceno={this.state.replyInstanceno} workflowValue={this.props.workflowValue} handleSaveReply={this.handleSaveReply.bind(this)}/>
                                        </Row>
                                    </PopoverBody>
                                </UncontrolledPopover>
                            </div>
                        )}
                    </div>
                </div>
                {this.state.openPicture &&
                    <Lightbox
                        medium={this.state.openPictureUrl}
                        //large={urlToHugeImageFile}
                        alt={this.state.openPictureUrl}
                        onClose={this.closeLightbox}
                    />
                }
            </Container >
        )
    }
}
export default connect((state: ApplicationState) => ({ ...state.Remark }), ({ ...RemarkStore.actionCreators }))(RemarkCommentTable);