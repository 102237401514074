import axios from 'axios';
import {
    LogCallBackInfo,
    LogCallbackStartResponse,
    UpdateLogCallbackBody,
    WorkflowCallBackLogParams,
    WorkflowWaitingCallback
} from '../schemas/CallbackLog.schema';
import { ResponseList } from 'models/Response';

export function retrieveCallbackWaiting() {
    return axios.get<ResponseList<WorkflowWaitingCallback>>(
        '/v1/callback/waiting',
        {
            headers: {
                Authorization:
                    'Bearer ' + localStorage.getItem('WF_APPLICATION')
            }
        }
    );
}

export function retrieveCallbackStart() {
    return axios.get<ResponseList<LogCallbackStartResponse>>(
        '/v1/callback/stat',
        {
            headers: {
                Authorization:
                    'Bearer ' + localStorage.getItem('WF_APPLICATION')
            }
        }
    );
}

export function retrieveWorkflowCallbackLog(params: WorkflowCallBackLogParams) {
    return axios.get<ResponseList<LogCallBackInfo>>('/v1/callback/log', {
        params,
        headers: {
            Authorization: 'Bearer ' + localStorage.getItem('WF_APPLICATION')
        }
    });
}

export function putCallbackLog(body: UpdateLogCallbackBody) {
    return axios.put<string>('/v1/callback/log', body, {
        headers: {
            Authorization: 'Bearer ' + localStorage.getItem('WF_APPLICATION')
        }
    });
}
