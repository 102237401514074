import React, { FormEvent, memo, useState } from 'react';
import { Button, Form } from 'react-bootstrap';
import DatePicker from 'react-datepicker';

export type SearchValues = {
    citizenId?: string;
    from?: Date | null;
    to?: Date | null;
};

type Props = {
    onSubmitSearch: (values: SearchValues) => void;
};

function AuthenticationHistorySearchBar(props: Props) {
    const { onSubmitSearch } = props;

    const [citizenId, setCitizenId] = useState<string>();
    const [from, setFrom] = useState<Date | null>();
    const [to, setTo] = useState<Date | null>();

    const handleSubmitSearch = (event: FormEvent<HTMLFormElement>) => {
        event.preventDefault();

        onSubmitSearch({ citizenId: citizenId?.trim(), from, to });
    };

    return (
        <form
            onSubmit={handleSubmitSearch}
            className="flex flex-row justify-end gap-4"
        >
            <Form.Group className="m-0">
                <Form.Label>Citizen ID</Form.Label>
                <Form.Control
                    value={citizenId}
                    onChange={event => setCitizenId(event.target.value)}
                />
            </Form.Group>
            <Form.Group className="m-0">
                <Form.Label>From</Form.Label>
                <DatePicker
                    selectsStart
                    selected={from}
                    startDate={from}
                    endDate={to}
                    onChange={setFrom}
                    showTimeSelect
                    timeFormat="HH:mm"
                    timeIntervals={5}
                    timeCaption="time"
                    dateFormat="dd/MM/yyyy HH:mm"
                    customInput={<Form.Control />}
                />
            </Form.Group>
            <Form.Group className="m-0">
                <Form.Label>To</Form.Label>
                <DatePicker
                    selectsEnd
                    selected={to}
                    startDate={from}
                    endDate={to}
                    minDate={from}
                    onChange={setTo}
                    showTimeSelect
                    timeFormat="HH:mm"
                    timeIntervals={5}
                    timeCaption="time"
                    dateFormat="dd/MM/yyyy HH:mm"
                    customInput={<Form.Control />}
                />
            </Form.Group>
            <Button
                type="submit"
                className="self-end"
            >
                Search
            </Button>
        </form>
    );
}

export default memo(AuthenticationHistorySearchBar);
