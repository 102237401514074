import axios from "axios";
import React, {
    useCallback,
    useEffect,
    useLayoutEffect,
    useRef,
    useState,
} from "react";
import { useLocation } from "react-router-dom";
import { FiArrowLeft, FiHome } from "react-icons/fi";
import {
    ResponseWorkflowInbox,
    WorkflowInbox,
} from "../../models/workflow/WorkflowInbox";
import Swal from "sweetalert2";

//interface callback {
//    redirect_url: string;
//    subject: string;
//    doc_no: string;
//}

interface Params {
    uuid: string;
    key: string;
    ref_key1?: string;
    ref_key2?: string;
    ref_key3?: string;
}

function EsignCallback() {
    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const intervalRef = useRef<number | null>(null);
    const [counter, setCounter] = useState<number>(5);
    const [docNo, setdocNo] = useState<string>();

    const uuid = searchParams.get("uuid");
    const key = searchParams.get("key");
    const refKey1 = searchParams.get("ref_key1");
    const refKey2 = searchParams.get("ref_key2");
    const refKey3 = searchParams.get("ref_key3");

    const params: Params = {
        uuid: uuid!,
        key: key!,
        ref_key1: refKey1!,
        ref_key2: refKey2!,
        ref_key3: refKey3!,
    };

    const [callbackResponse, setCallbackResponse] = useState({
        total_count: 0,
        result_list: [] as Array<WorkflowInbox>,
        message: "",
    });

    useEffect(() => {
        setTimeout(fetchCallback, 0);
    }, []);

    const fetchCallback = async () => {
        const element = document.getElementsByClassName("Loader__background");

        try {
            if (element.length > 0) {
                element[0].className = "Loader__background show";
            }
            const response = await axios.get<ResponseWorkflowInbox>(
                "/v1/callback/e-sign",
                {
                    params,
                    headers: {
                        Authorization: "Bearer " + localStorage.getItem("WF_APPLICATION"),
                    },
                }
            );

            setCallbackResponse(response.data);
            if (element.length > 0) {
                element[0].className = "Loader__background hide";
            }

            const formattedDocNo = response.data.result_list
                .map((val) => JSON.stringify(val.docNo))
                .join(", ");

            setdocNo(formattedDocNo.replace(/"/g, ""));
        } catch {
            Swal.fire({
                title: <strong>ERROR!</strong>,
                text: callbackResponse.message,
                icon: 'error'
            })
        } finally {
            if (element.length > 0) {
                element[0].className = "Loader__background hide";
            }
        }
        // response &&
        //     SweetAlert2.fire({
        //         icon: "success",
        //         title: "เสร็จสิ้น",
        //         text: "กรุณารอระบบ Workflow Confirm การอนุมัติสักครู่  ระบบจะนำทางกลับไปยังระบบ Workflow",
        //         confirmButtonText: "Ok!",
        //     }).then(async result => {
        //         if (result.isConfirmed) {
        //             window.location.replace("/workflowinbox")
        //         }
        //     });
    };

    const startCountDown = useCallback((redirectUrl: string | undefined) => {
        intervalRef.current = window.setInterval(
            (() => {
                let localCounter = counter;
                return () => {
                    if (localCounter === 0) {
                        clearInterval(intervalRef.current!);
                        setCounter(0);
                        window.location.replace(redirectUrl!);

                        return;
                    }

                    localCounter--;
                    setCounter((timer) => timer - 1);
                };
            })(),
            1000
        );
    }, []);

    return (
        <div
            style={{
                background: "#eceaea",
                height: "100vh",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                flexDirection: "column",
            }}
        >
            <div style={{ paddingTop: 20, paddingLeft: 40, paddingRight: 40 }}>
                {callbackResponse.total_count > 0 ? (
                    <div>
                        <div
                            style={{
                                background: "#FFFFFF",
                                padding: 30,
                                justifyContent: "center",
                                display: "flex",
                                flexDirection: "column",
                            }}
                        >
                            <span
                                style={{ fontSize: 32, textAlign: "center", color: "#28a745" }}
                            >
                                ระบบได้บันทึกการอนุมัติแบบฟอร์มของคุณแล้ว
                            </span>
                            <span
                                style={{ marginTop: 20, fontSize: 24, textAlign: "center" }}
                            >
                                คุณได้ทำการอนุมัติ Online Form ID: {docNo}
                            </span>
                            {callbackResponse.total_count === 1 ? (
                                <span
                                    style={{ marginTop: 20, fontSize: 24, textAlign: "center" }}
                                >
                                    เรื่อง: {callbackResponse.result_list[0].subject}
                                </span>
                            ) : (
                                <span
                                    style={{ marginTop: 20, fontSize: 24, textAlign: "center" }}
                                >
                                    คุณได้ทำการอนุมัติ Online Form ทั้งหมด:{" "}
                                    {callbackResponse.total_count} ฟอร์ม
                                </span>
                            )}
                        </div>
                        <div
                            style={{
                                background: "#f7f7f7",
                                display: "flex",
                                flexDirection: "row",
                                padding: 10,
                                gap: 10,
                                justifyContent: "center",
                                alignItems: "center",
                            }}
                        >
                            <div
                                style={{
                                    fontSize: 18,
                                    textAlign: "center",
                                    display: "flex",
                                    alignItems: "center",
                                    justifyItems: "center",
                                    color: "#007bff",
                                }}
                                onClick={() => {
                                    window.parent.location.href =
                                        "https://onlineform.focusonesoftware.com/OnlineForm/FormCreator/WFInboxApprove.aspx";
                                    //window.location.replace(
                                    //    "https://onlineform.focusonesoftware.com/OnlineForm/FormCreator/WFInboxApprove.aspx"
                                    //);
                                }}
                            >
                                <FiHome />
                                <span>Back to Home</span>
                            </div>
                            {/* <div style={{ fontSize: 18, textAlign: 'center', color: '#007bff' }} onClick={() => {
                                        window.location.replace("/workflowinbox")
                                    }}>
                                        <FiArrowLeft />
                                        ไปที่กล่องอนุมัติของฉัน
                                    </div> */}
                        </div>
                    </div>
                ) : callbackResponse.message === "" ? (
                    <div
                        style={{
                            background: "#FFFFFF",
                            padding: 30,
                            justifyContent: "center",
                            display: "flex",
                            flexDirection: "column",
                        }}
                    >
                        <span
                            style={{ fontSize: 32, textAlign: "center", color: "#28a745" }}
                        >
                            ระบบอยู่ระหว่างการอนุมัติแบบฟอร์มของคุณ
                        </span>
                        <span style={{ marginTop: 20, fontSize: 24, textAlign: "center" }}>
                            กรุณารอระบบยืนยันการอนุมัติของคุณสักครู่
                        </span>
                    </div>
                ) : (
                    <div>
                        <div
                            style={{
                                background: "#FFFFFF",
                                padding: 30,
                                justifyContent: "center",
                                display: "flex",
                                flexDirection: "column",
                            }}
                        >
                            <span
                                style={{ fontSize: 32, textAlign: "center", color: "#28a745" }}
                            >
                                การอนุมัติแบบฟอร์มของคุณเกิดข้อผิดพลาด
                            </span>
                            <span
                                style={{ marginTop: 20, fontSize: 24, textAlign: "center" }}
                            >
                                การอนุมัติแบบฟอร์มของคุณอาจได้รับการอนุมัติ (หรือปฎิเสธ)
                                แล้วโดยบุคคลอื่น
                            </span>
                        </div>
                        <div
                            style={{
                                background: "#f7f7f7",
                                display: "flex",
                                flexDirection: "row",
                                padding: 10,
                                gap: 10,
                                justifyContent: "center",
                                alignItems: "center",
                            }}
                        >
                            <div
                                style={{
                                    fontSize: 18,
                                    textAlign: "center",
                                    display: "flex",
                                    alignItems: "center",
                                    justifyItems: "center",
                                    color: "#007bff",
                                }}
                                onClick={() => {
                                    window.parent.location.href =
                                        "https://onlineform.focusonesoftware.com/OnlineForm/FormCreator/WFInboxApprove.aspx";
                                    //window.location.replace(
                                    //    "https://onlineform.focusonesoftware.com/OnlineForm/FormCreator/WFInboxApprove.aspx"
                                    //);
                                }}
                            >
                                <FiHome />
                                <span>Back to Home</span>
                            </div>
                            {/* <div style={{ fontSize: 18, textAlign: 'center', alignItems: 'center', color: '#007bff' }} onClick={() => {
                                            window.location.replace("/workflowinbox")
                                        }}>
                                            <FiArrowLeft />
                                            ไปที่กล่องอนุมัติของฉัน
                                        </div> */}
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
}

export default EsignCallback;
