import axios from 'axios';
import { WorkflowAdminReqeust } from 'data/MasterEmployee/schemas/MasterEmployee.shema';
import {
    MasterCompanyList,
    MasterCompanyResponse
} from 'models/company/MasterCompany';
import React, { useEffect, useState } from 'react';
import { Button, Col, Container, Form, Row } from 'react-bootstrap';
import { BsCalendar2Date } from 'react-icons/bs';
import { FaSearch } from 'react-icons/fa';
import { Input } from 'reactstrap';
import DatePicker from 'react-datepicker';
import {
    getWorkflowCallbackLog,
    updateWorkflowCallbackLog
} from 'data/CallbackLog/repositories/CallbackLog.repository';
import {
    LogCallBackInfo,
    WorkflowCallBackLogParams
} from 'data/CallbackLog/schemas/CallbackLog.schema';
import { ResponseList } from 'models/Response';
import CallbackLogTable from './components/CallbackLogTable';
import CallbackLogDetail from './components/CallbackLogDetail';
import Swal from 'sweetalert2';
import useLoader from 'hooks/useLoader';

function CallbackLog() {
    const loading = useLoader();
    const [companyMaster, setCompanyMater] = useState<MasterCompanyList[]>();
    const [selectdAllCompany, setSelectdAllCompany] = useState<boolean>(true);
    const [explorerDetail, setExplorerDetail] = useState<boolean>(false);
    const [callbackLogDetail, setCallbackLogDetail] =
        useState<LogCallBackInfo>();
    const [searchHelp, setSearchHelp] = useState<WorkflowCallBackLogParams>({
        doc_no: '',
        sid: '*',
        status: ''
    });
    const [workflowLogResponse, setWorkflowLogResponse] =
        useState<ResponseList<LogCallBackInfo>>();

    useEffect(() => {
        fetchCompanyMaster();
    }, []);

    const handleChangeSearchHelp = <K extends keyof WorkflowCallBackLogParams>(
        key: K,
        value: WorkflowCallBackLogParams[K]
    ) => {
        setSearchHelp(prevState => ({
            ...prevState,
            [key]: value
        }));
    };

    const mappingCompany = new Map<string, string>(
        companyMaster?.map(value => [value.sid, value.company_name])
    );

    const fetchCompanyMaster = async () => {
        try {
            loading.show();
            const response = await axios.get<MasterCompanyResponse>(
                '/v1/company_master',
                {
                    headers: {
                        Authorization:
                            'Bearer ' + localStorage.getItem('WF_APPLICATION')
                    }
                }
            );
            setCompanyMater(response.data.result_list);
        } finally {
            loading.hide();
        }
    };

    const fetchCallbackLog = async () => {
        if (searchHelp.status.length > 2) {
            searchHelp.status = '*';
        }

        if (searchHelp.doc_no === '' && searchHelp.status === '') {
            Swal.fire({
                title: 'กรุณาระบุ เงื่อนไข',
                text: `กรุณาระบุ เลขที่เอกสาร หรือ สถานะ`,
                icon: 'info',
                confirmButtonColor: '#3085d6',
                confirmButtonText: 'Ok!'
            });

            return;
        }

        try {
            loading.show();

            const [, response] = await getWorkflowCallbackLog(searchHelp);

            if (response) {
                setWorkflowLogResponse(response);
            }
        } finally {
            loading.hide();
        }
    };

    const handleSearch = async () => {
        await fetchCallbackLog();
    };

    const handleRefreshLog = async (id: number, instanceNo: string) => {
        Swal.fire({
            title: 'Refresh Log',
            text: `Instance Number: ${instanceNo}`,
            icon: 'info',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes'
        }).then(async result => {
            if (result.isConfirmed) {
                try {
                    loading.show();
                    const [, response] = await updateWorkflowCallbackLog({
                        id: id
                    });

                    if (response) {
                        await fetchCallbackLog();
                    }
                } finally {
                    loading.hide()
                }
            }
        });
    };

    const handleExplorerDetail = (logDetail?: LogCallBackInfo) => {
        setExplorerDetail(prev => !prev);

        if (logDetail) {
            setCallbackLogDetail(logDetail);
        }
    };

    const handleCheckboxChange = (value: string) => {
        setSearchHelp(prevState => {
            let newStatus = prevState.status.split(',').filter(Boolean);

            if (newStatus.includes(value)) {
                newStatus = newStatus.filter(item => item !== value);
            } else {
                newStatus.push(value);
            }

            const updatedStatus = newStatus.join(',');

            return {
                ...prevState,
                status: updatedStatus
            };
        });
    };

    return (
        <Container>
            <React.Fragment>
                <Row>
                    <Col className="import-export-actions">
                        <div
                            className="trapezoid-head"
                            style={{ width: '200px' }}
                        >
                            <span>Callback Log</span>
                        </div>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <div className="mat-box">
                            <div
                                className="trapezoid trapezoid-purple"
                                style={{
                                    width: '200px',
                                    display: 'inline-block'
                                }}
                            >
                                <span>
                                    <i
                                        className="fa fa-search"
                                        aria-hidden="true"
                                    ></i>
                                    Search menu
                                    <div
                                        style={{
                                            display: 'inline-block',
                                            float: 'right'
                                        }}
                                    ></div>
                                </span>
                            </div>
                            <div className="mat-box mb-5 rounded bg-white p-3 shadow">
                                <Row>
                                    <Col>
                                        <div className="mt-2">
                                            <Row>
                                                <Col
                                                    xs={12}
                                                    md={3}
                                                >
                                                    <Form.Group
                                                        className="mb-3"
                                                        controlId="formBasicEmail"
                                                    >
                                                        <Form.Label>
                                                            Company Name :
                                                        </Form.Label>
                                                        <div>
                                                            <input
                                                                type="checkbox"
                                                                checked={
                                                                    selectdAllCompany
                                                                }
                                                                onChange={e => {
                                                                    e.persist();
                                                                    setSelectdAllCompany(
                                                                        prev =>
                                                                            !prev
                                                                    );
                                                                    handleChangeSearchHelp(
                                                                        'sid',
                                                                        '*'
                                                                    );
                                                                }}
                                                            />
                                                            <span className="ml-2">
                                                                เลือกทั้งหมด
                                                            </span>
                                                        </div>
                                                        <Input
                                                            type="select"
                                                            onChange={e => {
                                                                e.persist();
                                                                handleChangeSearchHelp(
                                                                    'sid',
                                                                    e.target
                                                                        .value
                                                                );
                                                            }}
                                                            disabled={
                                                                selectdAllCompany
                                                            }
                                                        >
                                                            <option selected>
                                                                Please choose
                                                            </option>
                                                            {companyMaster &&
                                                                companyMaster.map(
                                                                    (
                                                                        value,
                                                                        index
                                                                    ) => (
                                                                        <option
                                                                            key={
                                                                                index
                                                                            }
                                                                            value={
                                                                                value.sid
                                                                            }
                                                                        >
                                                                            {
                                                                                value.company_name
                                                                            }
                                                                        </option>
                                                                    )
                                                                )}
                                                        </Input>
                                                    </Form.Group>
                                                </Col>
                                                <Col
                                                    xs={12}
                                                    md={2}
                                                    className="flex items-end justify-center"
                                                >
                                                    <div className="text-center">
                                                        <Button
                                                            variant="outline-info"
                                                            size="sm"
                                                            onClick={
                                                                handleSearch
                                                            }
                                                        >
                                                            <FaSearch /> Search
                                                        </Button>
                                                    </div>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col
                                                    xs={5}
                                                    md={2}
                                                >
                                                    <Form.Group
                                                        className="mb-3"
                                                        controlId="formBasicEmail"
                                                    >
                                                        <Form.Label>
                                                            เลขที่เอกสาร :
                                                        </Form.Label>
                                                        <Input
                                                            bsSize="sm"
                                                            onChange={e => {
                                                                e.persist();
                                                                handleChangeSearchHelp(
                                                                    'doc_no',
                                                                    e.target
                                                                        .value
                                                                );
                                                            }}
                                                        />
                                                    </Form.Group>
                                                </Col>
                                                {/*<Col*/}
                                                {/*    xs={5}*/}
                                                {/*    md={2}*/}
                                                {/*>*/}
                                                {/*    <Form.Group*/}
                                                {/*        className="mb-3"*/}
                                                {/*        controlId="formBasicEmail"*/}
                                                {/*    >*/}
                                                {/*        <Form.Label>*/}
                                                {/*            ปีเอกสาร :*/}
                                                {/*        </Form.Label>*/}
                                                {/*        <Input*/}
                                                {/*            bsSize="sm"*/}
                                                {/*            onChange={e => {*/}
                                                {/*                e.persist();*/}
                                                {/*                handleChangeSearchHelp(*/}
                                                {/*                    'doc_no',*/}
                                                {/*                    e.target*/}
                                                {/*                        .value*/}
                                                {/*                );*/}
                                                {/*            }}*/}
                                                {/*        />*/}
                                                {/*    </Form.Group>*/}
                                                {/*</Col>*/}
                                                {/*<Col*/}
                                                {/*    xs={5}*/}
                                                {/*    md={2}*/}
                                                {/*>*/}
                                                {/*    <Form.Group*/}
                                                {/*        className="mb-3"*/}
                                                {/*        controlId="formBasicEmail"*/}
                                                {/*    >*/}
                                                {/*        <Form.Label>*/}
                                                {/*            ปีเอกสาร :*/}
                                                {/*        </Form.Label>*/}
                                                {/*        <Input*/}
                                                {/*            bsSize="sm"*/}
                                                {/*            onChange={e => {*/}
                                                {/*                e.persist();*/}
                                                {/*                handleChangeSearchHelp(*/}
                                                {/*                    'doc_no',*/}
                                                {/*                    e.target*/}
                                                {/*                        .value*/}
                                                {/*                );*/}
                                                {/*            }}*/}
                                                {/*        />*/}
                                                {/*    </Form.Group>*/}
                                                {/*</Col>*/}
                                                <Col
                                                    xs={5}
                                                    md={1}
                                                >
                                                    <Form.Label>
                                                        สถานะ :
                                                    </Form.Label>
                                                    <div className="form-inline">
                                                        <Form.Group
                                                            className=""
                                                            controlId="formBasicCheckbox"
                                                        >
                                                            <Form.Check
                                                                type="checkbox"
                                                                id="checkError"
                                                                label="WAITING"
                                                                onClick={() => {
                                                                    handleCheckboxChange(
                                                                        'W'
                                                                    );
                                                                }}
                                                            />
                                                            <Form.Check
                                                                type="checkbox"
                                                                className="mx-5"
                                                                id="checkError"
                                                                label="ERROR"
                                                                onClick={() => {
                                                                    handleCheckboxChange(
                                                                        'E'
                                                                    );
                                                                }}
                                                            />
                                                        </Form.Group>
                                                    </div>
                                                </Col>
                                            </Row>
                                        </div>
                                    </Col>
                                </Row>
                            </div>
                            {!explorerDetail ? (
                                <CallbackLogTable
                                    mappingCompany={mappingCompany}
                                    workflowLogResponse={workflowLogResponse!}
                                    refreshLog={handleRefreshLog}
                                    explorerDetail={handleExplorerDetail}
                                />
                            ) : (
                                <CallbackLogDetail
                                    CallbackLogDetail={callbackLogDetail!}
                                    explorerDetail={handleExplorerDetail}
                                    mappingCompany={mappingCompany}
                                    refreshLog={handleRefreshLog}
                                />
                            )}
                        </div>
                    </Col>
                </Row>
            </React.Fragment>
        </Container>
    );
}

export default CallbackLog;
