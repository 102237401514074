import { Action, Reducer } from 'redux';
import { AppThunkAction } from '..';
// models
import * as Models from '../../models/group/CreateGroup'

interface LoadCreateGroup {
    type: 'LOAD_CREATE_GROUP'
}

interface LoadedCreateGroup {
    type: 'CREATEDPOSITIONGROUP'
    response: Models.ResponseCreateGroup
    status: Number,
    statusMessage: String
}

interface FailCreateGroup {
    type: 'CREATEFAIL'
    status: number,
    statusMessage: any
}

interface ClearCreateGroup {
    type: 'CLEARCREATEGROUP'
}

type KnownAction = LoadedCreateGroup | FailCreateGroup | ClearCreateGroup | LoadCreateGroup

export const actionCreators = {
    requestCreateGroup: (p: boolean, method: string, username: string, formData: Array<Models.Position>, delete_position_group_list: Array<Models.Position>, delete_position_condition_list: Array<Models.SubPosi>, company: string): AppThunkAction<KnownAction> => async (dispatch, getState) => {
        const appState = getState();
        console.log('call actionCreators : requestCreateGroup')
        if (appState && appState.createGroup && p && method == 'POST') {
            console.log(JSON.stringify(formData))
            console.log(JSON.stringify(delete_position_group_list))
            var body = {
                'username': username,
                'position_group_list': formData,
                "delete_position_group_list": delete_position_group_list,
                "delete_position_condition_list": delete_position_condition_list
            }
            console.log(body)
            console.log(JSON.stringify(body))
            await fetch(`/v2/Group/Create?app_id=` + company, {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + localStorage.getItem('WF_APPLICATION'),
                },
                body: JSON.stringify(body),
            })
                .then((response) => {
                    return response
                })
                .then((data) => {
                    //console.log(data)
                    if (!data.ok) {
                        data.json().then(err => {
                            //console.log(err.message_res)
                            console.log('dispatch : CREATEFAIL')
                            console.log(err)
                            console.log(JSON.stringify(err))
                            dispatch({ type: 'CREATEFAIL', status: 204, statusMessage: err.message });
                        })
                    } else {
                        data.json().then(data => {
                            //console.log(data)
                            console.log(JSON.stringify(data))
                            dispatch({ type: 'CREATEDPOSITIONGROUP', response: data as Models.ResponseCreateGroup, status: 200, statusMessage: '' });
                        })
                    }
                })

            dispatch({ type: 'LOAD_CREATE_GROUP' });

        } else if (method == 'CLEAR') {
            console.log('unloadstate')
            dispatch({ type: 'CLEARCREATEGROUP' });
        }
    }
}

const unloadedState: Models.CreatePositionGroupState = { isLoad: false };

export const reducer: Reducer<Models.CreatePositionGroupState> = (state: Models.CreatePositionGroupState | undefined, incomingAction: Action): Models.CreatePositionGroupState => {
    if (state == undefined) {
        return unloadedState;
    }
    const action = incomingAction as KnownAction;
    switch (action.type) {
        case 'LOAD_CREATE_GROUP':
            return {
                isLoad: false,
            }

        case 'CREATEDPOSITIONGROUP':
            return {
                isLoad: true,
                response: action.response,
                statusCreate: action.status

            }
        case 'CREATEFAIL':
            return {
                isLoad: true,
                MessageCreateGroup: action.statusMessage,
                statusCreate: action.status
            }

        case 'CLEARCREATEGROUP':
            return {
                isLoad: false,
                statusCreate: 400,
                MessageCreateGroup: ''
            }
        default: return state;
    }
}