import React from 'react';
import { Button } from 'reactstrap';

import FocusoneTextLogo from '../FocusoneTextLogo';
import IconCursorLogin from 'assets/image/icon-cursor-click.png';

function Step2() {
    return (
        <React.Fragment>
            <div className="flex items-baseline space-x-3 text-lg">
                <span className="inline-flex h-10 w-10 items-center justify-center rounded-full bg-[#F7980A] font-bold">
                    2
                </span>
                <span className="flex-1 text-lg font-bold ">
                    กดปุ่ม Connect to <FocusoneTextLogo className="text-lg" />{' '}
                    เพื่อเชื่อมต่อระบบการแจ้งเตือนผ่าน Line{' '}
                    <FocusoneTextLogo className="text-lg" />
                </span>
            </div>

            <div className="mt-6 flex flex-1 flex-col items-center gap-4">
                <Button
                    className="relative my-auto border-0 bg-[#06C755] px-5 py-3"
                    size="lg"
                    onClick={() => window.open('/line-connect', '_blank')}
                >
                    <img
                        src={IconCursorLogin}
                        className="absolute -left-6 top-4 h-[55px] w-[55px]"
                    />
                    Connect to{' '}
                    <FocusoneTextLogo className="text-xl font-normal" />
                </Button>
                <p className="p-4 text-center text-base text-neutral-800">
                    เพิ่ม <FocusoneTextLogo className="text-base" />{' '}
                    เป็นเพื่อนเพื่อรับการแจ้งเตือน ที่สะดวกและรวดเร็วผ่าน Line
                    Chat
                </p>
            </div>
        </React.Fragment>
    );
}

export default Step2;
