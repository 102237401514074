import React, { useState } from 'react';
import { Button, Row, Col, Table, Spinner, Form } from 'react-bootstrap';

import RoleMasterEmployeeViewModel from './RoleMasterEmployee.viewmodel';
import { FaEdit, FaPlusCircle, FaTimesCircle } from 'react-icons/fa';
import AddRoleMasterEmployee from './components/AddRoleMasterEmployee';
import { formatDateYYYYMMDD } from 'modules/Common';

function RoleMasterEmployee() {
    const {
        action,
        roleMasterEmployeeMapping,
        selectedRoleMasterEmployee,

        setCheckSCTL,
        handleSaveChange,
        handleChangeAction,
        handleDeletePositionCode,
        handleSearchLikeOperator,
        handleSelectdRoleMasterEmployee
    } = RoleMasterEmployeeViewModel();

    return (
        <React.Fragment>
            {action === 'main' ? (
                <div>
                    <div className="master-data-content">
                        <div
                            className="trapezoid-head"
                            style={{ width: '200px' }}
                        >
                            <span>Role Master Employee Criteria</span>
                        </div>
                    </div>
                    <Row>
                        <Col>
                            <div className="mat-box">
                                <div
                                    className="trapezoid trapezoid-purple"
                                    style={{
                                        width: '200px',
                                        display: 'inline-block'
                                    }}
                                >
                                    <span>
                                        <i
                                            className="fa fa-search"
                                            aria-hidden="true"
                                        ></i>
                                        Search menu
                                    </span>
                                </div>
                                <div className="mat-box mb-5 rounded bg-white p-3 shadow">
                                    <Row>
                                        <Col>
                                            <div className="mt-2">
                                                <Row>
                                                    <Col md={2}>
                                                        <Form.Group
                                                            className="mb-3"
                                                            controlId="formBasicRolecode"
                                                        >
                                                            <Form.Control
                                                                type="text"
                                                                size="sm"
                                                                placeholder="search..."
                                                                onChange={e => {
                                                                    handleSearchLikeOperator(
                                                                        e.target
                                                                            .value
                                                                    );
                                                                }}
                                                            />
                                                        </Form.Group>
                                                    </Col>
                                                    <Col md={4}>
                                                        <div className="flex gap-2">
                                                            <input
                                                                type="checkbox"
                                                                onChange={() => {
                                                                    setCheckSCTL(
                                                                        prev =>
                                                                            !prev
                                                                    );
                                                                }}
                                                            />
                                                            <span>
                                                                ค้นหาเฉพาะ Role
                                                                ที่สามารถเลือก
                                                                User ได้เอง
                                                            </span>
                                                        </div>
                                                    </Col>
                                                </Row>
                                            </div>
                                        </Col>
                                    </Row>
                                </div>
                                <Row>
                                    <Col>
                                        <div
                                            className="trapezoid trapezoid-purple"
                                            style={{
                                                display: 'inline-block'
                                            }}
                                        >
                                            <span>
                                                <i
                                                    className="fa fa-search"
                                                    aria-hidden="true"
                                                ></i>
                                                Role Master Employee
                                            </span>
                                        </div>
                                        <div
                                            style={{
                                                display: 'inline-block',
                                                float: 'right'
                                            }}
                                        >
                                            <Button
                                                variant="outline-info"
                                                size="sm"
                                                onClick={() => {
                                                    handleChangeAction('add');
                                                }}
                                            >
                                                <FaPlusCircle />
                                                &nbsp;Add Role Employee
                                            </Button>
                                        </div>
                                        <Table
                                            className="table-bordered"
                                            size="sm"
                                        >
                                            <thead>
                                                <tr
                                                    style={{ width: '100%' }}
                                                    className="table-active thead-light text-center"
                                                >
                                                    <th
                                                        style={{ width: '10%' }}
                                                    ></th>
                                                    <th
                                                        style={{ width: '30%' }}
                                                    >
                                                        Position Code
                                                    </th>
                                                    <th
                                                        style={{ width: '20%' }}
                                                    >
                                                        Position Name
                                                    </th>
                                                    <th
                                                        style={{ width: '20%' }}
                                                    >
                                                        Created By
                                                    </th>
                                                    <th
                                                        style={{ width: '20%' }}
                                                    >
                                                        Created On
                                                    </th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {!roleMasterEmployeeMapping ? (
                                                    <tr>
                                                        <td
                                                            colSpan={7}
                                                            style={{
                                                                background:
                                                                    'rgba(209, 250, 221, 1)',
                                                                color: 'black',
                                                                fontSize:
                                                                    '10pt',
                                                                textAlign:
                                                                    'center'
                                                            }}
                                                        >
                                                            <Spinner
                                                                animation="border"
                                                                size="sm"
                                                            />
                                                            &nbsp;&nbsp;Loading...
                                                        </td>
                                                    </tr>
                                                ) : (
                                                    roleMasterEmployeeMapping.map(
                                                        (value, index) => (
                                                            <tr key={index}>
                                                                <td className="text-center">
                                                                    <div>
                                                                        <Button
                                                                            variant="outline-info"
                                                                            size="sm"
                                                                            onClick={() => {
                                                                                handleSelectdRoleMasterEmployee(
                                                                                    value,
                                                                                    'edit'
                                                                                );
                                                                            }}
                                                                        >
                                                                            <FaEdit />
                                                                        </Button>
                                                                        <Button
                                                                            className="mx-1"
                                                                            variant="outline-danger"
                                                                            size="sm"
                                                                            onClick={() => {
                                                                                handleDeletePositionCode(
                                                                                    value.positioncode,
                                                                                    value.positionname
                                                                                );
                                                                            }}
                                                                        >
                                                                            <FaTimesCircle />
                                                                        </Button>
                                                                    </div>
                                                                </td>
                                                                <td>
                                                                    {
                                                                        value.positioncode
                                                                    }
                                                                </td>
                                                                <td>
                                                                    {
                                                                        value.positionname
                                                                    }
                                                                </td>
                                                                <td className="text-center">
                                                                    {
                                                                        value.created_by
                                                                    }
                                                                </td>
                                                                <td className="text-center">
                                                                    {formatDateYYYYMMDD(
                                                                        value.created_on
                                                                    )}
                                                                </td>
                                                            </tr>
                                                        )
                                                    )
                                                )}
                                            </tbody>
                                        </Table>
                                    </Col>
                                </Row>
                            </div>
                        </Col>
                    </Row>
                </div>
            ) : (
                <AddRoleMasterEmployee
                    action={action}
                    handleChangeAction={handleChangeAction}
                    roleMasterEmployee={selectedRoleMasterEmployee}
                    handleSaveChange={handleSaveChange}
                />
            )}
        </React.Fragment>
    );
}

export default RoleMasterEmployee;
