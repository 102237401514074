import * as React from "react";
import { connect } from "react-redux";
import { Button, Table, Spinner } from "react-bootstrap";
import { FaEdit, FaTimesCircle } from 'react-icons/fa';
import { Link } from "react-router-dom";
// import ReactDatatable  from '@ashvin27/react-datatable';
import Swal from 'sweetalert2'
// redux
import * as GroupStore from '../../store/group/PositionGroup'
import * as Models from '../../models/group/PositionGroup'

import * as DeleteGroupStore from '../../store/group/DeleteGroup'
import * as ModelsDeleteGroup from '../../models/group/DeleteGroup'
import { ApplicationState } from "../../store";


type groupProps = Models.GetPositionGroupState & typeof GroupStore.actionCreators;
type deletegroupProps = ModelsDeleteGroup.DeleteGroupState & typeof DeleteGroupStore.actionCreators

class PositionGroupTable extends React.Component<any, { response: any, isready: boolean, groupData: [], delete: boolean, company?: string, keyReset: boolean, roleid: string, empid: string, authority_group: string }, deletegroupProps & groupProps> {

    constructor(props: any) {
        super(props);
        this.state = {
            response: "",
            isready: true,
            groupData: [],
            delete: false,
            company: undefined,
            keyReset: false,
            roleid: "",
            empid: "",
            authority_group: ""
        }
    }

    componentDidMount() {
        //console.log(this.state.isready)
        //var company = this.props.company

        //if (company) {
        //    this.props.requestPositiongroup(this.state.isready, "POST", company, this.state.roleid, this.state.empid, this.state.authority_group);
        //    this.setState({
        //        isready: false,
        //        company: company
        //    })
        //}
    }

    ondelete = (idGroup: string) => {
        Swal.fire({
            title: 'ลบข้อมูล ?',
            text: 'คุณต้องการลบหรือไม่ ?',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'ใช่',
            cancelButtonText: 'ไม่'
        }).then((result) => {
            if (result.value) {
                //  this.props.submitUser(this.state)
                console.log(idGroup)
                this.props.requestdeleteGroup(true, "POST", idGroup, this.state.company)
                document.getElementsByClassName('Loader__background')[0].className = "Loader__background show";
                this.setState({
                    delete: true
                })
            }
        })
    }

    formatDMY = (dmy: string) => {

        var year = dmy.substring(0, 4);
        var month = dmy.substring(4, 6);
        var day = dmy.substring(6, 8);
        var Datevalue = "";
        if (dmy.length < 9) {
            Datevalue = year + '-' + month + '-' + day
        } else {
            var hour = dmy.substring(8, 10);
            var second = dmy.substring(10, 12);
            var millssecond = dmy.substring(12, 14);
            Datevalue = year + '-' + month + '-' + day + ' ' + hour + ':' + second + ':' + millssecond
        }

        return Datevalue;
    }

    componentDidUpdate(nextProps: any, nextState: any) {

        if (this.state.keyReset != this.props.keyReset) {
            if (this.props.keyReset) {
                this.props.callbackReset()
                this.setState({
                    roleid: this.props.roleid,
                    empid: this.props.empid,
                    authority_group: this.props.authority_group,
                    company: this.props.company
                })
                this.props.requestPositiongroup(true, "POST", this.props.company, this.props.roleid, this.props.empid, this.props.authority_group);
            }
        } else {
            var company = this.props.company
            if (company != this.state.company) {
                if (company) {
                    this.props.requestPositiongroup(true, "POST", this.props.company, "", "", "");
                }
                this.props.callbackResetSearch()
                this.setState({
                    company: company
                });
            }
        }

        if (this.props.isLoadDeleteGroup && this.props.statusDeleteGroup == 200) {
            document.getElementsByClassName('Loader__background')[0].className = "Loader__background hide";
            console.log('delete : ' + this.props.statusDelete)
            Swal.fire({
                title: 'สำเร็จ!',
                text: 'ลบข้อมูลสำเร็จ',
                icon: 'success',
                showCancelButton: false,
                confirmButtonColor: '#3085d6',
                confirmButtonText: 'ยืนยัน!',
                allowOutsideClick: false
            }).then((result) => {
                if (result.value) {
                    console.log('redirect!!')
                    this.props.requestdeleteGroup(true, "CLEAR", '', this.state.company)

                    this.props.requestPositiongroup(true, "POST", this.state.company, this.state.roleid, this.state.empid, this.state.authority_group);

                }
            })
        } else if (this.props.isLoadDeleteGroup && this.props.statusDeleteGroup != 200) {
            document.getElementsByClassName('Loader__background')[0].className = "Loader__background hide";
            var msg = JSON.parse(this.props.messageDeleteGroup)
            Swal.fire({
                title: 'ไม่สำเร็จ!',
                text: msg.message,
                icon: 'error',
                showCancelButton: false,
                confirmButtonColor: '#3085d6',
                confirmButtonText: 'ยืนยัน!',
                allowOutsideClick: false
            }).then((result) => {
                console.log('Delete Fail :')
                this.props.requestdeleteGroup(true, "CLEAR", '', this.state.company)
                this.props.requestPositiongroup(true, "POST", this.state.company, this.state.roleid, this.state.empid, this.state.authority_group);
            })
        }
    }

    loading() {
        document.getElementsByClassName('Loader__background')[0].className = "Loader__background show";
    }

    render() {
        return (
            <div style={{ overflowX: 'scroll' }}>
                <Table className="table-bordered" size="sm">
                    <thead>
                        <tr style={{ width: '100%' }} className="text-center table-active thead-light">
                            <th style={{ width: '10%' }}></th>
                            <th style={{ width: '20%' }}>Authority Group ID</th>
                            <th style={{ width: '20%' }}>Authority Group Name</th>
                            <th style={{ width: '10%' }}>Created By</th>
                            <th style={{ width: '15%' }}>Created On</th>
                            <th style={{ width: '10%' }}>Updated By</th>
                            <th style={{ width: '15%' }}>Updated On</th>
                        </tr>
                    </thead>
                    <tbody>
                        {!this.props.isLoadPositionGroup ? <tr><td colSpan={7} style={{ background: "rgba(209, 250, 221, 1)", color: "black", fontSize: '10pt', textAlign: "center" }}><Spinner animation="border" size="sm" />&nbsp;&nbsp;Loading...</td></tr> : <tr></tr>}
                        {this.props.isLoadPositionGroup && this.props.status == 204 && this.props.response['result_list'] == null ? <tr><td colSpan={7} style={{ background: "#b7b7a4", color: "black", fontSize: '10pt', textAlign: "center" }}>&nbsp;&nbsp;No Data...</td></tr> : <tr></tr>}
                        {this.props.status == 200 && this.props.response['result_list'] != null && this.props.response['result_list'].map((item: Models.PositionGroup, idx) =>
                            <tr key={idx}>
                                <td className="text-center">
                                    <div>
                                        <Link to={{ pathname: '/addauthoritygroup', state: { positioncode: item.positiongroupid } }}>
                                            <Button variant="outline-info" onClick={() => this.loading()} size="sm"><FaEdit /></Button>
                                        </Link>
                                        <Button className="mx-1" variant="outline-danger" size="sm" onClick={() => this.ondelete(item.positiongroupid)}><FaTimesCircle /></Button>
                                    </div>
                                </td>
                                <td>{item.positiongroupid}</td>
                                <td>{item.positiongroupname}</td>
                                <td>{item.created_by}</td>
                                <td>{this.formatDMY(String(item.created_on))}</td>
                                <td>{item.updated_by}</td>
                                <td>{this.formatDMY(String(item.updated_on))}</td>

                            </tr>
                        )}
                    </tbody>
                </Table>
            </div>
        );
    }
}
export default connect((state: ApplicationState) => ({ ...state.appState, ...state.positionGroup, ...state.deleteGroup }), ({ ...GroupStore.actionCreators, ...DeleteGroupStore.actionCreators }))(PositionGroupTable);
