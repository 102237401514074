import React from 'react';
import { twMerge } from 'tailwind-merge';

type Props<T extends string> = {
    tabs: readonly T[];
    icons?: readonly React.ReactNode[];
    trailingLabel?: string[];
    activeTab?: T;
    onChangeTab?: (tab: T) => void;
};

function Tabs<T extends string>(props: Props<T>) {
    const { tabs, icons, trailingLabel, activeTab, onChangeTab } = props;

    if (tabs.length === 0) {
        return null;
    }

    return (
        <div className="flex border-b border-b-zinc-300">
            {tabs.map((tab, index) => (
                <button
                    key={tab}
                    className={twMerge(
                        'inline-flex items-center gap-1 rounded-t-md border-x border-x-white px-4 py-2 text-neutral-800 transition-colors',
                        activeTab === tab &&
                            '-mb-[1px] border border-x-zinc-300 border-b-white border-t-zinc-300'
                    )}
                    onClick={() => onChangeTab && onChangeTab(tab)}
                >
                    {icons && icons[index]}
                    <span>{tab}</span>{' '}
                    {trailingLabel && trailingLabel[index] && (
                        <span>{trailingLabel[index]}</span>
                    )}
                </button>
            ))}
        </div>
    );
}

export default Tabs;
