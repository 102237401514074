import { Action, Reducer } from 'redux';
import { AppThunkAction } from '..';

import * as Models from '../../models/condition/DeleteConditionMaster'

interface BeforeDeleteConditionMaster {
    type: 'DELETE_CONDITIONMASTER'
}

interface DeleteConditionMaster {
    type: 'DELETECONDITIONMASTER'
    response: Models.ResDeleteConditionID
    statusDelete: number
}

interface DeleteFail {
    type: 'DELETEFAIL'
    statusDelete: number
}

interface ClearDelete {
    type: 'CLEARDELETE'
}

type KnownAction = DeleteConditionMaster | DeleteFail | ClearDelete | BeforeDeleteConditionMaster

export const actionCreators = {
    requestdeleteConditionMaster: (p: boolean, method: String, parameterid: string , company : string): AppThunkAction<KnownAction> => async (dispatch, getState) => {
        console.log('call actionCreators : requestdeleteConditionMaster')
        const appState = getState();
        if (appState && appState.deleteconditionMaster && p && method == 'POST') {
            await fetch(`/v2/Condition/delete?parameterid=` + parameterid + '&app_id=' + company, {
                method: 'DELETE',
                headers: {
                    'Authorization': 'Bearer ' + localStorage.getItem('WF_APPLICATION'),
                },
            })
                .then((response) => {
                    return response
                })
                .then((data) => {
                    //console.log(data)
                    if (!data.ok) {
                        data.json().then(err => {
                            //console.log(err.message_res)
                            console.log('dispatch : DELETEFAIL')
                            dispatch({ type: 'DELETEFAIL', statusDelete: 204 });
                        })
                    } else {
                        data.json().then(data => {
                            //console.log(data)
                            console.log('dispatch : DELETECONDITIONMASTER')
                            dispatch({ type: 'DELETECONDITIONMASTER', response: data as Models.ResDeleteConditionID, statusDelete: 200 });
                        })
                    }
                })

            dispatch({ type: 'DELETE_CONDITIONMASTER' });

        } else if (method == 'CLEAR') {
            console.log('dispatch : CLEARDELETE')
            dispatch({ type: 'CLEARDELETE' });
        }
    }
}

const unloadedState: Models.DeleteConditionIDState = { isLoad: false, statusDelete: 400 };

export const reducer: Reducer<Models.DeleteConditionIDState> = (state: Models.DeleteConditionIDState | undefined, incomingAction: Action): Models.DeleteConditionIDState => {
    if (state == undefined) {
        return unloadedState;
    }
    const action = incomingAction as KnownAction;
    switch (action.type) {
        case 'DELETE_CONDITIONMASTER':
            return {
                isLoad: false,

            }
        case 'DELETECONDITIONMASTER':
            return {
                isLoad: true,
                response: action.response,
                statusDelete: action.statusDelete

            }
        case 'DELETEFAIL':
            return {
                isLoad: true,
                statusDelete: action.statusDelete
            }

        case 'CLEARDELETE':
            return {
                isLoad: false,
                statusDelete: 400
            }
        default: return state;
    }
}