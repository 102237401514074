import React, { useEffect, useRef, useState } from 'react';
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import { useReadLocalStorage } from 'usehooks-ts';

import { Step1, Step2, Step3, Step4 } from './LineOnboarding';

type Props = {
    isOpen?: boolean;
    onToggle?: () => void;
};

function LineConnectOnboardModal(props: Props) {
    const { isOpen, onToggle } = props;

    const lineConnectStatus = useReadLocalStorage('line-connect-status');

    const [step, setStep] = useState(1);

    useEffect(() => {
        switch (lineConnectStatus) {
            default:
                return;
            case 'pending':
                setStep(3);
                break;
            case 'done':
                setStep(4);
                break;
        }
    }, [lineConnectStatus]);

    const next = () => {
        if (step === 4) {
            onToggle && onToggle();
            setTimeout(() => setStep(1), 150);
        }

        if (step < 4) {
            setStep(step + 1);
        }
    };

    const previous = () => {
        if (step > 1) {
            setStep(step - 1);
        }
    };

    const stepComponents = useRef(
        new Map<number, () => JSX.Element>([
            [1, Step1],
            [2, Step2],
            [3, Step3],
            [4, Step4],
        ])
    ).current;
    const SelectedStep = stepComponents.get(step) ?? (() => null);

    return (
        <Modal
            isOpen={isOpen}
            toggle={onToggle}
            size="sm"
            className="max-w-[420px]"
            contentClassName="min-h-[580px] rounded-[32px] overflow-hidden"
        >
            <ModalHeader
                toggle={onToggle}
                className="items-center [&_.modal-title]:flex-1"
            >
                <h2 className="flex-1 text-center">
                    เพิ่ม FocusOne
                    เป็นเพื่อนเพื่อรับการแจ้งเตือนที่สะดวกและรวดเร็วผ่าน Line
                    Chat
                </h2>
            </ModalHeader>
            <ModalBody className="flex flex-col items-center">
                <SelectedStep />
            </ModalBody>
            <ModalFooter className="p-0">
                <Button
                    size="lg"
                    className="m-0 flex-1 bg-[#00ac2a] rounded-0 border-0 py-2 px-4"
                    onClick={previous}
                >
                    ย้อนกลับ
                </Button>
                <Button
                    size="lg"
                    className="m-0 flex-1 bg-[#06C755] rounded-0 border-0 py-2 px-4"
                    onClick={next}
                >
                    {step < 4 ? 'ถัดไป' : 'ปิด'}
                </Button>
            </ModalFooter>
        </Modal>
    );
}

export default LineConnectOnboardModal;
