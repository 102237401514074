import {
    DatasourceReturnType,
    ResponseError
} from 'models/common/respone.model';
import {
    BufferLogRequest,
    BufferLogResponse,
    BufferLogResponseList,
    BufferStatResponse
} from '../schemas/LogBuffer.shema';
import {
    retrieveBufferStat,
    retrieveExportLogBuffer,
    retrieveLogBuffer
} from '../datasource/LogBuffer.datasource';
import { ResponseList } from 'models/Response';

export async function getLogBuffer(
    params: BufferLogRequest
): Promise<DatasourceReturnType<ResponseError, BufferLogResponse>> {
    try {
        const data = (await retrieveLogBuffer(params)).data;

        return [null!, data];
    } catch (error) {
        throw error;
    }
}

export async function getExportLogBuffer(
    params: BufferLogRequest
): Promise<DatasourceReturnType<ResponseError, BufferLogResponseList[]>> {
    try {
        const data = (await retrieveExportLogBuffer(params)).data;

        return [null!, data];
    } catch (error) {
        throw error;
    }
}

export async function getBufferStat(): Promise<
    DatasourceReturnType<ResponseError, ResponseList<BufferStatResponse>>
> {
    try {
        const data = (await retrieveBufferStat()).data;

        return [null!, data];
    } catch (error) {
        throw error;
    }
}
