import { Action, Reducer } from 'redux';
import { AppThunkAction } from '..';

import * as Models from '../../models/log/LogType'

interface LoadLogType {
    type: 'LOADLOGTYPE'
    response: Models.ResponseLogType
    status: Number,
    statusMessage: String
}
interface FailLoadLogType {
    type: 'LOADLOGTYPEFAIL'
    status: number,
    statusMessage: any
}

interface ClearLoadLogType {
    type: 'CLEARLOADLOGTYPE'
    status: number,
    statusMessage: any
}

type KnownAction = LoadLogType | FailLoadLogType | ClearLoadLogType

export const actionCreators = {
    requestLogTypeInbox: (p: boolean, method: String, sids: string[]): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        // console.log('call actionCreators : requestLogTypeInbox')
        if (appState && appState.workflowInbox && p && method == 'POST') {
            var sid = ""
            sids.forEach(element => {
                sid += "?sid=" + element
            });

            fetch('/v1/LogTypeReport/Get' + sid, {
                method: 'GET',
                headers: {
                    'Authorization': 'Bearer ' + localStorage.getItem('WF_APPLICATION'),
                },
            })
                .then(response => {
                    if (response.status === 200) {
                        return response.json();
                    } else {
                        return Promise.reject(response.json());
                    }
                })
                .then(data => dispatch({ type: 'LOADLOGTYPE', response: data as Models.ResponseLogType, status: 200, statusMessage: 'Ok' }))
                .catch(error => dispatch({ type: 'LOADLOGTYPEFAIL', status: 204, statusMessage: error.message }));
        } else if (method == "CLEAR") {
            console.log("dispatch : CLEARLOADLOGTYPE")
            dispatch({ type: 'CLEARLOADLOGTYPE', status: 400, statusMessage: "" });
        }
    },

    requestLogTypeReport: (p: boolean, method: String, wf_id: string, sids: string[]): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        console.log('call actionCreators : requestLogTypeInbox')
        if (appState && appState.workflowInbox && p && method == 'POST') {
            //console.log("post")
            //console.log(wf_id)
            //console.log(sid)
            var sid = ""
            sids.forEach(element => {
                sid += "&sid=" + element
            });
            fetch('/v1/LogTypeReport/Get?user_id=' + wf_id + sid, {
                method: 'GET',
                headers: {
                    'Authorization': 'Bearer ' + localStorage.getItem('WF_APPLICATION'),
                },
            })
                .then((response) => {
                    //console.log("requestLogTypeInbox")
                    console.log(response)
                    if (response.status != 200) {
                        throw new Error(response.json() as any)
                    } else {
                        return response.json() as Promise<Models.ResponseLogType>
                    }
                })
                .then(data => {
                    //console.log(data)
                    dispatch({ type: 'LOADLOGTYPE', response: data, status: 200, statusMessage: '' });
                })
                .catch(error => {
                    //console.log('errorrrr')
                    console.log(error)
                    dispatch({ type: 'LOADLOGTYPEFAIL', status: 204, statusMessage: error });
                });
        } else if (method == "CLEAR") {
            console.log("dispatch : CLEARLOADLOGTYPE")
            dispatch({ type: 'CLEARLOADLOGTYPE', status: 400, statusMessage: "" });
        }
    }
}

const unloadedState: Models.GetLogTypeState = { isLoadLogType: false };

export const reducer: Reducer<Models.GetLogTypeState> = (state: Models.GetLogTypeState | undefined, incomingAction: Action): Models.GetLogTypeState => {
    if (state == undefined) {
        return unloadedState;
    }
    const action = incomingAction as KnownAction;
    switch (action.type) {
        case 'LOADLOGTYPE':
            console.log("LOADLOGINBOX")
            return {
                isLoadLogType: true,
                responseLogType: action.response,
                statusLogType: action.status
            }

        case 'LOADLOGTYPEFAIL':
            return {
                isLoadLogType: true,
                messageLogType: action.statusMessage,
                statusLogType: action.status
            }
        case 'CLEARLOADLOGTYPE':
            return {
                isLoadLogType: false,
                messageLogType: "",
                statusLogType: 400
            }
        default: return state;
    }
}