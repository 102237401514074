import { Action, Reducer, createStore, combineReducers } from 'redux';
import { useSelector, useDispatch } from "react-redux";
import { AppThunkAction } from '..';
// models
import * as Models from '../../models/company/MasterCompany'

// #region interface GetMasterCompany
interface BeforeLoadMasterCompany {
    type: 'LOAD_MASTERCOMPANY'
}
interface LoadMasterCompany {
    type: 'LOADEDMASTERCOMPANY'
    response: Models.MasterCompanyRes
    status: number,
    statusMessage: String
}
interface FailMasterCompany {
    type: 'LOADMASTERCOMPANYFAIL'
    status: number,
    statusMessage: any
}
interface ClearLoadMasterCompany {
    type: 'CLEARLOADMASTERCOMPANY'
}
// #endregion

// #region interface AddMasterCompany
interface BeforeAddMasterCompany {
    type: 'ADD_MASTERCOMPANY'
}
interface AddMasterCompany {
    type: 'ADDDMASTERCOMPANY'
    response: string
    status: number,
    statusMessage: String
}
interface AddFailMasterCompany {
    type: 'ADDMASTERCOMPANYFAIL'
    status: number,
    statusMessage: any
}
interface ClearAddMasterCompany {
    type: 'CLEARADDMASTERCOMPANY'
}
// #endregion

type KnownAction = LoadMasterCompany | FailMasterCompany | BeforeLoadMasterCompany | ClearLoadMasterCompany | BeforeAddMasterCompany | AddMasterCompany | AddFailMasterCompany | ClearAddMasterCompany

export const actionCreators = {
    requestGetMasterCompany: (p: boolean, method: string, sid: string, status: string): AppThunkAction<KnownAction> => async (dispatch, getState) => {
        const appState = getState();
        console.log('call actionCreators : requestGetMasterCompany')
        if (appState && appState.positionEmployee && p && method == 'GET') {
            await fetch(`/v1/company_master?sid=` + sid + '&status=' + status, {
                method: 'GET',
                headers: {
                    'Authorization': 'Bearer ' + localStorage.getItem('WF_APPLICATION'),
                },
            })
                .then((response) => {
                    return response
                })
                .then((data) => {
                    //console.log(data)
                    if (!data.ok) {
                        data.json().then(err => {
                            //console.log(err.message_res)
                            console.log('dispatch : LOADMASTERCOMPANYFAIL')
                            dispatch({ type: 'LOADMASTERCOMPANYFAIL', status: 204, statusMessage: err.message });
                        })
                    } else {
                        data.json().then(data => {
                            //console.log(data)
                            console.log('dispatch : LOADEDMASTERCOMPANY')
                            dispatch({ type: 'LOADEDMASTERCOMPANY', response: data as Models.MasterCompanyRes, status: 200, statusMessage: '' });
                        })
                    }
                })
            dispatch({ type: 'LOAD_MASTERCOMPANY' });
        } else if (method == 'CLEAR') {
            dispatch({ type: 'CLEARLOADMASTERCOMPANY' });
        }
    },
}

const unloadedState: Models.GetMasterCompanyState = { isLoadGetMasterCompany: false };

const reducerGet: Reducer<Models.GetMasterCompanyState> = (state: Models.GetMasterCompanyState | undefined, incomingAction: Action): Models.GetMasterCompanyState => {
    if (state == undefined) {
        return unloadedState;
    }
    const action = incomingAction as KnownAction;
    switch (action.type) {
        case 'LOAD_MASTERCOMPANY':
            return {
                isLoadGetMasterCompany: false,
            }

        case 'LOADEDMASTERCOMPANY':
            return {
                isLoadGetMasterCompany: true,
                responseGetMasterCompany: action.response,
                statusGetMasterCompany: action.status
            }

        case 'LOADMASTERCOMPANYFAIL':
            return {
                isLoadGetMasterCompany: true,
                messageGetMasterCompany: action.statusMessage,
                statusGetMasterCompany: action.status
            }

        case 'CLEARLOADMASTERCOMPANY':
            return {
                isLoadGetMasterCompany: false,
                messageGetMasterCompany: '',
                statusGetMasterCompany: 400
            }
        default: return state;
    }
}

export const rootReducer = combineReducers({ GetMasterCompany: reducerGet });