import * as React from "react";
import { connect } from "react-redux";
import { Button, Row, Col, Table, Form, Tab, Spinner, Tabs, Card } from "react-bootstrap";
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { Container } from 'reactstrap';
import WorkflowDashboardInformation from './WorkflowDashboardInformation'
import Pagination from '@material-ui/lab/Pagination';
import RemarkWorkflowMaster from '../remark/RemarkWorkflowMaster';
import ReportInformation from './ReportInformation';
import Swal from 'sweetalert2'
import { ApplicationState } from "../../store";

import * as WorkflowDashboardStore from '../../store/workflow/WorkflowDashboard'
import * as ModelsWorkflowDashboard from '../../models/workflow/WorkflowInbox'
import TransactionInformation from './TransactionInformation';
import { numberWithCommas, formatDMY, formatDocDate, changeSidToName, changeWFTypeToApproveType } from '../../modules/Common'
import * as BatchDetailStore from '../../store/workflow/BatchDetail'
import * as BatchDetailModels from '../../models/workflow/BatchDetail'
type align_ = "left" | "center" | "right";

type Store = ModelsWorkflowDashboard.GetWorkflowInboxState & typeof WorkflowDashboardStore.actionCreators | BatchDetailModels.GetBatchDetailState & typeof BatchDetailStore.actionCreators

class WorkflowDashboardTable extends React.Component<any, {
    heightIframe: number,
    checkboxChecked: boolean,
    uniqueBatchId: any,
    valueBatchAll: any,
    showModal: boolean,
    checkRequest: boolean,
    handleClose: boolean,
    checkResponseTable: boolean,
    dataTable: Array<ModelsWorkflowDashboard.WorkflowInbox>,
    appId: string,
    wfId: string,
    docNo: string,
    wfInstanceno: string,
    userId: any,
    token: any,
    wf_status: string,
    doc_date_from: string,
    doc_date_to: string,
    page_size: number,
    pageBtn: any,
    pageTab: any,
    workflowValue: ModelsWorkflowDashboard.WorkflowValue,
    JSONReportList: ModelsWorkflowDashboard.JSONReportList,
    callBackReport: boolean,
    isShowBatchDetailModal: boolean,
    batchIdModal: string
}, Store> {
    constructor(props: any) {
        super(props);
        this.blindopenModal = this.blindopenModal.bind(this)

        this.state = {
            showModal: false,
            handleClose: false,
            checkResponseTable: true,
            dataTable: [],
            checkRequest: true,
            appId: "",
            wfId: "",
            docNo: "",
            wfInstanceno: "",
            userId: "",
            token: "",
            uniqueBatchId: [],
            valueBatchAll: [],
            checkboxChecked: false,
            wf_status: "",
            doc_date_from: "",
            doc_date_to: "",
            page_size: 100,
            pageBtn: [],
            pageTab: "",
            heightIframe: 0,
            workflowValue: {
                wfId: "",
                docNo: "",
                wfInstanceNo: "",
                userId: "",
                token: "",
                appId: "",
                wfType: "",
                fiscalYear: "",
                docType: ""
            },
            JSONReportList: {
                DataHeader: {
                    column: []
                },
                ListDataItem: []
            },
            callBackReport: false,
            isShowBatchDetailModal: false,
            batchIdModal: ""
        }

        this.BeforehandleChangePage = this.BeforehandleChangePage.bind(this)
        this.handlePageSize = this.handlePageSize.bind(this)
        this.jsonReportCallback = this.jsonReportCallback.bind(this)
    }

    componentDidMount() {
        const { docNo, wf_status, doc_date_from, doc_date_to, page_size, wfId } = this.state
        var userId = this.props.userId
        var token = this.props.token
        this.setState({
            userId: userId,
            token: token
        })
        console.log(userId)
        //this.requestDashboard(userId, token)
    }

    componentWillUnmount() {
        const { docNo, wf_status, doc_date_from, doc_date_to, page_size, wfId, userId, token } = this.state
        this.props.requestWorkflowDashboard(this.state.checkRequest, "CLEAR", userId, token, docNo, wf_status, doc_date_from, doc_date_to, 1, '', wfId, this.props.sids)
    }

    requestDashboard(userId: string, token: string) {
        const { docNo, wf_status, doc_date_from, doc_date_to, page_size, wfId } = this.state
        if (userId) {
            if (this.state.checkRequest) {
                this.props.requestWorkflowDashboard(this.state.checkRequest, "POST", userId, token, 1, this.state.page_size, '', this.props.sids, this.props.searchReq, this.props.wfStatus)
                this.setState({
                    checkRequest: false
                })
            }
        }
    }

    getApiFresh() {
        const { docNo, wf_status, doc_date_from, doc_date_to, page_size, wfId } = this.state
        var userId = this.props.userId
        var token = this.props.token
        this.setState({
            userId: userId,
            token: token
        })

        this.props.requestWorkflowDashboard("", "CLEAR", userId, token)
        //setTimeout(() => { this.props.requestWorkflowDashboard(this.state.checkRequest, "POST", userId, token, docNo, wf_status, doc_date_from, doc_date_to, 1, '', wfId, this.props.sids) }, 50);
        //setTimeout(() => { this.haddleChangePage(null, 1, null) }, 2000);
        this.props.callbackkey("stop")
    }

    handleClose = (appId: string, wfId: string, docNo: string, wfInstanceno: string, userId: string, wfType: string, fiscalYear: string, docType: string) => {
        if (this.state.showModal == false) {
            this.setState({
                showModal: true,
                appId: appId,
                wfId: wfId,
                docNo: docNo,
                wfInstanceno: wfInstanceno,
                userId: userId,
                workflowValue: {
                    wfId: wfId,
                    docNo: docNo,
                    wfInstanceNo: wfInstanceno,
                    userId: userId,
                    token: "",
                    appId: appId,
                    wfType: wfType,
                    fiscalYear: fiscalYear,
                    docType: docType
                },
            })
        } else {
            this.setState({
                showModal: false,
                wfId: "",
                docNo: "",
                wfInstanceno: "",
                JSONReportList: {
                    DataHeader: {
                        column: []
                    },
                    ListDataItem: []
                }
            })
        }
        setTimeout(() => {
            console.log(this.state.wfInstanceno)
        }, 1000);
    }

    blindopenModal = (value: any) => {
        this.setState({
            showModal: false,
            wfId: "",
            docNo: "",
            wfInstanceno: ""
        })
    }

    haddleChangePage = (key: any, pageNo: any, InputPageSize: any) => {

        if (InputPageSize != undefined || InputPageSize != null) {
            this.setState({
                page_size: InputPageSize
            })
        }
        const { docNo, wf_status, doc_date_from, doc_date_to, page_size, wfId, userId, token } = this.state
        if (key == null) {
            this.props.requestWorkflowDashboard(this.state.checkRequest, "CLEAR", userId, token, docNo, wf_status, doc_date_from, doc_date_to, 1, '', wfId, this.props.sids)
            var countingPage: any = 0
            var dataArr: any = []
            countingPage = Math.ceil(this.props.countTab / page_size)
            //console.log(page_size)
            for (let i = 0; i < countingPage; i++) {
                var data: any = {}
                data['page'] = i + 1
                data['pageTab'] = this.props.pageTab
                dataArr.push(data)
            }

            this.setState({
                pageBtn: dataArr,
                checkRequest: true,
                valueBatchAll: [],
                checkResponseTable: true
            })
            this.props.checkResponseTable(true)
            this.props.requestWorkflowDashboard(true, "POST", userId, token, pageNo, page_size, '', this.props.sids, this.props.searchReq, this.props.wfStatus)
        } else {
            this.props.requestWorkflowDashboard(this.state.checkRequest, "CLEAR", userId, token, docNo, wf_status, doc_date_from, doc_date_to, 1, '', wfId, this.props.sids)

            var countingPage: any = 0
            var dataArr: any = []
            countingPage = Math.ceil(this.props.countTab / page_size)

            for (let i = 0; i < countingPage; i++) {
                var data: any = {}
                data['page'] = i + 1
                data['pageTab'] = this.props.pageTab
                dataArr.push(data)
            }

            this.setState({
                pageBtn: dataArr,
                checkRequest: true,
                valueBatchAll: [],
                checkResponseTable: true
            })
            this.props.checkResponseTable(true)
            this.props.requestWorkflowDashboard(true, "POST", userId, token, pageNo, page_size, this.props.pageTab, this.props.sids, this.props.searchReq, this.props.wfStatus)
        }
    }

    componentDidUpdate(nextProps: any, nextState: any) {
        const { responseWorkflowInbox, statusWorkflowInbox, checkkey, countTab, pageTab, isLoadBatchDetail, statusBatchDetail, messageBatchDetail, ResponseTable } = this.props

        if (checkkey != "" && checkkey != undefined) {
            this.getApiFresh()
        }

        if (statusWorkflowInbox == 200 && ResponseTable && responseWorkflowInbox != null) {
            console.log(responseWorkflowInbox['result_list'])
            this.props.checkResponseTable(false)
            this.setState({
                dataTable: responseWorkflowInbox['result_list'],
                checkResponseTable: false
            })
        }

        if (nextProps.countTab != countTab) {
            this.setState({
                pageTab: pageTab
            })
            if (pageTab != null) {
                setTimeout(() => { this.haddleChangePage(pageTab, 1, null) }, 50);
                //this.haddleChangePage(pageTab, 1, null)
            } else {
                setTimeout(() => { this.haddleChangePage(null, 1, null) }, 50);
                //this.haddleChangePage(null, 1, null)
            }
        }

        if (isLoadBatchDetail && statusBatchDetail == 200 && !this.state.isShowBatchDetailModal) {
            document.getElementsByClassName('Loader__background')[0].className = "Loader__background hide";
            this.setState({
                isShowBatchDetailModal: true
            })
        } else if (isLoadBatchDetail && statusBatchDetail == 204 && !this.state.isShowBatchDetailModal) {
            this.props.requestGetBatchDetail(true, 'CLEAR', "", "", "", "", "")
            var msg = JSON.parse(messageBatchDetail)
            document.getElementsByClassName('Loader__background')[0].className = "Loader__background hide";
            Swal.fire({
                title: 'Error',
                text: msg.message,
                icon: 'error',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'ตกลง',
            }).then((result) => {
                if (result.value) {
                }
            })
        }
    }

    /*componentWillUnmount() {
        this.props.requestWorkflowDashboard(this.state.checkRequest, "CLEAR", this.state.userId)
    }
*/
    //numberWithCommas(n) {
    //    var parts = n.toString().split(".");

    //    return parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",") + (parts[1] ? "." + parts[1] : ".00");
    //}
    handleChangeAll(evt) {
        var group = "group"
        var node = "node"
        if (evt.target.checked == true) {
            Array.from(document.querySelectorAll("input[name=" + group + "], input[name=" + node + "]"))
                .forEach((checkbox) => {
                    //document.getElementById(checkbox.id).checked = value;
                    var cb = document.getElementById(checkbox.id) as HTMLInputElement;
                    if (cb) {
                        cb.checked = true;
                    }
                });
        } else {
            Array.from(document.querySelectorAll("input[name=" + group + "], input[name=" + node + "]"))
                .forEach((checkbox) => {
                    //document.getElementById(checkbox.id).checked = value;
                    var cb = document.getElementById(checkbox.id) as HTMLInputElement;
                    if (cb) {
                        cb.checked = false;
                    }
                });
        }
    }

    handleChangeGroup(evt, docNo, wfId, userId) {
        var name = "node"
        if (evt.target.checked == true) {
            Array.from(document.querySelectorAll("input[name=" + name + "][id^='" + evt.target.id + "-']"))
                .forEach((checkbox) => {
                    //document.getElementById(checkbox.id).checked = value;
                    var cb = document.getElementById(checkbox.id) as HTMLInputElement;
                    if (cb) {
                        cb.checked = true;
                    }
                });
        } else {
            Array.from(document.querySelectorAll("input[name=" + name + "][id^='" + evt.target.id + "-']"))
                .forEach((checkbox) => {
                    //document.getElementById(checkbox.id).checked = value;
                    var cb = document.getElementById(checkbox.id) as HTMLInputElement;
                    if (cb) {
                        cb.checked = false;
                    }
                });
        }
    }

    formatData(value) {
        var valueBatchreverse: any = []
        var valuecheckdate: any = []
        var valuecheckdateUse: any = []
        var uniqueBatchIdUse: any = [];

        value['result_list'].map(batch => {
            if (uniqueBatchIdUse.indexOf(batch.batchid) === -1) {
                uniqueBatchIdUse.push(batch.batchid)
            }
        });

        //valuecheckdate = uniqueBatchIdUse.sort((a, b) => a - b);
        valuecheckdate = uniqueBatchIdUse.sort();
        valuecheckdateUse = valuecheckdate.reverse()

        this.state.valueBatchAll.splice(this.state.valueBatchAll, uniqueBatchIdUse.length);

        for (let f = 0; f < valuecheckdateUse.length; f++) {
            if (valuecheckdateUse[f] != "" && String(parseInt(valuecheckdateUse[f])) != "NaN") {
                valueBatchreverse.push(valuecheckdateUse[f])
            }
        }
        for (let g = 0; g < valuecheckdateUse.length; g++) {
            if (String(parseInt(valuecheckdateUse[g])) == "NaN" && valuecheckdateUse[g] != "") {
                //console.log(valuecheckdateUse[g])
                valueBatchreverse.push(valuecheckdateUse[g])
            }
        }

        for (let f = 0; f < valuecheckdateUse.length; f++) {
            if (valuecheckdateUse[f] == "") {
                valueBatchreverse.push(valuecheckdateUse[f])
            }
        }

        for (let i = 0; i < uniqueBatchIdUse.length; i++) {
            var dataresult: any = []
            var money: number = 0.00
            for (let k = 0; k < value['result_list'].length; k++) {
                if (valueBatchreverse[i] == value['result_list'][k].batchid) {
                    dataresult.push(value['result_list'][k])
                    money += parseFloat(value['result_list'][k].docAmount)
                }
            }

            let data = {}

            if (valueBatchreverse[i] == "" || valueBatchreverse[i] == undefined || valueBatchreverse[i] == null) {
                data['batchid'] = "NONE"
                data['count_money_batchid'] = ""
                data['app_id'] = dataresult[0].app_id
                data['wf_type'] = dataresult[0].wfType
            } else {
                data['batchid'] = valueBatchreverse[i]
                data['count_money_batchid'] = money.toFixed(2)
                data['app_id'] = dataresult[0].app_id
                data['wf_type'] = dataresult[0].wfType
            }
            data['count_list_batchid'] = dataresult.length
            data['currency'] = dataresult[0].docCurrency == null ? "THB" : dataresult[0].docCurrency
            data['result_list'] = dataresult

            this.state.valueBatchAll.push(data)
        }
    }

    BeforehandleChangePage(event, value) {
        return this.haddleChangePage(this.props.pageTab, value, null)
    }

    handlePageSize = (e) => {
        var parse = parseInt(e.target.value)
        this.setState({
            page_size: parse
        })

        return this.haddleChangePage(this.props.pageTab, 1, parse)
    }

    jsonReportCallback = (value: string) => {
        console.log("jsonReportCallback")
        console.log(value)
        this.setState({
            callBackReport: true
        })
        try {
            if (value != null) {
                this.setState({
                    JSONReportList: JSON.parse(value)
                })
            }
        } catch (e) {
            console.log(e)
        }
    }

    handleOpenBatchDetail = (wf_type: string, batch_id: string, app_id: string) => {
        this.props.requestGetBatchDetail(true, 'GET', wf_type, batch_id, app_id, this.state.userId, 'report')
        document.getElementsByClassName('Loader__background')[0].className = "Loader__background show";
        this.setState({
            batchIdModal: batch_id
        })
    }

    handleCloseBatchDetail = () => {
        if (this.state.isShowBatchDetailModal) {
            this.props.requestGetBatchDetail(true, 'CLEAR', "", "", "", "", "")
            this.setState({
                isShowBatchDetailModal: false
            })
        } else {
            this.setState({
                isShowBatchDetailModal: true
            })
        }
    }

    render() {
        const { responseWorkflowInbox, statusWorkflowInbox, key, isLoadBatchDetail, statusBatchDetail, responseBatchDetail, messageBatchDetail, checkWorkflowType } = this.props
        if (this.props.responseWorkflowInbox != undefined && this.props.isLoadWorkflowInbox == true) {
            this.formatData(responseWorkflowInbox)
        }

        const isOnlineForm = this.state.workflowValue.appId === "e5mPFn1JEi76UvyyPkOf";

        console.log(checkWorkflowType)
        return (
            <Container>
                <Row>
                    <Col sm={6}>
                        <div className='d-flex justify-content-end justify-content-sm-start my-2 my-lg-0'>
                            <Form inline>
                                <Form.Label className="my-1 mr-2" htmlFor="inlineFormCustomSelectPref">
                                    Show
                                </Form.Label>
                                <Form.Control
                                    as="select"
                                    className="my-1 mr-sm-2"
                                    id="inlineFormCustomSelectPref"
                                    custom
                                    size="sm"
                                    value={this.state.page_size}
                                    onChange={this.handlePageSize}
                                >
                                    <option value="10">10</option>
                                    <option value="25">25</option>
                                    <option value="50">50</option>
                                    <option value="100">100</option>
                                </Form.Control>
                                <Form.Label className="my-1 mr-2" htmlFor="inlineFormCustomSelectPref">
                                    entries
                                </Form.Label>
                            </Form>
                        </div>
                    </Col>
                    <Col sm={6}>
                        <div className='d-flex justify-content-end my-2 my-lg-0'>
                            <Pagination count={this.state.pageBtn.length != 0 && this.state.pageBtn.length} shape="rounded" onChange={this.BeforehandleChangePage} defaultPage={1} showFirstButton showLastButton />
                        </div>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <div style={{ overflowX: 'scroll' }}>
                            <Table className="table-bordered align-middle table-responsive-sm" size="sm">
                                <thead >
                                    <tr className='text-center table-active thead-light' style={{ width: '100%' }}>
                                        <th className='th-center' style={{ width: '50px', textAlignLast: 'left' }}>
                                            <input type="checkbox" name="all"
                                                defaultChecked={this.state.checkboxChecked}
                                                onChange={this.handleChangeAll}
                                            />
                                        </th>
                                        <th style={{ width: '300px' }}>รายละเอียดการอนุมัติ</th>
                                        <th style={{ width: '200px', maxWidth: '150px', overflowWrap: 'break-word' }} >เลขที่เอกสาร</th>
                                        <th style={{ width: '100px', textAlignLast: 'center' }}>ปีเอกสาร</th>
                                        <th style={{ width: '200px', textAlignLast: 'center' }}>วันที่เอกสาร</th>
                                        <th style={{ width: '150px', textAlignLast: 'center', textAlign: 'center' }}>Approve<br />Type</th>
                                        <th style={{ width: '150px', textAlignLast: 'center' }}>บริษัท</th>
                                        <th style={{ width: '150px', textAlignLast: 'center' }}>สำหรับ</th>
                                        <th style={{ width: '100px', textAlignLast: 'right' }}>มูลค่า</th>
                                        <th style={{ width: '100px', textAlignLast: 'center' }}>สกุลเงิน</th>
                                        <th style={{ width: '100px', textAlignLast: 'center' }}>ผู้สร้าง</th>
                                        <th style={{ width: '100px', textAlignLast: 'center' }}>ลำดับการอนุมัติปัจจุบัน</th>
                                        <th style={{ width: '170px' }}>วันที่สร้าง</th>
                                        <th style={{ width: '150px', textAlignLast: 'center' }}>ผู้ปรับปรุงล่าสุด</th>
                                        <th style={{ width: '170px', }}>วันที่ปรับปรุงล่าสุด</th>
                                        <th style={{ width: '100px' }}>สถานะ</th>
                                    </tr>
                                </thead>

                                {!this.props.isLoadWorkflowInbox && checkWorkflowType > 0 ?
                                    <tbody>
                                        <tr>
                                            <td colSpan={16} style={{ background: "rgba(209, 250, 221, 1)", color: "black", fontSize: '10pt', textAlign: "center" }}>
                                                <Spinner animation="border" size="sm" />
                                                &nbsp;&nbsp;Loading...
                                            </td>
                                        </tr>
                                    </tbody>
                                    :
                                    <tbody>
                                        <tr>
                                        </tr>
                                    </tbody>}
                                {checkWorkflowType === 0 || checkWorkflowType > 0 && this.props.isLoadWorkflowInbox && this.props.responseWorkflowInbox == undefined ?
                                    <tbody>
                                        <tr>
                                            <td colSpan={16} style={{ background: "#b7b7a4", color: "black", fontSize: '10pt', textAlign: "center" }}>
                                                &nbsp;&nbsp;No Data...
                                            </td>
                                        </tr>
                                    </tbody>
                                    :
                                    <tbody>
                                        <tr>
                                        </tr>
                                    </tbody>}
                                {this.props.statusWorkflowInbox == 200 && this.props.isLoadWorkflowInbox && this.state.valueBatchAll != undefined && this.state.valueBatchAll.map((data, index) => {
                                    return (
                                        <tbody key={index}>
                                            <tr key={index}  >
                                                <td className="text-center" style={{ background: '#FABD02', fontWeight: "bold", cursor: 'pointer' }} >
                                                    <input id={index} type="checkbox" name="group"
                                                        defaultChecked={this.state.checkboxChecked}
                                                        onChange={(evt) => this.handleChangeGroup(evt, data.docNo, data.wfId, this.state.userId)}
                                                    />
                                                </td>
                                                <td onClick={() => this.handleOpenBatchDetail(data.wf_type, data.batchid, data.app_id)} style={{ background: '#FABD02', fontWeight: "bold", cursor: 'pointer' }} colSpan={7}>({data.count_list_batchid}) Batch ID: {data.batchid}</td>
                                                <td onClick={() => this.handleOpenBatchDetail(data.wf_type, data.batchid, data.app_id)} style={{ background: '#FABD02', fontWeight: "bold", color: "blue", textAlignLast: 'right', cursor: 'pointer' }} >{numberWithCommas(data.count_money_batchid)}</td>
                                                <td onClick={() => this.handleOpenBatchDetail(data.wf_type, data.batchid, data.app_id)} style={{ background: '#FABD02', textAlignLast: 'center', fontWeight: "bold", cursor: 'pointer' }} >{data.currency}</td>
                                                <td onClick={() => this.handleOpenBatchDetail(data.wf_type, data.batchid, data.app_id)} style={{ background: '#FABD02', cursor: 'pointer' }} colSpan={7} ></td>
                                            </tr>
                                            {this.state.valueBatchAll != undefined && data.result_list.map((data_r, index_r) => {
                                                var color = "";
                                                if (data_r.wfstatus == "CANCEL") {
                                                    color = "#F5D8F2"
                                                } else if (data_r.wfstatus == "APPROVED") {
                                                    color = "#C5EABD"
                                                }
                                                return (
                                                    <tr key={index + "-" + index_r} style={color ? { background: color } : {}}  >
                                                        <td className="text-center" style={{ width: '5%', textAlignLast: 'right' }}>
                                                            <input id={index + "-" + index_r} type="checkbox" name="node" data-wfinstanceno={data_r.wfInstanceno} data-docno={data_r.docNo} data-wftype={data_r.wfType} data-appid={data_r.app_id}
                                                                defaultChecked={this.state.checkboxChecked} data-batchid={data.batchid} data-filelist={JSON.stringify(data_r)}
                                                            />
                                                        </td>
                                                        <td style={{ cursor: 'pointer' }} onClick={() => this.handleClose(data_r.app_id, data_r.wfId, data_r.docNo, data_r.wfInstanceno, this.state.userId, data_r.wfType, data_r.docYear, data_r.docType)}>{data_r.subject}</td>
                                                        <td style={{ width: '150px', maxWidth: '150px', overflowWrap: 'break-word', cursor: 'pointer' }} onClick={() => this.handleClose(data_r.app_id, data_r.wfId, data_r.docNo, data_r.wfInstanceno, this.state.userId, data_r.wfType, data_r.docYear, data_r.docType)}>{data_r.docNo}</td>
                                                        <td style={{ textAlignLast: 'center', cursor: 'pointer' }} onClick={() => this.handleClose(data_r.app_id, data_r.wfId, data_r.docNo, data_r.wfInstanceno, this.state.userId, data_r.wfType, data_r.docYear, data_r.docType)}>{data_r.docYear}</td>
                                                        <td style={{ textAlignLast: 'center', cursor: 'pointer' }} onClick={() => this.handleClose(data_r.app_id, data_r.wfId, data_r.docNo, data_r.wfInstanceno, this.state.userId, data_r.wfType, data_r.docYear, data_r.docType)}>{formatDocDate(String(data_r.docDate))}</td>
                                                        <td style={{ textAlignLast: 'center', cursor: 'pointer' }} onClick={() => this.handleClose(data_r.app_id, data_r.wfId, data_r.docNo, data_r.wfInstanceno, this.state.userId, data_r.wfType, data_r.docYear, data_r.docType)}>{changeWFTypeToApproveType(data_r.wfType)}</td>
                                                        <td style={{ cursor: 'pointer' }} onClick={() => this.handleClose(data_r.app_id, data_r.wfId, data_r.docNo, data_r.wfInstanceno, this.state.userId, data_r.wfType, data_r.docYear, data_r.docType)}>{changeSidToName(data_r.app_id, this.props.sidArr)}</td>
                                                        <td style={{ cursor: 'pointer' }} onClick={() => this.handleClose(data_r.app_id, data_r.wfId, data_r.docNo, data_r.wfInstanceno, this.state.userId, data_r.wfType, data_r.docYear, data_r.docType)}>{data_r.bpName}</td>
                                                        <td style={{ textAlignLast: 'right', cursor: 'pointer' }} onClick={() => this.handleClose(data_r.app_id, data_r.wfId, data_r.docNo, data_r.wfInstanceno, this.state.userId, data_r.wfType, data_r.docYear, data_r.docType)}>{numberWithCommas(data_r.docAmount)}</td>
                                                        <td style={{ textAlignLast: 'center', cursor: 'pointer' }} onClick={() => this.handleClose(data_r.app_id, data_r.wfId, data_r.docNo, data_r.wfInstanceno, this.state.userId, data_r.wfType, data_r.docYear, data_r.docType)}>{data_r.docCurrency == "" || data_r.docCurrency == null ? "THB" : data_r.docCurrency}</td>
                                                        <td style={{ textAlignLast: 'center', cursor: 'pointer' }} onClick={() => this.handleClose(data_r.app_id, data_r.wfId, data_r.docNo, data_r.wfInstanceno, this.state.userId, data_r.wfType, data_r.docYear, data_r.docType)}>{data_r.requestorName}</td>
                                                        <td style={{ textAlignLast: 'center', cursor: 'pointer' }} onClick={() => this.handleClose(data_r.app_id, data_r.wfId, data_r.docNo, data_r.wfInstanceno, this.state.userId, data_r.wfType, data_r.docYear, data_r.docType)}>{data_r.curtaskid + '/' + data_r.maxtaskid}</td>
                                                        <td onClick={() => this.handleClose(data_r.app_id, data_r.wfId, data_r.docNo, data_r.wfInstanceno, this.state.userId, data_r.wfType, data_r.docYear, data_r.docType)}>{formatDMY(String(data_r.createdOnFormatDateTime))}</td>
                                                        <td style={{ textAlignLast: 'center', cursor: 'pointer' }} onClick={() => this.handleClose(data_r.app_id, data_r.wfId, data_r.docNo, data_r.wfInstanceno, this.state.userId, data_r.wfType, data_r.docYear, data_r.docType)}>{data_r.updated_byname}</td>
                                                        <td style={{ cursor: 'pointer' }} onClick={() => this.handleClose(data_r.app_id, data_r.wfId, data_r.docNo, data_r.wfInstanceno, this.state.userId, data_r.wfType, data_r.docYear, data_r.docType)}>{formatDMY(String(data_r.updatedOnFormatDateTime))}</td>
                                                        <td style={{ textAlignLast: 'center', cursor: 'pointer' }} onClick={() => this.handleClose(data_r.app_id, data_r.wfId, data_r.docNo, data_r.wfInstanceno, this.state.userId, data_r.wfType, data_r.docYear, data_r.docType)}>{data_r.wfstatus}</td>
                                                    </tr>
                                                )
                                            })}
                                        </tbody>
                                    )
                                })}
                            </Table>
                        </div>
                    </Col>
                </Row>
                <Modal className="modal-dialog-scrollable" isOpen={this.state.showModal} toggle={() => this.handleClose(this.state.appId, this.state.wfId, "", "", "", "", "", "")} size="lg" backdrop="static" keyboard={false} style={{ maxWidth: '1920px', width: '98%', height: '90%' }}>
                    <ModalHeader toggle={() => this.handleClose(this.state.appId, this.state.wfId, "", "", "", "", "", "")}>Workflow Information</ModalHeader>
                    <ModalBody style={{ height: "100%" }} >

                        <Tabs defaultActiveKey="WorkflowDashboardInformation" id="tab">
                            <Tab eventKey="WorkflowDashboardInformation" title="Workflow Information" >
                                <WorkflowDashboardInformation
                                    presentMode={isOnlineForm ? 'information' : 'classic'}
                                    appId={this.state.appId}
                                    wfId={this.state.wfId}
                                    docNo={this.state.docNo}
                                    wfInstanceNo={this.state.wfInstanceno}
                                    userId={this.state.userId}
                                    token={this.state.token}
                                    showmodal={this.state.showModal}
                                    blindopenModal={this.blindopenModal.bind(this)}
                                    jsonReportCallback={this.jsonReportCallback.bind(this)}
                                    canReject={true}
                                />
                            </Tab>
                            {
                                isOnlineForm
                                && (
                                    <Tab eventKey="OnlineForm" title="Online Form" tabClassName="[&.active]:bg-[#4373c5]">
                                        <WorkflowDashboardInformation
                                            presentMode="document"
                                            appId={this.state.appId}
                                            wfId={this.state.wfId}
                                            docNo={this.state.docNo}
                                            wfInstanceNo={this.state.wfInstanceno}
                                            userId={this.state.userId}
                                            token={this.state.token}
                                            showmodal={this.state.showModal}
                                            blindopenModal={this.blindopenModal.bind(this)}
                                            jsonReportCallback={this.jsonReportCallback.bind(this)}
                                            canReject={true}
                                        />
                                    </Tab>
                                )
                            }
                            {!isOnlineForm && this.state.callBackReport && <Tab eventKey="ReportInformation" title="Report information">
                                <ReportInformation JSONReportList={this.state.JSONReportList} />
                            </Tab>}
                            {!isOnlineForm && (this.state.workflowValue.wfType == "PR" || this.state.workflowValue.wfType == "PO") && <Tab eventKey="Transaction" title="Transaction Information">
                                <TransactionInformation workflowValue={this.state.workflowValue} />
                            </Tab>}
                            {
                                !isOnlineForm
                                && (
                                    <Tab eventKey="Remark" title="Work Chat">
                                        <RemarkWorkflowMaster workflowValue={this.state.workflowValue} />
                                    </Tab>
                                )
                            }
                        </Tabs>

                    </ModalBody>
                    <ModalFooter>
                        {/* <Button color="primary" onClick={() => this.handleClose(this.state.wfId, "", "", "")}>Save</Button> */}
                        <Button color="secondary" onClick={() => this.handleClose(this.state.appId, this.state.wfId, "", "", "", "", "", "")}>Cancel</Button>
                    </ModalFooter>
                </Modal>

                <Modal className="modal-dialog" isOpen={this.state.isShowBatchDetailModal} toggle={() => this.handleCloseBatchDetail()} size="lg" backdrop="static" keyboard={false} style={{ maxWidth: '1920px', width: '98%', height: '90%' }}>
                    <ModalHeader toggle={() => this.handleCloseBatchDetail()}>Batch Detail : {this.state.batchIdModal}</ModalHeader>
                    <ModalBody>
                        <Row>
                            <Col>
                                {isLoadBatchDetail && statusBatchDetail == 200 && responseBatchDetail.listStandardReport != undefined && responseBatchDetail.listStandardReport.map((ListItem: BatchDetailModels.listStandardReport, idList) => {
                                    return (
                                        <Row className="mt-3" key={idList}>
                                            <Col>
                                                <div className="master-data-content">
                                                    <div className='trapezoid-head' style={{ width: '500px' }}>
                                                        <span>
                                                            {ListItem.DataHeader.row.length == 0 && ListItem.DataHeader.column.length != 0 && (ListItem.DataHeader.column[0] != undefined ? ListItem.DataHeader.column[0].label + " : " + ListItem.DataHeader.column[0].value : '')}
                                                            {ListItem.DataHeader.row.length != 0 && ListItem.DataHeader.column.length == 0 && (ListItem.DataHeader.row[0].column[0] != undefined ? ListItem.DataHeader.row[0].column[0].label + " : " + ListItem.DataHeader.row[0].column[0].value : '')}
                                                            {/*{ListItem.DataHeader.column[0] != undefined ? ListItem.DataHeader.column[0].label + " : " + ListItem.DataHeader.column[0].value : ''}*/}
                                                        </span>
                                                    </div>
                                                </div>
                                                <div className="mat-box">

                                                    {ListItem.DataHeader.row.length == 0 ?

                                                        <Row>
                                                            <Col>
                                                                <Card>
                                                                    <Card.Body>
                                                                        {ListItem.DataHeader.column.length == 0 &&
                                                                            <Row>
                                                                                <Col>
                                                                                    <div className="text-center">
                                                                                        <label>ไม่พบข้อมูล..</label>
                                                                                    </div>
                                                                                </Col>
                                                                            </Row>
                                                                        }
                                                                        {ListItem.DataHeader.column.length != 0 &&
                                                                            <Row>
                                                                                <Col lg={12}>
                                                                                    <table className="text-left" style={{ width: '100%' }}>
                                                                                        <tbody>
                                                                                            {ListItem.DataHeader.column.map((data: ModelsWorkflowDashboard.ColoumList, idx) => {
                                                                                                return (
                                                                                                    <tr key={idx} style={{ width: '100%' }}>
                                                                                                        <td style={{ width: '20%' }}><label><b>{data.label} :</b></label></td>
                                                                                                        <td style={{ width: '80%' }}>{data.value}</td>
                                                                                                    </tr>
                                                                                                )
                                                                                            })}
                                                                                        </tbody>
                                                                                    </table>
                                                                                </Col>
                                                                            </Row>
                                                                        }
                                                                        {/*}*/}
                                                                    </Card.Body>
                                                                </Card>
                                                            </Col>
                                                        </Row>

                                                        :
                                                        <Row>
                                                            <Col>
                                                                <Card>
                                                                    <Card.Body>
                                                                        {ListItem.DataHeader.row.length == 0 &&
                                                                            <Row>
                                                                                <Col>
                                                                                    <div className="text-center">
                                                                                        <label>ไม่พบข้อมูล..</label>
                                                                                    </div>
                                                                                </Col>
                                                                            </Row>
                                                                        }
                                                                        {ListItem.DataHeader.row.length != 0 &&
                                                                            <Row>
                                                                                <Col lg={12}>
                                                                                    {ListItem.DataHeader.row.map((data: ModelsWorkflowDashboard.DataHeader, idx) => {
                                                                                        return (
                                                                                            <Row key={idx} className="mt-3">
                                                                                                {data.column.map((item: ModelsWorkflowDashboard.ColoumList, ids) => {
                                                                                                    return (
                                                                                                        <Col key={ids} lg="3" className="table-hover">
                                                                                                            <Row>
                                                                                                                <Col lg="5" className="font-weight-bold bg-light text-dark">{item.label} :</Col>
                                                                                                                <Col lg="7">{item.value}</Col>
                                                                                                            </Row>
                                                                                                        </Col>
                                                                                                    )
                                                                                                })}
                                                                                            </Row>
                                                                                        )
                                                                                    })}
                                                                                </Col>
                                                                            </Row>
                                                                        }
                                                                        {/*}*/}
                                                                    </Card.Body>
                                                                </Card>
                                                            </Col>
                                                        </Row>
                                                    }

                                                    {/*{ListItem.DataHeader.column.length != 0 &&*/}
                                                    {/*    <Row>*/}
                                                    {/*        <Col lg={12}>*/}
                                                    {/*            <table className="text-left" style={{ width: '100%' }}>*/}
                                                    {/*                <tbody>*/}
                                                    {/*                    {ListItem.DataHeader.column.length != 0 && ListItem.DataHeader.column.map((datacolumn: BatchDetailModels.column, idx) => {*/}
                                                    {/*                        return (*/}
                                                    {/*                            <tr key={idx} style={{ width: '100%' }}>*/}
                                                    {/*                                <td style={{ width: '20%' }}><label><b>{datacolumn.label} :</b></label></td>*/}
                                                    {/*                                <td style={{ width: '80%' }}>{datacolumn.value}</td>*/}
                                                    {/*                            </tr>*/}
                                                    {/*                        )*/}
                                                    {/*                    })}*/}
                                                    {/*                </tbody>*/}
                                                    {/*            </table>*/}
                                                    {/*        </Col>*/}
                                                    {/*    </Row>*/}
                                                    {/*}*/}


                                                    {ListItem.ListDataItem.length != 0 && ListItem.ListDataItem.map((data: BatchDetailModels.ListDataItem, idx) => {
                                                        return (
                                                            <Row key={idx}>
                                                                <Col lg={12}>
                                                                    <div style={{ overflowX: "scroll" }}>
                                                                        <table className="table table-bordered" style={{ width: "max-content" }}>
                                                                            <thead>
                                                                                <tr className="table-active">
                                                                                    {data.tableheader.length != 0 && data.tableheader.map((DataHeader: BatchDetailModels.tableheader, idHeader) => {
                                                                                        return (
                                                                                            <th key={idHeader} style={{ width: DataHeader.width != undefined ? DataHeader.width + "px" : "auto", textAlign: DataHeader.align as align_ }} align={DataHeader.align as align_}>{DataHeader.label}</th>
                                                                                        )
                                                                                    })}
                                                                                </tr>
                                                                            </thead>
                                                                            {data.tabledata.length == 0 && <tr><td colSpan={data.tableheader.length}><div className="text-center">No data..</div></td></tr>}
                                                                            {data.tabledata.length != 0 &&
                                                                                <tbody>
                                                                                    {data.tabledata.length != 0 && data.tabledata.map((dataTableData: BatchDetailModels.tabledata, idxTable) => {
                                                                                        return (
                                                                                            <tr key={idxTable}>
                                                                                                {dataTableData.row.length == 0 && <td colSpan={data.tabledata.length}><div className="text-center">No data..</div></td>}
                                                                                                {dataTableData.row.length != 0 && dataTableData.row.map((item: string, idx2) => {
                                                                                                    return (
                                                                                                        <td key={idx2} style={{ textAlign: data.tableheader[idx2].align as align_ }}>{item}</td>
                                                                                                    )
                                                                                                })}
                                                                                            </tr>
                                                                                        )
                                                                                    })}
                                                                                </tbody>
                                                                            }
                                                                        </table>
                                                                    </div>
                                                                </Col>
                                                            </Row>
                                                        )
                                                    })}
                                                </div>
                                            </Col>
                                        </Row>
                                    )
                                })
                                }
                            </Col>
                        </Row>
                    </ModalBody>
                    <ModalFooter>
                        {/* <Button color="primary" onClick={() => this.handleClose(this.state.wfId, "", "", "")}>Save</Button> */}
                        <Button color="secondary" onClick={() => this.handleCloseBatchDetail()}>Close</Button>
                    </ModalFooter>
                </Modal>
            </Container>
        );
    }
}
export default connect((state: ApplicationState) => ({ ...state.workflowDashboard, ...state.BatchDetail }), ({ ...WorkflowDashboardStore.actionCreators, ...BatchDetailStore.actionCreators }))(WorkflowDashboardTable)