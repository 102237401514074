import axios from 'axios';
import {
    AdminMasterEmployeeResponse,
    CreateAdminMasterEmployeeParams,
    ActionAdminMasterEmployeeResponse,
    MasterEmployeeResponse,
    RoleMasterEmployeeResponse,
    UpdateRoleMasterEmployeeRequest,
    DeleteRoleMasterEmployeeRequest,
    WorkflowAdminReqeust
} from '../schemas/MasterEmployee.shema';

export function retrieveAdminMasterEmployee(params: WorkflowAdminReqeust) {
    return axios.get<AdminMasterEmployeeResponse[]>(
        '/v1/configuration/GetMasterEmployee',
        {
            params,
            headers: {
                Authorization:
                    'Bearer ' + localStorage.getItem('WF_APPLICATION')
            }
        }
    );
}

export function createAdminMasterEmployee(
    params: CreateAdminMasterEmployeeParams
) {
    console.log(params);
    return axios.post<ActionAdminMasterEmployeeResponse>(
        '/v1/configuration/CreateMasterEmployee',
        null,
        {
            params,
            headers: {
                Authorization:
                    'Bearer ' + localStorage.getItem('WF_APPLICATION')
            }
        }
    );
}

export function retrieveMasterEmployee() {
    return axios.get<MasterEmployeeResponse>(
        '/v1/configuration/MasterEmployee',
        {
            headers: {
                Authorization:
                    'Bearer ' + localStorage.getItem('WF_APPLICATION')
            }
        }
    );
}

export function callDeleteAdminMasterEmployee(params: { userId: string }) {
    return axios.delete<ActionAdminMasterEmployeeResponse>(
        '/v1/configuration/DeleteAdminMasterEmployee',
        {
            params,
            headers: {
                Authorization:
                    'Bearer ' + localStorage.getItem('WF_APPLICATION')
            }
        }
    );
}

export function retrieveRoleMasterEmployee() {
    return axios.get<RoleMasterEmployeeResponse[]>(
        '/v1/configuration/GetRoleMasterEmployee',
        {
            headers: {
                Authorization:
                    'Bearer ' + localStorage.getItem('WF_APPLICATION')
            }
        }
    );
}

export function callCreateRoleMasterEmployee(
    body: UpdateRoleMasterEmployeeRequest
) {
    return axios.post<string>(
        '/v1/configuration/CreateRoleMasterEmployee',
        body,
        {
            headers: {
                'Content-Type': 'application/json'
            }
        }
    );
}

export function callUpdateRoleMasterEmployee(
    body: UpdateRoleMasterEmployeeRequest
) {
    return axios.post<string>(
        '/v1/configuration/UpdateRoleMasterEmployee',
        body,
        {
            headers: {
                'Content-Type': 'application/json'
            }
        }
    );
}

export function callDeleteRoleMasterEmployee(
    params: DeleteRoleMasterEmployeeRequest
) {
    return axios.delete<string>('/v1/configuration/DeleteRoleMasterEmployee', {
        params,
        headers: {
            Authorization: 'Bearer ' + localStorage.getItem('WF_APPLICATION')
        }
    });
}
