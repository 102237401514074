import React from 'react';
import { twMerge } from 'tailwind-merge';
import { IconType } from 'react-icons';
import { IoChevronForwardOutline } from 'react-icons/io5';
import { FaCheck, FaClock, FaQuestionCircle, FaTimes } from 'react-icons/fa';

import { WorkflowInbox } from 'models/workflow/WorkflowInbox';

const statusIcons = new Map<string, IconType>([
    ['APPROVED', FaCheck],
    ['CANCEL', FaTimes],
    ['WAITING', FaClock]
]);

const statusColorTwClasses = new Map<string, string>([
    ['APPROVED', 'text-[#90BE6D]'],
    ['CANCEL', 'text-red-600'],
    ['WAITING', 'text-orange-400']
]);

const approvalStatusColorTwClasses = {
    APPROVED: 'text-[#90BE6D]',
    CANCEL: 'text-red-400',
    WAITING: 'text-orange-400'
};

type Props = {
    mode: 'Inbox' | 'Report';
    displayStyle: 'list' | 'grid';
    document: WorkflowInbox;
    isSelected?: boolean;
    style?: React.CSSProperties;
    onSelectForm: (document: WorkflowInbox) => void;
    onClickViewDetails: <T extends boolean>(
        isOpen: T,
        document?: T extends false ? never : WorkflowInbox
    ) => void;
};

function OnlineFormThumbnail(props: Props) {
    const {
        mode,
        displayStyle,
        document,
        isSelected,
        style,
        onSelectForm,
        onClickViewDetails
    } = props;

    const [docDate, docTime] = document.createdOnFormatDateTime?.split(' ') ?? [
        '',
        ''
    ];

    const StatusIcon = statusIcons.get(document.wfstatus) ?? FaQuestionCircle;

    const isListStyle = displayStyle === 'list';
    const approvalDetail = document.listApprovalDetail;

    const employees = approvalDetail.reduce<JSX.Element[]>(
        (accumulate, approval, index) => {
            const employeeNames = approval.listEmployeeInPositionGroup.map(
                employee => employee.empname
            );

            accumulate.push(
                <span
                    key={`${approval.instanceno}-${index}`}
                    className={twMerge(
                        'inline-flex flex-wrap gap-1 font-semibold',
                        approvalStatusColorTwClasses[approval.status]
                    )}
                >
                    {employeeNames.map((name, index) => (
                        <span key={approval.instanceno + name}>
                            {name}
                            {employeeNames[index + 1] && ','}
                        </span>
                    ))}
                    {approvalDetail[index + 1] && ' > '}
                </span>
            );

            return accumulate;
        },
        []
    );

    return (
        <div
            style={style}
            className={twMerge(
                'relative flex items-start gap-3 rounded-md border p-4 transition-shadow hover:shadow-[0_0_0_0.1rem_rgba(48,133,214,.25)]',
                isSelected && 'shadow-[0_0_0_0.2rem_rgba(48,133,214,.25)]',
                mode === 'Report' && 'flex-col'
            )}
        >
            {mode === 'Inbox' && (
                <input
                    type="checkbox"
                    className="h-4 w-4 cursor-pointer accent-[#3085d6]"
                    checked={isSelected}
                    value={document.docNo}
                    onChange={() => onSelectForm(document)}
                />
            )}

            <button
                className="absolute right-4 top-3 inline-flex w-fit items-center justify-center rounded-full bg-white transition-colors hover:bg-zinc-200"
                onClick={() => onClickViewDetails(true, document)}
            >
                <span className="inline-flex items-center px-2 py-0.5 text-xs text-zinc-800">
                    View Details
                    <IoChevronForwardOutline
                        size={16}
                        color="#333"
                        className="relative left-1"
                    />
                </span>
            </button>

            {mode === 'Report' && (
                <div className="col-span-4">
                    <span
                        className={twMerge(
                            'inline-flex items-center gap-1',
                            statusColorTwClasses.get(document.wfstatus)
                        )}
                    >
                        <StatusIcon className="relative bottom-[1px]" />
                        <span className="font-semibold">
                            {document.wfstatus}
                        </span>
                    </span>
                </div>
            )}
            <div className="grid w-full flex-1 gap-4">
                <LabelValuePair
                    isListStyle={isListStyle}
                    label="Subject"
                    value={document.subject}
                />

                {/* Weird code. Please ask me if you want to know how it works and why am I doing this */}
                <div className="grid grid-cols-8 gap-y-4">
                    <div className="col-span-8 grid grid-cols-4 md:col-span-4 lg:col-span-3 lg:grid-cols-9">
                        <p className="col-span-4 font-semibold md:col-span-2 lg:col-span-3">
                            Form ID
                        </p>
                        <p
                            className={twMerge(
                                'col-span-4 font-semibold text-blue-600 md:col-span-2',
                                isListStyle
                                    ? 'lg:col-span-6'
                                    : 'lg:col-span-full'
                            )}
                        >
                            {document.docNo || '‎'}
                        </p>
                    </div>
                    <div className="col-span-8 grid grid-cols-8 md:col-span-4">
                        <p className="col-span-8 font-semibold md:col-span-2 lg:col-span-2 2xl:col-span-1">
                            Requestor
                        </p>
                        <p
                            className={twMerge(
                                'col-span-8 font-semibold text-blue-600 md:col-span-4',
                                isListStyle
                                    ? 'lg:col-span-6'
                                    : 'lg:col-span-full'
                            )}
                        >
                            {document.requestorName || '‎'}
                        </p>
                    </div>
                </div>

                <LabelValuePair
                    isListStyle={isListStyle}
                    label="Document Date"
                    value={`${docDate.split('.').join('/')} ${docTime}`}
                />

                <LabelValuePair
                    isListStyle={isListStyle}
                    label="Approval Status"
                    value={employees}
                />
            </div>
        </div>
    );
}

type LabelValuePairProps = {
    isListStyle?: boolean;
    label: string;
    value?: string | JSX.Element | JSX.Element[];
    className?: string;
};

function LabelValuePair(props: LabelValuePairProps) {
    const { label, value, className, isListStyle } = props;

    return (
        <div className={twMerge('grid grid-cols-8', className)}>
            <p
                className={twMerge(
                    'col-span-8 font-semibold md:col-span-2',
                    isListStyle ? 'lg:col-span-1' : 'lg:col-span-full'
                )}
            >
                {label}
            </p>

            {typeof value === 'string' ? (
                <p
                    className={twMerge(
                        'col-span-8 font-semibold text-blue-600 md:col-span-6',
                        isListStyle ? 'lg:col-span-7' : 'lg:col-span-full'
                    )}
                >
                    {value || '‎'}
                </p>
            ) : (
                <div
                    className={twMerge(
                        'col-span-8 flex flex-wrap gap-1 md:col-span-6',
                        isListStyle ? 'lg:col-span-7' : 'lg:col-span-full'
                    )}
                >
                    {value}
                </div>
            )}
        </div>
    );
}

export default OnlineFormThumbnail;
