import * as React from "react";
import { connect } from "react-redux";
import { Card, Button, Row, Col, Accordion, Form } from "react-bootstrap";
import { FaAngleDown, FaSearch } from "react-icons/fa";
import CreatableSelect from 'react-select/creatable';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Swal from "sweetalert2";

import * as ModelsWorkflowInbox from '../../models/workflow/WorkflowInbox';
//import * as SearchEmployeeStore from '../../store/employee/SearchEmployee'
//import * as SearchEmployeeModels from '../../models/employee/SearchEmployee'

import * as ConditionStore from '../../store/autocomplete/ConditionSearch'
import * as ModelsCondition from '../../models/autocomplete/ConditionSearch'

import { ApplicationState } from "../../store";

import * as MasterCompanyStore from '../../store/company/MasterCompany'
import * as MasterCompanyModels from '../../models/company/MasterCompany'

type store = MasterCompanyModels.GetMasterCompanyState & typeof MasterCompanyStore.actionCreators
type conditionProps = ModelsCondition.GetConditionState & typeof ConditionStore.actionCreators
//type searchemployeeProps = SearchEmployeeModels.SearchPositionEmployeeState & typeof SearchEmployeeStore.actionCreators;

export interface optionValue {
    value: string
    label: string
}

export interface searchValue {
    wfNo: string,
    fiscalYear: string,
    docAmount: string,
    docAmountTo: string,
    requestor: string,
    createBy: string,
    createByTo: string,
}

const customStyles = {
    multiValueRemove: (base, state) => {
        return state.data.isFixed ? { ...base, display: 'none', zIndex: 'auto' } : base;
    },
    menu: (provided, state) => ({
        ...provided,
        zIndex: 9999,
    }),
}

//const MyContainer = ({ className, children }) => {
//    return (
//        <div style={{ padding: "16px", background: "#216ba5", color: "#fff" }}>
//            <CalendarContainer className={className}>
//                <div style={{ zIndex: 9999 }}></div>
//            </CalendarContainer>
//        </div>
//    );
//};

interface State {
    dashboardStatus: any,
    checkedALL: boolean,
    checkedSLSN: boolean,
    checkedSLTS: boolean,
    checkedSRIC: boolean,
    sids: string[],
    option: Array<MasterCompanyModels.MasterCompanyList>,
    checkCompanyOption: boolean,
    valueSelect: any,
    wfnoSearch: ModelsWorkflowInbox.searchValue,
    blindCreateBy: string,
    blindCreateByTo: string,
    blindApproveBy: string,
    blindApproveByTo: string,
    blindYear: string,
    path: string,
    checkUserOption: boolean,
    optionSelectUser: any,
    selectUserValue: string
}

class WorkflowSearchIdTabMobile extends React.Component<any, State, store & conditionProps> {

    constructor(props: any) {
        super(props)

        this.state = {
            checkedALL: true,
            checkedSLSN: false,
            checkedSLTS: false,
            checkedSRIC: false,
            sids: ["*"],
            option: [],
            checkCompanyOption: true,
            valueSelect: [],
            wfnoSearch: {
                fiscalYear: "",
                wfNo: "",
                docAmount: "",
                docAmountTo: "",
                requester_id: "",
                createBy: "",
                createByTo: "",
                updatedFrom: "",
                updatedTo: "",
                userName: ""
            },
            dashboardStatus: {
                APPROVED: "",
                WAITING: "",
                CANCEL: "",
            },
            blindCreateBy: "",
            blindCreateByTo: "",
            blindApproveBy: "",
            blindApproveByTo: "",
            blindYear: "",
            path: "",
            checkUserOption: false,
            optionSelectUser: [],
            selectUserValue: "*"
        }
    }

    componentDidMount() {
        this.props.blindSidArr(this.state.option)
        var path = window.location.pathname
        console.log(path)
        this.setState({
            path: path
        })
        if (path == "/workflowinboxadmin" || path == "/workflowreport") {
            console.log("requestsearchemployee")
            this.props.requestConditionSearch(true, "POST", ["*"]);
            this.setState({
                checkUserOption: true
            })
        }
    }

    componentDidUpdate(nextProps: any, nextState: any) {
        if (this.props.GetMasterCompany.isLoadGetMasterCompany && this.props.GetMasterCompany.statusGetMasterCompany == 200 && this.state.checkCompanyOption) {
            this.setState({
                checkCompanyOption: false
            })
            var dataSelect: any = []
            for (let i = 0; i < this.props.GetMasterCompany.responseGetMasterCompany['result_list'].length; i++) {
                var data = {}
                data['value'] = this.props.GetMasterCompany.responseGetMasterCompany['result_list'][i]['sid']
                data['label'] = this.props.GetMasterCompany.responseGetMasterCompany['result_list'][i]['company_name']
                dataSelect.push(data)
            }
            this.setState({
                option: dataSelect
            })
        }
        if (this.props.isLoad && this.props.status == 200 && this.state.checkUserOption) {
            var searchData = this.props.response['result_list']
            var dataSelect: any = []
            if (searchData != null) {
                for (var i = 0; i < searchData.length; i++) {
                    var x = 0;
                    dataSelect.map(function (item: { value: string, label: string }) {
                        if (item.value.toUpperCase() == searchData[i]['code'].toUpperCase()) {
                            x++;
                        }
                    })

                    if (x == 0) {
                        var data = {}
                        data['value'] = searchData[i]['code']
                        data['label'] = searchData[i]['display']
                        dataSelect.push(data)
                    }
                }
            }

            this.setState({
                checkUserOption: false,
                optionSelectUser: dataSelect
            })
        }
    }

    handleInputChange = (newValue: any, action: any) => {
    };

    handleChangeSelect = (newValue: any, actionMeta: any) => {
        this.props.requestConditionSearch(true, "CLEAR", this.state.valueSelect);
        console.log(newValue)
        var sidArrValue: Array<string> = []
        if (newValue.length != 0) {
            if (newValue != '') {
                for (let i = 0; i < newValue.length; i++) {
                    sidArrValue.push(newValue[i].value)
                }
            } else {
                sidArrValue = []
            }
            console.log(sidArrValue)
            this.setState({ valueSelect: sidArrValue })
        } else {
            this.setState({ valueSelect: [] })
        }

        this.setState({
            optionSelectUser: [],
            checkUserOption: true
        })
        this.props.requestConditionSearch(true, "POST", sidArrValue);
    };

    handleChangeSelectUser = (newValue: any, actionMeta: any) => {
        console.log(newValue)
        if (newValue != undefined) {
            this.setState({
                selectUserValue: newValue['value']
            })
        } else {
            this.setState({
                selectUserValue: "*"
            })
        }

        //if (newValue.length != 0) {
        //    var sidArrValue: any = []
        //    if (newValue != '') {
        //        for (let i = 0; i < newValue.length; i++) {
        //            sidArrValue.push(newValue[i].value)
        //        }
        //    } else {
        //        sidArrValue = []
        //    }
        //    this.setState({ valueSelect: sidArrValue })
        //} else {
        //    this.setState({ valueSelect: [] })
        //}
    };

    handleChange = (event) => {
        this.setState({ ...this.state, [event.target.name]: event.target.checked });
    };

    handleChangeSearch = (e: any, key: any) => {
        var dataReq = this.state.wfnoSearch
        dataReq[key] = e.target.value
        this.setState({
            wfnoSearch: dataReq
        })
        console.log("dataReq");
    }

    dateFormat(date) {
        if (date != "" && date != null && date != undefined) {
            var dates = new Date(date),
                mnth = ("0" + (date.getMonth() + 1)).slice(-2),
                day = ("0" + date.getDate()).slice(-2);
            var dataRs = dates.getFullYear() + mnth + day + '000000'
            return dataRs
        } else {
            return ""
        }
    }

    yearFormat(year) {
        if (year != "" && year != null && year != undefined) {
            var years = new Date(year)
            var dataRs = years.getFullYear();
            return dataRs;
        } else {
            return ""
        }
    }


    handleChangeDateTo = (date, event) => {
        var dateDateTo = ""
        if (date != null) {
            dateDateTo = this.dateFormat(date);
        }
        var dataReq = this.state.wfnoSearch
        dataReq['createByTo'] = dateDateTo
        this.setState({
            blindCreateByTo: date,
            wfnoSearch: dataReq
        })
    }
    handleChangeApproveTo = (date, event) => {
        var dateApproveTo = ""
        if (date != null) {
            dateApproveTo = this.dateFormat(date);
        }
        var dataReq = this.state.wfnoSearch
        dataReq['updatedTo'] = dateApproveTo
        this.setState({
            blindApproveByTo: date,
            wfnoSearch: dataReq
        })
    }

    handleChangeYear = (year, event) => {
        var FiscalYear = ""
        if (year != null) {
            FiscalYear = this.yearFormat(year).toString();
        }
        var dataReq = this.state.wfnoSearch
        dataReq['fiscalYear'] = FiscalYear
        /*        console.log(FiscalYear)*/
        this.setState({
            blindYear: year,
            wfnoSearch: dataReq
        })

    }

    handleChangeDateBy = (date, event) => {
        var dateDateBy = ""
        if (date != null) {
            dateDateBy = this.dateFormat(date);
        }
        var dataReq = this.state.wfnoSearch
        dataReq['createBy'] = dateDateBy
        this.setState({
            blindCreateBy: date,
            wfnoSearch: dataReq
        })
    }
    handleChangeApproveeBy = (date, event) => {
        var dateApproveeBy = ""
        if (date != null) {
            dateApproveeBy = this.dateFormat(date);
        }
        var dataReq = this.state.wfnoSearch
        dataReq['updatedFrom'] = dateApproveeBy
        this.setState({
            blindApproveBy: date,
            wfnoSearch: dataReq
        })
    }

    handlecheckStatus(e: any, checkbox) {
        var Status = this.state.dashboardStatus;
        if (checkbox == "APPROVED") {
            if (e.target.checked == true) {
                Status.APPROVED = "APPROVED";
            } else {
                Status.APPROVED = "";
            }
        }
        else if (checkbox == "WAITING") {
            if (e.target.checked == true) {
                Status.WAITING = "WAITING";
            }
            else {
                Status.WAITING = "";
            }
        }
        else if (checkbox == "CANCEL") {
            if (e.target.checked == true) {
                Status.CANCEL = "CANCEL";
            }
            else {
                Status.CANCEL = "";
            }
        }
        this.setState({ dashboardStatus: Status })
    }

    beforeHandleSearch() {
        if (this.state.path == "/workflowinboxadmin" || this.state.path == "/workflowreport") {
            console.log(this.state.selectUserValue)
            if (this.state.selectUserValue == "*") {
                Swal.fire({
                    title: 'กรุณากรอกข้อมูลให้ครบถ้วน',
                    text: 'กรุณากรอกช่องชื่อผู้ใช้ก่อนกดค้นหาข้อมูล',
                    icon: 'warning',
                    showCancelButton: false,
                    confirmButtonColor: '#3085d6',
                    confirmButtonText: 'ตกลง',
                    allowOutsideClick: false,
                }).then((result) => {
                })
            } else {
                this.props.handleSearch(this.state.valueSelect, this.state.checkedALL, this.state.wfnoSearch, this.state.dashboardStatus, this.state.selectUserValue)
            }
        } else {
            this.props.handleSearch(this.state.valueSelect, this.state.checkedALL, this.state.wfnoSearch, this.state.dashboardStatus, this.state.selectUserValue)
        }
    }

    render() {
        return (
            <div style={{ width: '100%' }} className='h-50 d-inline-block'>
                {/*<Accordion style={{ width: '100%' }} className='h-50 d-inline-block'>*/}
                <Card>
                    <Card.Header className='h-50 d-inline-block' style={{ marginBottom: '-12px' }}>
                        <Row>
                            <div className='col-lg-6'>
                                <div className='d-flex justify-content-start my-2 my-lg-0'>
                                    {/*<Accordion.Toggle as={Button} variant="light" eventKey="0">*/}
                                    <Form.Label column sm="3" className="mt-2" style={{ fontSize: '16px' }}>ค้นหาข้อมูล</Form.Label>
                                    {/*</Accordion.Toggle>*/}
                                </div>
                            </div>
                            {/*<div className='col-lg-6'>
                                <div className='d-flex justify-content-end my-2 my-lg-0'>
                                    <Accordion.Toggle as={Button} variant="light" eventKey="0">
                                        <FaAngleDown />
                                    </Accordion.Toggle>
                                </div>
                            </div>*/}
                        </Row>
                    </Card.Header>
                    {/*<Accordion.Collapse eventKey="0">*/}
                    <Card.Body style={{ overflow: 'none' }}>
                        <Row className='mt-1'>
                            <Col>
                                <Form.Group controlId="formBasicCheckbox">
                                    <Form.Check type="checkbox" label="เลือกทั้งหมด" style={{ fontSize: '16px' }} checked={this.state.checkedALL} name="checkedALL" onChange={this.handleChange} />
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row className='mt-1' style={{ gap: '5px 0px' }}>
                            <Col sm={11}>
                                <div style={{ zIndex: 2000 }}>
                                    <CreatableSelect style={{ fontSize: '16px' }}
                                        isMulti
                                        isClearable
                                        onChange={(value, action) => this.handleChangeSelect(value, action)}
                                        //onInputChange={(value, action) => this.handleInputChange(value, action)}
                                        options={this.state.option}
                                        isDisabled={this.state.checkedALL}
                                        styles={customStyles}
                                        menuPosition={'fixed'}

                                    />
                                </div>
                            </Col>
                            <Col sm={1}>
                                <Button style={{ float: 'right' }} variant="outline-success" size="sm" onClick={() => this.beforeHandleSearch()}>
                                    <FaSearch style={{ fontSize: '16px' }} />
                                    &nbsp;ค้นหา
                                </Button>
                            </Col>
                        </Row>
                        {(this.state.path == "/workflowinboxadmin" || this.state.path == "/workflowreport") &&
                            <Row className="mt-1">
                                <Col lg={11}>
                                    <Form.Label><span style={{ color: 'red', fontSize: '16px' }}>*</span> ชื่อผู้ใช้ :</Form.Label>
                                    <div style={{ zIndex: 2000 }}>
                                        <CreatableSelect style={{ fontSize: '16px' }}
                                            isClearable
                                            onChange={(value, action) => this.handleChangeSelectUser(value, action)}
                                            //onInputChange={(value, action) => this.handleInputChange(value, action)}
                                            options={this.state.optionSelectUser}
                                            styles={customStyles}
                                            menuPosition={'fixed'}
                                        />
                                    </div>
                                </Col>
                                <Col></Col>
                            </Row>
                        }
                        <Row className='mt-1'>
                            <Col md="auto">
                                <Form.Label style={{ fontSize: '16px' }}>ปีเอกสาร :</Form.Label>
                                <div className="form-inline">
                                    {/*<Form.Control type="text" placeholder="ตั้งแต่วันที่" size="sm" onChange={(e) => this.handleChangeSearch(e, 'createBy')} />*/}
                                    <DatePicker style={{ fontSize: '16px' }}
                                        selected={this.state.blindYear}
                                        //onChange={(e) => this.handleChangeSearch(e, 'fiscalYear')}
                                        onChange={this.handleChangeYear}
                                        customInput={<Form.Control className="menu-form custom-datepicker" size="sm" placeholder="dd/mm/yyyy" />}
                                        showYearPicker
                                        dateFormat="yyyy"
                                        placeholderText="yyyy"
                                        isClearable
                                    />
                                </div>
                            </Col>
                            <Col md="4">
                                <Form.Group className="mb-3" >
                                    <Form.Label style={{ fontSize: '16px' }}>เลขที่เอกสาร :</Form.Label>
                                    <Form.Control type="text" placeholder="เลขที่เอกสาร" size="sm" style={{ fontSize: '16px' }} onChange={(e) => this.handleChangeSearch(e, 'wfNo')} />
                                </Form.Group>
                            </Col>
                            <Col md="auto">
                                <Form.Group className="mb-3" >
                                    <Form.Label style={{ fontSize: '16px' }}>มูลค่า :</Form.Label>
                                    <div className="form-inline">
                                        <Form.Control type="text" placeholder="มูลค่าตั้งแต่" size="sm" style={{ fontSize: '16px' }} onChange={(e) => this.handleChangeSearch(e, 'docAmount')} />
                                        <Form.Label style={{ fontSize: '16px' }} className="mx-1 text-center"> -</Form.Label>
                                        <Form.Control type="text" className="mx-1" placeholder="มูลค่าถึง" size="sm" style={{ fontSize: '16px' }} onChange={(e) => this.handleChangeSearch(e, 'docAmountTo')} />
                                        <Form.Label style={{ fontSize: '16px' }} className="mx-1 text-center"> บาท</Form.Label>
                                    </div>
                                </Form.Group>
                            </Col>
                            {/*<Col>
                                    <Form.Group className="mb-3" >
                                        <Form.Label>ผู้สร้าง :</Form.Label>
                                        <Form.Control type="text" placeholder="ผู้สร้าง" size="sm" onChange={(e) => this.handleChangeSearch(e, 'requester_id')}/>
                                    </Form.Group>
                                </Col>*/}
                            <Col>
                                <Form.Label style={{ fontSize: '16px' }}>วันที่เอกสาร :</Form.Label>
                                <div className="form-inline">
                                    {/*<Form.Control type="text" placeholder="ตั้งแต่วันที่" size="sm" onChange={(e) => this.handleChangeSearch(e, 'createBy')} />*/}
                                    <DatePicker style={{ fontSize: '16px' }}
                                        selected={this.state.blindCreateBy}
                                        onChange={this.handleChangeDateBy}
                                        customInput={<Form.Control className="menu-form custom-datepicker" size="sm" placeholder="dd/mm/yyyy" />}
                                        dateFormat="dd/MM/yyyy"
                                        placeholderText="dd/mm/yyyy"
                                        isClearable
                                    />
                                    <Form.Label className="mx-1 text-center" style={{ fontSize: '16px' }}> -</Form.Label>
                                    {/*<Form.Control type="text" className="mx-1" placeholder="ถึงวันที่" size="sm" onChange={(e) => this.handleChangeSearch(e, 'createByTo')}/>*/}
                                    <DatePicker style={{ fontSize: '16px' }}
                                        selected={this.state.blindCreateByTo}
                                        onChange={this.handleChangeDateTo}
                                        customInput={<Form.Control className="menu-form custom-datepicker" size="sm" placeholder="dd/mm/yyyy" />}
                                        dateFormat="dd/MM/yyyy"
                                        placeholderText="dd/mm/yyyy"
                                        isClearable
                                    />
                                </div>
                            </Col>
                        </Row>
                        {this.props.modeSearchApproveDate == true &&
                            <Row className='mt-2'>
                                <Col md="auto">
                                    <div className="form-inline" style={{ visibility: "hidden" }}>
                                        <DatePicker style={{ fontSize: '16px' }}
                                            customInput={<Form.Control className="menu-form custom-datepicker" size="sm" placeholder="dd/mm/yyyy" />}
                                            showYearPicker
                                            dateFormat="yyyy"
                                            placeholderText="yyyy"
                                        />
                                    </div>
                                </Col>
                                <Col md={{ span: "auto", offset: 4 }}>
                                    <Form.Label style={{ fontSize: '16px' }}>สถานะ :</Form.Label>
                                    <div className="form-inline" style={{ paddingRight: "90px" }}>
                                        <Form.Group className="mb-3" controlId="formBasicCheckbox">
                                            <Form.Check type="checkbox" id="checkActive" label="APPROVED" style={{ fontSize: '16px' }} onChange={(e) => this.handlecheckStatus(e, "APPROVED")} />
                                            <Form.Check type="checkbox" className="mx-5" id="checkError" label="WAITING" style={{ fontSize: '16px' }} onChange={(e) => this.handlecheckStatus(e, "WAITING")} />
                                            <Form.Check type="checkbox" className="" id="checkError" label="CANCEL" style={{ fontSize: '16px' }} onChange={(e) => this.handlecheckStatus(e, "CANCEL")} />
                                        </Form.Group>
                                    </div>
                                </Col>
                                <Col >
                                    <Form.Label style={{ fontSize: '16px' }}>วันที่ปรับปรุงล่าสุด :</Form.Label>
                                    <div className="form-inline">
                                        {/*<Form.Control type="text" placeholder="ตั้งแต่วันที่" size="sm" onChange={(e) => this.handleChangeSearch(e, 'createBy')} />*/}
                                        <DatePicker style={{ fontSize: '16px' }}
                                            selected={this.state.blindApproveBy}
                                            onChange={this.handleChangeApproveeBy}
                                            customInput={<Form.Control className="menu-form custom-datepicker" size="sm" placeholder="dd/mm/yyyy" />}
                                            dateFormat="dd/MM/yyyy"
                                            placeholderText="dd/mm/yyyy"
                                            isClearable
                                        />
                                        <Form.Label className="mx-1 text-center" style={{ fontSize: '16px' }}> -</Form.Label>
                                        {/*<Form.Control type="text" className="mx-1" placeholder="ถึงวันที่" size="sm" onChange={(e) => this.handleChangeSearch(e, 'createByTo')}/>*/}
                                        <DatePicker style={{ fontSize: '16px' }}
                                            selected={this.state.blindApproveByTo}
                                            onChange={this.handleChangeApproveTo}
                                            customInput={<Form.Control className="menu-form custom-datepicker" size="sm" placeholder="dd/mm/yyyy" />}
                                            dateFormat="dd/MM/yyyy"
                                            placeholderText="dd/mm/yyyy"
                                            isClearable
                                        />
                                    </div>
                                </Col>
                            </Row>
                        }
                    </Card.Body>
                    {/*</Accordion.Collapse>*/}
                </Card >
                {/*</Accordion>*/}
            </div>
        )

        /*Date Picker Old*/
        /*
         * <DatePicker
            selected={this.state.blindCreateByTo}
            onChange={this.handleChangeDateTo}
            customInput={<Form.Control className="menu-form custom-datepicker" size="sm" placeholder="dd/mm/yyyy" />}
            dateFormat="dd/MM/yyyy"
            isClearable
            placeholderText="dd/mm/yyyy"
            popperClassName="calendar-popout"
            popperPlacement="auto"
            popperModifiers={{
                offset: { enabled: true, offset: '5px, 10px' },
                preventOverflow: {
                    enabled: true,
                    escapeWithReference: false,
                    boundariesElement: 'viewport'
                }
            }}
        />
         */
    }

}

export default connect((state: ApplicationState) => ({ ...state.MasterCompany, ...state.appState, ...state.conditionSearch, }), ({ ...ConditionStore.actionCreators }))(WorkflowSearchIdTabMobile)