import { Action, Reducer } from 'redux';
import { AppThunkAction } from '..';

import * as Models from '../../models/workflow/SearchWorkflow'

interface BeforeLoadSearchWorkflow {
    type: 'LOAD_SEARCH_WORKFLOW_CONFIG'
}

interface LoadSearchWorkflow {
    type: 'LOADSEARCHWORKFLOW'
    response: Models.ResponseSearchWorkflowDetail
    status: Number
    statusMessage: String
}

interface FailSearchWorkflow {
    type: 'LOADFAIL'
    status: number,
    statusMessage: any
}

interface ClearSearchWorkflow {
    type: 'CLEARSEARCHWORKFLOW'
    response: Models.ResponseSearchWorkflowDetail
    status: Number
    statusMessage: String
}

type KnownAction = LoadSearchWorkflow | FailSearchWorkflow | ClearSearchWorkflow | BeforeLoadSearchWorkflow

export const actionCreators = {
    requestsearchworkflow: (p: boolean, method: String, wf_id: string , company? : string): AppThunkAction<KnownAction> => async (dispatch, getState) => {
        const appState = getState();
        console.log('call actionCreators : requestsearchworkflow')
        if (appState && appState.searchWorkflow && p && method == 'POST' && wf_id) {
            var wfIdUri = encodeURIComponent(wf_id);
            console.log(wfIdUri)
            await fetch(`/v2/Workflow/Search?wf_id=` + wfIdUri + '&app_id=' + company, {
                method: 'GET',
                headers: {
                    'Authorization': 'Bearer ' + localStorage.getItem('WF_APPLICATION'),
                },
            })
                .then((response) => {
                    return response
                })
                .then((data) => {
                    //console.log(data)
                    if (!data.ok) {
                        data.json().then(err => {
                            //console.log(err.message_res)
                            console.log('errorrrr')
                            dispatch({ type: 'LOADFAIL', status: 204, statusMessage: err.message });
                        })
                    } else {
                        data.json().then(data => {
                            //console.log(data)
                            dispatch({ type: 'LOADSEARCHWORKFLOW', response: data as Models.ResponseSearchWorkflowDetail, status: 200, statusMessage: '' });
                        })
                    }
                })

            dispatch({ type: 'LOAD_SEARCH_WORKFLOW_CONFIG' });

        } else if (method == "CLEAR") {
            dispatch({ type: 'CLEARSEARCHWORKFLOW', status: 204, statusMessage: "", response: [] as any });
        }
    }
}

const unloadedState: Models.SearchWorkflowState = { isLoad: false };

export const reducer: Reducer<Models.SearchWorkflowState> = (state: Models.SearchWorkflowState | undefined, incomingAction: Action): Models.SearchWorkflowState => {
    if (state == undefined) {
        return unloadedState;
    }
    const action = incomingAction as KnownAction;
    switch (action.type) {
        case 'LOAD_SEARCH_WORKFLOW_CONFIG':
            return {
                isLoad: false,
            }

        case 'LOADSEARCHWORKFLOW':
            return {
                isLoad: true,
                responseSearch: action.response,
                statusSearch: action.status
            }

        case 'LOADFAIL':
            return {
                isLoad: true,
                message: action.statusMessage,
                statusSearch: action.status
            }

        case 'CLEARSEARCHWORKFLOW':
            return {
                isLoad: true,
                responseSearch: action.response,
                statusSearch: action.status
            }
        default: return state;
    }
}