import React, { useEffect, useState } from 'react';
import { Button, Container, Row, Col, Form } from 'react-bootstrap';
import { Input, Label } from 'reactstrap';
import DatePicker from 'react-datepicker';
import { BsCalendar2Date } from 'react-icons/bs';

import { FaPlusCircle, FaSearch } from 'react-icons/fa';
import { convertToYYYYMMDD } from 'modules/Common';
import Pagination from '@material-ui/lab/Pagination';
import CallbackStartLogTable from './components/CallbackStartLogTable';
import { retrieveCallbackStart } from 'data/CallbackLog/datasources/CallbackLog.datasource';
import { getCallbackStart } from 'data/CallbackLog/repositories/CallbackLog.repository';
import { ResponseList } from 'models/Response';
import { LogCallbackStartResponse } from 'data/CallbackLog/schemas/CallbackLog.schema';
import {
    MasterCompanyList,
    MasterCompanyResponse
} from 'models/company/MasterCompany';
import axios from 'axios';
import { FiRefreshCw } from 'react-icons/fi';
import useLoader from 'hooks/useLoader';

function CallbackLogStart() {
    const loading = useLoader();
    const [timer, setTimer] = useState(120);
    const [callbackLogResponse, setCallbackLogResponse] =
        useState<ResponseList<LogCallbackStartResponse>>();
    const [companyMaster, setCompanyMater] = useState<MasterCompanyList[]>();
    const [isToggled, setIsToggled] = useState(true);

    useEffect(() => {
        fetchCallbackLogStart();
        fetchCompanyMaster();

        let interval;

        if (isToggled) {
            interval = setInterval(() => {
                setTimer(prevTimer => {
                    if (prevTimer === 1) {
                        fetchCallbackLogStart();

                        return 30;
                    } else {
                        return prevTimer - 1;
                    }
                });
            }, 1000);
        }
        return () => clearInterval(interval);
    }, [isToggled]);

    const handleChange = () => {
        setIsToggled(!isToggled);
    };

    const fetchCallbackLogStart = async () => {
        try {
            loading.show()
            const [, response] = await getCallbackStart();

            if (response) {
                setCallbackLogResponse(response);
            }
        } finally {
            loading.hide()
        }
    };
    const handleRefreshData = () => {
        fetchCallbackLogStart();
    };

    const fetchCompanyMaster = async () => {
        const response = await axios.get<MasterCompanyResponse>(
            '/v1/company_master',
            {
                headers: {
                    Authorization:
                        'Bearer ' + localStorage.getItem('WF_APPLICATION')
                }
            }
        );
        setCompanyMater(response.data.result_list);
    };

    const mappingCompany = new Map<string, string>(
        companyMaster?.map(value => [value.sid, value.company_name])
    );

    return (
        <Container>
            <Row>
                <Col className="import-export-actions">
                    <div
                        className="trapezoid-head"
                        style={{ width: '200px' }}
                    >
                        <span>Callback Stat</span>
                    </div>
                </Col>
            </Row>
            <Row>
                <Col>
                    <div className="mat-box mb-5 rounded bg-white p-3 shadow">
                        <Row>
                            <Col>
                                <div className="mt-2">
                                    <Row>
                                        <Col
                                            xs={12}
                                            md={4}
                                        >
                                            <Form.Group
                                                className="mb-3"
                                                controlId="formBasicEmail"
                                            >
                                                <Label>Auto Refresh</Label>
                                                <button
                                                    onClick={handleChange}
                                                    className={`ml-3 ${isToggled
                                                        ? 'bg-green-500'
                                                        : 'bg-red-500'
                                                        } rounded-lg px-4 py-2 text-white transition-colors duration-300 ease-in-out`}
                                                >
                                                    {isToggled ? 'ON' : 'OFF'}
                                                </button>
                                                <span className='ml-5 border-l-2 border-gray-950' />
                                                <button
                                                    onClick={handleRefreshData}
                                                    className="ml-5 rounded-md bg-blue-600 px-4 py-2 text-white"
                                                >
                                                    Refresh <FiRefreshCw />
                                                </button>
                                                <Label className="ml-5">
                                                    Total Server :{' '}
                                                    {
                                                        callbackLogResponse?.total_count
                                                    }{' '}
                                                    ON:{' '}
                                                    <span className="text-green-700">
                                                        {
                                                            callbackLogResponse?.total_count
                                                        }
                                                    </span>{' '}
                                                    OFF:{' '}
                                                    <span className="text-red-700">
                                                        0
                                                    </span>
                                                </Label>
                                            </Form.Group>
                                        </Col>
                                    </Row>
                                </div>
                            </Col>
                        </Row>
                    </div>
                    <CallbackStartLogTable
                        logCallbackResult={callbackLogResponse?.result_list!}
                        mappingCompany={mappingCompany}
                    />
                </Col>
            </Row>
        </Container>
    );
}

export default CallbackLogStart;
