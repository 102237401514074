import { Action, Reducer } from 'redux';
import { AppThunkAction } from '..';
// models
import * as Models from '../../models/employee/PositionEmployee'

//interface LoadPositionEmployee {
//    type: 'LOADPOSITIONEMPLOYEE'
//    responseEmployee: Models.ResponsePositionEmployee
//    status: Number,
//    statusMessage: String
//}

interface LoadPositionEmployee {
    type: 'LOAD_POSITION_EMPLOYEE'
}

interface LoadedPositionEmployee {
    type: 'LOADED_POSITION_EMPLOYEE'
    responseEmployee: Models.ResponsePositionEmployee
    status: Number,
    statusMessage: String
}

interface FailLoadPositionEmployee {
    type: 'LOAD_FAIL'
    status: number,
    statusMessage: any
}

type KnownAction = LoadPositionEmployee | LoadedPositionEmployee | FailLoadPositionEmployee

export const actionCreators = {
    requestPositionemployee: (sid: string, role_id: string, emp_id: string): AppThunkAction<KnownAction> => async (dispatch, getState) => {
        //console.log(sid)
        //console.log(role_id)
        //console.log(emp_id)
        const appState = getState();
        if (appState && appState.positionEmployee) {
            fetch(`/v1/Employee/Get?sid=` + sid + `&role_id=` + role_id + `&emp_id=` + emp_id, {
                method: 'GET',
                headers: {
                    'Authorization': 'Bearer ' + localStorage.getItem('WF_APPLICATION'),
                },
            })
                .then((response) => {
                    return response
                })
                .then((data) => {
                    //console.log(data)
                    if (!data.ok) {
                        data.json().then(err => {
                            //console.log(err.message_res)
                            dispatch({ type: 'LOAD_FAIL', status: 204, statusMessage: err.message });
                        })
                    } else {
                        data.json().then(data => {
                            //console.log(data)
                            dispatch({ type: 'LOADED_POSITION_EMPLOYEE', responseEmployee: data as Models.ResponsePositionEmployee, status: 200, statusMessage: '' });
                        })
                    }
                })

            dispatch({ type: 'LOAD_POSITION_EMPLOYEE' });
        }
    }
}

const unloadedState: Models.GetPositionEmployeeState = { isLoadPositionEmployee: false };

export const reducer: Reducer<Models.GetPositionEmployeeState> = (state: Models.GetPositionEmployeeState | undefined, incomingAction: Action): Models.GetPositionEmployeeState => {
    if (state == undefined) {
        return unloadedState;
    }
    const action = incomingAction as KnownAction;
    switch (action.type) {
        case 'LOAD_POSITION_EMPLOYEE':
            return {
                isLoadPositionEmployee: false,
                responseEmployee : [] as any
            }

        case 'LOADED_POSITION_EMPLOYEE':
            return {
                isLoadPositionEmployee: true,
                responseEmployee: action.responseEmployee,
                status: action.status
            }

        case 'LOAD_FAIL':
            return {
                isLoadPositionEmployee: true,
                message: action.statusMessage,
                status: action.status
            }
        default: return state;
    }
}