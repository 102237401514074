import { Action, Reducer } from 'redux';
import { AppThunkAction } from '..';

import * as Models from '../../models/workflow/CreateWorkflow'

interface LoadCreateWorkflow {
    type: 'LOADCREATEWORKFLOW'
}

interface CreateWorkflow {
    type: 'CREATEWORKFLOW'
    response: Models.CreateWorkflowDetail
    statusCreate: Number,
    statusMessage: String
}

interface FaliCreateWorkflow {
    type: 'CREATEWORKFLOWFAIL'
    statusCreate: number,
    statusMessage: any
}

interface ClearCreateWorkflow {
    type: 'CLEARCREATEWORKFLOW'
}

type KnownAction = CreateWorkflow | FaliCreateWorkflow | ClearCreateWorkflow | LoadCreateWorkflow

export const actionCreators = {
    requestCreateworkflow: (p: boolean, method: string, username: string, formData: Array<Models.CreateWorkflowDetail> , company : string): AppThunkAction<KnownAction> => async (dispatch, getState) => {
        const appState = getState();
        console.log('call actionCreators : requestCreateworkflow')
        if (appState && appState.createWorkflow && formData && p && method == 'POST') {
            //console.log("formData")
            console.log(JSON.stringify(formData))
            var body = {
                'username': username,
                'workflow': formData,
            }
           // console.log(body)
            await fetch(`/v2/Workflow/Create?app_id=` + company, {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + localStorage.getItem('WF_APPLICATION'),
                },
                body: JSON.stringify(body),
            })
                .then(response => {
                    return response
                })
                .then((data) => {
                    //console.log(data)
                    if (!data.ok) {
                        data.json().then(err => {
                            //console.log(err.message_res)
                            console.log('dispatch : CREATEWORKFLOWFAIL')
                            dispatch({ type: 'CREATEWORKFLOWFAIL', statusCreate: 204, statusMessage: err.message });
                        })
                    } else {
                        data.json().then(data => {
                            //console.log(data)
                            dispatch({ type: 'CREATEWORKFLOW', response: data as Models.CreateWorkflowDetail, statusCreate: 200, statusMessage: '' });
                        })
                    }
                })
            dispatch({ type: 'LOADCREATEWORKFLOW' });

        } else if (method == 'CLEAR') {
            console.log('unloadstate')
            dispatch({ type: 'CLEARCREATEWORKFLOW' });
        }
    }
}

const unloadedState: Models.CreateWorkflowState = { isLoadCreateWorkflow: false };

export const reducer: Reducer<Models.CreateWorkflowState> = (state: Models.CreateWorkflowState | undefined, incomingAction: Action): Models.CreateWorkflowState => {
    if (state == undefined) {
        return unloadedState;
    }
    const action = incomingAction as KnownAction;
    switch (action.type) {
        case 'LOADCREATEWORKFLOW':
            return {
                isLoadCreateWorkflow: false,

            }
        case 'CREATEWORKFLOW':
            return {
                isLoadCreateWorkflow: true,
                response: action.response,
                statusCreateWorkflow: action.statusCreate

            }
        case 'CREATEWORKFLOWFAIL':
            return {
                isLoadCreateWorkflow: true,
                messageCreateWorkflow: action.statusMessage,
                statusCreateWorkflow: action.statusCreate
            }
        case 'CREATEWORKFLOWFAIL':
            return {
                isLoadCreateWorkflow: true,
                messageCreateWorkflow: '',
                statusCreateWorkflow: 400
            }
        case 'CLEARCREATEWORKFLOW':
            return {
                isLoadCreateWorkflow: true,
                messageCreateWorkflow: '',
                statusCreateWorkflow: 0
            }
        default: return state;
    }
}