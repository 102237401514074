import React from 'react';
import { Button, Col, Form, Row } from 'react-bootstrap';
import { LogCallBackInfo, ObjCallbackLog } from 'data/CallbackLog/schemas/CallbackLog.schema';
import { formatYMDToDMY } from 'modules/Common';

type Props = {
    explorerDetail: () => void;
    CallbackLogDetail: LogCallBackInfo;
    mappingCompany: Map<string, string>;
    refreshLog: (id: number, instanceNo: string) => void;
};

function CallbackLogDetail(props: Props) {
    const { CallbackLogDetail, explorerDetail, mappingCompany, refreshLog } =
        props;

    return (
        <div>
            <div className="flex justify-end">
                <Button
                    variant="outline-success"
                    size="sm"
                    onClick={explorerDetail}
                >
                    Back
                </Button>
            </div>
            <Col>
                <Row className="flex gap-2">
                    <Form.Label className="w-[10%]">ID :</Form.Label>
                    <Form.Label>{CallbackLogDetail.id}</Form.Label>

                </Row>
                <Row className="flex gap-2">
                    <Form.Label className="w-[10%]">
                        Callback ID :
                    </Form.Label>
                    <Form.Label>{CallbackLogDetail.callbackId}</Form.Label>

                </Row>
                <Row className="flex gap-2">
                    <Form.Label className="w-[10%]">
                        Callback Action :
                    </Form.Label>
                    <Form.Label>{CallbackLogDetail.callbackAction}</Form.Label>
                </Row>
                <Row className="flex gap-2">
                    <Form.Label className="w-[10%]">Callback Status :</Form.Label>
                    <Form.Label>{CallbackLogDetail.callbackstatus}</Form.Label>

                </Row>
                <Row className="flex gap-2">
                    <Form.Label className="w-[10%]">Callback On :</Form.Label>
                    <Form.Label>{formatYMDToDMY(CallbackLogDetail.callback_on)}</Form.Label>
                </Row>
                <Row className="flex gap-2">
                    <Form.Label className="w-[10%]">
                        CallBack Error Message :
                    </Form.Label>
                    <Form.Label>
                        {CallbackLogDetail.callback_errmessage}
                    </Form.Label>
                </Row>
                <Row className="flex gap-2">
                    <Form.Label className="w-[10%]">Action ID :</Form.Label>
                    <Form.Label>{CallbackLogDetail.actionUserId}</Form.Label>
                </Row>
                <Row className="flex gap-2">
                    <Form.Label className="w-[10%]">Instanc No :</Form.Label>
                    <Form.Label>{CallbackLogDetail.instanceno}</Form.Label>
                </Row>
                <Row className="flex gap-2">
                    <Form.Label className="w-[10%]">Company :</Form.Label>
                    <Form.Label className="overflow-x-auto whitespace-pre-wrap">
                        {mappingCompany.get(CallbackLogDetail.sid)}
                    </Form.Label>
                </Row>
                <Row className="flex gap-2">
                    <Form.Label className="w-[10%]">
                        Doc No :
                    </Form.Label>
                    <Form.Label className="overflow-x-auto whitespace-pre-wrap">
                        {CallbackLogDetail.paramdocno}
                    </Form.Label>
                </Row>
                <Row className="flex gap-2">
                    <Form.Label className="w-[10%]">Refresh Callback On :</Form.Label>
                    <Form.Label className="overflow-x-auto whitespace-pre-wrap">
                        {formatYMDToDMY(CallbackLogDetail.recallback_on)}
                    </Form.Label>

                </Row>
                <Row className="flex gap-2">
                    <Form.Label className="w-[10%]">Refresh Callback Count :</Form.Label>
                    <Form.Label className="overflow-x-auto whitespace-pre-wrap">
                        {CallbackLogDetail.recallback_count}
                    </Form.Label>
                </Row>
                <Row className="flex gap-2">

                    <Form.Label className="w-[10%]">Obj Callback Content :</Form.Label>
                    <Form.Label>
                        {CallbackLogDetail.objCallbackContentGroup}
                    </Form.Label>
                </Row>
            </Col>
        </div>
    );
}

export default CallbackLogDetail;
