import React from 'react';

import FocusoneTextLogo from '../FocusoneTextLogo';
import IconCursorLogin from 'assets/image/icon-cursor-click.png';

function Step3() {
    return (
        <React.Fragment>
            <div className="flex items-baseline space-x-3 text-lg">
                <span className="inline-flex h-10 w-10 items-center justify-center rounded-full bg-[#F7980A] font-bold">
                    3
                </span>
                <span className="flex-1 text-lg font-bold ">
                    กดปุ่ม ใช่ เพื่อยืนยันการเชื่อมต่อ User เข้ากับระบบ Line
                </span>
            </div>

            <div className="mt-6 flex flex-1 flex-col items-center justify-center gap-4 rounded-md p-4 shadow-[0px_0px_8px_0px_rgba(69,69,69,0.2)] lg:mx-10 xl:mx-12 2xl:mx-0">
                <div className="flex flex-col items-center gap-3">
                    <img
                        src="https://www.focusoneerp.com/focusoneerp/Logo2.png"
                        alt="line-profile-pic"
                        className="h-20 w-20 rounded-full border object-contain"
                    />

                    <div className="text-center">
                        <p className="text-sm text-neutral-600">
                            เข้าสู่ระบบด้วยผู้ใช้
                        </p>
                        <p className="font-semibold text-neutral-800">
                            focusone
                        </p>
                    </div>
                </div>

                <p className="text-center text-neutral-800">
                    คุณต้องการรับการแจ้งเตือนของ focusone ผ่าน Line Chat{' '}
                    <FocusoneTextLogo className="text-sm" />
                </p>

                <div className="relative flex w-full flex-col gap-2">
                    <img
                        src={IconCursorLogin}
                        className="absolute -left-4 -top-1 h-[55px] w-[55px]"
                    />

                    <button className="flex-1 rounded-sm bg-[#06C755] px-4 py-2 text-white shadow-md active:brightness-95 disabled:opacity-60">
                        ใช่
                    </button>
                    <button className="flex-1 rounded-sm bg-red-500/95 px-4 py-2 text-white shadow-md active:brightness-95 disabled:opacity-60">
                        ไม่ใช่
                    </button>
                </div>
            </div>
        </React.Fragment>
    );
}

export default Step3;
