import React from 'react';
import { IconType } from "react-icons";
import { FaCheck, FaClock, FaQuestionCircle, FaTimes } from "react-icons/fa";

import { formatDMY } from "modules/Common";
import { ResponseWorkflowInboxValue } from "models/workflow/SearchWorkflowInbox";

import Stepper from "./Stepper";

const statusIcons = new Map<string, IconType>([
    ['APPROVED', FaCheck],
    ['CANCEL', FaTimes],
    ['WAITING', FaClock]
]);

const STATUS_COLOR = {
    APPROVED: 'success',
    CANCEL: 'danger',
    WAITING: 'warning'
};

type ApprovalInChargeStepperProps = {
    document: ResponseWorkflowInboxValue;
};

function ApprovalInChargeStepper(props: ApprovalInChargeStepperProps) {
    const { document } = props;

    const isSpecialMode =
        document.specialMode && document.specialMode === 'guarantee';
    const approvalDetail = document.listApprovalDetail;

    return (
        <Stepper.Container className="mt-3">
            {approvalDetail && approvalDetail
                .slice(0, isSpecialMode ? 1 : undefined)
                .map((approval, index) => {
                    const alreadyApprovedOrRejected =
                        approval.status === 'APPROVED' ||
                        approval.status === 'CANCEL';
                    const Icon =
                        statusIcons.get(approval.status) ?? FaQuestionCircle;

                    return (
                        <Stepper
                            icon={Icon}
                            status={STATUS_COLOR[approval.status]}
                        >
                            <div className="flex gap-4">
                                <div>
                                    <p>
                                        {!isSpecialMode &&
                                            alreadyApprovedOrRejected &&
                                            formatDMY(
                                                String(
                                                    approval.updatedOnFormatDateTime
                                                )
                                            )}
                                    </p>
                                </div>
                                <div>
                                    <p>
                                        สถานะ: {approval.status || ' '} จาก{' '}
                                        {approval.listEmployeeInPositionGroup
                                            .map(employee => employee.empname)
                                            .join(', ')}
                                    </p>
                                    <p>
                                        {(approval.reason &&
                                            `รายละเอียด: ${approval.reason}`) ||
                                            ' '}
                                    </p>
                                </div>
                            </div>
                        </Stepper>
                    );
                })}
        </Stepper.Container>
    );
}

export default ApprovalInChargeStepper;