export function debounce<F extends Function>(callback: F, millisec: number) {
    let timeoutId: ReturnType<typeof setTimeout>;

    return ((...args: any) => {
        clearTimeout(timeoutId);
        timeoutId = setTimeout(() => callback(...args), millisec);
    }) as unknown as F;
}

export const toCapitalCase = (str: string) => {
    if (str.length === 0) {
        return '';
    }

    return str[0].toUpperCase() + str.slice(1);
};