import { Action, Reducer } from 'redux';
import { AppThunkAction } from '..';

import * as Models from '../../models/condition/CreateConditionID'

interface BeforeCreateConditionID {
    type: 'CREATE_CONDITIONID'
}
interface CreateConditionID {
    type: 'CREATECONDITIONID'
    response: Models.ReqCreateConditionID
    status: Number,
    statusMessage: String
}
interface FailCreateConditionID {
    type: 'CREATEFAIL'
    status: number,
    statusMessage: any
}
interface ClearConditionID {
    type: 'CLEAR'
    status: number
}


type KnownAction = CreateConditionID | FailCreateConditionID | BeforeCreateConditionID | ClearConditionID

export const actionCreators = {
    requestCreateConditionMaster: (p: boolean, method: String, formData: Array<Models.ReqCreateConditionID>, company?: string): AppThunkAction<KnownAction> => async (dispatch, getState) => {
        console.log('call actionCreators : requestCreatePositionemployee')
        const appState = getState();
        if (appState && appState.createCondtiotionID && p && method == 'POST') {
            console.log(formData)
            await fetch(`/v2/Condition/Create?app_id=` + company, {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + localStorage.getItem('WF_APPLICATION'),
                },
                body: JSON.stringify(formData),
            })
                .then((response) => {
                    return response
                })
                .then((data) => {
                    console.log(data)
                    if (!data.ok) {
                        data.json().then(err => {
                            //console.log(err.message_res)
                            console.log('dispatch : CREATEFAIL')
                            dispatch({ type: 'CREATEFAIL', status: 204, statusMessage: err.message });
                        })
                    } else {
                        data.json().then(data => {
                            //console.log(data)
                            console.log('dispatch : CREATECONDITIONID')
                            dispatch({ type: 'CREATECONDITIONID', response: data as Models.ReqCreateConditionID, status: 200, statusMessage: '' });
                        })
                    }
                })
            dispatch({ type: 'CREATE_CONDITIONID' });
        } else if (method == 'PUT') {
            console.log(formData)
            await fetch(`/v2/Condition/Update?app_id=` + company, {
                method: 'PUT',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + localStorage.getItem('WF_APPLICATION'),
                },
                body: JSON.stringify(formData),
            })
                .then((response) => {
                    return response
                })
                .then((data) => {
                    //console.log(data)
                    if (!data.ok) {
                        data.json().then(err => {
                            //console.log(err.message_res)
                            console.log('dispatch : CREATEFAIL')
                            dispatch({ type: 'CREATEFAIL', status: 204, statusMessage: err });
                        })
                    } else {
                        data.json().then(data => {
                            //console.log(data)
                            console.log('dispatch : CREATECONDITIONID')
                            dispatch({ type: 'CREATECONDITIONID', response: data as Models.ReqCreateConditionID, status: 200, statusMessage: '' });
                        })
                    }
                })
            dispatch({ type: 'CREATE_CONDITIONID' });
        } else if (method == "CLEAR") {
            console.log("CLEAR")
            dispatch({ type: 'CLEAR', status : 400 });
        }
    }
}

const unloadedState: Models.CreateConditionIDState = { isLoad: false };

export const reducer: Reducer<Models.CreateConditionIDState> = (state: Models.CreateConditionIDState | undefined, incomingAction: Action): Models.CreateConditionIDState => {
    if (state == undefined) {
        return unloadedState;
    }
    const action = incomingAction as KnownAction;
    switch (action.type) {
        case 'CREATE_CONDITIONID':
            return {
                isLoad: false,

            }
        case 'CREATECONDITIONID':
            return {
                isLoad: true,
                response: action.response,
                statusCreate: action.status

            }
        case 'CREATEFAIL':
            return {
                isLoad: true,
                messageCreate: action.statusMessage,
                statusCreate: action.status
            }
        case 'CLEAR':
            return {
                isLoad: false,
                statusCreate: action.status
            }
        default: return state;
    }
}