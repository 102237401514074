import React, { PropsWithChildren, useEffect } from 'react';
import { Container, Row, Col } from 'reactstrap';
import { useLocation } from 'react-router';

import NavMenu from './NavMenu';
//import SideBarMenu from './SideBarMenu';

function SubLayout(props: PropsWithChildren<{}>) {
    const location = useLocation();

    return (
        <React.Fragment>
            {
                Boolean(location.pathname.includes('workflow-inbox-detail') && window.location.ancestorOrigins.item(0)?.includes('onlineform'))
                    ? (
                        props.children
                    ) : (
                        <>
                            <NavMenu />
                            <Container fluid={true}>
                                <Row>
                                    {/* <Col md="2" style={{ paddingLeft: "0", zIndex: 1 }}>
                                    <SideBarMenu />
                                </Col> */}
                                    <Col md="12" style={{ marginLeft: 0 }}>
                                        <main role="main" style={{ minHeight: "92.5vh" }}>
                                            {props.children}
                                        </main>
                                    </Col>
                                </Row>
                            </Container>
                        </>
                    )
            }
        </React.Fragment>
    )
};

export default SubLayout