import React from 'react';
import gifs from '../../assets/Gifs';
import OverlayPortal from './OverlayPortal';

interface Props {
    loading: boolean;
    message: string;
}

function LoadingOverlay({ loading, message }: Props) {
    return (
        <OverlayPortal>
            <div className="container">
                {loading && (
                    <div className="fixed left-0 top-0 z-[9999] flex h-screen w-screen flex-col items-center justify-center bg-[rgb(247_247_247_/_83%)] ">
                        <img
                            src={gifs.F1_LOADINGGIF}
                            alt="my-gif"
                            className="h-60 w-60"
                        />
                        {/* <div className="animate-spin w-16 h-16 border-8 border-t-[#3d5af1] border-r-transparent border-b-[#3d5af1] border-l-transparent rounded-[50%]" /> */}
                        {message !== '' && (
                            <span className="text-[20px] font-normal leading-4 text-black">
                                {message}
                            </span>
                        )}
                    </div>
                )}
            </div>
        </OverlayPortal>
    );
}

export default LoadingOverlay;
