import { Action, Reducer, combineReducers } from 'redux';
import { AppThunkAction } from '..';
import { RequestOptions } from '../../models/Common';
import { ResponseList } from '../../models/Response';
import { SimulateWorkflow, SimulateWorkflowCriteria, SimulateWorkflowStateStore,AddSimulateWorkflowCriteriaState, EditSimulateWorkflowCriteriaState, DeleteSimulateWorkflowCriteriaState } from '../../models/simulateworkflow/SimulateWorkflow';
import * as Models from '../../models/simulateworkflow/SimulateWorkflow'


// #region interface GetSimulateWorkflow
interface RequestSimulateWorkflow {
    type: 'REQUEST_SIMULATE_WORKFLOW';
}

interface ReceiveSimulateWorkflow {
    type: 'RECEIVE_SIMULATE_WORKFLOW';
    data: ResponseList<SimulateWorkflow>;
}

interface FailureSimulateWorkflow {
    type: 'FAILURE_SIMULATE_WORKFLOW';
    error: any
}

interface ClearSimulateWorkflow {
    type: 'CLEAR_SIMULATE_WORKFLOW';
}
// #endregion

// #region interface GetSimulateWorkflowCriteria
interface RequestSimulateWorkflowCriteria {
    type: 'REQUEST_SIMULATE_WORKFLOW_CRITERIA';
}

interface ReceiveSimulateWorkflowCriteria {
    type: 'RECEIVE_SIMULATE_WORKFLOW_CRITERIA';
    data: ResponseList<SimulateWorkflowCriteria>;
}

interface FailureSimulateWorkflowCriteria {
    type: 'FAILURE_SIMULATE_WORKFLOW_CRITERIA';
    error: any
}

interface ClearSimulateWorkflowCriteria {
    type: 'CLEAR_SIMULATE_WORKFLOW_CRITERIA';
}
// #endregion

// #region interface AddSimulateWorkflowCriteria
interface AddSimulateWorkflowCriteria {
    type: 'ADD_SIMULATE_WORKFLOW_CRITERIA';
}

interface AddSuccessSimulateWorkflowCriteria {
    type: 'ADD_SUCCESS_SIMULATE_WORKFLOW_CRITERIA'
    status: number
    response: string
}

interface AddFailureSimulateWorkflowCriteria {
    type: 'ADD_FAILURE_SIMULATE_WORKFLOW_CRITERIA';
    status: number
    error: any
}

interface ClearAddSimulateWorkflowCriteria {
    type: 'CLEAR_ADD_SIMULATE_WORKFLOW_CRITERIA';
}
// #endregion

// #region interface EditSimulateWorkflowCriteria
interface EditSimulateWorkflowCriteria {
    type: 'EDIT_SIMULATE_WORKFLOW_CRITERIA';
}

interface EditSuccessSimulateWorkflowCriteria {
    type: 'EDIT_SUCCESS_SIMULATE_WORKFLOW_CRITERIA'
    status: number
    response: string
}

interface EditFailureSimulateWorkflowCriteria {
    type: 'EDIT_FAILURE_SIMULATE_WORKFLOW_CRITERIA';
    status: number
    error: any
}

interface ClearEditSimulateWorkflowCriteria {
    type: 'CLEAR_EDIT_SIMULATE_WORKFLOW_CRITERIA';
}
// #endregion

// #region interface DeleteSimulateWorkflowCriteria
interface DeleteSimulateWorkflowCriteria {
    type: 'DELETE_SIMULATE_WORKFLOW_CRITERIA';
}

interface DeleteSuccessSimulateWorkflowCriteria {
    type: 'DELETE_SUCCESS_SIMULATE_WORKFLOW_CRITERIA'
    status: number
    response: string
}

interface DeleteFailureSimulateWorkflowCriteria {
    type: 'DELETE_FAILURE_SIMULATE_WORKFLOW_CRITERIA';
    status: number
    error: any
}

interface ClearDeleteSimulateWorkflowCriteria {
    type: 'CLEAR_DELETE_SIMULATE_WORKFLOW_CRITERIA';
}
// #endregion

type KnownAction =
    | RequestSimulateWorkflow
    | ReceiveSimulateWorkflow
    | FailureSimulateWorkflow
    | ClearSimulateWorkflow
    | RequestSimulateWorkflowCriteria
    | ReceiveSimulateWorkflowCriteria
    | FailureSimulateWorkflowCriteria
    | ClearSimulateWorkflowCriteria
    | AddSimulateWorkflowCriteria
    | AddSuccessSimulateWorkflowCriteria
    | AddFailureSimulateWorkflowCriteria
    | ClearAddSimulateWorkflowCriteria
    | EditSimulateWorkflowCriteria
    | EditSuccessSimulateWorkflowCriteria
    | EditFailureSimulateWorkflowCriteria
    | ClearEditSimulateWorkflowCriteria
    | DeleteSimulateWorkflowCriteria
    | DeleteSuccessSimulateWorkflowCriteria
    | DeleteFailureSimulateWorkflowCriteria
    | ClearDeleteSimulateWorkflowCriteria;
    

export const actionCreators = {
    requestSimulateWorkflow: (options: RequestOptions<null, { sids?: string[], token: string[], param1?: string[], param2?: string[] }>): AppThunkAction<KnownAction> => (dispatch, _getState) => {
        const { method, params } = options;
        console.log(method);
        console.log(params);
        // console.log(localStorage.getItem('WF_APPLICATION'));

        if (method == 'GET') {
            dispatch({ type: 'REQUEST_SIMULATE_WORKFLOW' });

            const searchSids = new URLSearchParams(params?.sids!.map(sid => ['sid', sid])).toString().replace('&', '?');
            const searchParam1 = new URLSearchParams(params?.param1!.map(param1 => ['param1', param1])).toString().replace('&', '?');
            const searchParam2 = new URLSearchParams(params?.param2!.map(param2 => ['param2', param2])).toString().replace('&', '?');
            /*const searchSids = "";*/
            //const searchParam1 = "";
            //const searchParam2 = "";
            delete params?.sids;
            const searchOptional = new URLSearchParams(params as any).toString().replace(/\%2C/g, '');
            const searchTokens = params?.token.map(token => JSON.stringify({ access_token: token }));

            fetch(`/v1/simulate-workflow?${searchSids}&${searchOptional}`, {
                method,
                headers: {
                'Authorization': `Bearer ${searchTokens}`
                }
            })
            .then(response => {
                if (response.ok) {
                    return response.json();
                }

                return response.json().then(results => {
                    const errorMessage = JSON.parse(results.message).message;
                    throw new Error(errorMessage);
                });
            })
            .then(data => {
                console.log('RECEIVE_SIMULATE_WORKFLOW', data);
                dispatch({ type: 'RECEIVE_SIMULATE_WORKFLOW', data });
            })
            .catch(error => {
                console.error('FAILURE_SIMULATE_WORKFLOW:', error);
                dispatch({ type: 'FAILURE_SIMULATE_WORKFLOW', error });
            })
        } else if (method == 'CLEAR') {
            console.log('CLEAR_SIMULATE_WORKFLOW');
            dispatch({ type: 'CLEAR_SIMULATE_WORKFLOW' });
        }
    },

    requestSimulateWorkflowCriteria: (options: RequestOptions<null, { sid: string, token: string}>): AppThunkAction<KnownAction> => (dispatch, _getState) => {
        const { method, params } = options;
        console.log(method);
        console.log(params);

        if (method == 'GET') {
            dispatch({ type: 'REQUEST_SIMULATE_WORKFLOW_CRITERIA' });

            const sid = params?.sid!.toString()
            const token = params?.token.toString()

            fetch(`/v1/simulate-workflow-criteria?sid=${sid}&accessToken=${token}`, {
                method,
                headers: {
                'Authorization': `Bearer ${token}`
                },
            })
            .then(response => {
                if(response.ok){
                    return response.json();
                }

                return response.json().then(results => {
                    const errorMessage = JSON.parse(results.message).message;
                    throw new Error(errorMessage);
                })
            })
            .then(data => {
                console.log('RECEIVE_SIMULATE_WORKFLOW_CRITERIA:', data);
                dispatch({ type: 'RECEIVE_SIMULATE_WORKFLOW_CRITERIA', data });
            })
            .catch (error => {
                console.error('FAILURE_SIMULATE_WORKFLOW:', error);
                dispatch({ type: 'FAILURE_SIMULATE_WORKFLOW', error })
            });
        } else if (method == 'CLEAR') {
            console.log('CLEAR_SIMULATE_WORKFLOW');
            dispatch({ type: 'CLEAR_SIMULATE_WORKFLOW' });
        }
    },

    requestAddSimulateWorkflowCriteria: (options: RequestOptions<null, { sid: string, token: string, criteriaName: string, criteriaParam: string[]  }>): AppThunkAction<KnownAction> => (dispatch, _getState) => {
        const { method, params } = options;
        console.log(method);
        console.log(params);

        if (method === 'POST') {
            dispatch({type: 'REQUEST_SIMULATE_WORKFLOW_CRITERIA'})

            const sid = params?.sid!.toString().trim()
            const criteriaName = params?.criteriaName.toString().trim()
            const token = params?.token.toString().trim()
            const criteriaParam = params?.criteriaParam

            const unique = Math.random().toString(36).substring(2, 10);
            const timestamp = new Date().toISOString().replace(/[-:.T]/g, "").substring(0, 14);
            const criteriaCode = `${unique}${timestamp}`;
          
            const payload = {
                sid: sid!,
                criteria_code: criteriaCode,
                criteria_name: criteriaName,
                token: token,
                criteria_param: criteriaParam
            };

            // console.log(payload)

            fetch(`/v1/simulate-workflow-criteria?sid=${sid}&accessToken=${token}`, {
                method,
                headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
                },
                body: JSON.stringify(payload)
            })
            .then(response => {
                if(!response.ok){
                    return response.json().then(results => {
                        const errorMessage = JSON.parse(results.message).message;
                        throw new Error(errorMessage);
                    })
                }
                console.log('ADD_SUCCESS_SIMULATE_WORKFLOW_CRITERIA', response);
                dispatch({ type: 'ADD_SUCCESS_SIMULATE_WORKFLOW_CRITERIA', status: 200, response: 'success' });
            })
            .catch(error => {
                console.error('ADD_FAILURE_SIMULATE_WORKFLOW_CRITERIA:', error);
                dispatch({ type: 'ADD_FAILURE_SIMULATE_WORKFLOW_CRITERIA', status: 400, error });
            })
            .finally(()=>{
                console.log('ADD_SIMULATE_WORKFLOW_CRITERIA');
                dispatch({ type: 'ADD_SIMULATE_WORKFLOW_CRITERIA'});
            })
        } else if (method === 'CLEAR') {
            console.log('CLEAR_ADD_SIMULATE_WORKFLOW_CRITERIA');
            dispatch({ type: 'CLEAR_ADD_SIMULATE_WORKFLOW_CRITERIA' });
        }
    },

    requestEditSimulateWorkflowCriteria: (options: RequestOptions<null, { sid: string, token: string, criteriaCode: string,criteriaName: string, }>): AppThunkAction<KnownAction> => (dispatch, _getState) => {
        const { method, params } = options;
        console.log(method);
        console.log(params);

        if (method === 'PUT') {
            dispatch({type: 'REQUEST_SIMULATE_WORKFLOW_CRITERIA'})

            const sid = params?.sid!.toString().trim()
            const criteriaCode = params?.criteriaCode.toString().trim()
            const criteriaName = params?.criteriaName.toString().trim()
            const token = params?.token.toString().trim()

            const payload = {
                sid: sid!,
                criteria_code: criteriaCode,
                criteria_name: criteriaName,
                token: token,
            };

            // console.log(payload)

            fetch(`/v1/simulate-workflow-criteria?sid=${sid}&accessToken=${token}`, {
                method,
                headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
                },
                body: JSON.stringify(payload)
            })
            .then(response => {
                if(!response.ok){
                    return response.json().then(results => {
                        const errorMessage = JSON.parse(results.message).message;
                        throw new Error(errorMessage);
                    })
                }
                console.log('EDIT_SUCCESS_SIMULATE_WORKFLOW_CRITERIA', response);
                dispatch({ type: 'EDIT_SUCCESS_SIMULATE_WORKFLOW_CRITERIA', status: 200, response: 'success' });
            })
            .catch(error => {
                console.error('EDIT_FAILURE_SIMULATE_WORKFLOW_CRITERIA:', error);
                dispatch({ type: 'EDIT_FAILURE_SIMULATE_WORKFLOW_CRITERIA', status: 400, error });
            })
            .finally(()=>{
                console.log('EDIT_SIMULATE_WORKFLOW_CRITERIA');
                dispatch({ type: 'EDIT_SIMULATE_WORKFLOW_CRITERIA'});
            })
        } else if (method === 'CLEAR') {
            console.log('CLEAR_EDIT_SIMULATE_WORKFLOW_CRITERIA');
            dispatch({ type: 'CLEAR_EDIT_SIMULATE_WORKFLOW_CRITERIA' });
        }
    },

    requestDeleteSimulateWorkflowCriteria: (options: RequestOptions<null, { sid: string, token: string, criteriaCode: string }>): AppThunkAction<KnownAction> => (dispatch, _getState) => {
        const { method, params } = options;
        console.log(method);
        console.log(params);

        if (method === 'DELETE') {
            dispatch({type: 'REQUEST_SIMULATE_WORKFLOW_CRITERIA'})

            const sid = params?.sid!.toString().trim()
            const criteriaCode = params?.criteriaCode.toString().trim()
            const token = params?.token.toString().trim()
          
            fetch(`/v1/simulate-workflow-criteria?sid=${sid}&accessToken=${token}&criteriaCode=${criteriaCode}`, {
                method,
                headers: {
                'Authorization': `Bearer ${token}`
                },
            })
            .then(response => {
                if(!response.ok){
                    return response.json().then(results => {
                        const errorMessage = JSON.parse(results.message).message;
                        throw new Error(errorMessage);
                    })
                }
                console.log('DELETE_SUCCESS_SIMULATE_WORKFLOW_CRITERIA', response);
                dispatch({ type: 'DELETE_SUCCESS_SIMULATE_WORKFLOW_CRITERIA', status: 200, response: 'success' });
            })
            .catch(error => {
                console.error('DELETE_FAILURE_SIMULATE_WORKFLOW_CRITERIA:', error);
                dispatch({ type: 'DELETE_FAILURE_SIMULATE_WORKFLOW_CRITERIA', status: 400, error });
            })
            .finally(()=>{
                console.log('DELETE_SIMULATE_WORKFLOW_CRITERIA');
                dispatch({ type: 'DELETE_SIMULATE_WORKFLOW_CRITERIA'});
            })
        } else if (method === 'CLEAR') {
            console.log('CLEAR_DELETE_SIMULATE_WORKFLOW_CRITERIA');
            dispatch({ type: 'CLEAR_DELETE_SIMULATE_WORKFLOW_CRITERIA' });
        }
    }
}

const initialState: SimulateWorkflowStateStore = {
    isLoading: false,
    response: null,
    status: 0,
    error: null
};

const initialStateAdd: AddSimulateWorkflowCriteriaState = {
    isLoadAdd: false,
    statusAdd: 0,
    response: null,
    error: ""
};

const initialStateEdit: EditSimulateWorkflowCriteriaState = {
    isLoadEdit: false,
    statusEdit: 0,
    response: null,
    error: ""
};

const initialStateDelete: DeleteSimulateWorkflowCriteriaState = {
    isLoadDelete: false,
    statusDelete: 0,
    response: null,
    error: ""
};

const reducerSimulateWorkflow: Reducer<SimulateWorkflowStateStore> = (state = initialState, action: KnownAction) => {
    // console.log('reducerSimulateWorkflow:',state);
    switch (action.type) {
        case 'REQUEST_SIMULATE_WORKFLOW': {
            return {
                ...state,
                isLoading: true
            };
        }
        case 'RECEIVE_SIMULATE_WORKFLOW': {
            return {
                ...state,
                isLoading: false,
                response: action.data,
                status: 200
            };
        }
        case 'FAILURE_SIMULATE_WORKFLOW': {
            return {
                ...state,
                isLoading: false,
                status: 400,
                error: action.error
            };
        }
        case 'CLEAR_SIMULATE_WORKFLOW': {
            return initialState;
        }
        default: return state;
    }
}

const reducerGetSimulateWorkflowCriteria: Reducer<SimulateWorkflowStateStore> = (state = initialState, action: KnownAction) => {
    // console.log('reducerSimulateWorkflowCriteria:',state);
    switch (action.type) {
        case 'REQUEST_SIMULATE_WORKFLOW_CRITERIA': {
            return {
                ...state,
                isLoading: true
            };
        }
        case 'RECEIVE_SIMULATE_WORKFLOW_CRITERIA': {
            return {
                ...state,
                response: action.data,
                isLoading: false,
                status: 200
            };
        }
        case 'FAILURE_SIMULATE_WORKFLOW_CRITERIA': {
            return {
                ...state,
                isLoading: true,
                status: 400,
                error: action.error
            };
        }
        case 'CLEAR_SIMULATE_WORKFLOW_CRITERIA': {
            return initialState;
        }
        default: return state;
    }

}

const reducerAddSimulateWorkflowCriteria: Reducer<AddSimulateWorkflowCriteriaState> = (state = initialStateAdd, action: KnownAction) => {
    // console.log('reducerAddSimulateWorkflowCriteria:',state);
    switch (action.type) {
        case 'ADD_SIMULATE_WORKFLOW_CRITERIA': {
            return {
                isLoadAdd: false
            };
        }
        case 'ADD_SUCCESS_SIMULATE_WORKFLOW_CRITERIA':
            console.log(action)
            return {
                isLoadAdd: true,
                statusAdd: action.status,
                response: action.response
            }
        case 'ADD_FAILURE_SIMULATE_WORKFLOW_CRITERIA': {
            return {
                isLoadAdd: true,
                statusAdd: action.status,
                error: action.error
            };
        }
        case 'CLEAR_ADD_SIMULATE_WORKFLOW_CRITERIA': {
            return {
                isLoadAdd: false,
                error: '',
                statusAdd: 400
            }
        }
        default: return state;
    }
}

const reducerEditSimulateWorkflowCriteria: Reducer<EditSimulateWorkflowCriteriaState> = (state = initialStateEdit, action: KnownAction) => {
    // console.log('reducerAddSimulateWorkflowCriteria:',state);
    switch (action.type) {
        case 'EDIT_SIMULATE_WORKFLOW_CRITERIA': {
            return {
                isLoadEdit: false
            };
        }
        case 'EDIT_SUCCESS_SIMULATE_WORKFLOW_CRITERIA':
            console.log(action)
            return {
                isLoadEdit: true,
                statusEdit: action.status,
                response: action.response
            }
        case 'EDIT_FAILURE_SIMULATE_WORKFLOW_CRITERIA': {
            return {
                isLoadEdit: true,
                statusEdit: action.status,
                error: action.error
            };
        }
        case 'CLEAR_EDIT_SIMULATE_WORKFLOW_CRITERIA': {
            return {
                isLoadEdit: false,
                error: '',
                statusEdit: 400
            }
        }
        default: return state;
    }
}

const reducerDeleteSimulateWorkflowCriteria: Reducer<DeleteSimulateWorkflowCriteriaState> = (state = initialStateDelete, action: KnownAction) => {
    // console.log('reducerAddSimulateWorkflowCriteria:',state);
    switch (action.type) {
        case 'DELETE_SIMULATE_WORKFLOW_CRITERIA': {
            return {
                isLoadDelete: false
            };
        }
        case 'DELETE_SUCCESS_SIMULATE_WORKFLOW_CRITERIA':
            console.log(action)
            return {
                isLoadDelete: true,
                statusDelete: action.status,
                response: action.response
            }
        case 'DELETE_FAILURE_SIMULATE_WORKFLOW_CRITERIA': {
            return {
                isLoadDelete: true,
                statusDelete: action.status,
                error: action.error
            };
        }
        case 'CLEAR_DELETE_SIMULATE_WORKFLOW_CRITERIA': {
            return {
                isLoadDelete: false,
                error: '',
                statusDelete: 400
            }
        }
        default: return state;
    }
}

export const reducer = combineReducers({ SimulateWorkflow: reducerSimulateWorkflow, SimulateWorkflowCriteria: reducerGetSimulateWorkflowCriteria, AddCriteria: reducerAddSimulateWorkflowCriteria, EditCriteria: reducerEditSimulateWorkflowCriteria, DeleteCriteria: reducerDeleteSimulateWorkflowCriteria });