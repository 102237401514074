import { Action, Reducer } from 'redux';
import { AppThunkAction } from '..';

import * as Models from '../../models/workflow/ApproveRejectWorkflowAdmin'

interface ApproveWorkflowAdmin {
    type: 'APPROVE_WORKFLOWADMIN'
    response: Models.ResponseWorkflowAdminApprove
    statusResponse: Number,
    statusMessage: String
}

interface ApproveWorkflowAdminFail {
    type: 'APPROVE_WORKFLOWADMIN_FAIL'
    statusResponse: number,
    statusMessage: any
}

interface ClearApproveWorkflowAdmin {
    type: 'CLEAR_APPROVE_WORKFLOWADMIN'
    statusResponse: number,
    statusMessage: any
}

interface RejectWorkflowAdmin {
    type: 'REJECT_WORKFLOWADMIN'
    response: Models.ResponseWorkflowAdminApprove
    statusResponse: Number,
    statusMessage: String
}

interface RejectWorkflowAdminFail {
    type: 'REJECT_WORKFLOWADMIN_FAIL'
    statusResponse: number,
    statusMessage: any
}

interface ClearRejectWorkflowAdmin {
    type: 'CLEAR_REJECT_WORKFLOWADMIN'
    statusResponse: number,
    statusMessage: any
}

type KnownAction = ApproveWorkflowAdmin | ApproveWorkflowAdminFail | RejectWorkflowAdmin | RejectWorkflowAdminFail | ClearApproveWorkflowAdmin | ClearRejectWorkflowAdmin

export const actionCreators = {
    requestApproveWorkflowAdmin: (p: boolean, method: String, userId: String, docNo: string, message: string, wf_type: string): AppThunkAction<KnownAction> => async (dispatch, getState) => {
        const appState = getState();
        console.log('call actionCreators : requestApproveWorkflowAdmin')
        if (appState && appState.approveRejectWorkflowAdmin && p && method == 'PUT') {
            var body = {
                'app_id': 'abcd',
                'user_id': userId,
                'doc_no': docNo,
                'message': message,
                'wf_type': wf_type
            }
            console.log(body)
            await fetch(`workflow/approve`, {
                method: 'PUT',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + localStorage.getItem('WF_APPLICATION'),
                },
                body: JSON.stringify(body),
            })
                .then(response => {
                    return response
                })
                .then((data) => {
                    console.log(data)
                    if (!data.ok) {
                        data.json().then(err => {
                            //console.log(err.message_res)
                            console.log('dispatch : APPROVE_WORKFLOWADMIN_FAIL')
                            dispatch({ type: 'APPROVE_WORKFLOWADMIN_FAIL', statusResponse: 300, statusMessage: err.message });
                        })
                    } else {
                        data.json().then(data => {
                            //console.log(data)
                            dispatch({ type: 'APPROVE_WORKFLOWADMIN', response: data as Models.ResponseWorkflowAdminApprove, statusResponse: 200, statusMessage: '' });
                        })
                    }
                })
        } else if (method == "CLEAR") {
            console.log('dispatch : CLEAR_APPROVE_WORKFLOWADMIN')
            dispatch({ type: 'CLEAR_APPROVE_WORKFLOWADMIN', statusResponse: 400, statusMessage: "" });
        }
    },
    requestRejectWorkflow: (p: boolean, method: String, userId: String, docNo: string, message: string, wf_type: string): AppThunkAction<KnownAction> => async (dispatch, getState) => {
        const appState = getState();
        console.log('call actionCreators : requestRejectWorkflow')
        if (appState && appState.approveRejectWorkflowAdmin && p && method == 'PUT') {
            var body = {
                'app_id': 'abc',
                'user_id': userId,
                'doc_no': docNo,
                'message': message,
                'wf_type': wf_type
            }
            console.log(body)
            await fetch(`/workflow/reject`, {
                method: 'PUT',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + localStorage.getItem('WF_APPLICATION'),
                },
                body: JSON.stringify(body),
            })
                .then(response => {
                    return response
                })
                .then((data) => {
                    console.log(data)
                    if (!data.ok) {
                        data.json().then(err => {
                            //console.log(err.message_res)
                            console.log('dispatch : REJECT_WORKFLOWADMIN_FAIL')
                            dispatch({ type: 'REJECT_WORKFLOWADMIN_FAIL', statusResponse: 400, statusMessage: err.message });
                        })
                    } else {
                        data.json().then(data => {
                            //console.log(data)
                            dispatch({ type: 'REJECT_WORKFLOWADMIN', response: data as Models.ResponseWorkflowAdminApprove, statusResponse: 200, statusMessage: '' });
                        })
                    }
                })
        } else if (method == "CLEAR") {
            console.log('dispatch : CLEAR_REJECT_WORKFLOWADMIN')
            dispatch({ type: 'CLEAR_REJECT_WORKFLOWADMIN', statusResponse: 400, statusMessage: "" });
        }
    },
    requestApproveWorkflowList: (p: boolean, method: String, wfList: any, message: string): AppThunkAction<KnownAction> => async (dispatch, getState) => {
        const appState = getState();
        console.log('call actionCreators : requestApproveWorkflow')
        if (appState && appState.approveRejectWorkflowAdmin && p && method == 'PUT') {
            var tasks: any = [];
            var finalResult: any;

            var body = wfList

            fetch(`v3/workflowdashboard/approve`, {
                method: 'PUT',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + localStorage.getItem('WF_APPLICATION'),
                },
                body: JSON.stringify(body),
            })
                .then((response) => {
                    return response
                })
                .then((data) => {
                    console.log(data)
                    if (!data.ok) {
                        data.json().then(err => {
                            console.log(err.message)
                            dispatch({ type: 'APPROVE_WORKFLOWADMIN_FAIL', statusResponse: 204, statusMessage: err.message });
                        })
                        .catch((error) => {
                            dispatch({ type: 'APPROVE_WORKFLOWADMIN_FAIL', statusResponse: 204, statusMessage: 'เกิดข้อผิดพลาด <br> กรุณาปิดหน้าต่าง Workflow แล้วทำรายการใหม่อีกครั้ง' });
                        });
                    } else {
                        data.json().then(data => {
                            console.log(data)
                            dispatch({ type: 'APPROVE_WORKFLOWADMIN', response: data, statusResponse: 200, statusMessage: '' });
                        })
                    }
                });

        } else if (method == "CLEAR") {
            console.log('dispatch : CLEAR_APPROVE_WORKFLOWADMIN')
            dispatch({ type: 'CLEAR_APPROVE_WORKFLOWADMIN', statusResponse: 400, statusMessage: "" });
        }
    },
    requestRejectWorkflowList: (p: boolean, method: String, wfList: any, message: string): AppThunkAction<KnownAction> => async (dispatch, getState) => {
        const appState = getState();
        console.log('call actionCreators : requestRejectWorkflow')
        console.log(appState)
        console.log(appState.approveRejectWorkflowAdmin)
        console.log(p)
        console.log(method)
        if (appState && appState.approveRejectWorkflowAdmin && p && method == 'PUT') {
            var tasks: any = [];
            var finalResult: any;

            var body = wfList
            console.log(JSON.stringify(body))

            fetch(`v3/workflow/report-admin/force-reject`, {
                method: 'PUT',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + localStorage.getItem('WF_APPLICATION'),
                },
                body: JSON.stringify(body),
            })
                .then((response) => {
                    return response
                })
                .then((data) => {
                    console.log(data)
                    if (!data.ok) {
                        data.json().then(err => {
                            console.log(err.message)
                            dispatch({ type: 'REJECT_WORKFLOWADMIN_FAIL', statusResponse: 204, statusMessage: err.message });
                        })
                        .catch((error) => {
                            dispatch({ type: 'REJECT_WORKFLOWADMIN_FAIL', statusResponse: 204, statusMessage: 'เกิดข้อผิดพลาด <br> กรุณาปิดหน้าต่าง Workflow แล้วทำรายการใหม่อีกครั้ง' });
                        });
                    } else {
                        data.json().then(data => {
                            console.log(data)
                            dispatch({ type: 'REJECT_WORKFLOWADMIN', response: data, statusResponse: 200, statusMessage: '' });
                        })
                    }
                });

        } else if (method == "CLEAR") {
            console.log('dispatch : CLEAR_REJECT_WORKFLOWADMIN')
            dispatch({ type: 'CLEAR_REJECT_WORKFLOWADMIN', statusResponse: 400, statusMessage: "" });
        }
    }
}

const unloadedState: Models.ApproveRejectWorkflowAdminState = { isLoadApproveReject: false };

export const reducer: Reducer<Models.ApproveRejectWorkflowAdminState> = (state: Models.ApproveRejectWorkflowAdminState | undefined, incomingAction: Action): Models.ApproveRejectWorkflowAdminState => {
    if (state == undefined) {
        return unloadedState;
    }
    const action = incomingAction as KnownAction;
    switch (action.type) {
        case 'APPROVE_WORKFLOWADMIN':
            return {
                isLoadApproveReject: true,
                responseApproveReject: action.response,
                statusResponseApproveReject: action.statusResponse

            }
        case 'APPROVE_WORKFLOWADMIN_FAIL':
            return {
                isLoadApproveReject: true,
                messageResponseApproveReject: action.statusMessage,
                statusResponseApproveReject: 400
            }
        case 'REJECT_WORKFLOWADMIN':
            return {
                isLoadApproveReject: true,
                responseApproveReject: action.response,
                statusResponseApproveReject: action.statusResponse
            }
        case 'REJECT_WORKFLOWADMIN_FAIL':
            return {
                isLoadApproveReject: true,
                messageResponseApproveReject: action.statusMessage,
                statusResponseApproveReject: 400
            }
        case 'CLEAR_APPROVE_WORKFLOWADMIN':
            return {
                isLoadApproveReject: false,
                messageResponseApproveReject: "",
                statusResponseApproveReject: 0
            }
        case 'CLEAR_REJECT_WORKFLOWADMIN':
            return {
                isLoadApproveReject: false,
                messageResponseApproveReject: "",
                statusResponseApproveReject: 0
            }
        default: return state;
    }
}