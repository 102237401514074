import React from 'react';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';

import { Button } from 'common/components';
import { WorkflowInbox } from 'models/workflow/WorkflowInbox';
import { UserInformation } from 'models/auth/SignIn';
import OnlineFormInformation from 'components/workflow/OnlineFormInformation';

type Props = {
    accessToken: string;
    auth: UserInformation;
    isOpen?: boolean;
    selectedDocument?: WorkflowInbox;
    toggle?: () => void;
    toggleConfirmModal?: (action: DocumentAction) => void;
};

function OnlineFormInformationModal(props: Props) {
    const {
        isOpen,
        selectedDocument,
        accessToken,
        auth,
        toggle,
        toggleConfirmModal
    } = props;

    return (
        <Modal
            isOpen={isOpen}
            toggle={toggle}
            className="h-[90%] w-[98%] max-w-[1920px]"
        >
            <ModalHeader toggle={toggle}>
                <p className="text-base font-semibold">
                    {selectedDocument?.subject}
                </p>
                <p>
                    <span className="text-neutral-500">DOCUMENT NO</span>{' '}
                    <span className="text-[#3085d6]">
                        {selectedDocument?.docNo}
                    </span>
                </p>
            </ModalHeader>
            <ModalBody>
                <OnlineFormInformation
                    noCheckParams
                    accessToken={accessToken}
                    auth={auth}
                    toggleConfirmModal={toggleConfirmModal}
                    document={selectedDocument}
                />
            </ModalBody>
            <ModalFooter>
                <Button
                    onClick={toggle}
                    className="w-[110px] justify-center"
                >
                    Close
                </Button>
            </ModalFooter>
        </Modal>
    );
}

export default OnlineFormInformationModal;
