import React, { useState } from 'react';
import { Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import { Col, Form, Row } from 'react-bootstrap';

import { Button } from 'common/components';

type Props = {
    isOpen?: boolean;
    toggle?: () => void;
    onClickConfirm?: (confirmMessage: string) => void;
};

function ApprovalConfirmationModal(props: Props) {
    const { isOpen, toggle, onClickConfirm } = props;

    const [message, setMessage] = useState('');

    return (
        <Modal
            isOpen={isOpen}
            toggle={toggle}
            size="lg"
            backdrop="static"
            keyboard={false}
        >
            <ModalHeader
                toggle={toggle}
                className="mx-6 p-0 pb-2 pt-4 text-lg font-semibold text-neutral-500"
            >
                Message
            </ModalHeader>
            <ModalBody className="mx-6 px-4 pb-0 pt-4">
                <Row>
                    <Col>
                        <Form.Group>
                            <Form.Label className="text-neutral-500">
                                Type something
                            </Form.Label>
                            <Form.Control
                                as="textarea"
                                rows={5}
                                onChange={event =>
                                    setMessage(event.target.value)
                                }
                                className="border-zinc-300"
                            />
                        </Form.Group>
                    </Col>
                </Row>
            </ModalBody>
            <ModalFooter className="mx-6 border-none p-0 pb-4">
                <Button
                    className="w-[110px] justify-center"
                    onClick={toggle}
                >
                    Cancel
                </Button>
                <Button
                    className="w-[110px] justify-center border-0 bg-[#269E41] text-white"
                    onClick={() => onClickConfirm && onClickConfirm(message)}
                >
                    Confirm
                </Button>
            </ModalFooter>
        </Modal>
    );
}

export default ApprovalConfirmationModal;
