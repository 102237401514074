import * as React from "react";
import { connect } from "react-redux";
import { Button, Table, Spinner } from "react-bootstrap";
import { FaEdit, FaTimesCircle } from 'react-icons/fa';
import { Link } from "react-router-dom";
// import ReactDatatable  from '@ashvin27/react-datatable';
import DataTable from 'react-data-table-component';
import Swal from 'sweetalert2'
// 
import * as WorkflowStore from '../../store/workflow/WorkflowConfig'
import * as Models from '../../models/workflow/WorkflowConfig'

import * as DeleteWorkflowStore from '../../store/workflow/DeleteWorkflow'
import * as DeleteWorkflowModels from '../../models/workflow/DeleteWorkflow'

import { ApplicationState } from "../../store";

type groupProps = Models.GetWorkflowState & typeof WorkflowStore.actionCreators;
type deleteWorkflowProps = DeleteWorkflowModels.DeleteWorkflowState & typeof DeleteWorkflowStore.actionCreators;

class WorkflowTable extends React.Component<any, { response: any, isready: boolean, delete: boolean, CheckresponseSearch: boolean, company?: string, keyReset: boolean, roleid: string, empid: string, authority_group: string, workflowid: string, workflowName: string }, deleteWorkflowProps> {

    constructor(props: any) {
        super(props);
        this.state = {
            response: "",
            isready: true,
            delete: false,
            CheckresponseSearch: true,
            company: undefined,
            keyReset: false,
            roleid: "",
            empid: "",
            authority_group: "",
            workflowid: "",
            workflowName: ""
        }
    }

    componentDidMount() {
        //console.log(this.state.isready)
        //var company = this.props.company
        //if (company != this.state.company) {
        //    if (company) {
        //        this.props.requestWorkflowConfig(this.state.isready, "POST", company, this.state.roleid, this.state.empid, this.state.authority_group, this.state.workflowid);
        //    }

        //    this.setState({
        //        company: company,
        //        isready: false
        //    });
        //}
    }

    componentDidUpdate(nextProps: any, nextState: any) {
        const { responseSearch, statusDeleteWorkflowMaste, isLoadWorkflowMaste, isLoadDeleteWorkflowMaste } = this.props
        if (this.state.keyReset != this.props.keyReset) {
            if (this.props.keyReset) {
                this.props.callbackReset()
                this.setState({
                    roleid: this.props.roleid,
                    empid: this.props.empid,
                    authority_group: this.props.authority_group,
                    workflowid: this.props.workflowid,
                    workflowName: this.props.workflowName,
                    company: this.props.company
                })
                this.props.requestWorkflowConfig(true, "POST", this.props.company, this.props.roleid, this.props.empid, this.props.authority_group, this.props.workflowid);
            }
        } else {
            var company = this.props.company
            if (this.props.company != this.state.company) {

                if (company) {
                    this.props.requestWorkflowConfig(true, "POST", this.props.company, "", "", "", "");
                }
                this.props.callbackResetSearch()
                this.setState({
                    company: company,
                });
            }
        }

        if (isLoadDeleteWorkflowMaste && statusDeleteWorkflowMaste == 200) {
            document.getElementsByClassName('Loader__background')[0].className = "Loader__background hide";
            console.log('delete : ' + this.props.statusDelete)
            this.props.requestdeleteWorkflow(true, "CLEAR", '', this.state.company)
            Swal.fire({
                title: 'สำเร็จ!',
                text: 'ลบข้อมูลสำเร็จ',
                icon: 'success',
                showCancelButton: false,
                confirmButtonColor: '#3085d6',
                confirmButtonText: 'ยืนยัน!'
            }).then((result) => {
                if (result.value) {
                    console.log("reload table")
                    this.props.requestWorkflowConfig(true, "POST", this.state.company, this.state.roleid, this.state.empid, this.state.authority_group, this.state.workflowid);
                }
            })
            this.setState({
                delete: false
            })
        } else if (isLoadDeleteWorkflowMaste && statusDeleteWorkflowMaste != 200) {
            document.getElementsByClassName('Loader__background')[0].className = "Loader__background hide";
            console.log('delete : ' + this.props.statusDelete)
            this.props.requestdeleteWorkflow(true, "CLEAR", '', this.state.company)
            Swal.fire({
                title: 'ไม่สำเร็จ!',
                text: 'ลบข้อมูลไม่สำเร็จ',
                icon: 'error',
                showCancelButton: false,
                confirmButtonColor: '#3085d6',
                confirmButtonText: 'ยืนยัน!'
            }).then((result) => {
                if (result.value) {
                    console.log("reload table")
                    this.props.requestWorkflowConfig(true, "POST", this.state.company, this.state.roleid, this.state.empid, this.state.authority_group, this.state.workflowid);
                }
            })
            this.setState({
                delete: false
            })
        }
    }

    ondelete = (wfid: any) => {
        console.log("Delete :" + wfid)
        Swal.fire({
            title: 'ลบข้อมูล ?',
            text: 'คุณต้องการลบหรือไม่ ?',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'ใช่',
            cancelButtonText: 'ไม่'
        }).then((result) => {
            if (result.value) {
                this.props.requestdeleteWorkflow(true, "POST", wfid, this.state.company)
                document.getElementsByClassName('Loader__background')[0].className = "Loader__background show";
                this.setState({
                    delete: true
                })
            }
        })
    }

    formatDMY = (dmy: string) => {

        var year = dmy.substring(0, 4);
        var month = dmy.substring(4, 6);
        var day = dmy.substring(6, 8);
        var Datevalue = "";
        if (dmy.length < 9) {
            Datevalue = year + '-' + month + '-' + day
        } else {
            var hour = dmy.substring(8, 10);
            var second = dmy.substring(10, 12);
            var millssecond = dmy.substring(12, 14);
            Datevalue = year + '-' + month + '-' + day + ' ' + hour + ':' + second + ':' + millssecond
        }

        return Datevalue;
    }

    loading() {
        document.getElementsByClassName('Loader__background')[0].className = "Loader__background show";
    }

    render() {
        const { responseSearch, statusDeleteWorkflowMaste, isLoadDeleteWorkflowMaste } = this.props

        return (
            <div style={{ overflowY: 'scroll', maxHeight: '720px' }}>
                <Table className="table-bordered align-middle" size="sm">
                    <thead className='thead-light'>
                        <tr style={{ width: '100%' }} className="text-center table-active">
                            <th style={{ width: '8%' }}></th>
                            <th style={{ width: '20%' }}>Workflow ID</th>
                            <th style={{ width: '22%' }}>Workflow Name</th>
                            <th style={{ width: '10%' }}>Active</th>
                            <th style={{ width: '10%' }}>Create By</th>
                            <th style={{ width: '10%' }}>Create On</th>
                            <th style={{ width: '10%' }}>Update By</th>
                            <th style={{ width: '10%' }}>Update On</th>
                        </tr>
                    </thead>
                    <tbody>
                        {!this.props.isLoadWorkflowMaster ? <tr><td colSpan={8} style={{ background: "rgba(209, 250, 221, 1)", color: "black", fontSize: '10pt', textAlign: "center" }}><Spinner animation="border" size="sm" />&nbsp;&nbsp;Loading...</td></tr> : <tr></tr>}
                        {this.props.statusWorkflowMaste == 200 && this.props.isLoadWorkflowMaster && this.props.responseWorkflowMaste['result_list'] == null ? <tr><td colSpan={8} style={{ background: "#b7b7a4", color: "black", fontSize: '10pt', textAlign: "center" }}>&nbsp;&nbsp;No Data...</td></tr> : <tr></tr>}
                        {this.props.statusWorkflowMaste == 200 && this.props.isLoadWorkflowMaster && this.props.responseWorkflowMaste['result_list'] != null && this.props.responseWorkflowMaste['result_list'].map((item: Models.WorkflowConfig) =>
                            <>
                                {(!this.state.workflowName || (this.state.workflowName && item.wfname && item.wfname.toLocaleLowerCase().includes(this.state.workflowName.toLocaleLowerCase()))) &&
                                    <tr key={item.wfid}>
                                        <td className="text-center">
                                            <Link to={{ pathname: '/addworkflow', state: { wfid: item.wfid, mode: "Edit" } }}>
                                                <Button variant="outline-info" onClick={() => this.loading()} size="sm"><FaEdit /></Button>
                                            </Link>
                                            <Button className="mx-1" variant="outline-danger" size="sm" onClick={() => this.ondelete(item.wfid)}><FaTimesCircle /></Button>
                                        </td>
                                        <td>{item.wfid}</td>
                                        <td>{item.wfname}</td>
                                        <td className="text-center">{item.active}</td>
                                        <td className="text-center">{item.created_by}</td>
                                        <td>{this.formatDMY(String(item.created_on))}</td>
                                        <td className="text-center">{item.updated_by}</td>
                                        <td>{this.formatDMY(String(item.updated_on))}</td>
                                    </tr>
                                }
                            </>
                        )}
                    </tbody>
                </Table>
            </div>
        );
    }
}

export default connect((state: ApplicationState) => ({ ...state.appState, ...state.workflowConfig, ...state.WfWithWfType, ...state.deleteWorkflow }), ({ ...WorkflowStore.actionCreators, ...DeleteWorkflowStore.actionCreators }))(WorkflowTable);
