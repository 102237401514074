import * as React from "react";
import { CardBody, Container, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import { ApplicationState } from "../../store";
import { Button, Card, Col, Form, Row, Table } from "react-bootstrap";
import { FaCheck, FaTimesCircle, FaCheckCircle } from "react-icons/fa";
import * as ModelsWorkflowInbox from '../../models/workflow/WorkflowInbox';
import * as ModelSearchWorkflowInbox from '../../models/workflow/SearchWorkflowInbox';
import * as ModelApproveRejectWorkflow from '../../models/workflow/ApproveRejectWorkflow';
import SignInComponent from '../auth/SignInComponent';
import { connect } from "react-redux";
import * as ApproveRejectWorkflowStore from '../../store/workflow/ApproveRejectWorkflow';
import * as SearchWorkflowInboxStore from '../../store/workflow/SearchWorkflowInbox';
import ReportInformationMobileTable from "../mobiletable/ReportInformationMobileTable";
import BorderGrid from '../../common/BorderGrid';
import { ViewportContext } from '../../context/ViewportProvider';

type align_ = "left" | "center" | "right";

interface State {
    isLoading: boolean,
    src: string,
    JSONReport_List: ModelsWorkflowInbox.JSONReportList,
    userId: string,
    docNoList: any,
    successModalHeader: string,
    messageModal: boolean,
    modeApproveReject: string,
    workflowValueUse: ModelsWorkflowInbox.WorkflowValue,
    formData: Array<ModelSearchWorkflowInbox.ResponseWorkflowInboxValue>,
    message: string,
    successModal: boolean,
    checkurl: any,
    controlRedirect: boolean,
    checkapprove: boolean,
    loginModal: boolean,
    srcLogin: any,
    srcHistory: any,
    width: number,
    heigth: number,
}

class ReportInformationMobile extends React.Component<any, State>{
    public static contextType = ViewportContext;

    constructor(props: any) {
        super(props);
        this.state = {
            isLoading: true,
            src: "",
            JSONReport_List: {
                DataHeader: {
                    column: []
                },
                ListDataItem: []
            },
            userId: "",
            docNoList: [],
            successModalHeader: "",
            messageModal: false,
            modeApproveReject: "",
            workflowValueUse: {
                wfId: "",
                docNo: "",
                wfInstanceNo: "",
                userId: "",
                token: "",
                sid: '',
                appId: ''
            },
            formData: [],
            message: "",
            successModal: false,
            checkurl: "",
            controlRedirect: false,
            checkapprove: true,
            loginModal: false,
            srcLogin: "",
            srcHistory: "",
            width: window.innerWidth,
            heigth: window.innerHeight,
        }

        this.blindLogin = this.blindLogin.bind(this);
    }

    componentDidMount() {
        const { JSONReportList } = this.props
        const { isLoading } = this.state

        if (JSONReportList != undefined && isLoading) {
            this.setState({
                isLoading: false
            })
        }

        //window.addEventListener('resize', this.handleResize);
    }

    componentDidUpdate(nextProps: any, nextState: any) {
        const { JSONReportList } = this.props
        const { isLoading } = this.state

        if (JSONReportList != undefined && isLoading && JSONReportList.ListDataItem != undefined) {
            this.setState({
                isLoading: false
            })
        }

        window.addEventListener('resize', this.handleResize);
    }

    public blindLogin(value: any) {
        //console.log(value)
        if (value == true && this.state.loginModal) {
            window.location.href = "" + this.state.srcHistory + ""
            this.setState({
                loginModal: false
            })
        }
    }

    handleResize = () => {
        this.setState({
            width: window.innerWidth
        })
    }

    handleMessage = () => {
        if (this.state.messageModal) {
            this.setState({
                messageModal: false,
                modeApproveReject: "",
                message: "",
                docNoList: []
            })
        } else {
            this.setState({
                messageModal: true
            })
        }
    }

    handleSuccessModal = () => {
        const { workflowValueUse } = this.state
        if (this.state.successModal) {
            this.props.requestApproveWorkflow(true, "CLEAR", workflowValueUse.userId, workflowValueUse.docNo, "");
            //this.props.requestsearchworkflowInbox(true, "CLEAR", workflowValueUse.wfId, workflowValueUse.docNo, workflowValueUse.userId, workflowValueUse.wfInstanceNo)
            this.props.refreshPageChild()
            if (this.state.checkurl == "No_URL") {
                this.props.blindopenModal("reload")
            } else {
                this.setState({
                    controlRedirect: true
                })
            }
            this.setState({
                successModal: false,
                checkapprove: true,
                successModalHeader: ""
            })
        } else {
            this.setState({
                successModal: true,
                successModalHeader: ""
            })
        }
    }

    handleLoginModal = () => {
        if (this.state.loginModal) {
            this.setState({
                loginModal: false,
            })
        } else {
            this.setState({
                loginModal: true
            })
        }
    }

    onchangeMessage = (e: { target: { name: any; value: any; }; }) => {
        this.setState({
            message: e.target.value
        })
        setTimeout(() => {
            console.log(this.state.message)
        }, 500);
    }

    onsuccessApproveReject = (docNo: string, userId: string, wfType: string) => {
        document.getElementsByClassName('Loader__background')[0].className = "Loader__background show";

        var messagePush: any = []
        messagePush = this.state.docNoList
        for (let i = 0; i < messagePush.length; i++) {
            messagePush[i]['message'] = this.state.message
        }
        this.setState({
            docNoList: messagePush
        })
        //console.log(this.state.docNoList);

        if (this.state.modeApproveReject == "approved") {
            //this.props.requestApproveWorkflow(true, "PUT", userId, docNo, this.state.message, wfType);
            this.props.requestApproveWorkflowList(true, "PUT", this.state.docNoList);
        } else {
            //this.props.requestRejectWorkflow(true, "PUT", userId, docNo, this.state.message, wfType);
            this.props.requestRejectWorkflowList(true, "PUT", this.state.docNoList);
        }
    }

    handleApprove = (wfInstanceNo: string, docNo: string, userId: string, wfType: string, sid: string) => {
        console.log(wfInstanceNo + " | " + docNo + " | " + userId + " | " + wfType)
        var wfUse: any = []
        var wfins = {
            app_id: sid,
            user_id: userId,
            admin_user_id: this.state.userId,
            wf_type: wfType,
            doc_no_list: [docNo],
            instance_no_list: [wfInstanceNo]
        }
        wfUse.push(wfins)
        console.log(wfins)
        this.setState({
            docNoList: wfUse,
            successModalHeader: "Approved Status"
        })
        document.getElementsByClassName('Loader__background')[0].className = "Loader__background show";
        setTimeout(() => {
            document.getElementsByClassName('Loader__background')[0].className = "Loader__background hide";
            this.setState({
                messageModal: true,
                modeApproveReject: "approved"
            })
        }, 500)

        //this.props.requestApproveWorkflow(true, "PUT", userId, docNo, "", wfType);
    }

    handleReject = (wfInstanceNo: string, docNo: string, userId: string, wfType: string, sid: string) => {
        console.log(wfInstanceNo + " | " + docNo + " | " + userId + " | " + wfType)
        var wfUse: any = []
        var wfins = {
            app_id: sid,
            user_id: userId,
            admin_user_id: this.state.userId,
            wf_type: wfType,
            doc_no_list: [docNo],
            instance_no_list: [wfInstanceNo]
        }
        wfUse.push(wfins)
        this.setState({
            docNoList: wfUse,
            successModalHeader: "Rejected Status"
        })
        document.getElementsByClassName('Loader__background')[0].className = "Loader__background show";
        setTimeout(() => {
            document.getElementsByClassName('Loader__background')[0].className = "Loader__background hide";
            this.setState({
                messageModal: true,
                modeApproveReject: "reject"
            })
        }, 500)
        //this.props.requestRejectWorkflow(true, "PUT", userId, docNo, "", wfType);
    }

    render() {
        const { JSONReportList } = this.props
        const { workflowValueUse } = this.state

        const tableFooter = {
            //width: '100%',
            backgroundColor: '#1473E6',
            color: '#FFFFFF'
        }

        const breakpoint = 768; // change this if enable table responsesive
        //console.log("width now =", this.state.width)

        const { width, heigth } = this.context;
        //console.log("width now =", width)

        return (
            <div>
                <Container fluid={true} className="mt-3">
                    <Row>
                        <Col>
                            <Button
                                variant="outline-success mx-1"
                                size="sm"
                                onClick={() => this.handleApprove(workflowValueUse.wfInstanceNo, workflowValueUse.docNo, workflowValueUse.userId, this.state.formData['wfType'], workflowValueUse.appId ? workflowValueUse.appId : '')}>
                                <FaCheck />&nbsp;Approve
                            </Button>
                            <Button
                                variant="outline-danger mx-1"
                                size="sm"
                                onClick={() => this.handleReject(workflowValueUse.wfInstanceNo, workflowValueUse.docNo, workflowValueUse.userId, this.state.formData['wfType'], workflowValueUse.appId ? workflowValueUse.appId : '')}>
                                <FaTimesCircle />&nbsp;Reject
                            </Button>
                        </Col>
                    </Row>

                    {JSONReportList.DataHeader.row == undefined
                        ?<Row className="mt-3">
                            <Col>
                                <Card>
                                    <Card.Body>
                                        {JSONReportList.DataHeader.column.length == 0 &&
                                            <Row>
                                                <Col>
                                                    <div className="text-center">
                                                        <label>ไม่พบข้อมูล..</label>
                                                    </div>
                                                </Col>
                                            </Row>
                                        }
                                        <Row>
                                            <BorderGrid>
                                                {JSONReportList.DataHeader.column.length != 0 &&
                                                    JSONReportList.DataHeader.column
                                                        .map((data: ModelsWorkflowInbox.ColoumList, index) => {
                                                            //const isLeft = index % 2 === 0;
                                                            //const isTop = index === 0 || index === 1;
                                                            //const styles =
                                                            //    (isLeft)
                                                            //        ? `border-left border-bottom border-right`
                                                            //        : `border-right border-bottom`

                                                            return (
                                                                //<React.Fragment key={index}>
                                                                //    <Col sm={6} className={`${styles} ${isTop && 'border-top'}`}>
                                                                //        <div>
                                                                //            <label>{data.label}</label>
                                                                //        </div>
                                                                //        <div>
                                                                //            <b>{data.value}</b>
                                                                //        </div>
                                                                //    </Col>
                                                                //</React.Fragment>

                                                                <BorderGrid.Row key={index}>
                                                                    <BorderGrid.Col>
                                                                        <div>
                                                                            <label>{data.label}</label>
                                                                        </div>
                                                                        <div>
                                                                            <b>{data.value}</b>
                                                                        </div>
                                                                    </BorderGrid.Col>
                                                                </BorderGrid.Row>
                                                            )
                                                        })
                                                }
                                            </BorderGrid>
                                        </Row>
                                    </Card.Body>
                                </Card>
                            </Col>
                        </Row>

                        :<Row>
                            <Col>
                                <Card>
                                    <Card.Body>
                                        {JSONReportList.DataHeader.row.length == 0 &&
                                            <Row>
                                                <Col>
                                                    <div className="text-center">
                                                        <label>ไม่พบข้อมูล..</label>
                                                    </div>
                                                </Col>
                                            </Row>
                                        }
                                        {JSONReportList.DataHeader.row.length != 0 &&
                                            <Row>
                                                <Col lg={12}>
                                                    {JSONReportList.DataHeader.row.map((data: ModelsWorkflowInbox.DataHeader, idx) => {
                                                        return (
                                                            <Row key={idx} className="mt-3">
                                                                {data.column.map((item: ModelsWorkflowInbox.ColoumList, ids) => {
                                                                    return (
                                                                        <Col key={ids} lg="3" className="table-hover">
                                                                            <Row>
                                                                                <Col lg="5" className="font-weight-bold bg-light text-dark">{item.label} :</Col>
                                                                                <Col lg="7">{item.value}</Col>
                                                                            </Row>
                                                                        </Col>
                                                                    )
                                                                })}
                                                            </Row>
                                                        )
                                                    })}
                                                </Col>
                                            </Row>
                                        }
                                        {/*}*/}
                                    </Card.Body>
                                </Card>
                            </Col>
                        </Row>
                    }

                    {/*Footer*/}
                    {width > breakpoint ?
                        !this.state.isLoading &&
                        JSONReportList.ListDataItem != undefined &&
                        JSONReportList.ListDataItem.length != 0 &&
                        JSONReportList.ListDataItem.map((ListItem: ModelsWorkflowInbox.DataItem, idList) => {
                            return (
                                <Row className="mt-3" key={idList}>
                                    <Col>
                                        <div style={{ overflowX: "scroll" }}>
                                            <table className="table table-bordered" style={{ width: 'max-content' }}>
                                                <thead>
                                                    {/*<tr className="table-active d-flex">*/}
                                                    <tr className="table-active" style={tableFooter}>
                                                        {ListItem.tableheader.map((data: ModelsWorkflowInbox.tableheader, idx) => {
                                                            return (
                                                                <th
                                                                    key={idx}
                                                                    style={{ width: data.width != "" ? data.width + "px" : "auto", textAlign: data.align as align_ }}
                                                                    align={data.align as align_}>
                                                                    {data.label}
                                                                </th>
                                                                //<th className="col-lg-6" key={idx}>{data.label}</th>
                                                            )
                                                        })}
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {ListItem.tabledata.map((data: ModelsWorkflowInbox.tabledata, idx) => {
                                                        return (
                                                            //<tr key={idx} className="d-flex">
                                                            <tr key={idx}>
                                                                {data.row.map((item: string, idx2) => {
                                                                    return (
                                                                        <td key={idx2} style={{ textAlign: ListItem.tableheader[idx2].align as align_ }}>{item}</td>
                                                                        //<td className="col-lg-6" key={idx2}>{item}</td>
                                                                    )
                                                                })}
                                                            </tr>
                                                        )
                                                    })}
                                                </tbody>
                                            </table>
                                        </div>
                                    </Col>
                                </Row>
                            )
                        })

                        : <ReportInformationMobileTable
                            isLoading={this.state.isLoading}
                            listDataItem={JSONReportList.ListDataItem} />
                    }

                    <Modal isOpen={this.state.messageModal} toggle={() => this.handleMessage()} size="lg" backdrop="static" keyboard={false}>
                        <ModalHeader toggle={() => this.handleMessage()}>Message</ModalHeader>
                        <ModalBody>
                            <Row>
                                <Col>
                                    <Form.Group controlId="exampleForm.ControlTextarea1">
                                        <Form.Control as="textarea" rows={5} onChange={this.onchangeMessage} />
                                    </Form.Group>
                                </Col>
                            </Row>
                        </ModalBody>
                        <ModalFooter>
                            {/*  <Button color="primary" onClick={() => this.handleClose(this.state.wfId, "", "", "")}>Save</Button>*/}
                            <Button variant="info" onClick={() => this.onsuccessApproveReject(workflowValueUse.docNo, workflowValueUse.userId, this.state.formData['wfType'])}>Confirm</Button>
                            <Button variant="secondary" onClick={() => this.handleMessage()}>Cancel</Button>
                        </ModalFooter>
                    </Modal>

                    {/*success modal*/}
                    <Modal isOpen={this.state.successModal} toggle={() => this.handleSuccessModal()} size="lg" backdrop="static" keyboard={false}>
                        <ModalHeader toggle={() => this.handleSuccessModal()}>{this.state.successModalHeader}</ModalHeader>
                        <ModalBody scrollable>
                            <Row>
                                <Col>
                                    {this.props.isLoadApproveReject && this.props.statusResponseApproveReject == 200 && this.props.responseApproveReject.length != 0 && this.props.responseApproveReject['result_list'].map((item: ModelApproveRejectWorkflow.ApproveRejectWorkflowResponse, idx) => {
                                        console.log(item)
                                        return (
                                            <Card className="shadow-sm p-1 mb-1 bg-white rounded">
                                                <CardBody>
                                                    <Row>
                                                        <Col lg={2}>
                                                            <div style={{ alignContent: 'center', verticalAlign: 'middle', textAlign: 'center' }}>
                                                                {item.status == "SUCCESS" ? <React.Fragment><FaCheckCircle size={50} color="green" /></React.Fragment> : <React.Fragment><FaTimesCircle size={80} color="red" style={{ paddingTop: '30px' }} /></React.Fragment>}
                                                            </div>
                                                        </Col>
                                                        <Col lg={10}>
                                                            <Table className="table table-borderless table-hover" size="sm">
                                                                <tr className="require-field" style={{ width: '100%' }}>
                                                                    <td className="font-weight-bold" style={{ width: '30%' }}>Document NO. :</td>
                                                                    <td style={{ width: '70%' }}>{item.docNo}</td>
                                                                </tr>
                                                                <tr className="require-field" style={{ width: '100%' }}>
                                                                    <td className="font-weight-bold" style={{ width: '30%' }}>Status :</td>
                                                                    <td style={{ width: '70%' }}>{item.status}</td>
                                                                </tr>
                                                                <tr className="require-field" style={{ width: '100%' }}>
                                                                    <td className="font-weight-bold" style={{ width: '30%' }}>Message :</td>
                                                                    <td style={{ width: '70%' }}>{item.message}</td>
                                                                </tr>
                                                                {item.status != 'SUCCESS' ?
                                                                    <tr className="require-field" style={{ width: '100%' }}>
                                                                        <td className="font-weight-bold" style={{ width: '30%' }}>Error Message :</td>
                                                                        <td style={{ width: '70%' }}>{item.errorMessage}</td>
                                                                    </tr>
                                                                    : ''}
                                                            </Table>
                                                        </Col>
                                                    </Row>
                                                </CardBody>
                                            </Card>
                                        )
                                    })}
                                </Col>
                            </Row>
                        </ModalBody>
                        <ModalFooter>
                            <Button variant="danger" onClick={() => this.handleSuccessModal()}>Close</Button>
                        </ModalFooter>
                    </Modal>

                    <Modal isOpen={this.state.loginModal} toggle={() => this.handleLoginModal()} size="xl" backdrop="static" keyboard={false}>
                        <ModalBody>
                            <SignInComponent AnonymousMode={this.state.loginModal} blindLogin={this.blindLogin.bind(this)} />
                            <iframe style={{ WebkitWritingMode: "vertical-lr", overflow: "none" }} src={this.state.srcLogin} name="myIframe" height="70%" width="100%" title="Iframe Example" frameBorder="0" ref="iframe"></iframe>
                        </ModalBody>
                    </Modal>
                </Container>
            </div>
        )
    }
}

export default connect((state: ApplicationState) => ({
    ...state.searchworkflowInbox,
    ...state.approveRejectWorkflow
}), ({
    ...SearchWorkflowInboxStore.actionCreators,
    ...ApproveRejectWorkflowStore.actionCreators
}))(ReportInformationMobile);