import React from 'react';
import { Button, Container, Row, Col, Form } from 'react-bootstrap';
import { Input } from 'reactstrap';
import WorkflowConfigAdminViewModel from './WorkflowConfigAdmin.viewmodel';
import { FaPlus, FaSearch } from 'react-icons/fa';
import WorkfloConfigAdminTable from './components/WorkflowConfigAdminTable';
import AddWorkflowConfigAdmin from './components/AddWorkflowConfigAdmin';

function WorkflowConfigAdmin() {
    const {
        modeAddUser,
        companyMaster,
        userAdminResponse,
        selectdAllCompany,
        userMasterEmployee,

        mappingCompany,
        handleAddAdminUser,
        handleAddUserAdmin,
        setSelectdAllCompany,
        handleChangeSearchHelp,
        fetchUserAdminMasterEmployee,
        handleDeleteAdminMasterEmployee
    } = WorkflowConfigAdminViewModel();

    return (
        <Container>
            {modeAddUser === false ? (
                <React.Fragment>
                    <Row>
                        <Col className="import-export-actions">
                            <div
                                className="trapezoid-head"
                                style={{ width: '200px' }}
                            >
                                <span>Admin Master Employee</span>
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <div className="mat-box">
                                <div
                                    className="trapezoid trapezoid-purple"
                                    style={{
                                        width: '200px',
                                        display: 'inline-block'
                                    }}
                                >
                                    <span>
                                        <i
                                            className="fa fa-search"
                                            aria-hidden="true"
                                        ></i>
                                        Search menu
                                        <div
                                            style={{
                                                display: 'inline-block',
                                                float: 'right'
                                            }}
                                        ></div>
                                    </span>
                                </div>
                                <div className="mat-box mb-5 rounded bg-white p-3 shadow">
                                    <Row>
                                        <Col>
                                            <div className="mt-2">
                                                <Row>
                                                    <Col
                                                        xs={12}
                                                        md={3}
                                                    >
                                                        <Form.Group
                                                            className="mb-3"
                                                            controlId="formBasicEmail"
                                                        >
                                                            <Form.Label>
                                                                Company Name :
                                                            </Form.Label>
                                                            <div>
                                                                <input
                                                                    type="checkbox"
                                                                    checked={selectdAllCompany}
                                                                    onChange={e => {
                                                                        e.persist();
                                                                        setSelectdAllCompany(prev => !prev)
                                                                        handleChangeSearchHelp(
                                                                            'company',
                                                                            ""
                                                                        );
                                                                    }}
                                                                />
                                                                <span className="ml-2">
                                                                    เลือกทั้งหมด
                                                                </span>
                                                            </div>
                                                            <Input
                                                                type="select"
                                                                onChange={e => {
                                                                    e.persist();
                                                                    handleChangeSearchHelp(
                                                                        'company',
                                                                        e.target
                                                                            .value
                                                                    );
                                                                }}
                                                                disabled={selectdAllCompany}
                                                            >
                                                                <option
                                                                    selected
                                                                >
                                                                    Please
                                                                    choose
                                                                </option>
                                                                {companyMaster &&
                                                                    companyMaster.map(
                                                                        (
                                                                            value,
                                                                            index
                                                                        ) => (
                                                                            <option
                                                                                key={
                                                                                    index
                                                                                }
                                                                                value={
                                                                                    value.sid
                                                                                }
                                                                            >
                                                                                {
                                                                                    value.company_name
                                                                                }
                                                                            </option>
                                                                        )
                                                                    )}
                                                            </Input>
                                                        </Form.Group>
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col
                                                        xs={12}
                                                        md={2}
                                                    >
                                                        <Form.Group
                                                            className="mb-3"
                                                            controlId="formBasicEmail"
                                                        >
                                                            <Form.Label>
                                                                Role Access:
                                                            </Form.Label>
                                                            <Input
                                                                bsSize="sm"
                                                                onChange={e => {
                                                                    handleChangeSearchHelp(
                                                                        'roleAccess',
                                                                        e.target
                                                                            .value
                                                                    );
                                                                }}
                                                            />
                                                        </Form.Group>
                                                    </Col>
                                                    <Col
                                                        xs={12}
                                                        md={2}
                                                    >
                                                        <Form.Group
                                                            className="mb-3"
                                                            controlId="formBasicEmail"
                                                        >
                                                            <Form.Label>
                                                                Employee ID :
                                                            </Form.Label>
                                                            <Input
                                                                bsSize="sm"
                                                                onChange={e => {
                                                                    handleChangeSearchHelp(
                                                                        'userID',
                                                                        e.target
                                                                            .value
                                                                    );
                                                                }}
                                                            />
                                                        </Form.Group>
                                                    </Col>
                                                    <Col
                                                        xs={12}
                                                        md={2}
                                                    >
                                                        <Form.Group
                                                            className="mb-3"
                                                            controlId="formBasicEmail"
                                                        >
                                                            <Form.Label>
                                                                Employee Name :
                                                            </Form.Label>
                                                            <Input
                                                                bsSize="sm"
                                                                onChange={e => {
                                                                    handleChangeSearchHelp(
                                                                        'employeeName',
                                                                        e.target
                                                                            .value
                                                                    );
                                                                }}
                                                            />
                                                        </Form.Group>
                                                    </Col>
                                                </Row>
                                                <Col
                                                    xs={12}
                                                    md={2}
                                                    className="flex items-center"
                                                >
                                                    <div className="text-center">
                                                        <Button
                                                            variant="outline-info"
                                                            size="sm"
                                                            onClick={
                                                                fetchUserAdminMasterEmployee
                                                            }
                                                        >
                                                            <FaSearch /> Search
                                                        </Button>
                                                    </div>
                                                </Col>
                                            </div>
                                        </Col>
                                    </Row>
                                </div>
                                <Row>
                                    <Col>
                                        <div className="flex items-end justify-end">
                                            <Button
                                                variant="outline-success"
                                                size="sm"
                                                onClick={handleAddAdminUser}
                                            >
                                                <FaPlus /> Add Admin
                                            </Button>
                                        </div>
                                        <WorkfloConfigAdminTable
                                            userAdminMasterEmployee={
                                                userAdminResponse!
                                            }
                                            deleteUserAdmin={
                                                handleDeleteAdminMasterEmployee
                                            }
                                            mappingCompany={mappingCompany}
                                        />
                                    </Col>
                                </Row>
                            </div>
                        </Col>
                    </Row>
                </React.Fragment>
            ) : (
                <AddWorkflowConfigAdmin
                    userMasterEmployee={userMasterEmployee!}
                    companyMapping={companyMaster!}
                    handleAddUser={handleAddUserAdmin}
                />
            )}
        </Container>
    );
}

export default WorkflowConfigAdmin;
