import * as React from "react";
import { Row, Col } from "react-bootstrap";
import { Container } from 'reactstrap';
import RemarkCommentTable from './RemarkCommentTable'
import RemarkAddComment from './RemarkAddComment'

class RemarkWorkflowMaster extends React.Component<any, {}>{

    render() {
        return (
            <Container fluid={true} className="mt-4">
                {/*Under Develop*/}
                <Row>
                    <Col className="overflow-auto">
                        <RemarkCommentTable workflowValue={this.props.workflowValue}/>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <RemarkAddComment workflowValue={this.props.workflowValue}/>
                    </Col>
                </Row>
            </Container>
        )
    }
}
export default RemarkWorkflowMaster;