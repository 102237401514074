import React, { PropsWithChildren } from 'react';
import { twMerge } from 'tailwind-merge';
import { IconType } from 'react-icons';

type OwnProps = {
    icon?: IconType;
};

type Props = React.DetailedHTMLProps<
    React.ButtonHTMLAttributes<HTMLButtonElement>,
    HTMLButtonElement
> &
    OwnProps;

function Button(props: PropsWithChildren<Props>) {
    const { icon: Icon, className, children, ...buttonProps } = props;

    return (
        <button
            {...buttonProps}
            className={twMerge(
                'inline-flex h-9 items-center gap-1.5 rounded-[50px] border border-zinc-400 px-4 py-1.5 text-neutral-800 disabled:cursor-not-allowed disabled:opacity-80',
                className
            )}
        >
            {Icon && <Icon size={16} />}
            {children}
        </button>
    );
}

export default Button;
