import { Action, Reducer } from 'redux';
import { AppThunkAction } from '..';
// models
import * as Models from '../../models/workflow/WorkflowInbox'

interface BeforeLoadWorkflowReport {
    type: 'LOAD_WORKFLOWREPORT'
}
interface LoadWorkflowReport {
    type: 'LOADWORKFLOWREPORT'
    response: Models.ResponseWorkflowInbox
    status: number,
    statusMessage: String
}
interface FailLoadWorkflowReport {
    type: 'LOADWORKFLOWREPORTFAIL'
    status: number,
    statusMessage: any
}

interface ClearLoadWorkflowReport {
    type: 'CLEARLOADWORKFLOWREPORT'
    status: number,
    statusMessage: any
}

type KnownAction = LoadWorkflowReport | FailLoadWorkflowReport | ClearLoadWorkflowReport | BeforeLoadWorkflowReport

export const actionCreators = {
    requestWorkflowReportAdmin: (p: boolean, method: String, user_id: string, token: string = "", page: string, page_size: string, wf_type: string, sids: string[], searchReq: Models.searchValue, wfStatus: string[]): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        console.log('call actionCreators : requestWorkflowReportAdmin')
        if (appState && appState.workflowDashboard && p && method == 'POST') {

            var sid = ""
            sids.forEach(element => {
                sid += "&app_id=" + element
            });

            var wfstatus = ""
            wfStatus.forEach(element => {
                wfstatus += "&wf_status=" + element
            });
            var accessToken = localStorage.getItem('WF_APPLICATION')
            if (token) {
                var jObj = { access_token: token }
                accessToken = JSON.stringify(jObj)
            }

            var createdOnTo = searchReq.createByTo
            //if (createdOnTo) {
            //    createdOnTo += '235900';
            //}
            var updatedOnTo = searchReq.updatedTo
            if (updatedOnTo) {
                updatedOnTo += '235900';
            }

            var createdfrom = searchReq.createBy
            //if (createdfrom) {
            //    createdfrom += '000001'
            //}

            var updatefrom = searchReq.updatedFrom
            if (updatefrom) {
                updatefrom += '000000'
            }
            console.log(searchReq);
            if (searchReq.userName != "") {
                if (searchReq.userName == "*") {
                    user_id = "";
                } else {
                    user_id = searchReq.userName.toString()
                }
            }
            fetch('/v4/workflow/report-admin?user_id=' + user_id + '&doc_no=' + searchReq.wfNo + '&doc_year=' + searchReq.fiscalYear + '&doc_date_from=' + createdfrom + '&doc_date_to=' + createdOnTo + '&updated_from=' + updatefrom + '&updated_to=' + updatedOnTo + '&page=' + page + '&page_size=' + page_size + '&wf_type=' + wf_type + sid + wfstatus + '&requester_id=' + searchReq.requester_id + '&amount_from=' + searchReq.docAmount + '&amount_to=' + searchReq.docAmountTo, {
                method: 'GET',
                headers: {
                    'Authorization': 'Bearer ' + accessToken,
                },
            })
                .then((response) => {
                    console.log("requestWorkflowReportAdmin")
                    // console.log(response)
                    return response
                })
                .then((data) => {
                    //console.log(data)
                    if (!data.ok) {
                        data.json().then(err => {
                            //console.log(err.message_res)
                            console.log('errorrrr')
                            console.log(err)
                            dispatch({ type: 'LOADWORKFLOWREPORTFAIL', status: 204, statusMessage: err.message });
                        })
                    } else {
                        data.json().then(data => {
                            //console.log(data)
                            var byDate
                            if (data.total_count > 0) {
                                byDate = data.result_list.slice(0);
                                byDate.sort(function (a, b) {
                                    var x, y;
                                    if (b.docDate != null) {
                                        x = b.docDate.toLowerCase();
                                    }
                                    if (a.docDate != null) {
                                        y = a.docDate.toLowerCase();
                                    }
                                    return x < y ? -1 : x > y ? 1 : 0;
                                });
                                data.result_list = byDate
                            } else {
                                data = undefined
                            }

                            dispatch({ type: 'LOADWORKFLOWREPORT', response: data as Models.ResponseWorkflowInbox, status: 200, statusMessage: '' });
                        })
                    }
                })

            dispatch({ type: 'LOAD_WORKFLOWREPORT' });

        } else if (method == "CLEAR") {
            console.log("dispatch : CLEARLOADWORKFLOWREPORT")
            dispatch({ type: 'CLEARLOADWORKFLOWREPORT', status: 400, statusMessage: "" });
        }
    },

    requestWorkflowReportSumary: (p: boolean, method: String, user_id: string, token: string = "", page: string, page_size: string, wf_type: string, sids: string[], searchReq: Models.searchValue, wfStatus: string[]): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        console.log('call actionCreators : requestWorkflowReportSumary')
        if (appState && appState.workflowDashboard && p && method == 'POST') {

            var sid = ""
            sids.forEach(element => {
                sid += "&app_id=" + element
            });

            var wfstatus = ""
            wfStatus.forEach(element => {
                wfstatus += "&wf_status=" + element
            });
            var accessToken = localStorage.getItem('WF_APPLICATION')
            if (token) {
                var jObj = { access_token: token }
                accessToken = JSON.stringify(jObj)
            }
            console.log(wfstatus)
            var createdOnTo = searchReq.createByTo
            if (createdOnTo) {
                createdOnTo = createdOnTo.replace(/\d{6}$/, '999999');
            }
            var updatedOnTo = searchReq.updatedTo
            if (updatedOnTo) {
                updatedOnTo = updatedOnTo.replace(/\d{6}$/, '999999');
            }
            console.log(searchReq);
            if (searchReq.userName != "") {
                if (searchReq.userName == "*") {
                    user_id = "";
                } else {
                    user_id = searchReq.userName.toString()
                }
            }
            fetch('/v4/workflow/summay/report?doc_no=' + searchReq.wfNo + '&doc_year=' + searchReq.fiscalYear + '&doc_date_from=' + searchReq.createBy + '&doc_date_to=' + createdOnTo + '&page=' + page + '&page_size=' + page_size + '&wf_type=' + wf_type + sid + wfstatus + '&requester_id=' + searchReq.requester_id + '&amount_from=' + searchReq.docAmount + '&amount_to=' + searchReq.docAmountTo, {
                method: 'GET',
                headers: {
                    'Authorization': 'Bearer ' + accessToken,
                },
            })
                .then((response) => {
                    console.log("requestWorkflowReportAdmin")
                    // console.log(response)
                    return response
                })
                .then((data) => {
                    //console.log(data)
                    if (!data.ok) {
                        data.json().then(err => {
                            //console.log(err.message_res)
                            console.log('errorrrr')
                            console.log(err)
                            dispatch({ type: 'LOADWORKFLOWREPORTFAIL', status: 204, statusMessage: err.message });
                        })
                    } else {
                        data.json().then(data => {
                            //console.log(data)
                            var byDate
                            if (data.total_count > 0) {
                                byDate = data.result_list.slice(0);
                                byDate.sort(function (a, b) {
                                    var x, y;
                                    if (b.docDate != null) {
                                        x = b.docDate.toLowerCase();
                                    }
                                    if (a.docDate != null) {
                                        y = a.docDate.toLowerCase();
                                    }
                                    return x < y ? -1 : x > y ? 1 : 0;
                                });
                                data.result_list = byDate
                            } else {
                                data = undefined
                            }

                            dispatch({ type: 'LOADWORKFLOWREPORT', response: data as Models.ResponseWorkflowInbox, status: 200, statusMessage: '' });
                        })
                    }
                })

            dispatch({ type: 'LOAD_WORKFLOWREPORT' });

        } else if (method == "CLEAR") {
            console.log("dispatch : CLEARLOADWORKFLOWREPORT")
            dispatch({ type: 'CLEARLOADWORKFLOWREPORT', status: 400, statusMessage: "" });
        }
    }
}

const unloadedState: Models.GetWorkflowInboxState = { isLoadWorkflowInbox: false };

export const reducer: Reducer<Models.GetWorkflowInboxState> = (state: Models.GetWorkflowInboxState | undefined, incomingAction: Action): Models.GetWorkflowInboxState => {
    if (state == undefined) {
        return unloadedState;
    }
    const action = incomingAction as KnownAction;
    switch (action.type) {
        case 'LOAD_WORKFLOWREPORT':
            console.log("LOAD_WORKFLOWREPORT")
            return {
                isLoadWorkflowInbox: false,
            }

        case 'LOADWORKFLOWREPORT':
            console.log("LOADWORKFLOWREPORT")
            return {
                isLoadWorkflowInbox: true,
                responseWorkflowInbox: action.response,
                statusWorkflowInbox: action.status
            }

        case 'LOADWORKFLOWREPORTFAIL':
            return {
                isLoadWorkflowInbox: true,
                messageWorkflowInbox: action.statusMessage,
                statusWorkflowInbox: action.status
            }
        case 'CLEARLOADWORKFLOWREPORT':
            return {
                isLoadWorkflowInbox: false,
                messageWorkflowInbox: "",
                statusWorkflowInbox: 400
            }
        default: return state;
    }
}