import * as React from "react";
import { connect } from "react-redux";
import { Button, Row, Col, Table, Form } from "react-bootstrap";
import { FaPlusCircle, FaTrashAlt, FaFileAlt } from 'react-icons/fa';
import Swal from 'sweetalert2'
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';

import Search from '../autocomplete/AutoCompleteUser'
import * as ModelsCreateCustom from '../../models/workflow/CreateCustom'

import { ApplicationState } from "../../store";
import * as createCustomModels from '../../models/workflow/CreateCustom'
import * as createCustomStore from '../../store/workflow/CreateCustom'
import QueryString from "query-string"
import { onClickFile } from '../../modules/Common'

import * as WorkflowConfigStore from '../../store/workflow/WorkflowConfig'
import * as WorkflowConfigModels from '../../models/workflow/WorkflowConfig'

import * as WfWithWfTypeStore from '../../store/workflow/CreateWorkflowWithWfType'
import * as WfWithWfTypeModels from '../../models/workflow/CreateWorkflowWithWfType'

import * as AppState from '../../store/AppState'
import * as ModelsAppState from '../../models/AppState'

import * as MasterCompanyStore from '../../store/company/MasterCompany'
import * as MasterCompanyModels from '../../models/company/MasterCompany'

type AppStateProps = ModelsAppState.AppState & typeof AppState.actionCreators
type store = MasterCompanyModels.GetMasterCompanyState & typeof MasterCompanyStore.actionCreators

export interface optionValue {
    value: string
    label: string
}

type createCustomProps = createCustomModels.CreateCustomState & typeof createCustomStore.actionCreators | WorkflowConfigModels.GetWorkflowState & typeof WorkflowConfigStore.actionCreators | WfWithWfTypeModels.CreateWfWithWfTypeState & typeof WfWithWfTypeStore.actionCreators

class CreateWorkflowWithWftype extends React.Component<any, { wf_approve_list_select: Array<ModelsCreateCustom.wf_approve_list_select>, wf_approve_list_detail_select: Array<ModelsCreateCustom.wf_approve_list_detail_select>, getDataIframe: any, messageModal: boolean, wf_header: string, wf_detail: string, wf_approve_list: Array<ModelsCreateCustom.wf_approve_list>, wf_approve_list_detail: Array<ModelsCreateCustom.wf_approve_list_detail>, checksuccess: boolean, app_id: string, option: Array<MasterCompanyModels.MasterCompanyList>, company: any, isreadyWorkflowconfig: boolean, optionWorkflowConfig: Array<optionValue>, DmsUrl: string, wf_type: string },
    createCustomProps & AppStateProps & store> {
    constructor(props: any) {
        super(props);

        this.handlechageAutocomplete = this.handlechageAutocomplete.bind(this)

        this.state = {
            wf_header: "",
            wf_detail: "",
            wf_approve_list: [],
            wf_approve_list_detail: [],
            wf_approve_list_select: [],
            wf_approve_list_detail_select: [],
            checksuccess: false,
            messageModal: false,
            getDataIframe: [],
            app_id: '',
            option: [],
            company: undefined,
            isreadyWorkflowconfig: true,
            optionWorkflowConfig: [],
            wf_type: '',
            DmsUrl: ""
        }
    }

    handleMessage = () => {
        this.setState({ messageModal: !this.state.messageModal })
    }

    handlechangeHeader = (e: { target: { name: any; value: any; }; }) => {
        console.log("wf_header :" + e.target.value)
        this.setState({
            wf_header: e.target.value
        })
    }

    handlechangeDetail = (e: { target: { name: any; value: any; }; }) => {
        console.log("wf_detail :" + e.target.value)
        this.setState({
            wf_detail: e.target.value
        })
    }

    handleChangeAppID = (e: { target: { name: any; value: any; }; }) => {
        console.log("app_id :" + e.target.value)
        this.setState({
            app_id: e.target.value,
            isreadyWorkflowconfig: true,
            optionWorkflowConfig: []
        })
        this.props.requestWorkflowConfig(true, "POST", e.target.value);
    }

    handleAddPosition = () => {
        var blindposi: Array<ModelsCreateCustom.wf_approve_list> = [];
        var blindposiSelect: Array<ModelsCreateCustom.wf_approve_list_select> = [];
        blindposi = this.state.wf_approve_list
        blindposiSelect = this.state.wf_approve_list_select
        var landingsubposi: ModelsCreateCustom.wf_approve_list_detail = {
            wf_id: ""
        }

        var landingsubposiSelect: ModelsCreateCustom.wf_approve_list_detail_select = {
            code: "",
            name: "",
            display: "",
        }

        var landing: ModelsCreateCustom.wf_approve_list = {
            wf_approve_list: [landingsubposi]
        }

        var landingSelect: ModelsCreateCustom.wf_approve_list_select = {
            wf_approve_list_select: [landingsubposiSelect]
        }

        blindposi.push(landing)
        blindposiSelect.push(landingSelect)
        this.setState({ wf_approve_list: blindposi, wf_approve_list_select: blindposiSelect });
    }

    handleAddSubPosition = (idx: number) => {
        var blindsubposi: Array<ModelsCreateCustom.wf_approve_list> = [];
        var blindposiSelect: Array<ModelsCreateCustom.wf_approve_list_select> = [];
        blindsubposi = this.state.wf_approve_list
        blindposiSelect = this.state.wf_approve_list_select
        var landingsub: ModelsCreateCustom.wf_approve_list_detail = {
            wf_id: ""
        }
        var landingsubposiSelect: ModelsCreateCustom.wf_approve_list_detail_select = {
            code: "",
            name: "",
            display: "",
        }

        blindsubposi[idx].wf_approve_list.push(landingsub)
        blindposiSelect[idx].wf_approve_list_select.push(landingsubposiSelect)
        this.setState({ wf_approve_list: blindsubposi, wf_approve_list_select: blindposiSelect })
    }

    handleRemoveSpecificPosition = (idx: number) => {
        var rows = this.state.wf_approve_list
        var rowsSelect = this.state.wf_approve_list_select
        rows.splice(idx, 1)
        rowsSelect.splice(idx, 1)
        this.setState({ wf_approve_list: rows, wf_approve_list_select: rowsSelect })
    }

    handleRemoveSpecificSub = (idx: number, subid: number, check: string) => {
        if (check == "Allow") {
            var rows = this.state.wf_approve_list
            var rowsSelect = this.state.wf_approve_list_select
            rows[idx].wf_approve_list.splice(subid, 1)
            rowsSelect[idx].wf_approve_list_select.splice(subid, 1)
            this.setState({ wf_approve_list: rows, wf_approve_list_select: rowsSelect })

        } else {
            var row = this.state.getDataIframe
            this.state.getDataIframe.splice(idx, 1)
            this.setState({ getDataIframe: row })
        }
    }

    public handlechageAutocomplete(idx: number, subid: number, value: any,) {
        console.log(idx, subid, value[0]['code'])
        const rows = this.state.wf_approve_list;
        const rowsSelect = this.state.wf_approve_list_select;
        rows[idx].wf_approve_list[subid].wf_id = value[0]['code']

        rowsSelect[idx].wf_approve_list_select[subid].code = value[0]['code']
        rowsSelect[idx].wf_approve_list_select[subid].name = value[0]['name']
        rowsSelect[idx].wf_approve_list_select[subid].display = value[0]['display']

        this.setState({
            wf_approve_list: rows, wf_approve_list_select: rowsSelect
        });
        console.log(this.state.wf_approve_list_detail)
    }

    onsave = () => {
        document.getElementsByClassName('Loader__background')[0].className = "Loader__background show";
        this.props.requestCreateCustom(true, "POST", this.state.wf_approve_list, this.state.wf_header, this.state.wf_detail, this.state.getDataIframe, this.state.app_id)
        this.setState({
            checksuccess: true
        })
    }

    componentDidMount() {
        this.props.updateUrl(this.props.location.pathname)
        const { token, userid } = QueryString.parse(this.props.location.search);
        //console.log('ccwf token', token);
        //console.log('ccwf userid', userid);
        if (token != null) {
            var data = {
                "user_id": userid,
                "access_token": token
            };
            localStorage.setItem('WF_APPLICATION', JSON.stringify(data));
        }

        fetch("/v1/env")
            .then(res => res.json())
            .then(
                (result) => {
                    console.log(result.ScgDmsUrl)
                    this.setState({
                        DmsUrl: result.dms_url
                    });
                },
                (error) => {
                }
            )
    }

    componentWillUnmount() {
        this.props.requestCreateCustom(true, "CLEAR", this.state.wf_approve_list, this.state.wf_header, this.state.wf_detail, "")
    }

    componentDidUpdate(nextProps: any, nextState: any) {
        const { statusCreateCustom, messageCreateCustom, isLoadCreateCustom, SearchWfWithWfType } = this.props
        var company = this.props.company
        if (company != this.state.company) {
            console.log(nextProps.company)
            if (company) {
                this.props.requestWorkflowConfig(true, "CLEAR", company);
                this.setState({
                    isreadyWorkflowconfig: true,
                    optionWorkflowConfig: []
                })
                //this.props.requestWorkflowConfig(true, "POST", company);
            }

            this.setState({
                company: company,
            });
        }

        if (this.props.GetMasterCompany.isLoadGetMasterCompany && this.props.GetMasterCompany.statusGetMasterCompany == 200 && this.state.option.length == 0) {
            this.setState({
                option: this.props.GetMasterCompany.responseGetMasterCompany['result_list']
            })
        }

        if (this.state.checksuccess && statusCreateCustom == 200 && isLoadCreateCustom) {
            document.getElementsByClassName('Loader__background')[0].className = "Loader__background hide";
            this.setState({
                checksuccess: false
            })
            Swal.fire({
                title: 'สำเร็จ!',
                text: 'เพิ่มข้อมูลสำเร็จ',
                icon: 'success',
                showCancelButton: false,
                confirmButtonColor: '#3085d6',
                confirmButtonText: 'ยืนยัน!'
            }).then((result) => {
                if (result.value) {
                    console.log('redirect!!')
                    //return <Link to="/workflowinbox" />;
                    //window.location.href = "/workflowinbox"
                    this.props.requestCreateCustom(true, "CLEAR", this.state.wf_approve_list, this.state.wf_header, this.state.wf_detail, "")
                    this.props.history.push('/workflowinbox')
                }
            })
        } else if (this.state.checksuccess && statusCreateCustom == 204 && isLoadCreateCustom) {
            document.getElementsByClassName('Loader__background')[0].className = "Loader__background hide";
            this.setState({
                checksuccess: false
            })
            var msg = JSON.parse(messageCreateCustom);
            Swal.fire({
                title: 'ไม่สำเร็จ!',
                text: msg.message,
                icon: 'error',
                showCancelButton: false,
                confirmButtonColor: '#3085d6',
                confirmButtonText: 'ยืนยัน!'
            }).then((result) => {
                if (result.value) {
                    this.props.requestCreateCustom(true, "CLEAR", this.state.wf_approve_list, this.state.wf_header, this.state.wf_detail, "")
                }
            })
        }

        if (this.state.isreadyWorkflowconfig && this.props.isLoadWorkflowMaster && this.props.statusWorkflowMaste == 200 && this.props.responseWorkflowMaste['result_list'] != undefined) {
            if (this.props.responseWorkflowMaste['total_cout'] == 0) {
                this.setState({
                    optionWorkflowConfig: [],
                    isreadyWorkflowconfig: false
                })
            } else {
                var dataResult = this.props.responseWorkflowMaste['result_list']
                var dataArr: any = []
                for (let i = 0; i < dataResult.length; i++) {
                    var dataReq: any = {}
                    dataReq['value'] = dataResult[i]['wfid']
                    dataReq['label'] = dataResult[i]['wfid'] + ' : ' + dataResult[i]['wfname']
                    dataArr.push(dataReq)
                }
                this.setState({
                    optionWorkflowConfig: dataArr,
                    isreadyWorkflowconfig: false
                })
                console.log(dataArr)
            }
        }

        if (SearchWfWithWfType.isLoadSearchWfWithWfType && SearchWfWithWfType.statusSearchWfWithWfType == 200) {
            console.log(SearchWfWithWfType.responseSearchWfWithWfType)
            document.getElementsByClassName('Loader__background')[0].className = "Loader__background hide";
            this.props.requestSearchWfWithWftype(true, 'CLEAR', '')

        } else if (SearchWfWithWfType.isLoadSearchWfWithWfType && SearchWfWithWfType.statusSearchWfWithWfType != 200) {
            document.getElementsByClassName('Loader__background')[0].className = "Loader__background hide";
            var msg = SearchWfWithWfType.messageSearchWfWithWfType
            Swal.fire({
                title: 'ค้นหาไม่สำเร็จ!',
                text: msg.message,
                icon: 'error',
                showCancelButton: false,
                confirmButtonColor: '#3085d6',
                confirmButtonText: 'ยืนยัน!'
            }).then((result) => {
                if (result.value) {
                    this.props.requestSearchWfWithWftype(true, 'CLEAR', '')
                }
            })
        }

    }

    handleChangeAll = (evt) => {
        const myFrame = document.getElementById("myFrame") as HTMLIFrameElement;
        if (myFrame != null) {
            //myFrame!.contentWindow!.postMessage('Iframe', 'https://scg-dms.sonarinno.com')
            myFrame!.contentWindow!.postMessage('Iframe', this.state.DmsUrl)
            window.addEventListener("message", (event) => {
                console.log(window.location.origin)
                if (event.origin != window.location.origin) {
                    this.formatDataIframe(event.data)
                }
            }, false);
            this.handleMessage()
        }
    }

    formatDataIframe(value) {
        var objData: any = []
        for (let i = 0; i < value.length; i++) {
            let data = {}
            data['id'] = value[i].id
            data['file_name'] = value[i].file_name
            data['file_path'] = value[i].file_path
            objData.push(data)
        }
        this.setState({ getDataIframe: objData })
    }

    handleChangeWfType = (e: { target: { name: any; value: any; }; }) => {
        console.log(e.target.value)
        if (this.state.app_id != '') {
            this.setState({
                wf_type: e.target.value
            })
            this.props.requestSearchWfWithWftype(true, 'POST', e.target.value)
            document.getElementsByClassName('Loader__background')[0].className = "Loader__background show";
        }
    }

    handleFocusWfType = () => {
        if (this.state.app_id == '') {
            Swal.fire({
                title: 'โปรดเลือก SID ก่อน!',
                text: 'โปรดเลือก SID ก่อนเลือกผู้อนุมัตื',
                icon: 'warning',
                showCancelButton: false,
                confirmButtonColor: '#3085d6',
                confirmButtonText: 'ยืนยัน!'
            }).then((result) => {
            })
        }
    }

    render() {
        var ls = localStorage.getItem('WF_APPLICATION');
        //var iframeSrc = "https://scg-dms.sonarinno.com/ingress?token="
        var iframeSrc = this.state.DmsUrl + "/ingress?token=";
        if (ls) {
            var j = JSON.parse(ls);
            iframeSrc = iframeSrc + j.access_token + '&_=' + Date.now();
        }
        return (
            <div>
                <div className="master-data-content">
                    <div className='trapezoid-head' style={{ width: '300px' }}>
                        <span>
                            Create Workflow With Workflow Type
                        </span>
                    </div>
                </div>
                <div className="mat-box">
                    <Row>
                        <Col>
                            <div className="container">
                                <Row>
                                    <Col>
                                        <Form.Group>
                                            {/*<Form.Label><h5>รายละเอียด</h5></Form.Label>*/}
                                            <div className='trapezoid trapezoid-purple' style={{ width: '100px' }}>
                                                <span>
                                                    SID
                                                </span>
                                            </div>
                                            <Form.Control as="select" onChange={this.handleChangeAppID} defaultValue={this.state.app_id}>
                                                <option value="" hidden selected>โปรดเลือก..</option>
                                                {this.state.option.map((data, index) => {
                                                    return (
                                                        <option key={index} value={data.sid}>{data.company_name}</option>
                                                    )
                                                })}
                                            </Form.Control>
                                        </Form.Group>
                                    </Col>
                                    <Col>
                                        <Form.Group>
                                            {/*<Form.Label><h5>รายละเอียด</h5></Form.Label>*/}
                                            <div className='trapezoid trapezoid-purple' style={{ width: '200px' }}>
                                                <span>
                                                    Workflow Type
                                                </span>
                                            </div>
                                            <Form.Control as="select" onChange={this.handleChangeWfType} onClick={this.handleFocusWfType} defaultValue={this.state.wf_type} >
                                                <option value="" hidden selected>โปรดเลือก SID ก่อนจะเลือก Workflow Type</option>
                                                {this.props.isLoadWorkflowMaster && this.props.responseWorkflowMaste['total_count'] == 0 &&
                                                    <option value={undefined} className="text-center">No Data..</option>
                                                }
                                                {!this.state.isreadyWorkflowconfig && this.state.optionWorkflowConfig.map((data, index) => {
                                                    return (
                                                        <option key={index} value={data.value}>{data.label}</option>
                                                    )
                                                })}
                                            </Form.Control>
                                        </Form.Group>
                                    </Col>
                                </Row>

                                <Form.Group>
                                    {/*<Form.Label><h5>หัวเรื่อง</h5></Form.Label>*/}
                                    <div className='trapezoid trapezoid-purple' style={{ width: '100px' }}>
                                        <span>
                                            หัวเรื่อง
                                        </span>
                                    </div>
                                    <Form.Control type="text" placeholder="" name='' onChange={this.handlechangeHeader} />
                                </Form.Group>

                                <Form.Group>
                                    {/*<Form.Label><h5>รายละเอียด</h5></Form.Label>*/}
                                    <div className='trapezoid trapezoid-purple' style={{ width: '100px' }}>
                                        <span>
                                            รายละเอียด
                                        </span>
                                    </div>
                                    <Form.Control type="text" as="textarea" placeholder="" name='' rows={3} onChange={this.handlechangeDetail} />
                                </Form.Group>

                                <div className='trapezoid trapezoid-purple' style={{ width: '100px' }}>
                                    <span>
                                        ผู้อนุมัติ
                                    </span>
                                </div>
                                <Table className="custom-table table-hover" size="sm">
                                    <thead>
                                        <tr className='text-center' style={{ width: '100%' }}>
                                            <th style={{ width: '5%' }}>#</th>
                                            <th style={{ width: '5%' }}>Step</th>
                                            <th style={{ width: '90%' }}></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {this.state.wf_approve_list.map((item: ModelsCreateCustom.wf_approve_list, idx) => (
                                            <tr id="addr0" key={idx}>
                                                <td className='text-center'><Button className="mx-2" variant="outline-danger" size="sm" onClick={() => this.handleRemoveSpecificPosition(idx)}><FaTrashAlt /></Button></td>
                                                <td className='text-center'>{idx + 1}</td>
                                                <td className='text-center' colSpan={2}>
                                                    <Table>
                                                        <thead>
                                                            <tr className='text-center' style={{ width: '100%' }}>
                                                                <th style={{ width: '5%' }}>#</th>
                                                                <th style={{ width: '5%' }}>No.</th>
                                                                <th style={{ width: '90%' }}>User</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {this.state.wf_approve_list[idx].wf_approve_list.map((subitem: ModelsCreateCustom.wf_approve_list_detail, subid) => (
                                                                <tr id="addr2" key={subid}>
                                                                    <td style={{ width: '5%' }}><Button className="mx-2" variant="outline-danger" size="sm" onClick={() => this.handleRemoveSpecificSub(idx, subid, "Allow")}><FaTrashAlt /></Button></td>
                                                                    <td style={{ width: '5%' }}>{subid + 1}</td>
                                                                    <td style={{ width: '90%' }}><Search valueSearch={this.state.wf_approve_list_select[idx].wf_approve_list_select[subid]} handlechageAutocomplete={this.handlechageAutocomplete.bind(this, idx, subid)} app_id={this.state.app_id} /></td>
                                                                </tr>
                                                            ))}
                                                            <tr>
                                                                <td className='text-center'><Button className="mx-2" variant="outline-info" size="sm" onClick={() => this.handleAddSubPosition(idx)}><FaPlusCircle /></Button></td>
                                                                <td colSpan={2}></td>
                                                            </tr>
                                                        </tbody>
                                                    </Table>

                                                </td>
                                            </tr>
                                        ))}
                                        <tr>
                                            <td className='text-center'><Button className="mx-2" variant="outline-info" size="sm" onClick={this.handleAddPosition}><FaPlusCircle /></Button></td>
                                            <td></td>
                                            <td></td>
                                        </tr>
                                    </tbody>
                                </Table>
                                {/*<h4>เอกสารแนบ</h4>*/}
                                <div className='trapezoid trapezoid-purple' style={{ width: '130px' }}>
                                    <span>
                                        เอกสารแนบ
                                    </span>
                                </div>
                                <Table className="custom-table" hover size="sm">
                                    <thead>
                                        <tr style={{ width: '100%' }}>
                                            <th className="th-center" style={{ width: '10%' }}>No.</th>
                                            <th style={{ width: '80%', textAlignLast: "left" }}>ชื่อ File</th>
                                            <th style={{ width: '10%' }}></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {this.state.getDataIframe.map((item, id) => (
                                            <tr key={id}>
                                                <td style={{ textAlign: "center" }}>{id + 1}</td>
                                                <td style={{ cursor: "pointer" }}> <a href="javascript:void(0);" data-filename={item.file_name} data-filepath={item.file_path} onClick={onClickFile}><FaFileAlt />&nbsp;{item.file_name}</a></td>
                                                <td style={{ textAlign: "center" }}><Button className="mx-2" variant="outline-danger" size="sm" onClick={() => this.handleRemoveSpecificSub(id, id, "File")}><FaTrashAlt /></Button></td>
                                            </tr>
                                        ))}
                                        <tr>
                                            <td style={{ textAlign: "center" }}><Button className="mx-2" variant="outline-info" size="sm" onClick={this.handleMessage}><FaPlusCircle /></Button></td>
                                            <td></td>
                                            <td></td>
                                        </tr>
                                    </tbody>
                                </Table>
                                <div>
                                    <Button type="button" style={{ float: "right" }} className="btn btn-outline-primary" onClick={this.onsave} >ขออนุมัติ</Button>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </div>
                <Modal className="modal-dialog-scrollable" isOpen={this.state.messageModal} toggle={() => this.handleMessage()} size="lg" backdrop="static" keyboard={false} style={{ maxWidth: '1920px', width: '98%', height: '90%' }}>
                    <ModalHeader toggle={() => this.handleMessage()}>Select File</ModalHeader>
                    <ModalBody>

                        {/*<div style={{ height: "100%", width: "100%" }} dangerouslySetInnerHTML={{ __html: myContent }} />;*/}
                        <iframe src={iframeSrc} id="myFrame" width="100%" height="100%" frameBorder="0" ref="iframe" >
                        </iframe>

                    </ModalBody>
                    <ModalFooter>
                        <Button variant="outline-info" onClick={this.handleChangeAll}>Confirm</Button>
                        <Button variant="outline-secondary" onClick={() => this.handleMessage()}>Cancel</Button>
                    </ModalFooter>
                </Modal>
            </div>
        )
    }
}

export default connect((state: ApplicationState) => ({ ...state.appState, ...state.createCustom, ...state.workflowConfig, ...state.WfWithWfType, ...state.MasterCompany }), ({ ...createCustomStore.actionCreators, ...WorkflowConfigStore.actionCreators, ...WfWithWfTypeStore.actionCreators, ...AppState.actionCreators }))(CreateWorkflowWithWftype)
